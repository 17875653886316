import { coralBloks } from '@krakentech/coral-storyblok';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { FC } from 'react';

import { Anchor } from '@/components/storyblok/bloks/Anchor';
import { BannerImage } from '@/components/storyblok/bloks/BannerImage';
import { Button } from '@/components/storyblok/bloks/Button';
import { CampaignButton } from '@/components/storyblok/bloks/campaign-components/CampaignButton';
import { CampaignForm } from '@/components/storyblok/bloks/campaign-components/CampaignForm';
import { CampaignVisibilityWrapper } from '@/components/storyblok/bloks/campaign-components/CampaignVisibilityWrapper';
import { Chip } from '@/components/storyblok/bloks/Chip';
import { CMSAlert } from '@/components/storyblok/bloks/CMSAlert';
import { CMSCompanyInformation } from '@/components/storyblok/bloks/CMSCompanyInformation';
import { CMSCountDown } from '@/components/storyblok/bloks/CMSCountDown';
import { CMSEmailSubscription } from '@/components/storyblok/bloks/CMSEmailSubscription';
import { CMSGoogleReview } from '@/components/storyblok/bloks/CMSGoogleReview';
import { CMSImage } from '@/components/storyblok/bloks/CMSImage';
import { CMSImageLink } from '@/components/storyblok/bloks/CMSImageLink';
import { CMSLink } from '@/components/storyblok/bloks/CMSLink';
import { CMSPdfLink } from '@/components/storyblok/bloks/CMSPdfLink';
import { CMSQuoteButton } from '@/components/storyblok/bloks/CMSQuoteButton';
import { CMSSeoAnalyzer } from '@/components/storyblok/bloks/CMSSeoAnalyzer';
import { CMSSocialShareButton } from '@/components/storyblok/bloks/CMSSocialShareButton';
import { CMSSparkles } from '@/components/storyblok/bloks/CMSSparkles';
import { CMSTabs } from '@/components/storyblok/bloks/CMSTabs';
import { CollapsibleDetailsRichText } from '@/components/storyblok/bloks/CollapsibleDetailsRichText';
import { ComplexInfoCardGrid } from '@/components/storyblok/bloks/ComplexInfoCardGrid';
import { CoralContainer } from '@/components/storyblok/bloks/CoralContainer';
import { EmailTester } from '@/components/storyblok/bloks/EmailTester';
import { FAQ } from '@/components/storyblok/bloks/FAQ';
import { ReferralLinkShareCardBlok } from '@/components/storyblok/bloks/friend-components/ReferralLinkShareCardBlok';
import { ReferredRewardAmount } from '@/components/storyblok/bloks/friend-components/ReferredRewardAmount';
import { Grid } from '@/components/storyblok/bloks/Grid';
import { ImageAndText } from '@/components/storyblok/bloks/ImageAndText';
import { InfoBlock } from '@/components/storyblok/bloks/InfoBlock';
import { InfoCard } from '@/components/storyblok/bloks/InfoCard';
import { LoggedInOrOutVisibilityWrapper } from '@/components/storyblok/bloks/LoggedInOrOutVisibilityWrapper';
import { Meta } from '@/components/storyblok/bloks/Meta';
import { Page } from '@/components/storyblok/bloks/Page';
import { PageHeader } from '@/components/storyblok/bloks/PageHeader';
import { QuickLinks } from '@/components/storyblok/bloks/QuickLinks';
import { RateCalculator } from '@/components/storyblok/bloks/RateCalculator';
import { RawHTML } from '@/components/storyblok/bloks/RawHTML';
import { RichText } from '@/components/storyblok/bloks/RichText';
import { RichTextCustomLists } from '@/components/storyblok/bloks/RichTextCustomLists';
import { VoteButton } from '@/components/storyblok/bloks/senryu-components/VoteButton';
import { Spacer } from '@/components/storyblok/bloks/Spacer';
import { Stack } from '@/components/storyblok/bloks/Stack';
import { Table } from '@/components/storyblok/bloks/Table';
import { YoutubeVideo } from '@/components/storyblok/bloks/YoutubeVideo';

/** Resolve Storyblok components to Next.js components */
/* eslint sort-keys:error */
export const Components = {
	alert: CMSAlert,
	anchor: Anchor,
	banner_image: BannerImage,
	campaign_button: CampaignButton,
	campaign_form: CampaignForm,
	campaign_visibility_wrapper: CampaignVisibilityWrapper,
	chip: Chip,
	cms_button: Button,
	cms_countdown: CMSCountDown,
	cms_email_subscription: CMSEmailSubscription,
	cms_google_review: CMSGoogleReview,
	cms_image_and_text: ImageAndText,
	cms_image_link: CMSImageLink,
	cms_link: CMSLink,
	cms_quote_button: CMSQuoteButton,
	cms_social_share_button: CMSSocialShareButton,
	collapsible_details: CollapsibleDetailsRichText,
	company_information_block_type: CMSCompanyInformation,
	complex_info_card: InfoCard,
	complex_info_card_grid: ComplexInfoCardGrid,
	container: CoralContainer,
	email_tester: EmailTester,
	faq: FAQ,
	friend_referral_card_blok: ReferralLinkShareCardBlok,
	grid: Grid,
	image: CMSImage,
	info_block: InfoBlock,
	logged_in_status_visibility_wrapper: LoggedInOrOutVisibilityWrapper,
	meta: Meta,
	page: Page,
	page_header: PageHeader,
	pdf_link: CMSPdfLink,
	quick_links: QuickLinks,
	rate_calculator: RateCalculator,
	raw_html: RawHTML,
	referred_reward_amount: ReferredRewardAmount,
	'rich-text': RichText,
	'rich-text-custom-lists': RichTextCustomLists,
	rich_text: RichText,
	rich_text_custom_lists: RichTextCustomLists,
	seo_analyzer: CMSSeoAnalyzer,
	spacer: Spacer,
	sparkles: CMSSparkles,
	stack: Stack,
	table: Table,
	tabs: CMSTabs,
	vote_button: VoteButton,
	youtube_video: YoutubeVideo,
	...coralBloks,
};

interface Props {
	blok: SbBlokData;
	className?: string;
	props?: any;
}

export const DynamicComponent: FC<Props> = ({ blok, className, props }) => {
	/**
	 * @note The first component that will generally be returned by Storyblok here is Page. If you have issues with props
	 * passing to child components, confirm that they are being passed to the DynamicComponent inside of our Page component.
	 */
	// @ts-expect-error
	if (typeof Components[blok.component] !== 'undefined') {
		// @ts-expect-error
		const Component = Components[blok.component];

		return (
			<div
				{...storyblokEditable(blok)}
				data-test="feature"
				className={`h-full ${className ?? ''} ${
					blok.component === 'referred_reward_amount' ? 'inline-block' : ''
				}`}
			>
				<Component blok={blok} props={props} />
			</div>
		);
	}

	// fallback if the component doesn't exist
	return (
		<p>
			The component <strong>{blok.component as string}</strong> has not been
			created yet.
		</p>
	);
};
