import { Button, Container } from '@krakentech/coral';
import { IconFacebook, IconX } from '@krakentech/icons';
import { FC } from 'react';

import { CampaignProps } from '@/pages/campaigns/[slug]';
import { CMSFriendsProps } from '@/pages/data/friend/[slug]';

const FACEBOOK_SHARE_BASE_URL = 'https://www.facebook.com/share.php';
const TWITTER_SHARE_BASE_URL = 'https://twitter.com/share';

type ButtonProps = {
	blok: {
		button_text?: string;
		hashtags?: string;
		platform: 'x' | 'facebook';
		sharing_description?: string;
		sharing_url?: string;
	};
	props?: CampaignProps | CMSFriendsProps;
};

export const CMSSocialShareButton: FC<ButtonProps> = ({ blok }) => {
	return (
		<Container>
			<Button
				target="_blank"
				href={
					blok?.platform === 'facebook'
						? `${FACEBOOK_SHARE_BASE_URL}?u=${blok?.sharing_url}`
						: `${TWITTER_SHARE_BASE_URL}?text=${
								blok?.sharing_description
							}&url=${blok?.sharing_url}&hashtags=${blok.hashtags ?? ''}`
				}
				size="small"
				color="secondary"
				startIcon={blok?.platform === 'facebook' ? <IconFacebook /> : <IconX />}
			>
				<span className="text-ice">{blok?.button_text}</span>
			</Button>
		</Container>
	);
};
