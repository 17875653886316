import { Card, CardTheme } from '@krakentech/coral';
import { FC } from 'react';

import { Button, ButtonProps } from '@/components/storyblok/bloks/Button';
import { optimizeStoryblokImage } from '@/components/storyblok/utils/optimizeStoryblokImage';

type InfoCardProps = {
	blok: {
		btn_text: string;
		card_text: string;
		card_title: string;
		card_url: string;
		image: {
			alt: string;
			filename: string;
		};
		is_link?: boolean;
		theme?: CardTheme;
	};
};

export const InfoCard: FC<InfoCardProps> = ({
	blok: {
		btn_text,
		card_text,
		card_title,
		card_url,
		image,
		is_link,
		theme = 'dark',
	},
}) => {
	const infoCardButtonProps: ButtonProps = {
		blok: {
			text: btn_text || '詳しくはこちら',
			url: card_url,
			variant: 'secondary',
			size: 'full-width',
			background_color: '',
		},
	};

	const handleLinkClick = (): void => {
		is_link && card_url && window.open(card_url, '_blank');
	};

	return (
		<div className="h-full w-full p-2 lg:p-4">
			<Card
				fullHeight
				theme={theme}
				image={{
					src: optimizeStoryblokImage({
						src: image.filename,
						options: { size: '1024x0' },
					}),
					width: 250,
				}}
				padding="small"
			>
				<div
					className={`flex w-full flex-col items-stretch text-ice md:max-w-md ${
						is_link ? 'cursor-pointer' : ''
					}`}
					onClick={handleLinkClick}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							handleLinkClick();
						}
					}}
					tabIndex={0}
					role="button"
				>
					<p className="mb-4 break-words px-4 text-2xl font-bold text-voltage">
						{card_title}
					</p>
					<div className="flex h-full flex-col justify-between px-4 text-lg">
						<p className="mb-8 text-ellipsis break-words font-bold">
							{card_text}
						</p>
						{!is_link && <Button {...infoCardButtonProps} />}
					</div>
				</div>
			</Card>
		</div>
	);
};
