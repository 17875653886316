import { FC } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import { Container } from '@/components/storyblok/bloks/Container';

type RawHTMLProps = {
	blok: {
		raw_html: string;
	};
};

export const RawHTML: FC<RawHTMLProps> = ({ blok }) => (
	<Container className="py-6">
		<div dangerouslySetInnerHTML={{ __html: render(blok.raw_html) }} />
	</Container>
);
