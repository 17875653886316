import { Grid as CoralGrid, CoralSpacing } from '@krakentech/coral';
import { FC } from 'react';

import { DynamicComponent } from '@/components/storyblok/index';
import { CampaignProps } from '@/pages/campaigns/[slug]';
import { CMSFriendsProps } from '@/pages/data/friend/[slug]';
import { StoryContent } from '@/types/storyblok';

export interface GridProps {
	blok: {
		children: StoryContent;
		columns?: number;
		gap?: keyof CoralSpacing | 0;
		has_border?: boolean;
	};
	props?: CMSFriendsProps | CampaignProps;
}

export const Grid: FC<GridProps> = ({
	blok: { children, columns = 3, has_border = false, gap = 'none' },
	props,
}) => {
	const colSpan = Math.floor(12 / columns);

	return (
		<div className="mx-auto w-full px-4 sm:max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl">
			<CoralGrid
				alignItems="normal"
				justifyContent="stretch"
				templateColumns="repeat(1, 1fr)"
				gap="xs"
				md={{ templateColumns: 'repeat(12,1fr)', gap }}
			>
				{children.map((blok) => (
					<CoralGrid.Item colSpan={1} md={{ colSpan }} key={blok._uid}>
						<DynamicComponent
							blok={blok}
							className={has_border ? 'divide-y border border-ink' : ''}
							props={props}
						/>
					</CoralGrid.Item>
				))}
			</CoralGrid>
		</div>
	);
};
