// @ts-nocheck
import { GraphQLClient, RequestOptions } from 'graphql-request';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>;
};
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	BigInt: any;
	Date: any;
	DateTime: any;
	Decimal: string;
	Email: any;
	GenericScalar: any;
	JSONString: any;
	NonEmptyString: any;
	RoleString: any;
	Time: any;
};

export type ApiBrownout = {
	__typename?: 'APIBrownout';
	/** The API type effected by this brownout. */
	apiType?: Maybe<ApiType>;
	/** For GraphQL APIs this will be a field identifier in the format <ParentType>.≤fieldName>, for REST APIs this will be an endpoint. */
	disablesRequestsTo?: Maybe<Scalars['NonEmptyString']>;
	/** The iso formatted datetime at which this brownout will end. */
	endsAt?: Maybe<Scalars['NonEmptyString']>;
	/** The iso formatted datetime at which this brownout will take effect. */
	startsAt?: Maybe<Scalars['NonEmptyString']>;
	/** The current status of this brownout. */
	status?: Maybe<ApiBrownoutStatus>;
};

/** Paginator of API brownouts. */
export type ApiBrownoutConnection = {
	__typename?: 'APIBrownoutConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<ApiBrownoutEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `APIBrownout` and its cursor. */
export type ApiBrownoutEdge = {
	__typename?: 'APIBrownoutEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<ApiBrownout>;
};

export type ApiBrownoutInput = {
	/** Return brownouts with these statuses. */
	statuses?: InputMaybe<Array<InputMaybe<ApiBrownoutStatus>>>;
};

/** Possible API Brownout statuses. */
export enum ApiBrownoutStatus {
	Aborted = 'ABORTED',
	Cancelled = 'CANCELLED',
	Completed = 'COMPLETED',
	InProgress = 'IN_PROGRESS',
	Scheduled = 'SCHEDULED',
}

export type ApiCallType = {
	__typename?: 'APICallType';
	/** Storage for the API client to submit any contextual information. */
	context?: Maybe<Scalars['JSONString']>;
	/** The request's correlation id. */
	correlationId: Scalars['String'];
	createdAt: Scalars['DateTime'];
	id: Scalars['ID'];
	/** Input data for the API call if any. */
	inputData?: Maybe<Scalars['JSONString']>;
	/** Free field for the API caller to categorise their own operation name. This field can be used to filter entries on the UI. */
	operationName: Scalars['String'];
	/** The response from the API call if any. */
	response?: Maybe<Scalars['JSONString']>;
};

/** An enumeration. */
export enum ApiExceptionCategories {
	Account = 'ACCOUNT',
	Unknown = 'UNKNOWN',
}

/** Paginator of API exceptions. */
export type ApiExceptionConnectionTypeConnection = {
	__typename?: 'APIExceptionConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<ApiExceptionConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `APIExceptionConnectionType` and its cursor. */
export type ApiExceptionConnectionTypeEdge = {
	__typename?: 'APIExceptionConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<ApiExceptionType>;
};

export type ApiExceptionEventType = {
	__typename?: 'APIExceptionEventType';
	/** The category of the event. */
	category: Scalars['String'];
	/** A JSON context to be provided with the event, if any. */
	context?: Maybe<Scalars['JSONString']>;
	createdAt: Scalars['DateTime'];
	/** A description of the event. */
	description?: Maybe<Scalars['String']>;
	/** The type of the event. */
	eventType: Scalars['String'];
	id: Scalars['ID'];
};

export type ApiExceptionNoteType = {
	__typename?: 'APIExceptionNoteType';
	/** The content of the API Exception note. */
	body: Scalars['String'];
	/** Timestamp of when the API Exception note was created. */
	createdAt: Scalars['DateTime'];
	/** The ID of the API Exception note. */
	id: Scalars['ID'];
};

/** An enumeration. */
export enum ApiExceptionPriority {
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM',
}

export type ApiExceptionQueryInput = {
	/** The account number to filter for. */
	accountNumber?: InputMaybe<Scalars['ID']>;
	/** The category to filter for. */
	category?: InputMaybe<ApiExceptionCategories>;
	/** The channel to filter for. */
	channel?: InputMaybe<Scalars['String']>;
	/** The customer contact to filter for. */
	customerContact?: InputMaybe<Scalars['String']>;
	/** The external identifier to filter for. */
	externalIdentifier?: InputMaybe<Scalars['String']>;
	/** The priority to filter for. */
	priority?: InputMaybe<ApiExceptionPriority>;
	/** The resolution status to filter for. */
	resolutionStatus?: InputMaybe<ApiExceptionResolutionStatus>;
	/** The resolution type to filter for. */
	resolutionType?: InputMaybe<ApiExceptionResolutionType>;
	/** The supply point identifier to filter for. */
	supplyPointIdentifier?: InputMaybe<Scalars['ID']>;
	/** Tags to filter for. */
	tags?: InputMaybe<Array<InputMaybe<ApiExceptionTags>>>;
	/** The user ID to filter for. */
	userId?: InputMaybe<Scalars['ID']>;
};

/** An enumeration. */
export enum ApiExceptionResolutionStatus {
	Assigned = 'ASSIGNED',
	Cancelled = 'CANCELLED',
	InProgress = 'IN_PROGRESS',
	Resolved = 'RESOLVED',
	Unassigned = 'UNASSIGNED',
	Unsuccessful = 'UNSUCCESSFUL',
	WaitingOnThirdParty = 'WAITING_ON_THIRD_PARTY',
}

/** An enumeration. */
export enum ApiExceptionResolutionType {
	Automatic = 'AUTOMATIC',
	Manual = 'MANUAL',
	Unassigned = 'UNASSIGNED',
}

/** An enumeration. */
export enum ApiExceptionTags {
	MoveIn = 'MOVE_IN',
	MoveOut = 'MOVE_OUT',
	ProductUpdate = 'PRODUCT_UPDATE',
}

export type ApiExceptionType = {
	__typename?: 'APIExceptionType';
	/** The account number provided to the exception. */
	accountNumber?: Maybe<Scalars['String']>;
	/** The API calls associated with this exception if any. */
	apiCalls?: Maybe<Array<Maybe<ApiCallType>>>;
	/** The user assigned to handle this exception if any. */
	assignedUser?: Maybe<AssignedUserType>;
	/** Category associated with this exception. */
	category?: Maybe<ApiExceptionCategories>;
	/** Free field for the API caller to categorise a channel. This could be (but not limited to) the client's team that calleded the API, the name of the 'flow' the call belongs to, etc. */
	channel: Scalars['String'];
	/** Storage for the API client to submit any contextual information. */
	context?: Maybe<Scalars['JSONString']>;
	createdAt: Scalars['DateTime'];
	/** The customer contact provided to the exception. */
	customerContact?: Maybe<Scalars['String']>;
	/** The events associated with this exception if any. */
	events?: Maybe<Array<Maybe<ApiExceptionEventType>>>;
	/** External identifier submitted by the API client to track this exception on their end. */
	externalIdentifier: Scalars['String'];
	id: Scalars['ID'];
	/** The key date associated with the exception, if available. */
	keyDate?: Maybe<Scalars['Date']>;
	/** Notes associated with this exception if any. */
	notes?: Maybe<Array<Maybe<ApiExceptionNoteType>>>;
	/** The operations team assigned to this exception if any. */
	operationsTeam?: Maybe<OperationsTeamType>;
	/** The current priority for the API exception. */
	priority: ApiExceptionPriority;
	/** The current resolution status for the API exception. */
	resolutionStatus: ApiExceptionResolutionStatus;
	/** The current resolution type for the API exception. */
	resolutionType: ApiExceptionResolutionType;
	/** The supply point identifier provided to the exception. */
	supplyPointIdentifier?: Maybe<Scalars['String']>;
	/** Tags associated with this exception if any. */
	tags?: Maybe<Array<Maybe<ApiExceptionTags>>>;
	/** The user id provided to the exception. */
	userId?: Maybe<Scalars['Int']>;
};

/** Possible API types. */
export enum ApiType {
	Graphql = 'GRAPHQL',
	Rest = 'REST',
}

/**
 * Interface for all lifecycle journey processes that
 * inherit from AbstractSupplyPointProcess.
 */
export type AbstractSupplyPointProcessInterface = {
	/** The ID or the primary key of the lifecycle process. */
	id?: Maybe<Scalars['ID']>;
	/** The status of the process. */
	status?: Maybe<LifecycleSupplyPointProcessStatus>;
	/** The supply points associated with the process. */
	supplyPoints: SupplyPointConnectionTypeConnection;
};

/**
 * Interface for all lifecycle journey processes that
 * inherit from AbstractSupplyPointProcess.
 */
export type AbstractSupplyPointProcessInterfaceSupplyPointsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8223: Unauthorized.
 * - KT-CT-8201: Received an invalid quoteId.
 * - KT-CT-8224: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type AcceptGoodsQuote = {
	__typename?: 'AcceptGoodsQuote';
	/** Goods purchase created. */
	goodsPurchase?: Maybe<GoodsPurchase>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type AcceptGoodsQuoteInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** A JSON object containing client parameters to store on the quote. */
	clientParams?: InputMaybe<Scalars['JSONString']>;
	/** A JSON object containing market parameters to store on the purchase. */
	marketParams?: InputMaybe<Scalars['JSONString']>;
	/** ID of the accepted quote. */
	quoteId: Scalars['Int'];
};

/** Properties relating to the accessibility of features. */
export type AccessibilityInterface = {
	/** Whether the element is hidden from view. */
	accessibilityHidden?: Maybe<Scalars['Boolean']>;
	/** Accessible description of the element. */
	accessibilityLabel?: Maybe<Scalars['String']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type Account = AccountInterface & {
	__typename?: 'Account';
	/** The type of account. */
	accountType?: Maybe<AccountTypeChoices>;
	/** List of active hardship agreements for the user when is_in_hardship is True. */
	activeHardshipAgreements?: Maybe<Array<Maybe<HardshipAgreementType>>>;
	/** The referral schemes currently active for this account. */
	activeReferralSchemes?: Maybe<ReferralSchemeTypes>;
	/**
	 *
	 * The billing address of this account, stored in the new
	 * libaddressinput-based format.
	 *
	 * Note that `name` and `organization` are very unlikely to be
	 * supplied here; the `billing_name` field on the account
	 * itself is generally used for that purpose instead.
	 *
	 */
	address?: Maybe<RichAddressType>;
	/** Fetch annual statements for the account. */
	annualStatements?: Maybe<AnnualStatementConnectionTypeConnection>;
	/** Applications by this account to become our customer. More recent applications will be listed first. */
	applications?: Maybe<AccountApplicationConnectionTypeConnection>;
	/** Assistance agreements for account. */
	assistanceAgreements?: Maybe<Array<Maybe<AssistanceAgreementType>>>;
	/** A list of available demand response campaigns in which this account can participate. */
	availableDemandResponseCampaignsForRegistration?: Maybe<
		Array<Maybe<Scalars['String']>>
	>;
	/** The current account balance. */
	balance: Scalars['Int'];
	/** Fetch a specific issued bill (invoice/statement) for the account. */
	bill?: Maybe<BillInterface>;
	/** The billing address of the account. */
	billingAddress?: Maybe<Scalars['String']>;
	billingAddressLine1?: Maybe<Scalars['String']>;
	billingAddressLine2?: Maybe<Scalars['String']>;
	billingAddressLine3?: Maybe<Scalars['String']>;
	billingAddressLine4?: Maybe<Scalars['String']>;
	billingAddressLine5?: Maybe<Scalars['String']>;
	billingAddressPostcode?: Maybe<Scalars['String']>;
	billingCountryCode?: Maybe<Scalars['String']>;
	billingDeliveryPointIdentifier?: Maybe<Scalars['String']>;
	/** The billing email of the account. */
	billingEmail?: Maybe<Scalars['String']>;
	/** The billing name of the account. */
	billingName?: Maybe<Scalars['String']>;
	/** Information about the account's billing cycle. */
	billingOptions?: Maybe<BillingOptionsType>;
	/** The billing sub name of the account. */
	billingSubName?: Maybe<Scalars['String']>;
	/** Fetch issued bills (invoices/statements) for the account. */
	bills?: Maybe<BillConnectionTypeConnection>;
	/** The brand of the account. */
	brand?: Maybe<Scalars['String']>;
	/** Business info related to a business account. */
	business?: Maybe<BusinessType>;
	/**
	 * The company type of a business account.
	 * @deprecated The 'businessType' field is deprecated.
	 *
	 * Use `business.businessType` instead
	 *
	 * - Marked as deprecated on 2022-03-09.
	 * - Will be removed on 2024-01-01.
	 */
	businessType?: Maybe<BusinessTypeOptions>;
	/** The campaigns associated with an account. */
	campaigns?: Maybe<Array<Maybe<AccountCampaignType>>>;
	/** Check whether the account can be withdrawn. */
	canBeWithdrawn?: Maybe<Scalars['Boolean']>;
	/** Whether the account can request a credit refund. */
	canRequestRefund?: Maybe<Scalars['Boolean']>;
	/** The method the account has specified they prefer we contact them. */
	commsDeliveryPreference?: Maybe<CommsDeliveryPreference>;
	/**
	 * @deprecated The 'communicationDeliveryPreference' field is deprecated.
	 *
	 * Use `commsDeliveryPreference` instead
	 *
	 * - Marked as deprecated on 2022-05-27.
	 * - Will be removed on 2024-01-01.
	 */
	communicationDeliveryPreference?: Maybe<Scalars['String']>;
	/** A JSON object containing consent information. */
	consentData?: Maybe<Scalars['GenericScalar']>;
	/** Contribution agreements for account. */
	contributionAgreements?: Maybe<Array<Maybe<ContributionAgreementType>>>;
	/** The datetime that the account was originally created. */
	createdAt?: Maybe<Scalars['DateTime']>;
	/** Debt collection proceedings for account. */
	debtCollectionProceedings?: Maybe<Array<Maybe<DebtCollectionProceedingType>>>;
	/** The demand response campaigns associated with an account. */
	demandResponseCampaignParticipants?: Maybe<
		Array<Maybe<DemandResponseCampaignParticipantType>>
	>;
	/** The direct debit instructions of the account */
	directDebitInstructions?: Maybe<DirectDebitInstructionConnectionTypeConnection>;
	/** The document accessibility preference of the account. */
	documentAccessibility?: Maybe<DocumentAccessibilityChoices>;
	/** The account events that were recorded for the account. */
	events?: Maybe<AccountEventConnectionTypeConnection>;
	/** Files attached to this account. */
	fileAttachments?: Maybe<Array<Maybe<AccountFileAttachment>>>;
	/** Check whether the account has an active energy agreement. */
	hasActiveAgreement?: Maybe<Scalars['Boolean']>;
	/** Check whether the account has a future energy agreement. */
	hasFutureAgreement?: Maybe<Scalars['Boolean']>;
	id: Scalars['ID'];
	/** True if there is an active Hardship Agreement for this account. False otherwise. */
	isInHardship?: Maybe<Scalars['Boolean']>;
	/** Ledgers provide the foundation of bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular customer account. */
	ledgers?: Maybe<Array<Maybe<LedgerType>>>;
	/** Lifeline Agency application. */
	lifelineAgencyApplication?: Maybe<LifelineAgencyApplication>;
	/** The maximum amount a customer is allowed to request as a refund and the reason why that's the maximum amount. */
	maximumRefund?: Maybe<MaximumRefundType>;
	/** Metadata associated with the account. */
	metadata?: Maybe<Array<Maybe<Metadata>>>;
	/** Notes for the account. */
	notes?: Maybe<Array<Maybe<AccountNoteType>>>;
	/** A code that uniquely identifies the account. */
	number?: Maybe<Scalars['String']>;
	/** The current account overdue balance. */
	overdueBalance?: Maybe<Scalars['Int']>;
	/** Files attached to this account. */
	paginatedFileAttachments?: Maybe<AccountFileAttachmentConnectionTypeConnection>;
	/** Paginated payment forecasts for an account. Starts from today's date (inclusive). The interface supports `last` but does not guarantee 'lastness'. */
	paginatedPaymentForecast?: Maybe<PaymentForecastConnectionTypeConnection>;
	/**
	 * A list displaying the payment forecast for an account. The list starts from today's date (inclusive).
	 * @deprecated The 'paymentForecast' field is deprecated.
	 *
	 * Please use 'paginatedPaymentForecast' instead.
	 *
	 * - Marked as deprecated on 2024-01-03.
	 * - Will be removed on 2025-01-01.
	 */
	paymentForecast?: Maybe<Array<Maybe<PaymentForecastType>>>;
	/** The payment instructions of the account */
	paymentInstructions?: Maybe<PaymentInstructionConnection>;
	/** Current active payment schedule detail of the account. */
	paymentSchedule?: Maybe<PaymentScheduleType>;
	/** The schedules that describe how we would expect to take payments for an account on a given month. */
	paymentSchedules?: Maybe<PaymentScheduleConnectionTypeConnection>;
	/** The payments made into an account from a payment instruction. */
	payments?: Maybe<AccountPaymentConnectionTypeConnection>;
	/** The portfolio this account is linked to. */
	portfolio?: Maybe<PortfolioType>;
	/** The language that the account preferred for communications. */
	preferredLanguageForComms?: Maybe<Scalars['String']>;
	/** Properties linked to the account now and in the future. */
	properties?: Maybe<Array<Maybe<Property>>>;
	provisionalTransactions?: Maybe<ProvisionalTransactionConnectionTypeConnection>;
	/** Account references linked to this account. */
	references?: Maybe<Array<Maybe<AccountReferenceType>>>;
	/** The referrals created by this account. */
	referrals?: Maybe<ReferralConnectionTypeConnection>;
	/** Number of referrals created by this account. */
	referralsCreated?: Maybe<Scalars['Int']>;
	/** The repayments that have been requested for this account. */
	repayments?: Maybe<AccountRepaymentConnectionTypeConnection>;
	/** Details about the eligibility status for requesting a refund. */
	requestRefundEligibility?: Maybe<RequestRefundEligibilityType>;
	/** The rewards applied to this account. */
	rewards?: Maybe<Array<Maybe<RewardType>>>;
	/** List of billing address lines. */
	splitBillingAddress?: Maybe<Array<Maybe<Scalars['String']>>>;
	/** The current status of the account. */
	status?: Maybe<AccountStatus>;
	/** Fetch transactions that have taken place on the account. */
	transactions?: Maybe<TransactionConnectionTypeConnection>;
	/** Unique reference number from a 3rd party enrolment. */
	urn?: Maybe<Scalars['String']>;
	users: Array<AccountUser>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountAnnualStatementsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountApplicationsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountBalanceArgs = {
	includeAllLedgers?: InputMaybe<Scalars['Boolean']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountBillArgs = {
	billType?: InputMaybe<BillTypeEnum>;
	id: Scalars['ID'];
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountBillsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	fromDate?: InputMaybe<Scalars['Date']>;
	includeBillsWithoutPDF?: InputMaybe<Scalars['Boolean']>;
	includeHeldStatements?: InputMaybe<Scalars['Boolean']>;
	includeHistoricStatements?: InputMaybe<Scalars['Boolean']>;
	includeOpenStatements?: InputMaybe<Scalars['Boolean']>;
	issuedFromDate?: InputMaybe<Scalars['Date']>;
	issuedToDate?: InputMaybe<Scalars['Date']>;
	last?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	onlyCurrentEmail?: InputMaybe<Scalars['Boolean']>;
	orderBy?: InputMaybe<BillsOrderBy>;
	toDate?: InputMaybe<Scalars['Date']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountDirectDebitInstructionsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	statuses?: InputMaybe<Array<InputMaybe<DirectDebitInstructionStatus>>>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountEventsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	eventTypes?: InputMaybe<Array<InputMaybe<AccountEventType>>>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountLedgersArgs = {
	ledgerId?: InputMaybe<Scalars['ID']>;
	ledgerNumber?: InputMaybe<Scalars['String']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountPaginatedFileAttachmentsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	category?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountPaginatedPaymentForecastArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	dateTo?: InputMaybe<Scalars['Date']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	ledgerId?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountPaymentForecastArgs = {
	dateTo: Scalars['Date'];
	ledgerId?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountPaymentInstructionsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	statuses?: InputMaybe<Array<InputMaybe<PaymentInstructionStatus>>>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountPaymentSchedulesArgs = {
	active?: InputMaybe<Scalars['Boolean']>;
	activeOnDate?: InputMaybe<Scalars['Date']>;
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	includeDormant?: InputMaybe<Scalars['Boolean']>;
	last?: InputMaybe<Scalars['Int']>;
	ledgerId?: InputMaybe<Scalars['ID']>;
	ledgerType?: InputMaybe<Scalars['Int']>;
	reason?: InputMaybe<PaymentScheduleReasonOptions>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountPaymentsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	includePromises?: InputMaybe<Scalars['Boolean']>;
	last?: InputMaybe<Scalars['Int']>;
	ledgerId?: InputMaybe<Scalars['String']>;
	reason?: InputMaybe<PaymentReasonOptions>;
	status?: InputMaybe<AccountPaymentStatusOptions>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountPropertiesArgs = {
	activeFrom?: InputMaybe<Scalars['DateTime']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountProvisionalTransactionsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountReferralsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountRepaymentsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	statuses?: InputMaybe<Array<InputMaybe<AccountRepaymentStatusOptions>>>;
};

/** The account object can be one of several attached to a `Portfolio` (which usually has an `AccountUser` which is used to authenticate access to the `Portfolio`'s accounts). Typically a person has a single account attached to a portfolio and properties on the same account will appear on the same bill. Where separate bills are desired, multiple accounts  are created and added to the same portfolio of accounts. */
export type AccountTransactionsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	fromDate?: InputMaybe<Scalars['Date']>;
	includeAllLedgers?: InputMaybe<Scalars['Boolean']>;
	last?: InputMaybe<Scalars['Int']>;
	ledgerId?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	orderBy?: InputMaybe<TransactionsOrderBy>;
	toDate?: InputMaybe<Scalars['Date']>;
	transactionTypes?: InputMaybe<Array<InputMaybe<TransactionTypeFilter>>>;
	transactionTypesExcluded?: InputMaybe<
		Array<InputMaybe<TransactionTypeFilter>>
	>;
};

export type AccountApplicationConnectionTypeConnection = {
	__typename?: 'AccountApplicationConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<AccountApplicationConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AccountApplicationConnectionType` and its cursor. */
export type AccountApplicationConnectionTypeEdge = {
	__typename?: 'AccountApplicationConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<AccountApplicationType>;
};

/** An enumeration. */
export enum AccountApplicationStatus {
	/** Current */
	Current = 'CURRENT',
	/** Failed */
	Failed = 'FAILED',
	/** Historic */
	Historic = 'HISTORIC',
	/** Withdrawn */
	Withdrawn = 'WITHDRAWN',
}

export type AccountApplicationType = {
	__typename?: 'AccountApplicationType';
	/** Last day of the cooling off period. Barring changes or objections, the account will be gained on the next business day after this date. This value will only be returned for current applications. */
	coolingOffEndDate?: Maybe<Scalars['Date']>;
	/** Date at which this account decided to switch to us. */
	dateOfSale?: Maybe<Scalars['Date']>;
	/** Whether this account application represents a migration into the current system or a regular gain. */
	isMigrated?: Maybe<Scalars['Boolean']>;
	/** The source system for a migrated account. This could be the previous supplier or the previous account management system. */
	migrationSource?: Maybe<Scalars['String']>;
	salesChannel: Scalars['String'];
	/** The sales subchannel used when signing up. This could for example be a price comparison site. */
	salesSubchannel?: Maybe<Scalars['String']>;
	status: AccountApplicationStatus;
};

export type AccountBalanceTransferType = {
	__typename?: 'AccountBalanceTransferType';
	/** Debit details. */
	accountCharge?: Maybe<AccountChargeType>;
	/** Credit details. */
	accountCredit?: Maybe<AccountCreditType>;
	/** Balance transfer ID. */
	id?: Maybe<Scalars['ID']>;
	/** The reason for the balance transfer. */
	reason?: Maybe<Scalars['String']>;
};

export type AccountBillingAddressInput = {
	/** The account number of the account to update. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** Billing address details. */
	billingAddress?: InputMaybe<BillingAddressDetailsInput>;
};

/** An enumeration. */
export enum AccountBillingOptionsPeriodLength {
	/** Monthly */
	Monthly = 'MONTHLY',
	/** Quarterly */
	Quarterly = 'QUARTERLY',
}

export type AccountCampaignType = {
	__typename?: 'AccountCampaignType';
	/** The date on which the associated campaign itself concludes. */
	campaignExpiryDate?: Maybe<Scalars['Date']>;
	/** The date on which the account's participation in the campaign ends. */
	expiryDate?: Maybe<Scalars['Date']>;
	/** The name of the campaign. */
	name?: Maybe<Scalars['String']>;
	/** The slug of the campaign. */
	slug?: Maybe<Scalars['String']>;
	/** The date that the account's link to the campaign started. */
	startDate?: Maybe<Scalars['Date']>;
};

export type AccountChargeMetadataType = {
	__typename?: 'AccountChargeMetadataType';
	/** A JSON object containing unstructured data about the account charge. */
	data?: Maybe<Scalars['JSONString']>;
	/** The date and time the metadata was last updated. */
	updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AccountChargeType = {
	__typename?: 'AccountChargeType';
	/** The climate change levy amount of the charge. */
	cclAmount?: Maybe<Scalars['BigInt']>;
	/** The display note for the charge. */
	displayNote?: Maybe<Scalars['String']>;
	/** The gross amount of the charge. */
	grossAmount?: Maybe<Scalars['BigInt']>;
	/** The ID of the account charge. */
	id?: Maybe<Scalars['ID']>;
	metadata?: Maybe<AccountChargeMetadataType>;
	/** The net amount of the charge. */
	netAmount?: Maybe<Scalars['BigInt']>;
	/** The note for the charge. */
	note?: Maybe<Scalars['String']>;
	/** The reason for the charge. */
	reason?: Maybe<Scalars['String']>;
	/** The sales tax amount of the charge. */
	salesTaxAmount?: Maybe<Scalars['BigInt']>;
};

export type AccountCreditMetadataType = {
	__typename?: 'AccountCreditMetadataType';
	data: Scalars['JSONString'];
	updatedAt: Scalars['DateTime'];
};

/**
 *
 *     Please note: these labels are exposed in the API documentation.
 *
 */
export enum AccountCreditReasonType {
	/** External referral credit */
	ExternalReferralCredit = 'EXTERNAL_REFERRAL_CREDIT',
}

export type AccountCreditType = {
	__typename?: 'AccountCreditType';
	grossAmount: Scalars['BigInt'];
	id: Scalars['ID'];
	metadata?: Maybe<AccountCreditMetadataType>;
	netAmount: Scalars['BigInt'];
	note: Scalars['String'];
	reason: Scalars['String'];
	salesTaxAmount: Scalars['BigInt'];
};

export type AccountEvent = EmailEventType | PrintEventType;

export type AccountEventConnectionTypeConnection = {
	__typename?: 'AccountEventConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<AccountEventConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AccountEventConnectionType` and its cursor. */
export type AccountEventConnectionTypeEdge = {
	__typename?: 'AccountEventConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<AccountEvent>;
};

/** An enumeration. */
export enum AccountEventType {
	/** The email messages that were received by the account. */
	EmailReceived = 'EMAIL_RECEIVED',
	/** The email messages that were sent by the account. */
	EmailSent = 'EMAIL_SENT',
	/** The marketing email messages that were sent by the account. */
	MarketingEmailSent = 'MARKETING_EMAIL_SENT',
	/** The print messages that were cancelled. */
	PrintCancelled = 'PRINT_CANCELLED',
	/** The print messages that failed to be delivered. */
	PrintFailed = 'PRINT_FAILED',
	/** The print messages that were returned to sender. */
	PrintReturned = 'PRINT_RETURNED',
	/** The print messages that were sent to the print partner by the account. */
	PrintSent = 'PRINT_SENT',
	/** The print messages that were sent by the print partner. */
	PrintSucceeded = 'PRINT_SUCCEEDED',
}

export type AccountFileAttachment = FileAttachment &
	Node & {
		__typename?: 'AccountFileAttachment';
		category: Scalars['String'];
		fetchUrl?: Maybe<Scalars['String']>;
		filename: Scalars['String'];
		/** The ID of the object */
		id: Scalars['ID'];
		/** Is the file ready for use / downloadable? */
		isReady?: Maybe<Scalars['Boolean']>;
		/** Is the file uploaded to S3? */
		isUploaded?: Maybe<Scalars['Boolean']>;
		sizeInBytes?: Maybe<Scalars['Int']>;
	};

export type AccountFileAttachmentConnectionTypeConnection = {
	__typename?: 'AccountFileAttachmentConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<AccountFileAttachmentConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AccountFileAttachmentConnectionType` and its cursor. */
export type AccountFileAttachmentConnectionTypeEdge = {
	__typename?: 'AccountFileAttachmentConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<AccountFileAttachment>;
};

export type AccountInterface = {
	/** The type of account. */
	accountType?: Maybe<AccountTypeChoices>;
	/** List of active hardship agreements for the user when is_in_hardship is True. */
	activeHardshipAgreements?: Maybe<Array<Maybe<HardshipAgreementType>>>;
	/** The referral schemes currently active for this account. */
	activeReferralSchemes?: Maybe<ReferralSchemeTypes>;
	/**
	 *
	 * The billing address of this account, stored in the new
	 * libaddressinput-based format.
	 *
	 * Note that `name` and `organization` are very unlikely to be
	 * supplied here; the `billing_name` field on the account
	 * itself is generally used for that purpose instead.
	 *
	 */
	address?: Maybe<RichAddressType>;
	/** Fetch annual statements for the account. */
	annualStatements?: Maybe<AnnualStatementConnectionTypeConnection>;
	/** Applications by this account to become our customer. More recent applications will be listed first. */
	applications?: Maybe<AccountApplicationConnectionTypeConnection>;
	/** Assistance agreements for account. */
	assistanceAgreements?: Maybe<Array<Maybe<AssistanceAgreementType>>>;
	/** The current account balance. */
	balance: Scalars['Int'];
	/** Fetch a specific issued bill (invoice/statement) for the account. */
	bill?: Maybe<BillInterface>;
	/** The billing address of the account. */
	billingAddress?: Maybe<Scalars['String']>;
	billingAddressLine1?: Maybe<Scalars['String']>;
	billingAddressLine2?: Maybe<Scalars['String']>;
	billingAddressLine3?: Maybe<Scalars['String']>;
	billingAddressLine4?: Maybe<Scalars['String']>;
	billingAddressLine5?: Maybe<Scalars['String']>;
	billingAddressPostcode?: Maybe<Scalars['String']>;
	billingCountryCode?: Maybe<Scalars['String']>;
	billingDeliveryPointIdentifier?: Maybe<Scalars['String']>;
	/** The billing email of the account. */
	billingEmail?: Maybe<Scalars['String']>;
	/** The billing name of the account. */
	billingName?: Maybe<Scalars['String']>;
	/** Information about the account's billing cycle. */
	billingOptions?: Maybe<BillingOptionsType>;
	/** The billing sub name of the account. */
	billingSubName?: Maybe<Scalars['String']>;
	/** Fetch issued bills (invoices/statements) for the account. */
	bills?: Maybe<BillConnectionTypeConnection>;
	/** The brand of the account. */
	brand?: Maybe<Scalars['String']>;
	/** Business info related to a business account. */
	business?: Maybe<BusinessType>;
	/**
	 * The company type of a business account.
	 * @deprecated The 'businessType' field is deprecated.
	 *
	 * Use `business.businessType` instead
	 *
	 * - Marked as deprecated on 2022-03-09.
	 * - Will be removed on 2024-01-01.
	 */
	businessType?: Maybe<BusinessTypeOptions>;
	/** The campaigns associated with an account. */
	campaigns?: Maybe<Array<Maybe<AccountCampaignType>>>;
	/** Whether the account can request a credit refund. */
	canRequestRefund?: Maybe<Scalars['Boolean']>;
	/** The method the account has specified they prefer we contact them. */
	commsDeliveryPreference?: Maybe<CommsDeliveryPreference>;
	/**
	 * @deprecated The 'communicationDeliveryPreference' field is deprecated.
	 *
	 * Use `commsDeliveryPreference` instead
	 *
	 * - Marked as deprecated on 2022-05-27.
	 * - Will be removed on 2024-01-01.
	 */
	communicationDeliveryPreference?: Maybe<Scalars['String']>;
	/** Contribution agreements for account. */
	contributionAgreements?: Maybe<Array<Maybe<ContributionAgreementType>>>;
	/** The datetime that the account was originally created. */
	createdAt?: Maybe<Scalars['DateTime']>;
	/** Debt collection proceedings for account. */
	debtCollectionProceedings?: Maybe<Array<Maybe<DebtCollectionProceedingType>>>;
	/** The direct debit instructions of the account */
	directDebitInstructions?: Maybe<DirectDebitInstructionConnectionTypeConnection>;
	/** The document accessibility preference of the account. */
	documentAccessibility?: Maybe<DocumentAccessibilityChoices>;
	/** The account events that were recorded for the account. */
	events?: Maybe<AccountEventConnectionTypeConnection>;
	/** Files attached to this account. */
	fileAttachments?: Maybe<Array<Maybe<AccountFileAttachment>>>;
	/** True if there is an active Hardship Agreement for this account. False otherwise. */
	isInHardship?: Maybe<Scalars['Boolean']>;
	/** Ledgers provide the foundation of bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular customer account. */
	ledgers?: Maybe<Array<Maybe<LedgerType>>>;
	/** The maximum amount a customer is allowed to request as a refund and the reason why that's the maximum amount. */
	maximumRefund?: Maybe<MaximumRefundType>;
	/** Metadata associated with the account. */
	metadata?: Maybe<Array<Maybe<Metadata>>>;
	/** Notes for the account. */
	notes?: Maybe<Array<Maybe<AccountNoteType>>>;
	/** A code that uniquely identifies the account. */
	number?: Maybe<Scalars['String']>;
	/** The current account overdue balance. */
	overdueBalance?: Maybe<Scalars['Int']>;
	/** Files attached to this account. */
	paginatedFileAttachments?: Maybe<AccountFileAttachmentConnectionTypeConnection>;
	/** Paginated payment forecasts for an account. Starts from today's date (inclusive). The interface supports `last` but does not guarantee 'lastness'. */
	paginatedPaymentForecast?: Maybe<PaymentForecastConnectionTypeConnection>;
	/**
	 * A list displaying the payment forecast for an account. The list starts from today's date (inclusive).
	 * @deprecated The 'paymentForecast' field is deprecated.
	 *
	 * Please use 'paginatedPaymentForecast' instead.
	 *
	 * - Marked as deprecated on 2024-01-03.
	 * - Will be removed on 2025-01-01.
	 */
	paymentForecast?: Maybe<Array<Maybe<PaymentForecastType>>>;
	/** The schedules that describe how we would expect to take payments for an account on a given month. */
	paymentSchedules?: Maybe<PaymentScheduleConnectionTypeConnection>;
	/** The payments made into an account from a payment instruction. */
	payments?: Maybe<AccountPaymentConnectionTypeConnection>;
	/** The portfolio this account is linked to. */
	portfolio?: Maybe<PortfolioType>;
	/** The language that the account preferred for communications. */
	preferredLanguageForComms?: Maybe<Scalars['String']>;
	provisionalTransactions?: Maybe<ProvisionalTransactionConnectionTypeConnection>;
	/** Account references linked to this account. */
	references?: Maybe<Array<Maybe<AccountReferenceType>>>;
	/** The referrals created by this account. */
	referrals?: Maybe<ReferralConnectionTypeConnection>;
	/** Number of referrals created by this account. */
	referralsCreated?: Maybe<Scalars['Int']>;
	/** The repayments that have been requested for this account. */
	repayments?: Maybe<AccountRepaymentConnectionTypeConnection>;
	/** Details about the eligibility status for requesting a refund. */
	requestRefundEligibility?: Maybe<RequestRefundEligibilityType>;
	/** The rewards applied to this account. */
	rewards?: Maybe<Array<Maybe<RewardType>>>;
	/** List of billing address lines. */
	splitBillingAddress?: Maybe<Array<Maybe<Scalars['String']>>>;
	/** The current status of the account. */
	status?: Maybe<AccountStatus>;
	/** Fetch transactions that have taken place on the account. */
	transactions?: Maybe<TransactionConnectionTypeConnection>;
	/** Unique reference number from a 3rd party enrolment. */
	urn?: Maybe<Scalars['String']>;
};

export type AccountInterfaceAnnualStatementsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfaceApplicationsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfaceBalanceArgs = {
	includeAllLedgers?: InputMaybe<Scalars['Boolean']>;
};

export type AccountInterfaceBillArgs = {
	billType?: InputMaybe<BillTypeEnum>;
	id: Scalars['ID'];
};

export type AccountInterfaceBillsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	fromDate?: InputMaybe<Scalars['Date']>;
	includeBillsWithoutPDF?: InputMaybe<Scalars['Boolean']>;
	includeHeldStatements?: InputMaybe<Scalars['Boolean']>;
	includeHistoricStatements?: InputMaybe<Scalars['Boolean']>;
	includeOpenStatements?: InputMaybe<Scalars['Boolean']>;
	issuedFromDate?: InputMaybe<Scalars['Date']>;
	issuedToDate?: InputMaybe<Scalars['Date']>;
	last?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	onlyCurrentEmail?: InputMaybe<Scalars['Boolean']>;
	orderBy?: InputMaybe<BillsOrderBy>;
	toDate?: InputMaybe<Scalars['Date']>;
};

export type AccountInterfaceDirectDebitInstructionsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	statuses?: InputMaybe<Array<InputMaybe<DirectDebitInstructionStatus>>>;
};

export type AccountInterfaceEventsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	eventTypes?: InputMaybe<Array<InputMaybe<AccountEventType>>>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfaceLedgersArgs = {
	ledgerId?: InputMaybe<Scalars['ID']>;
	ledgerNumber?: InputMaybe<Scalars['String']>;
};

export type AccountInterfacePaginatedFileAttachmentsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	category?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfacePaginatedPaymentForecastArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	dateTo?: InputMaybe<Scalars['Date']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	ledgerId?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfacePaymentForecastArgs = {
	dateTo: Scalars['Date'];
	ledgerId?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfacePaymentSchedulesArgs = {
	active?: InputMaybe<Scalars['Boolean']>;
	activeOnDate?: InputMaybe<Scalars['Date']>;
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	includeDormant?: InputMaybe<Scalars['Boolean']>;
	last?: InputMaybe<Scalars['Int']>;
	ledgerId?: InputMaybe<Scalars['ID']>;
	ledgerType?: InputMaybe<Scalars['Int']>;
	reason?: InputMaybe<PaymentScheduleReasonOptions>;
};

export type AccountInterfacePaymentsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	includePromises?: InputMaybe<Scalars['Boolean']>;
	last?: InputMaybe<Scalars['Int']>;
	ledgerId?: InputMaybe<Scalars['String']>;
	reason?: InputMaybe<PaymentReasonOptions>;
	status?: InputMaybe<AccountPaymentStatusOptions>;
};

export type AccountInterfaceProvisionalTransactionsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfaceReferralsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type AccountInterfaceRepaymentsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	statuses?: InputMaybe<Array<InputMaybe<AccountRepaymentStatusOptions>>>;
};

export type AccountInterfaceTransactionsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	fromDate?: InputMaybe<Scalars['Date']>;
	includeAllLedgers?: InputMaybe<Scalars['Boolean']>;
	last?: InputMaybe<Scalars['Int']>;
	ledgerId?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	orderBy?: InputMaybe<TransactionsOrderBy>;
	toDate?: InputMaybe<Scalars['Date']>;
	transactionTypes?: InputMaybe<Array<InputMaybe<TransactionTypeFilter>>>;
	transactionTypesExcluded?: InputMaybe<
		Array<InputMaybe<TransactionTypeFilter>>
	>;
};

export type AccountLedgerInput = {
	/** The account number. */
	accountNumber: Scalars['ID'];
	/** The ledger id for the account. */
	ledgerId: Scalars['ID'];
};

export type AccountNoteType = {
	__typename?: 'AccountNoteType';
	body: Scalars['String'];
	createdAt: Scalars['DateTime'];
	isPinned: Scalars['Boolean'];
};

export type AccountPaymentConnectionTypeConnection = {
	__typename?: 'AccountPaymentConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<AccountPaymentConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AccountPaymentConnectionType` and its cursor. */
export type AccountPaymentConnectionTypeEdge = {
	__typename?: 'AccountPaymentConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<AccountPaymentType>;
};

/** An enumeration. */
export enum AccountPaymentStatusOptions {
	/** The payment has been approved by the merchant and added to your Kraken account. */
	Cleared = 'CLEARED',
	/** This payment was deleted. From this point it cannot be altered. */
	Deleted = 'DELETED',
	/** The payment failed permanently. */
	Failed = 'FAILED',
	/** The payment has been made successfully and applied to the Kraken balance. */
	Fulfilled = 'FULFILLED',
	/** Payments made in a previous system and then imported into Kraken. */
	Historic = 'HISTORIC',
	/** The payment has been submitted. From this point it cannot be altered. */
	Pending = 'PENDING',
	/** A payment promise has been created, but it has not left the customers bank account. */
	Promised = 'PROMISED',
	/** The payment promise has been broken. */
	PromiseBroken = 'PROMISE_BROKEN',
	/** The initial state of a payment in Kraken. It should be scheduled with a payment vendor in the future. */
	Requested = 'REQUESTED',
	/** The payment has been scheduled for collection and the customer has been notified. While a payment is scheduled, it can still be deleted. */
	Scheduled = 'SCHEDULED',
	/** Third Party payments are those recorded for financial purposes in a different system but should be added to statements. */
	ThirdParty = 'THIRD_PARTY',
}

/** An enumeration. */
export enum AccountPaymentTransactionTypeChoices {
	Agency = 'AGENCY',
	AllpayCard = 'ALLPAY_CARD',
	AllpayCash = 'ALLPAY_CASH',
	AllpayCheque = 'ALLPAY_CHEQUE',
	AustraliaPost = 'AUSTRALIA_POST',
	BacsDeposit = 'BACS_DEPOSIT',
	Bpay = 'BPAY',
	Bpoint = 'BPOINT',
	BristolPound = 'BRISTOL_POUND',
	Btre = 'BTRE',
	Cash = 'CASH',
	Centrepay = 'CENTREPAY',
	Cheque = 'CHEQUE',
	CreditCard = 'CREDIT_CARD',
	DcaCollection = 'DCA_COLLECTION',
	DdFinalCollection = 'DD_FINAL_COLLECTION',
	DdFirstCollection = 'DD_FIRST_COLLECTION',
	DdRegularCollection = 'DD_REGULAR_COLLECTION',
	DdRePresentation = 'DD_RE_PRESENTATION',
	DebitCard = 'DEBIT_CARD',
	Dwp = 'DWP',
	EapaVoucher = 'EAPA_VOUCHER',
	Eft = 'EFT',
	ErroneousPayment = 'ERRONEOUS_PAYMENT',
	FailedRepaymentReversal = 'FAILED_REPAYMENT_REVERSAL',
	FuelDirect = 'FUEL_DIRECT',
	Heeas = 'HEEAS',
	Ideal = 'IDEAL',
	Ivr = 'IVR',
	Konbini = 'KONBINI',
	PagopaNotice = 'PAGOPA_NOTICE',
	PaymentFee = 'PAYMENT_FEE',
	PaypointCard = 'PAYPOINT_CARD',
	PaypointCash = 'PAYPOINT_CASH',
	PaypointCheque = 'PAYPOINT_CHEQUE',
	Payzone = 'PAYZONE',
	PostOfficeCard = 'POST_OFFICE_CARD',
	PostOfficeCash = 'POST_OFFICE_CASH',
	PostOfficeCheque = 'POST_OFFICE_CHEQUE',
	PostOfficeSavingsStamps = 'POST_OFFICE_SAVINGS_STAMPS',
	PrepaidCard = 'PREPAID_CARD',
	PrepayCard = 'PREPAY_CARD',
	PrepayKey = 'PREPAY_KEY',
	PrepaySmart = 'PREPAY_SMART',
	PrepayToken = 'PREPAY_TOKEN',
	TransferFromSap = 'TRANSFER_FROM_SAP',
	Unknown = 'UNKNOWN',
	Urgs = 'URGS',
}

export type AccountPaymentType = {
	__typename?: 'AccountPaymentType';
	/** Amount of payment in pence */
	amount: Scalars['BigInt'];
	/** The billing document identifier to which the payment relates. */
	billingDocumentIdentifier?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	/** The date this payment is scheduled to be debited */
	paymentDate: Scalars['Date'];
	reference: Scalars['String'];
	/** The current status of the payment. */
	status?: Maybe<AccountPaymentStatusOptions>;
	/** Surcharge amount generated by this payment. */
	surchargeAmount?: Maybe<Scalars['Int']>;
	/** The transaction type of the payment. */
	transactionType?: Maybe<AccountPaymentTransactionTypeChoices>;
};

/** The input type for the account reference. */
export type AccountReferenceInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The namespace for the reference. */
	namespace: Scalars['String'];
	/** The reference value. */
	value: Scalars['String'];
};

export type AccountReferenceType = {
	__typename?: 'AccountReferenceType';
	account: Account;
	createdAt: Scalars['DateTime'];
	namespace: Scalars['String'];
	updatedAt: Scalars['DateTime'];
	value: Scalars['String'];
};

export type AccountReminder = {
	__typename?: 'AccountReminder';
	/** Reminder content. */
	content?: Maybe<Scalars['String']>;
	/** When the reminder is due. */
	dueAt?: Maybe<Scalars['DateTime']>;
	/** The reminder type. */
	reminderType?: Maybe<AccountReminderTypes>;
};

/** Contains reminder type choices for all territories. */
export enum AccountReminderTypes {
	AccountCoolOff = 'ACCOUNT_COOL_OFF',
	AccountMigrationSyncXoserveMeterMismatch = 'ACCOUNT_MIGRATION_SYNC_XOSERVE_METER_MISMATCH',
	ActivatePaymentCancellationStopped = 'ACTIVATE_PAYMENT_CANCELLATION_STOPPED',
	AdHoc = 'AD_HOC',
	AgreementRevoked = 'AGREEMENT_REVOKED',
	AmperageChange = 'AMPERAGE_CHANGE',
	AssignedToCreditTeam = 'ASSIGNED_TO_CREDIT_TEAM',
	AusEmbeddedChildNmis = 'AUS_EMBEDDED_CHILD_NMIS',
	AusEmbeddedWaterEstimationRequiredForSkippedReading = 'AUS_EMBEDDED_WATER_ESTIMATION_REQUIRED_FOR_SKIPPED_READING',
	AusEmbeddedWaterReadingFailure = 'AUS_EMBEDDED_WATER_READING_FAILURE',
	AusGetCorrectPhoneNumber = 'AUS_GET_CORRECT_PHONE_NUMBER',
	AusIndustryCustomerOwnReadingNotSent = 'AUS_INDUSTRY_CUSTOMER_OWN_READING_NOT_SENT',
	AusVicSharedfuseNotification = 'AUS_VIC_SHAREDFUSE_NOTIFICATION',
	AutomatedBillingDisabled = 'AUTOMATED_BILLING_DISABLED',
	Billing = 'BILLING',
	BillingAmendedIntervalDataReceivedCausingOvercharge = 'BILLING_AMENDED_INTERVAL_DATA_RECEIVED_CAUSING_OVERCHARGE',
	BillingAmendedMeterReadBasic = 'BILLING_AMENDED_METER_READ_BASIC',
	BillingAmendedMeterReadCesElecBasic = 'BILLING_AMENDED_METER_READ_CES_ELEC_BASIC',
	BillingAmendedMeterReadCesElecInterval = 'BILLING_AMENDED_METER_READ_CES_ELEC_INTERVAL',
	BillingAmendedMeterReadGas = 'BILLING_AMENDED_METER_READ_GAS',
	BillingAmendedMeterReadInterval = 'BILLING_AMENDED_METER_READ_INTERVAL',
	BillingAmendedMeterReadIntervalLegacy = 'BILLING_AMENDED_METER_READ_INTERVAL_LEGACY',
	BillingAmendedMeterReadPreMigration = 'BILLING_AMENDED_METER_READ_PRE_MIGRATION',
	BillingBackdatedConcessionReceived = 'BILLING_BACKDATED_CONCESSION_RECEIVED',
	BillingBackdatedUmsRecordReceived = 'BILLING_BACKDATED_UMS_RECORD_RECEIVED',
	BillingCustomerSelfReadReceived = 'BILLING_CUSTOMER_SELF_READ_RECEIVED',
	BillingMissingRead = 'BILLING_MISSING_READ',
	BillingQuotedNtcDoesNotAlign = 'BILLING_QUOTED_NTC_DOES_NOT_ALIGN',
	BillingRegisterReplacedOrRemoved = 'BILLING_REGISTER_REPLACED_OR_REMOVED',
	CancelMoveOutUnableToReinstateFutureAgreements = 'CANCEL_MOVE_OUT_UNABLE_TO_REINSTATE_FUTURE_AGREEMENTS',
	ChurnPrevention = 'CHURN_PREVENTION',
	CommercialEnergisationInterventionRequired = 'COMMERCIAL_ENERGISATION_INTERVENTION_REQUIRED',
	CommsToBePrinted = 'COMMS_TO_BE_PRINTED',
	ContractCommsNotDelivered = 'CONTRACT_COMMS_NOT_DELIVERED',
	CosGain = 'COS_GAIN',
	CosGainMigrationEcoesMeterMismatch = 'COS_GAIN_MIGRATION_ECOES_METER_MISMATCH',
	CosGainRelRetrievalFailure = 'COS_GAIN_REL_RETRIEVAL_FAILURE',
	CosLoss = 'COS_LOSS',
	CustomerDetailsChange = 'CUSTOMER_DETAILS_CHANGE',
	CustomerReportedSmartMeterIssues = 'CUSTOMER_REPORTED_SMART_METER_ISSUES',
	D0010CreationFailure = 'D0010_CREATION_FAILURE',
	D0052CreationFailure = 'D0052_CREATION_FAILURE',
	D0205CreationFailure = 'D0205_CREATION_FAILURE',
	DeuInboundAperak = 'DEU_INBOUND_APERAK',
	DeuMasterDataSynchronisation = 'DEU_MASTER_DATA_SYNCHRONISATION',
	DeuMasterDataUpdate = 'DEU_MASTER_DATA_UPDATE',
	DeuMeterReadings = 'DEU_METER_READINGS',
	DeuSwichtingProcessFailed = 'DEU_SWICHTING_PROCESS_FAILED',
	DisconnectionMandatoryNoticeEmailFailure = 'DISCONNECTION_MANDATORY_NOTICE_EMAIL_FAILURE',
	DisconnectionMandatoryNoticeSmsFailure = 'DISCONNECTION_MANDATORY_NOTICE_SMS_FAILURE',
	DunningBestEndeavoursCall = 'DUNNING_BEST_ENDEAVOURS_CALL',
	DunningDisconnectionDeEnergisationAssessment = 'DUNNING_DISCONNECTION_DE_ENERGISATION_ASSESSMENT',
	DunningDisconnectionMandatoryNoticeEmailFailure = 'DUNNING_DISCONNECTION_MANDATORY_NOTICE_EMAIL_FAILURE',
	DunningDisconnectionOutboundReminderCall = 'DUNNING_DISCONNECTION_OUTBOUND_REMINDER_CALL',
	DunningMandatoryNoticeEmailFailure = 'DUNNING_MANDATORY_NOTICE_EMAIL_FAILURE',
	DunningOutboundReminderCall = 'DUNNING_OUTBOUND_REMINDER_CALL',
	DunningReminder = 'DUNNING_REMINDER',
	DunningReminderCall = 'DUNNING_REMINDER_CALL',
	DunningReminderDisconnectionApplication = 'DUNNING_REMINDER_DISCONNECTION_APPLICATION',
	DunningReminderFuturePayment = 'DUNNING_REMINDER_FUTURE_PAYMENT',
	DunningReminderPaymentMade = 'DUNNING_REMINDER_PAYMENT_MADE',
	DunningVacantConsumptionDeEnergisationAssessment = 'DUNNING_VACANT_CONSUMPTION_DE_ENERGISATION_ASSESSMENT',
	DuplicateCardFingerprinTs = 'DUPLICATE_CARD_FINGERPRINTs',
	EmbeddedNetworkAllChildLifeSupportRecordsAreDeregistered = 'EMBEDDED_NETWORK_ALL_CHILD_LIFE_SUPPORT_RECORDS_ARE_DEREGISTERED',
	EmbeddedNetworkExceptionDistributorOwnedParentLifeSupportRecord = 'EMBEDDED_NETWORK_EXCEPTION_DISTRIBUTOR_OWNED_PARENT_LIFE_SUPPORT_RECORD',
	EmbeddedNetworkExceptionParentMeterPointLostOrLosing = 'EMBEDDED_NETWORK_EXCEPTION_PARENT_METER_POINT_LOST_OR_LOSING',
	EmbeddedNetworkExceptionParentMeterPointReceivedDangerousLifeSupportNotification = 'EMBEDDED_NETWORK_EXCEPTION_PARENT_METER_POINT_RECEIVED_DANGEROUS_LIFE_SUPPORT_NOTIFICATION',
	EmbeddedNetworkExceptionParentMeterPointReceivedLifeSupportNotification = 'EMBEDDED_NETWORK_EXCEPTION_PARENT_METER_POINT_RECEIVED_LIFE_SUPPORT_NOTIFICATION',
	FamilyIssuesAdded = 'FAMILY_ISSUES_ADDED',
	FieldworksAccountCreatedWithNoEmailAddress = 'FIELDWORKS_ACCOUNT_CREATED_WITH_NO_EMAIL_ADDRESS',
	FieldworksAllocateNmiMarketParticipantsNotSet = 'FIELDWORKS_ALLOCATE_NMI_MARKET_PARTICIPANTS_NOT_SET',
	FieldworksAssignMeteringCoordinatorStepErrored = 'FIELDWORKS_ASSIGN_METERING_COORDINATOR_STEP_ERRORED',
	FieldworksAssignMeteringCoordinatorStepFailed = 'FIELDWORKS_ASSIGN_METERING_COORDINATOR_STEP_FAILED',
	FieldworksAssignMeteringProviderStepErrored = 'FIELDWORKS_ASSIGN_METERING_PROVIDER_STEP_ERRORED',
	FieldworksAssignMeteringProviderStepFailed = 'FIELDWORKS_ASSIGN_METERING_PROVIDER_STEP_FAILED',
	FieldworksBulkDeploymentJourneyAutoCancelled = 'FIELDWORKS_BULK_DEPLOYMENT_JOURNEY_AUTO_CANCELLED',
	FieldworksChangeRetailerErrored = 'FIELDWORKS_CHANGE_RETAILER_ERRORED',
	FieldworksChangeRetailerFailed = 'FIELDWORKS_CHANGE_RETAILER_FAILED',
	FieldworksContactSoRecipientToUpdateTheSoDetails = 'FIELDWORKS_CONTACT_SO_RECIPIENT_TO_UPDATE_THE_SO_DETAILS',
	FieldworksExpectedMeterPointNotCreated = 'FIELDWORKS_EXPECTED_METER_POINT_NOT_CREATED',
	FieldworksExpectedMeterReadNotReceived = 'FIELDWORKS_EXPECTED_METER_READ_NOT_RECEIVED',
	FieldworksExpectedNtcsNotReceived = 'FIELDWORKS_EXPECTED_NTCS_NOT_RECEIVED',
	FieldworksInvestigateWhetherJourneyNeedsContinuation = 'FIELDWORKS_INVESTIGATE_WHETHER_JOURNEY_NEEDS_CONTINUATION',
	FieldworksJourneyAttachments = 'FIELDWORKS_JOURNEY_ATTACHMENTS',
	FieldworksJourneyCancelledDueToLifeSupportRegistration = 'FIELDWORKS_JOURNEY_CANCELLED_DUE_TO_LIFE_SUPPORT_REGISTRATION',
	FieldworksMaintainRegisterBillableOverrideForUnsolicitedMeterChanges = 'FIELDWORKS_MAINTAIN_REGISTER_BILLABLE_OVERRIDE_FOR_UNSOLICITED_METER_CHANGES',
	FieldworksManuallyCompleteCustomerMoveIn = 'FIELDWORKS_MANUALLY_COMPLETE_CUSTOMER_MOVE_IN',
	FieldworksMeterpointIsNotActive = 'FIELDWORKS_METERPOINT_IS_NOT_ACTIVE',
	FieldworksMeterFaultJourneyAutoCancelled = 'FIELDWORKS_METER_FAULT_JOURNEY_AUTO_CANCELLED',
	FieldworksMeterFaultNcomCommsFailed = 'FIELDWORKS_METER_FAULT_NCOM_COMMS_FAILED',
	FieldworksMeterFaultReceived = 'FIELDWORKS_METER_FAULT_RECEIVED',
	FieldworksMirnDiscoveryFailed = 'FIELDWORKS_MIRN_DISCOVERY_FAILED',
	FieldworksMoveOutCancelledWhileSupplyAbolishmentInProgress = 'FIELDWORKS_MOVE_OUT_CANCELLED_WHILE_SUPPLY_ABOLISHMENT_IN_PROGRESS',
	FieldworksMultipleOpenJourneysForMeterPoint = 'FIELDWORKS_MULTIPLE_OPEN_JOURNEYS_FOR_METER_POINT',
	FieldworksNewConnectionCompletionReview = 'FIELDWORKS_NEW_CONNECTION_COMPLETION_REVIEW',
	FieldworksNewConnectionDataNotValid = 'FIELDWORKS_NEW_CONNECTION_DATA_NOT_VALID',
	FieldworksObtainCustomerApprovalBeforeProgressing = 'FIELDWORKS_OBTAIN_CUSTOMER_APPROVAL_BEFORE_PROGRESSING',
	FieldworksObtainSupplyAbolishmentApproval = 'FIELDWORKS_OBTAIN_SUPPLY_ABOLISHMENT_APPROVAL',
	FieldworksPayerContactFailedValidation = 'FIELDWORKS_PAYER_CONTACT_FAILED_VALIDATION',
	FieldworksRequestCustomerClassificationStepErrored = 'FIELDWORKS_REQUEST_CUSTOMER_CLASSIFICATION_STEP_ERRORED',
	FieldworksRequestCustomerClassificationStepFailed = 'FIELDWORKS_REQUEST_CUSTOMER_CLASSIFICATION_STEP_FAILED',
	FieldworksServiceOrderAttachments = 'FIELDWORKS_SERVICE_ORDER_ATTACHMENTS',
	FieldworksServiceOrderCompleted = 'FIELDWORKS_SERVICE_ORDER_COMPLETED',
	FieldworksServiceOrderFailed = 'FIELDWORKS_SERVICE_ORDER_FAILED',
	FieldworksServiceOrderPartiallyCompleted = 'FIELDWORKS_SERVICE_ORDER_PARTIALLY_COMPLETED',
	FieldworksServiceOrderStatusUpdated = 'FIELDWORKS_SERVICE_ORDER_STATUS_UPDATED',
	FieldworksServiceOrderUnableToAccessWithCustomerConsultation = 'FIELDWORKS_SERVICE_ORDER_UNABLE_TO_ACCESS_WITH_CUSTOMER_CONSULTATION',
	FieldworksSupplyPeriodDoesNotExistForMeterpoint = 'FIELDWORKS_SUPPLY_PERIOD_DOES_NOT_EXIST_FOR_METERPOINT',
	FieldworksUploadAttachmentToJemenaPortal = 'FIELDWORKS_UPLOAD_ATTACHMENT_TO_JEMENA_PORTAL',
	FinalBillingUnableToBill = 'FINAL_BILLING_UNABLE_TO_BILL',
	FlowFileError = 'FLOW_FILE_ERROR',
	FollowUpPaymentPromise = 'FOLLOW_UP_PAYMENT_PROMISE',
	FraActivationProcessAwaitingSwitchInConfirmation = 'FRA_ACTIVATION_PROCESS_AWAITING_SWITCH_IN_CONFIRMATION',
	FraActivationProcessAwaitingSwitchReadings = 'FRA_ACTIVATION_PROCESS_AWAITING_SWITCH_READINGS',
	FraActivationProcessCommanderSouscription = 'FRA_ACTIVATION_PROCESS_COMMANDER_SOUSCRIPTION',
	FraActivationProcessGasProviderChangeRequest = 'FRA_ACTIVATION_PROCESS_GAS_PROVIDER_CHANGE_REQUEST',
	FraActivationProcessInceptionChecks = 'FRA_ACTIVATION_PROCESS_INCEPTION_CHECKS',
	FraActivationProcessPrmEligibility = 'FRA_ACTIVATION_PROCESS_PRM_ELIGIBILITY',
	FraActivationProcessScheduleSiteworks = 'FRA_ACTIVATION_PROCESS_SCHEDULE_SITEWORKS',
	FraElecActivationProcessWrongSwitchReadingsForProviderCalendarTemporalClasses = 'FRA_ELEC_ACTIVATION_PROCESS_WRONG_SWITCH_READINGS_FOR_PROVIDER_CALENDAR_TEMPORAL_CLASSES',
	FraEnergyChequeUnknown = 'FRA_ENERGY_CHEQUE_UNKNOWN',
	FraFsl = 'FRA_FSL',
	FraTerminationProcessNeedsIntervention = 'FRA_TERMINATION_PROCESS_NEEDS_INTERVENTION',
	FraTerminationRequestErrored = 'FRA_TERMINATION_REQUEST_ERRORED',
	GasExceptionCustomerDetailsNotificationFailed = 'GAS_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_FAILED',
	GasExceptionCustomerTransferCancelled = 'GAS_EXCEPTION_CUSTOMER_TRANSFER_CANCELLED',
	GasExceptionCustomerTransferRejected = 'GAS_EXCEPTION_CUSTOMER_TRANSFER_REJECTED',
	GasExceptionLifeSupportNotificationFailed = 'GAS_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_FAILED',
	GasExceptionMeterDataVerifyRequestFailed = 'GAS_EXCEPTION_METER_DATA_VERIFY_REQUEST_FAILED',
	GasExceptionMeterDataVerifyResponseNoChangeWithExplanation = 'GAS_EXCEPTION_METER_DATA_VERIFY_RESPONSE_NO_CHANGE_WITH_EXPLANATION',
	GasExceptionMeterDataVerifyResponseOverdue = 'GAS_EXCEPTION_METER_DATA_VERIFY_RESPONSE_OVERDUE',
	GasExceptionMeterDataVerifyResponseWithoutRevisedRead = 'GAS_EXCEPTION_METER_DATA_VERIFY_RESPONSE_WITHOUT_REVISED_READ',
	GasExceptionSiteAccessDetailsNotificationFailed = 'GAS_EXCEPTION_SITE_ACCESS_DETAILS_NOTIFICATION_FAILED',
	GasExceptionSiteAccessDetailsReceivedForNonExistentMeter = 'GAS_EXCEPTION_SITE_ACCESS_DETAILS_RECEIVED_FOR_NON_EXISTENT_METER',
	GasExceptionSiteAddressDetailsNotificationFailed = 'GAS_EXCEPTION_SITE_ADDRESS_DETAILS_NOTIFICATION_FAILED',
	GasManualServiceOrderRequiredForEnrolment = 'GAS_MANUAL_SERVICE_ORDER_REQUIRED_FOR_ENROLMENT',
	GasNotificationChangeOfSiteAddress = 'GAS_NOTIFICATION_CHANGE_OF_SITE_ADDRESS',
	GasReadingRemovedInstalledReceived = 'GAS_READING_REMOVED_INSTALLED_RECEIVED',
	GasUnknownCustomerClassificationCodeReceived = 'GAS_UNKNOWN_CUSTOMER_CLASSIFICATION_CODE_RECEIVED',
	GbrAddPhotoToMeterReading = 'GBR_ADD_PHOTO_TO_METER_READING',
	GbrGetPhotoOfMeterAndCallSupplier = 'GBR_GET_PHOTO_OF_METER_AND_CALL_SUPPLIER',
	GbrRaiseDispute = 'GBR_RAISE_DISPUTE',
	GbrVerifyMhhsMessage = 'GBR_VERIFY_MHHS_MESSAGE',
	HardshipGraduationAssessment = 'HARDSHIP_GRADUATION_ASSESSMENT',
	HardshipNoPaymentPlan = 'HARDSHIP_NO_PAYMENT_PLAN',
	HardshipRemovalAssessment = 'HARDSHIP_REMOVAL_ASSESSMENT',
	HeldBillingDocument = 'HELD_BILLING_DOCUMENT',
	HeldStatement = 'HELD_STATEMENT',
	IndustryChangeOfSupplierDoubleGain = 'INDUSTRY_CHANGE_OF_SUPPLIER_DOUBLE_GAIN',
	IndustryCustomerTransferDelayed = 'INDUSTRY_CUSTOMER_TRANSFER_DELAYED',
	IndustryExceptionChangeOfSupplierCancelled = 'INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_CANCELLED',
	IndustryExceptionChangeOfSupplierConflictingPeriod = 'INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_CONFLICTING_PERIOD',
	IndustryExceptionChangeOfSupplierGainCompletionOverdue = 'INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_GAIN_COMPLETION_OVERDUE',
	IndustryExceptionChangeOfSupplierObjected = 'INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_OBJECTED',
	IndustryExceptionChangeOfSupplierRejected = 'INDUSTRY_EXCEPTION_CHANGE_OF_SUPPLIER_REJECTED',
	IndustryExceptionChangeRequestCancellationFailed = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_CANCELLATION_FAILED',
	IndustryExceptionChangeRequestCancelled = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_CANCELLED',
	IndustryExceptionChangeRequestCompleted = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_COMPLETED',
	IndustryExceptionChangeRequestObjected = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_OBJECTED',
	IndustryExceptionChangeRequestObjectionMissingAck = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_OBJECTION_MISSING_ACK',
	IndustryExceptionChangeRequestObjectionRejected = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_OBJECTION_REJECTED',
	IndustryExceptionChangeRequestObjectionWithdrawalRejected = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_OBJECTION_WITHDRAWAL_REJECTED',
	IndustryExceptionChangeRequestRejected = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_REJECTED',
	IndustryExceptionChangeRequestWithdrawalRejected = 'INDUSTRY_EXCEPTION_CHANGE_REQUEST_WITHDRAWAL_REJECTED',
	IndustryExceptionCustomerDetailsNotificationInvalidBillingAddress = 'INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_INVALID_BILLING_ADDRESS',
	IndustryExceptionCustomerDetailsNotificationMissingBusinessAcceptance = 'INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_MISSING_BUSINESS_ACCEPTANCE',
	IndustryExceptionCustomerDetailsNotificationMissingMandatoryFields = 'INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_MISSING_MANDATORY_FIELDS',
	IndustryExceptionCustomerDetailsNotificationRejected = 'INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_NOTIFICATION_REJECTED',
	IndustryExceptionCustomerDetailsRequestSpecialReason = 'INDUSTRY_EXCEPTION_CUSTOMER_DETAILS_REQUEST_SPECIAL_REASON',
	IndustryExceptionFailedToCancelDeenergisationServiceOrder = 'INDUSTRY_EXCEPTION_FAILED_TO_CANCEL_DEENERGISATION_SERVICE_ORDER',
	IndustryExceptionHouseMoveEnrolmentCannotCalculateMoveInReading = 'INDUSTRY_EXCEPTION_HOUSE_MOVE_ENROLMENT_CANNOT_CALCULATE_MOVE_IN_READING',
	IndustryExceptionHouseMoveEnrolmentServiceOrderAlreadyInProgress = 'INDUSTRY_EXCEPTION_HOUSE_MOVE_ENROLMENT_SERVICE_ORDER_ALREADY_IN_PROGRESS',
	IndustryExceptionHouseMoveEnrolmentUnableToCopyLastMeterReading = 'INDUSTRY_EXCEPTION_HOUSE_MOVE_ENROLMENT_UNABLE_TO_COPY_LAST_METER_READING',
	IndustryExceptionLifeSupportContactUserRemovedFromAccount = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_CONTACT_USER_REMOVED_FROM_ACCOUNT',
	IndustryExceptionLifeSupportMultipleAccountsMatches = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_MULTIPLE_ACCOUNTS_MATCHES',
	IndustryExceptionLifeSupportMultipleLifeSupportContactMatches = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_MULTIPLE_LIFE_SUPPORT_CONTACT_MATCHES',
	IndustryExceptionLifeSupportNotificationContainsUnexpectedData = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_CONTAINS_UNEXPECTED_DATA',
	IndustryExceptionLifeSupportNotificationFailedToSend = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_FAILED_TO_SEND',
	IndustryExceptionLifeSupportNotificationInvalidContactMethod = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_INVALID_CONTACT_METHOD',
	IndustryExceptionLifeSupportNotificationInvalidPhone = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_INVALID_PHONE',
	IndustryExceptionLifeSupportNotificationMissingBusinessAcceptance = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_MISSING_BUSINESS_ACCEPTANCE',
	IndustryExceptionLifeSupportNotificationReceivedFromNonRegistrationOwner = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_RECEIVED_FROM_NON_REGISTRATION_OWNER',
	IndustryExceptionLifeSupportNotificationRejected = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_REJECTED',
	IndustryExceptionLifeSupportNotificationUnknownContact = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_NOTIFICATION_UNKNOWN_CONTACT',
	IndustryExceptionLifeSupportRequestMissingBusinessAcceptance = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_REQUEST_MISSING_BUSINESS_ACCEPTANCE',
	IndustryExceptionLifeSupportRequestMissingLifeSupportNotification = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_REQUEST_MISSING_LIFE_SUPPORT_NOTIFICATION',
	IndustryExceptionLifeSupportRequestRejected = 'INDUSTRY_EXCEPTION_LIFE_SUPPORT_REQUEST_REJECTED',
	IndustryExceptionMeterPointEnrolmentIncompleteCouldNotSendServiceOrder = 'INDUSTRY_EXCEPTION_METER_POINT_ENROLMENT_INCOMPLETE_COULD_NOT_SEND_SERVICE_ORDER',
	IndustryExceptionNextScheduledReadDateTooFarInFuture = 'INDUSTRY_EXCEPTION_NEXT_SCHEDULED_READ_DATE_TOO_FAR_IN_FUTURE',
	IndustryExceptionRelinkingOccurredDuringSdrSync = 'INDUSTRY_EXCEPTION_RELINKING_OCCURRED_DURING_SDR_SYNC',
	IndustryExceptionSiteAccessDetailsNotificationMissingBusinessAcceptance = 'INDUSTRY_EXCEPTION_SITE_ACCESS_DETAILS_NOTIFICATION_MISSING_BUSINESS_ACCEPTANCE',
	IndustryExceptionSiteAccessDetailsNotificationRejected = 'INDUSTRY_EXCEPTION_SITE_ACCESS_DETAILS_NOTIFICATION_REJECTED',
	IndustryExceptionStandingDataPropertiesAddressFailedToUpdate = 'INDUSTRY_EXCEPTION_STANDING_DATA_PROPERTIES_ADDRESS_FAILED_TO_UPDATE',
	IndustryExceptionUnableToEnrolMeterPointIncompleteCouldNotSendChangeRequest = 'INDUSTRY_EXCEPTION_UNABLE_TO_ENROL_METER_POINT_INCOMPLETE_COULD_NOT_SEND_CHANGE_REQUEST',
	IndustryExceptionUnableToEnrolMeterPointInvalidNmiMeterStatus = 'INDUSTRY_EXCEPTION_UNABLE_TO_ENROL_METER_POINT_INVALID_NMI_METER_STATUS',
	IndustryExceptionUnableToEnrolMeterPointNextScheduledReadDatePast = 'INDUSTRY_EXCEPTION_UNABLE_TO_ENROL_METER_POINT_NEXT_SCHEDULED_READ_DATE_PAST',
	IndustryExceptionUnableToProcessRolr = 'INDUSTRY_EXCEPTION_UNABLE_TO_PROCESS_ROLR',
	IndustryLifeSupportCancelDeregistrationFailed = 'INDUSTRY_LIFE_SUPPORT_CANCEL_DEREGISTRATION_FAILED',
	IndustryLifeSupportManualBestEndeavourRequired = 'INDUSTRY_LIFE_SUPPORT_MANUAL_BEST_ENDEAVOUR_REQUIRED',
	IndustryLifeSupportRegistrationFollowUpRequired = 'INDUSTRY_LIFE_SUPPORT_REGISTRATION_FOLLOW_UP_REQUIRED',
	IndustryLifeSupportReviewAfterCancelledMoveOutForNextAccount = 'INDUSTRY_LIFE_SUPPORT_REVIEW_AFTER_CANCELLED_MOVE_OUT_FOR_NEXT_ACCOUNT',
	IndustryLifeSupportReviewAttemptedCancelledMoveOutForNextAccount = 'INDUSTRY_LIFE_SUPPORT_REVIEW_ATTEMPTED_CANCELLED_MOVE_OUT_FOR_NEXT_ACCOUNT',
	IndustryLifeSupportReviewDeregistration = 'INDUSTRY_LIFE_SUPPORT_REVIEW_DEREGISTRATION',
	IndustryLifeSupportReviewPostDeregistration = 'INDUSTRY_LIFE_SUPPORT_REVIEW_POST_DEREGISTRATION',
	IndustryLifeSupportReviewPostDeregistrationComms = 'INDUSTRY_LIFE_SUPPORT_REVIEW_POST_DEREGISTRATION_COMMS',
	IndustryManualActionRequired = 'INDUSTRY_MANUAL_ACTION_REQUIRED',
	IndustryManualCustomerDetailsNotificationRequired = 'INDUSTRY_MANUAL_CUSTOMER_DETAILS_NOTIFICATION_REQUIRED',
	IndustryManualLifeSupportNotificationRequired = 'INDUSTRY_MANUAL_LIFE_SUPPORT_NOTIFICATION_REQUIRED',
	IndustryMeterPointMissingCustomerClassification = 'INDUSTRY_METER_POINT_MISSING_CUSTOMER_CLASSIFICATION',
	IndustryReadingsNotSent = 'INDUSTRY_READINGS_NOT_SENT',
	IndustrySendLifeSupportDeRegistrationForm = 'INDUSTRY_SEND_LIFE_SUPPORT_DE_REGISTRATION_FORM',
	IndustryUnableToCreateRecord = 'INDUSTRY_UNABLE_TO_CREATE_RECORD',
	IndustryVicDroManualLifeSupportExtensionRequest = 'INDUSTRY_VIC_DRO_MANUAL_LIFE_SUPPORT_EXTENSION_REQUEST',
	IndustryWarningMessageReceived = 'INDUSTRY_WARNING_MESSAGE_RECEIVED',
	ItaAccountWithdrawnWithoutNotification = 'ITA_ACCOUNT_WITHDRAWN_WITHOUT_NOTIFICATION',
	ItaProcessWelcomePackStep = 'ITA_PROCESS_WELCOME_PACK_STEP',
	JpnBillingFixInvalidChargeData = 'JPN_BILLING_FIX_INVALID_CHARGE_DATA',
	JpnBillingPatternChangedDueToReadingDateChange = 'JPN_BILLING_PATTERN_CHANGED_DUE_TO_READING_DATE_CHANGE',
	JpnCallForDunningCampaign = 'JPN_CALL_FOR_DUNNING_CAMPAIGN',
	JpnConfirmationOfRelocation = 'JPN_CONFIRMATION_OF_RELOCATION',
	JpnConfirmPaymentForDunningCampaign = 'JPN_CONFIRM_PAYMENT_FOR_DUNNING_CAMPAIGN',
	JpnFailedToSendSmsForBillingInfo = 'JPN_FAILED_TO_SEND_SMS_FOR_BILLING_INFO',
	JpnFailedToSendSmsForConvenienceStorePayment = 'JPN_FAILED_TO_SEND_SMS_FOR_CONVENIENCE_STORE_PAYMENT',
	JpnFinalReadingOutsideAgreement = 'JPN_FINAL_READING_OUTSIDE_AGREEMENT',
	JpnMultipleValidReferrals = 'JPN_MULTIPLE_VALID_REFERRALS',
	JpnReadingsNotReceived = 'JPN_READINGS_NOT_RECEIVED',
	JpnShortTermMoveInRejection = 'JPN_SHORT_TERM_MOVE_IN_REJECTION',
	JpnSupplyDetailsUpdateConfirmation = 'JPN_SUPPLY_DETAILS_UPDATE_CONFIRMATION',
	JpnSupplyPointFailsCanSupplyCheck = 'JPN_SUPPLY_POINT_FAILS_CAN_SUPPLY_CHECK',
	JpnSwitchInScheduleOverlap = 'JPN_SWITCH_IN_SCHEDULE_OVERLAP',
	Kmt = 'KMT',
	MailFailed = 'MAIL_FAILED',
	MailReturned = 'MAIL_RETURNED',
	MandatoryCommsNotDelivered = 'MANDATORY_COMMS_NOT_DELIVERED',
	MarketSupplyExceptionAgreementFailedToCreate = 'MARKET_SUPPLY_EXCEPTION_AGREEMENT_FAILED_TO_CREATE',
	MarketSupplyExceptionAgreementFailedToReverseTermination = 'MARKET_SUPPLY_EXCEPTION_AGREEMENT_FAILED_TO_REVERSE_TERMINATION',
	MarketSupplyExceptionAgreementFailedToTerminate = 'MARKET_SUPPLY_EXCEPTION_AGREEMENT_FAILED_TO_TERMINATE',
	MarketSupplyExceptionAgreementFailedToUpdate = 'MARKET_SUPPLY_EXCEPTION_AGREEMENT_FAILED_TO_UPDATE',
	MarketSupplyExceptionMissingAccountQuotedProduct = 'MARKET_SUPPLY_EXCEPTION_MISSING_ACCOUNT_QUOTED_PRODUCT',
	MeterExchangeOneWayNotification = 'METER_EXCHANGE_ONE_WAY_NOTIFICATION',
	MeterFaultAndIssueOneWayNotificationAccepted = 'METER_FAULT_AND_ISSUE_ONE_WAY_NOTIFICATION_ACCEPTED',
	MeterFaultAndIssueOneWayNotificationRejected = 'METER_FAULT_AND_ISSUE_ONE_WAY_NOTIFICATION_REJECTED',
	MoveIn = 'MOVE_IN',
	MoveInCesLifeSupportRequired = 'MOVE_IN_CES_LIFE_SUPPORT_REQUIRED',
	MoveInCommsNotDelivered = 'MOVE_IN_COMMS_NOT_DELIVERED',
	MoveInDefaultPaymentScheduleFailed = 'MOVE_IN_DEFAULT_PAYMENT_SCHEDULE_FAILED',
	MoveInMoveOutManualProcess = 'MOVE_IN_MOVE_OUT_MANUAL_PROCESS',
	MoveOut = 'MOVE_OUT',
	NetworkTariffOneWayNotification = 'NETWORK_TARIFF_ONE_WAY_NOTIFICATION',
	NewPsrAddedToPrepayAccount = 'NEW_PSR_ADDED_TO_PREPAY_ACCOUNT',
	NonEnergyPaymentCallReminder = 'NON_ENERGY_PAYMENT_CALL_REMINDER',
	NoticeOfMeteringWorksOneWayNotification = 'NOTICE_OF_METERING_WORKS_ONE_WAY_NOTIFICATION',
	NzBillingQuery = 'NZ_BILLING_QUERY',
	NzDunning = 'NZ_DUNNING',
	NzMeteringQuery = 'NZ_METERING_QUERY',
	NzSolarMeterChange = 'NZ_SOLAR_METER_CHANGE',
	NzSwitchingWithdrawal = 'NZ_SWITCHING_WITHDRAWAL',
	NzVacantProperty = 'NZ_VACANT_PROPERTY',
	ObsoleteBillingAmendedStandingDataRegisterChanged = 'OBSOLETE_BILLING_AMENDED_STANDING_DATA_REGISTER_CHANGED',
	ObsoleteBillingAmendedStandingDataRegisterDeleted = 'OBSOLETE_BILLING_AMENDED_STANDING_DATA_REGISTER_DELETED',
	PaymentsFailedRepayment = 'PAYMENTS_FAILED_REPAYMENT',
	PaymentInstructionFailed = 'PAYMENT_INSTRUCTION_FAILED',
	PaymentPlan = 'PAYMENT_PLAN',
	PaymentPlanHardshipCompletion = 'PAYMENT_PLAN_HARDSHIP_COMPLETION',
	PaymentPlanHardshipCompletionWorkflowCancelled = 'PAYMENT_PLAN_HARDSHIP_COMPLETION_WORKFLOW_CANCELLED',
	PaymentPlanMissedInstalment = 'PAYMENT_PLAN_MISSED_INSTALMENT',
	PaymentScheduleFailedToCreate = 'PAYMENT_SCHEDULE_FAILED_TO_CREATE',
	PlannedInterruption = 'PLANNED_INTERRUPTION',
	PlannedInterruptionMedicalDependency = 'PLANNED_INTERRUPTION_MEDICAL_DEPENDENCY',
	PlannedInterruptionNotificationSentToLifeSupportCustomer = 'PLANNED_INTERRUPTION_NOTIFICATION_SENT_TO_LIFE_SUPPORT_CUSTOMER',
	PlannedInterruptionOneWayNotification = 'PLANNED_INTERRUPTION_ONE_WAY_NOTIFICATION',
	PlannedInterruptionOneWayNotificationRejected = 'PLANNED_INTERRUPTION_ONE_WAY_NOTIFICATION_REJECTED',
	PostHardshipCancellation = 'POST_HARDSHIP_CANCELLATION',
	PropertyAddressNeedsUpdate = 'PROPERTY_ADDRESS_NEEDS_UPDATE',
	PsrImportCouldNotIdentifyAccountUser = 'PSR_IMPORT_COULD_NOT_IDENTIFY_ACCOUNT_USER',
	SaConcessionStatementClosed = 'SA_CONCESSION_STATEMENT_CLOSED',
	ScheduledBillingAddressUpdate = 'SCHEDULED_BILLING_ADDRESS_UPDATE',
	ScheduledOccupierPackSend = 'SCHEDULED_OCCUPIER_PACK_SEND',
	ServiceOrderAcknowledgementOverdue = 'SERVICE_ORDER_ACKNOWLEDGEMENT_OVERDUE',
	ServiceOrderCancellationFailed = 'SERVICE_ORDER_CANCELLATION_FAILED',
	ServiceOrderCancellationRequestRejected = 'SERVICE_ORDER_CANCELLATION_REQUEST_REJECTED',
	ServiceOrderFailed = 'SERVICE_ORDER_FAILED',
	ServiceOrderInitialResponseOverdue = 'SERVICE_ORDER_INITIAL_RESPONSE_OVERDUE',
	ServiceOrderNotCompleted = 'SERVICE_ORDER_NOT_COMPLETED',
	ServiceOrderOtherJobEnquiryCode = 'SERVICE_ORDER_OTHER_JOB_ENQUIRY_CODE',
	ServiceOrderPartiallyCompleted = 'SERVICE_ORDER_PARTIALLY_COMPLETED',
	ServiceOrderRequestRejected = 'SERVICE_ORDER_REQUEST_REJECTED',
	ServiceOrderUnableToCharge = 'SERVICE_ORDER_UNABLE_TO_CHARGE',
	ServiceOrderUnsolicitedReceived = 'SERVICE_ORDER_UNSOLICITED_RECEIVED',
	ServiceOrderUnsolicitedReceivedDeenergisedMeterPoint = 'SERVICE_ORDER_UNSOLICITED_RECEIVED_DEENERGISED_METER_POINT',
	SmartflexDeviceIntegrationPostponed = 'SMARTFLEX_DEVICE_INTEGRATION_POSTPONED',
	SmartChangeOfTenancyInProgress = 'SMART_CHANGE_OF_TENANCY_IN_PROGRESS',
	SmartChangeOfTenancyRequestFailed = 'SMART_CHANGE_OF_TENANCY_REQUEST_FAILED',
	SmartMeterModeChangeUnsupported = 'SMART_METER_MODE_CHANGE_UNSUPPORTED',
	SmartPrepayAddDebtFailure = 'SMART_PREPAY_ADD_DEBT_FAILURE',
	SmartPrepayNeedToAdjustDebt = 'SMART_PREPAY_NEED_TO_ADJUST_DEBT',
	SmartPrepayTopUpFailure = 'SMART_PREPAY_TOP_UP_FAILURE',
	SmartPrepayTopUpMultipleActiveProcesses = 'SMART_PREPAY_TOP_UP_MULTIPLE_ACTIVE_PROCESSES',
	SmartPrepayTopUpUtrn = 'SMART_PREPAY_TOP_UP_UTRN',
	Smets2DataRequestFollowup = 'SMETS2_DATA_REQUEST_FOLLOWUP',
	Smets2HealthCheck = 'SMETS2_HEALTH_CHECK',
	Smets2IhdHealthCheck = 'SMETS2_IHD_HEALTH_CHECK',
	SolarExportCreditsExcess = 'SOLAR_EXPORT_CREDITS_EXCESS',
	SolrFinalBillReminder = 'SOLR_FINAL_BILL_REMINDER',
	SpecialReadCancellationFailed = 'SPECIAL_READ_CANCELLATION_FAILED',
	SpecialReadFailed = 'SPECIAL_READ_FAILED',
	SpecialReadOutstanding = 'SPECIAL_READ_OUTSTANDING',
	SpecialReadWithEstimateReadReceived = 'SPECIAL_READ_WITH_ESTIMATE_READ_RECEIVED',
	UnsupportedFeature = 'UNSUPPORTED_FEATURE',
	UnsupportedPaymentDay = 'UNSUPPORTED_PAYMENT_DAY',
	WaterAccountReversion = 'WATER_ACCOUNT_REVERSION',
	WaterMeterReadingIssue = 'WATER_METER_READING_ISSUE',
	WithdrawalReceived = 'WITHDRAWAL_RECEIVED',
	WorkflowCancellationFailed = 'WORKFLOW_CANCELLATION_FAILED',
	WorkflowStepErrored = 'WORKFLOW_STEP_ERRORED',
	WorkflowStepFailed = 'WORKFLOW_STEP_FAILED',
}

export type AccountRepaymentConnectionTypeConnection = {
	__typename?: 'AccountRepaymentConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<AccountRepaymentConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AccountRepaymentConnectionType` and its cursor. */
export type AccountRepaymentConnectionTypeEdge = {
	__typename?: 'AccountRepaymentConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<AccountRepaymentType>;
};

/** An enumeration. */
export enum AccountRepaymentStatusOptions {
	/** The repayment has been approved but not made yet. */
	Approved = 'APPROVED',
	/** The repayment failed permanently. This could be because of technical issues, or if the merchant rejects the payment for some reason. The payment will need to be retried by ops. */
	Failed = 'FAILED',
	/** Payments made in a previous system and then imported into Kraken. */
	Historic = 'HISTORIC',
	/** The repayment has been made to the merchant to be sent to the customer. This is a terminal state, we don't get any further confirmation. */
	Paid = 'PAID',
	/** The request for a repayment has been received but not actioned yet. */
	Requested = 'REQUESTED',
	/** The payment has been submitted to the merchant. It is still possible for this repayment to fail. */
	Submitted = 'SUBMITTED',
	/** Third Party payments are those recorded for financial purposes in a different system but should be added to statements. */
	ThirdParty = 'THIRD_PARTY',
}

export type AccountRepaymentType = {
	__typename?: 'AccountRepaymentType';
	/** Amount of payment in pence */
	amount: Scalars['BigInt'];
	id: Scalars['ID'];
	/** The date this payment is scheduled to be debited */
	paymentDate: Scalars['Date'];
	/** The current status of the repayment. */
	status?: Maybe<AccountRepaymentStatusOptions>;
};

export type AccountSearchInputType = {
	/** Internal account id (not account number). */
	account?: InputMaybe<Scalars['String']>;
	/** The account number eg. A-FF15AE70. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** Account Reference. */
	accountReferences?: InputMaybe<Scalars['String']>;
	/** Account or Billing name. */
	billingName?: InputMaybe<Scalars['String']>;
	/** Business name. */
	businessName?: InputMaybe<Scalars['String']>;
	/** Business identifier or number. */
	businessNumber?: InputMaybe<Scalars['String']>;
	/** SPIN of electricity supply point. */
	elecspin?: InputMaybe<Scalars['String']>;
	/** Gas SPIN of supply point. */
	gasspin?: InputMaybe<Scalars['String']>;
	/** Location (Supply or Billing, full or partial, address or post code). */
	location?: InputMaybe<Scalars['String']>;
	/** Meter Serial Number. */
	meterSerialNumber?: InputMaybe<Scalars['String']>;
	/** The portfolio number eg. P-A123B456. */
	portfolioNumber?: InputMaybe<Scalars['String']>;
	/** SPIN of supply points. */
	spin?: InputMaybe<Scalars['String']>;
	/** Statements. */
	statements?: InputMaybe<Scalars['String']>;
	/** URN Number. */
	urn?: InputMaybe<Scalars['String']>;
	/** The Account User ID (not account number). */
	user?: InputMaybe<Scalars['String']>;
};

/** A single accout search hit */
export type AccountSearchItemType = {
	__typename?: 'AccountSearchItemType';
	/** The account found. */
	account?: Maybe<Account>;
	/** How well the account matched the search terms. */
	score?: Maybe<Scalars['Decimal']>;
};

/** An enumeration. */
export enum AccountStatementStatus {
	Closed = 'CLOSED',
	Open = 'OPEN',
}

export enum AccountStatus {
	/** Supply could have started, be ongoing or ended. */
	Active = 'ACTIVE',
	/** Dormant. Users should not be able to log into dormant accounts. */
	Dormant = 'DORMANT',
	/** An error occurred when we tried to enroll a meter point. This may be deprecated in future in favour of exposing this through enrollment property of a meter point. */
	EnrolmentError = 'ENROLMENT_ERROR',
	/** Meter point enrollment was rejected. This may be deprecated in future in favour of exposing this through enrollment property of a meter point. */
	EnrolmentRejected = 'ENROLMENT_REJECTED',
	/** Account requires processes to be completed before supply can be set up */
	Incomplete = 'INCOMPLETE',
	/** A pending account is one that has been created but no registrations have started. */
	Pending = 'PENDING',
	/** Void. Account created in error. */
	Void = 'VOID',
	/** Withdrawn before supply started */
	Withdrawn = 'WITHDRAWN',
}

/** An enumeration. */
export enum AccountTypeChoices {
	/** An account designed to supply/bill business premises. */
	Business = 'BUSINESS',
	/** An account created when we supply a business premises but do not have details for the occupants. */
	BusinessOccupier = 'BUSINESS_OCCUPIER',
	/** An account created when we supply a business premises and know there are definitely no occupants. */
	BusinessVacant = 'BUSINESS_VACANT',
	/** An account designed to supply/bill domestic premises. */
	Domestic = 'DOMESTIC',
	/** An account created when we supply domestic premises that are managed by a business, i.e., a B2B2C model. */
	Managed = 'MANAGED',
	/** An account created when we supply a domestic premises but do not have details for the occupants. */
	Occupier = 'OCCUPIER',
	/** An account which is responsible for all other accounts in the portfolio, i.e. pays the bills for them. */
	PortfolioLead = 'PORTFOLIO_LEAD',
	/** An account created when we supply a domestic premises and know there are definitely no occupants. */
	Vacant = 'VACANT',
}

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUser = {
	__typename?: 'AccountUser';
	/** Additional user details, if they exist. */
	accountUserMeta?: Maybe<AccountUserMeta>;
	/** List of roles a user has for each account they're linked to. */
	accountUserRoles?: Maybe<Array<Maybe<AccountUserRoleType>>>;
	/** List of accounts that the user is linked to either via portfolio role or account role. */
	accounts?: Maybe<Array<Maybe<AccountInterface>>>;
	/** List of alternative phone numbers for the account user. */
	alternativePhoneNumbers?: Maybe<Array<Maybe<Scalars['String']>>>;
	/** AccountUser's date of birth. */
	dateOfBirth?: Maybe<Scalars['Date']>;
	/** List of details linked to this user. */
	details?: Maybe<Array<Maybe<AccountUserDetailType>>>;
	/**
	 * We recommend you use fullName instead of this field.
	 * @deprecated The 'displayName' field is deprecated.
	 *
	 * Please use fullName instead of this field.
	 *
	 * - Marked as deprecated on 2019-12-11.
	 * - Will be removed on 2024-01-01.
	 */
	displayName?: Maybe<Scalars['String']>;
	email: Scalars['String'];
	familyName: Scalars['String'];
	/**
	 * We recommend you use preferredName or fullName instead of this field.
	 * @deprecated The 'firstName' field is deprecated.
	 *
	 * Use 'givenName' instead.
	 *
	 * - Marked as deprecated on 2020-09-23.
	 * - Will be removed on 2023-06-05.
	 */
	firstName?: Maybe<Scalars['String']>;
	/** The user's full name. */
	fullName?: Maybe<Scalars['String']>;
	givenName: Scalars['String'];
	/** Whether there are family issues. */
	hasFamilyIssues?: Maybe<Scalars['Boolean']>;
	id: Scalars['ID'];
	/** Designates whether this user is deceased. */
	isDeceased: Scalars['Boolean'];
	/** True if user is linked to an account with an active hardship agreement. */
	isInHardship?: Maybe<Scalars['Boolean']>;
	landline: Scalars['String'];
	/**
	 * The user's landline phone number.
	 * @deprecated The 'landlinePhoneNumber' field is deprecated.
	 *
	 * Use 'landline' instead.
	 *
	 * - Marked as deprecated on 2021-03-22.
	 * - Will be removed on 2024-01-01.
	 */
	landlinePhoneNumber?: Maybe<Scalars['String']>;
	/**
	 * We recommend you use preferredName or fullName instead of this field.
	 * @deprecated The 'lastName' field is deprecated.
	 *
	 * Use 'familyName' instead.
	 *
	 * - Marked as deprecated on 2020-09-23.
	 * - Will be removed on 2023-06-05.
	 */
	lastName?: Maybe<Scalars['String']>;
	/** The user's secret key to access the Developer API. */
	liveSecretKey?: Maybe<Scalars['String']>;
	mobile: Scalars['String'];
	/**
	 * We recommend you use portfolioIds instead of this field.
	 * @deprecated The 'portfolioId' field is deprecated.
	 *
	 * Please use 'portfolioIds' instead.
	 *
	 * - Marked as deprecated on 2022-08-04.
	 * - Will be removed on 2024-01-01.
	 */
	portfolioId?: Maybe<Scalars['ID']>;
	/** List of portfolio ids that the user is linked to via their portfolio roles. */
	portfolioIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
	/** List of roles a user has for each portfolio they're linked to. */
	portfolioUserRoles?: Maybe<Array<Maybe<PortfolioUserRoleType>>>;
	/** List of portfolios that the user is linked to via their portfolio roles. */
	portfolios?: Maybe<PortfolioConnectionTypeConnection>;
	preferences?: Maybe<AccountUserCommsPreferences>;
	/** The user's preferred name. */
	preferredName?: Maybe<Scalars['String']>;
	/** The user's pronouns e.g. 'she/her', 'he/him', 'they/them'. */
	pronouns?: Maybe<Scalars['String']>;
	specialCircumstances?: Maybe<SpecialCircumstancesType>;
	title?: Maybe<Scalars['String']>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserAccountUserRolesArgs = {
	accountNumber?: InputMaybe<Scalars['String']>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserAccountsArgs = {
	allowedBrandCodes?: InputMaybe<Array<InputMaybe<BrandChoices>>>;
	excludeAccountTypes?: InputMaybe<Array<InputMaybe<AccountTypeChoices>>>;
	excludeAccountsWithoutAgreements?: InputMaybe<Scalars['Boolean']>;
	restrictToAccountNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	restrictToPublicFacingBrands?: InputMaybe<Scalars['Boolean']>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserPortfolioIdsArgs = {
	allowedBrandCodes?: InputMaybe<Array<InputMaybe<BrandChoices>>>;
	restrictToPublicFacingBrands?: InputMaybe<Scalars['Boolean']>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserPortfolioUserRolesArgs = {
	accountNumber?: InputMaybe<Scalars['String']>;
	portfolioNumber?: InputMaybe<Scalars['String']>;
};

/** User objects are the core of the authentication system. They typically represent a customer who manages a portfolio of one or more accounts. */
export type AccountUserPortfoliosArgs = {
	after?: InputMaybe<Scalars['String']>;
	allowedBrandCodes?: InputMaybe<Array<InputMaybe<BrandChoices>>>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	restrictToPublicFacingBrands?: InputMaybe<Scalars['Boolean']>;
};

/** Information about the preferences set up for a user. */
export type AccountUserCommsPreferences = {
	__typename?: 'AccountUserCommsPreferences';
	/** What format the user would like to receive their emails in. */
	emailFormat?: Maybe<EmailFormats>;
	/** This setting allows the user to adjust the default font size of the communications sent to them. */
	fontSizeMultiplier?: Maybe<Scalars['Float']>;
	/** Whether the user has opted in to receive meter reading confirmation emails. */
	isOptedInMeterReadingConfirmations?: Maybe<Scalars['Boolean']>;
	/** Whether a user has opted in to receive messages from the client or client group. For example, for Octopus Energy this describes whether a user is opted in to offers from Octopus Investments. */
	isOptedInToClientMessages?: Maybe<Scalars['Boolean']>;
	/** Whether a user has opted in to receive messages offering discounts or other services not directly related to the services the client provides. */
	isOptedInToOfferMessages?: Maybe<Scalars['Boolean']>;
	/** Whether a user has opted in to receive messages we recommend they read, but are not vital to the utilities the client provides. For example, these could be reminders that the client will take a payment. */
	isOptedInToRecommendedMessages?: Maybe<Scalars['Boolean']>;
	/** Whether the user has opted in to receive SMS messages. */
	isOptedInToSmsMessages?: Maybe<Scalars['Boolean']>;
	/** Whether a user has opted in to receive messages from the client's preferred third parties. */
	isOptedInToThirdPartyMessages?: Maybe<Scalars['Boolean']>;
	/** Whether a user has opted in to receive messages updating them on client activities. */
	isOptedInToUpdateMessages?: Maybe<Scalars['Boolean']>;
	/** Whether a user has opted to have inverted colours in their emails. This is currently only relevant to the Octopus Energy brand, whose emails have a dark background by default. */
	isUsingInvertedEmailColours?: Maybe<Scalars['Boolean']>;
	/** Song which will be used as hold music for the user. */
	preferredHoldMusic?: Maybe<Songs>;
};

export type AccountUserDetailType = {
	__typename?: 'AccountUserDetailType';
	/** The namespace for the property. */
	namespace?: Maybe<Scalars['String']>;
	/** The property value. */
	value?: Maybe<Scalars['String']>;
};

export type AccountUserMeta = {
	__typename?: 'AccountUserMeta';
	kanaFamilyName?: Maybe<Scalars['String']>;
	kanaGivenName?: Maybe<Scalars['String']>;
	kanjiFamilyName?: Maybe<Scalars['String']>;
	kanjiGivenName?: Maybe<Scalars['String']>;
};

/** The role a user has in association with one account. */
export type AccountUserRoleType = {
	__typename?: 'AccountUserRoleType';
	account: Account;
	id: Scalars['ID'];
	/** The account role. */
	role?: Maybe<Scalars['RoleString']>;
	user: AccountUser;
};

/** Actions are events created by buttons and other interaction. */
export type ActionInterface = {
	/** The name of the action object's type. */
	typeName?: Maybe<Scalars['String']>;
};

/** Actions are things to do upon a user interaction, such as tapping a button. */
export type ActionType =
	| BackendScreenEventActionType
	| CloseActionType
	| DeeplinkActionType
	| LinkActionType
	| ScreenActionType;

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type AddCampaignToAccount = {
	__typename?: 'AddCampaignToAccount';
	/** Whether the campaign was successfully added. */
	campaignAdded?: Maybe<Scalars['Boolean']>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type AddCampaignToAccountInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The slug of the campaign we want to assign. */
	campaign: Scalars['String'];
	/** The date in which the link between the campaing and the account is meant to expire. If null, no specific expiring date will be set. */
	expiryDate?: InputMaybe<Scalars['Date']>;
	/** The date in which the link between the campaing and the account is meant to start. If null, no specific start date will be set. */
	startDate?: InputMaybe<Scalars['Date']>;
};

export type AddOnRateType = {
	__typename?: 'AddOnRateType';
	/** Add-on details. */
	addon?: Maybe<AddOnType>;
	/** Add-on's product rate details. */
	productRate?: Maybe<ProductRate>;
};

export type AddOnType = {
	__typename?: 'AddOnType';
	/** Rate band for rates matching this add-on. */
	band?: Maybe<Scalars['String']>;
	/** Customer-facing long description. */
	description?: Maybe<Scalars['String']>;
	/** What family does the add-on belong to. */
	family?: Maybe<Scalars['String']>;
	/** Customer-facing short title. */
	title?: Maybe<Scalars['String']>;
};

/** Affiliate link for the organization. */
export type AffiliateLinkType = {
	__typename?: 'AffiliateLinkType';
	contactEmail: Scalars['String'];
	contactName: Scalars['String'];
	id: Scalars['ID'];
	isBusiness: Scalars['Boolean'];
	landingUrl: Scalars['String'];
	organisation?: Maybe<AffiliateOrganisationType>;
	subdomain: Scalars['String'];
	trainingStatus: LinkTrainingStatus;
};

export type AffiliateOrganisationType = {
	__typename?: 'AffiliateOrganisationType';
	/** Is this partner allowed to specify payment methods other than Direct Debit in the import csv or API. */
	allowAlternativePaymentMethods?: Maybe<Scalars['Boolean']>;
	/** Are meter point registrations limited for profile classes 1 and 2 for registrations from csv or API. */
	canRegisterBusinessMeterPoints?: Maybe<Scalars['Boolean']>;
	/** Allow registration requests with customers without an email address. */
	canRegisterCustomersWithoutEmailAddress?: Maybe<Scalars['Boolean']>;
	/** Allow registration requests with exiting account user emails to add to the portfolio belonging to the account user. */
	canRegisterPortfolioAccounts?: Maybe<Scalars['Boolean']>;
	/** Allow performing tariff renewals via API. */
	canRenewTariffs?: Maybe<Scalars['Boolean']>;
	/** Allow this partner access to the IVR support API (modify their own IVR handling through third party 'IVR Flow Editor'). */
	canUseIvrSupportApi?: Maybe<Scalars['Boolean']>;
	/** Default Account Type. */
	defaultAccountType?: Maybe<AccountTypeChoices>;
	id: Scalars['ID'];
	/** Restrict to field-sales-only products? This is only allowed for the 'field-sales' and 'events' sales channels. */
	isFieldSalesOnlyProduct?: Maybe<Scalars['Boolean']>;
	name: Scalars['String'];
	/** Sales Channel. */
	salesChannel?: Maybe<SalesChannelChoices>;
	/** Allow this partner to skip validation that ensures all meter points belong to the same address. */
	skipMeterPointAddressValidation?: Maybe<Scalars['Boolean']>;
};

/** A tracked session for the affiliate link. */
export type AffiliateSessionType = {
	__typename?: 'AffiliateSessionType';
	id: Scalars['ID'];
	ipAddress?: Maybe<Scalars['String']>;
	link?: Maybe<AffiliateLinkType>;
	queryParams: Scalars['JSONString'];
	userAgent: Scalars['String'];
};

export type Agreement = AgreementInterface & {
	__typename?: 'Agreement';
	/** List of add-on rates on the agreement. */
	addonRates?: Maybe<Array<Maybe<AddOnRateType>>>;
	/** The datetime the agreement was entered. */
	agreedFrom?: Maybe<Scalars['DateTime']>;
	/** The datetime the agreement was terminated. */
	agreedTo?: Maybe<Scalars['DateTime']>;
	/** The ID of the agreement. */
	id?: Maybe<Scalars['Int']>;
	/** Whether the agreement is revoked. */
	isRevoked?: Maybe<Scalars['Boolean']>;
	/** The agreement additional params. */
	params?: Maybe<Scalars['GenericScalar']>;
	product?: Maybe<Product>;
	/** The start datetime of the agreement. */
	validFrom?: Maybe<Scalars['DateTime']>;
	/** The end datetime of the agreement. */
	validTo?: Maybe<Scalars['DateTime']>;
};

export type AgreementInterface = {
	/** The datetime the agreement was entered. */
	agreedFrom?: Maybe<Scalars['DateTime']>;
	/** The datetime the agreement was terminated. */
	agreedTo?: Maybe<Scalars['DateTime']>;
	/** The ID of the agreement. */
	id?: Maybe<Scalars['Int']>;
	/** Whether the agreement is revoked. */
	isRevoked?: Maybe<Scalars['Boolean']>;
	/** The start datetime of the agreement. */
	validFrom?: Maybe<Scalars['DateTime']>;
	/** The end datetime of the agreement. */
	validTo?: Maybe<Scalars['DateTime']>;
};

export enum Alignment {
	Center = 'CENTER',
	End = 'END',
	Start = 'START',
}

/**
 * Allow a repayment to be submitted.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3944: Account repayment does not exist.
 * - KT-CT-3945: Unable to allow a repayment to be submitted.
 * - KT-CT-3950: The provided reason text is too long.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type AllowRepaymentSubmission = {
	__typename?: 'AllowRepaymentSubmission';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** The repayment ID. */
	repaymentId?: Maybe<Scalars['ID']>;
	/** Resulting Repayment Intervention details. */
	repaymentIntervention?: Maybe<RepaymentInterventionType>;
};

/** Returned when an account already has a LINE account linked to it. */
export type AlreadyLinkedError = {
	__typename?: 'AlreadyLinkedError';
	/** The type of error that occurred. */
	type: LineLinkErrorType;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3924: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type AmendPayment = {
	__typename?: 'AmendPayment';
	payment?: Maybe<AccountPaymentType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type AmendPaymentInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The new amount for the amended payment. */
	amount: Scalars['Int'];
	/** The new date to collect the payment. */
	paymentDate: Scalars['Date'];
	/** The ID of the payment to amend. */
	paymentId: Scalars['Int'];
	/** Reason for amending the payment. */
	reason?: InputMaybe<Scalars['String']>;
};

export enum Amperage {
	'10' = '_10',
	'15' = '_15',
	'20' = '_20',
	'30' = '_30',
	'40' = '_40',
	'50' = '_50',
	'60' = '_60',
}

/** Returned when a supplied amperage change date is in the past. */
export type AmperageChangeDateInvalid = {
	__typename?: 'AmperageChangeDateInvalid';
	/** The field that for which this error should be associated. */
	field?: Maybe<Scalars['String']>;
	/** The error message to display to the user. */
	message: Scalars['String'];
	/** The first day that a customer may request an amperage change. Always today. */
	startDate: Scalars['Date'];
};

/** AmperageChange was successfully initiated. */
export type AmperageChangeInitiated = {
	__typename?: 'AmperageChangeInitiated';
	/** The message to display to the user on amperage change initiation. */
	message: Scalars['String'];
};

export type AmperageChangeProcess = {
	__typename?: 'AmperageChangeProcess';
	/** The requested change date provided by the customer. */
	amperageChangeDate?: Maybe<Scalars['Date']>;
	/** The requested amperage value to change to, provided by the customer. */
	requestedAmperageValue?: Maybe<Scalars['Int']>;
};

/** A media element containing an animation, such as a Lottie. */
export type AnimationType = AccessibilityInterface &
	IdentifiableInterface &
	MediaInterface &
	SizedItemInterface & {
		__typename?: 'AnimationType';
		/** Whether the element is hidden from view. */
		accessibilityHidden?: Maybe<Scalars['Boolean']>;
		/** Accessible description of the element. */
		accessibilityLabel?: Maybe<Scalars['String']>;
		/** The horizontal alignment of the media. */
		horizontalAlignment?: Maybe<Alignment>;
		/** Unique identifier of the object. */
		id?: Maybe<Scalars['ID']>;
		/** The resource URL of the media. */
		mediaUrl: Scalars['String'];
		/** The name of the object's type. */
		typename?: Maybe<Scalars['String']>;
		/** The measurement of the element. */
		width?: Maybe<ItemSizeType>;
	};

export type AnnualStatementConnectionTypeConnection = {
	__typename?: 'AnnualStatementConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<AnnualStatementConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `AnnualStatementConnectionType` and its cursor. */
export type AnnualStatementConnectionTypeEdge = {
	__typename?: 'AnnualStatementConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<AnnualStatementType>;
};

/** Annual statements that are sent to the account. They summarize important information about usage and tariffs. */
export type AnnualStatementType = {
	__typename?: 'AnnualStatementType';
	id?: Maybe<Scalars['ID']>;
	pdfUrl?: Maybe<Scalars['String']>;
	periodEndsAt: Scalars['DateTime'];
	periodStartsAt: Scalars['DateTime'];
};

/** An annulment is a billing document that annuls another billing document. */
export type AnnulmentBillingDocumentType = {
	__typename?: 'AnnulmentBillingDocumentType';
	/** ID of the billing document annulled by this annulment. */
	annulledBillingDocumentId: Scalars['Int'];
	/** First time the annulment was issued. */
	firstIssued?: Maybe<Scalars['DateTime']>;
	id: Scalars['Int'];
	/** URL to the PDF of the annulment. */
	pdfUrl?: Maybe<Scalars['String']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3934: Repayment request already approved.
 * - KT-CT-3935: Repayment request cannot be paid.
 * - KT-CT-3959: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type ApproveRepayment = {
	__typename?: 'ApproveRepayment';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** The approved repayment. */
	repayment?: Maybe<AccountRepaymentType>;
};

export type ApproveRepaymentInput = {
	/** The account number for the requested repayment. */
	accountNumber: Scalars['ID'];
	/** The id of the account repayment to be approved. */
	repaymentId: Scalars['ID'];
};

export type AssignInkBucketInput = {
	/** The name of the bucket to assign the conversation to. */
	bucketName: Scalars['String'];
	clientMutationId?: InputMaybe<Scalars['String']>;
	/** The relay id of the conversation that will be assigned to the bucket. */
	conversationRelayId: Scalars['ID'];
};

export type AssignInkBucketPayload = {
	__typename?: 'AssignInkBucketPayload';
	/** The bucket that the conversation will be assigned to. */
	bucket: InkBucket;
	clientMutationId?: Maybe<Scalars['String']>;
	/** The conversation that will be assigned to the bucket. */
	conversation: InkConversation;
};

export type AssignedUserType = {
	__typename?: 'AssignedUserType';
	/** The ID of the assigned user. */
	id: Scalars['ID'];
	/** The username of the assigned user. */
	username: Scalars['String'];
};

/** A single Assistance Agreement. */
export type AssistanceAgreementType = {
	__typename?: 'AssistanceAgreementType';
	/** The start datetime of the agreement. */
	activeFrom?: Maybe<Scalars['Date']>;
	/** The end datetime of the agreement, if any. */
	activeTo?: Maybe<Scalars['Date']>;
	/** The type of assistance provided by the agreement. */
	assistanceType?: Maybe<Scalars['String']>;
};

/** The relationship of the on-site attendant to the Account holder. */
export enum AttendantType {
	AccountHolder = 'ACCOUNT_HOLDER',
	Family = 'FAMILY',
	Other = 'OTHER',
	Realtor = 'REALTOR',
	User = 'USER',
}

/**
 * Returns an Action to perform, e.g. a screen to load.
 *
 * BackendScreenEvents are specific types of Action which trigger a mutation in the Kraken backend.
 * They return an action (any type), such as a ScreenActionType (which is then used to load the next screen).
 * Any action registered in the registry should really be an "event" with some side-effect in the backend.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-8002: No event found.
 * - KT-CT-8003: Event has no execute function.
 * - KT-CT-8004: Error executing event in the backend.
 * - KT-CT-8007: Incorrect or missing parameters for backend screen event.
 * - KT-GB-9310: Account ineligible for joining Octoplus.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type BackendScreenEvent = {
	__typename?: 'BackendScreenEvent';
	/** An action to perform. */
	action?: Maybe<ActionType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** An action which triggers some event in the Kraken backend. */
export type BackendScreenEventActionType = ActionInterface &
	IdentifiableInterface & {
		__typename?: 'BackendScreenEventActionType';
		/** The ID of the event to trigger. */
		eventId: Scalars['String'];
		/** Unique identifier of the object. */
		id?: Maybe<Scalars['ID']>;
		/** List of key-value pairs to pass as parameters to the event. */
		params: Array<Maybe<BackendScreenParam>>;
		/** The name of the action object's type. */
		typeName?: Maybe<Scalars['String']>;
		/** The name of the object's type. */
		typename?: Maybe<Scalars['String']>;
	};

/** Input for a backend action. */
export type BackendScreenEventInput = {
	/** The ID of the action to perform. */
	eventId: Scalars['ID'];
	/** List of key-value pairs (strings) to pass as parameters to the mutation. */
	params?: InputMaybe<Array<InputMaybe<BackendScreenParamInputType>>>;
};

/** A backend screen is the top-level container for mobile UI. */
export type BackendScreenInterface = {
	/** The name of the screen. */
	name: Scalars['String'];
	/** The refresh / polling frequency in milliseconds. */
	refreshFrequency?: Maybe<Scalars['Int']>;
	/** Serialized JSON representation of the screen. */
	screenData?: Maybe<Scalars['String']>;
};

/** A key-value pair (both Strings) which is passed as a parameter to a screen. */
export type BackendScreenParam = {
	__typename?: 'BackendScreenParam';
	key: Scalars['String'];
	value: Scalars['String'];
};

/** A key-value pair (both Strings) which is passed in parameters to a backend action. */
export type BackendScreenParamInputType = {
	key: Scalars['String'];
	value: Scalars['String'];
};

/** A backend screen is the top-level container for mobile UI. */
export type BackendScreenType =
	| ComponentListType
	| Dashboard
	| GenericBackendScreen;

/** A payment schedule which triggers a payment the balance of a ledger drops below a certain value. */
export type BalanceTriggeredScheduleInput = {
	/** The ledger balance (in the minor currency unit) which when passed will trigger a payment to be taken. */
	balanceThreshold: Scalars['Int'];
	/** The target balance (in the minor currency unit) for the ledger; used to calculate the payment amount. Defaults to zero. */
	targetBalance?: InputMaybe<Scalars['Int']>;
};

export enum BankAccountTypeChoices {
	Current = 'CURRENT',
	Ordinary = 'ORDINARY',
}

export type BankDetailsInput = {
	accountHolder?: InputMaybe<Scalars['String']>;
	accountNumber?: InputMaybe<Scalars['String']>;
	accountType?: InputMaybe<Scalars['String']>;
	bankCode?: InputMaybe<Scalars['String']>;
	branchCode?: InputMaybe<Scalars['String']>;
	iban?: InputMaybe<Scalars['String']>;
};

export type BankTransferPaymentMethodCreated = {
	__typename?: 'BankTransferPaymentMethodCreated';
	/** Account that has switched to bank transfer payment method. */
	accountNumber: Scalars['String'];
};

/**
 *
 *
 * This field is a connection type. Connections are used to implement [cursor based pagination](https://graphql.org/learn/pagination/#pagination-and-edges).
 *
 *
 */
export type BillConnectionTypeConnection = {
	__typename?: 'BillConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<BillConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `BillConnectionType` and its cursor. */
export type BillConnectionTypeEdge = {
	__typename?: 'BillConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<BillInterface>;
};

export type BillInterface = {
	billType?: Maybe<BillTypeEnum>;
	fromDate?: Maybe<Scalars['Date']>;
	id?: Maybe<Scalars['ID']>;
	/** The date the bill was sent to the customer. */
	issuedDate?: Maybe<Scalars['Date']>;
	/**
	 * Requesting this field generates a temporary URL at which bill is available.
	 *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
	 *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
	 *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
	 *         been created/issued yet.
	 *
	 */
	temporaryUrl?: Maybe<Scalars['String']>;
	toDate?: Maybe<Scalars['Date']>;
};

/**
 * A payment schedule which triggers a payment when a bill is issued.
 *
 * Optionally a payment day frequency multiplier can be specified in which case the
 * payment is taken on the first occurrence of the payment day after a bill has been issued.
 */
export type BillTriggeredScheduleInput = {
	/** The multiple of months at which payment are taken. Required when a payment day is chosen, ignored if payment day not set. */
	frequencyMultiplier?: InputMaybe<Scalars['Int']>;
	/** The day of the month at which to take payment (1-28). If not provided payment is taken whenever a bill is issued. */
	paymentDay?: InputMaybe<Scalars['Int']>;
};

export enum BillTypeEnum {
	CreditNote = 'CREDIT_NOTE',
	Invoice = 'INVOICE',
	PreKraken = 'PRE_KRAKEN',
	Statement = 'STATEMENT',
}

export type BillingAddressDetailsInput = {
	/** Administrative area. */
	administrativeArea?: InputMaybe<Scalars['String']>;
	/** Billing country. */
	country?: InputMaybe<Scalars['String']>;
	/** Billing delivery point identifier. */
	deliveryPointIdentifier?: InputMaybe<Scalars['String']>;
	/** Billing dependent locality. */
	dependentLocality?: InputMaybe<Scalars['String']>;
	/** Billing locality. */
	locality?: InputMaybe<Scalars['String']>;
	/** Billing postal code. */
	postalCode?: InputMaybe<Scalars['String']>;
	/** Billing sorting code. */
	sortingCode?: InputMaybe<Scalars['String']>;
	/** Billing street address. */
	streetAddress?: InputMaybe<Scalars['String']>;
	/** Billing structured street address. */
	structuredStreetAddress?: InputMaybe<Scalars['GenericScalar']>;
};

export type BillingInput = {
	/** Billing address line 1. */
	billingAddressLine1?: InputMaybe<Scalars['String']>;
	/** Billing address line 2. */
	billingAddressLine2?: InputMaybe<Scalars['String']>;
	/** Billing postcode. */
	billingPostcode?: InputMaybe<Scalars['String']>;
};

/** Information about an account's billing schedule. */
export type BillingOptionsType = {
	__typename?: 'BillingOptionsType';
	/** The date on which the current billing cycle will end. Null if the account is on flexible billing. */
	currentBillingPeriodEndDate?: Maybe<Scalars['Date']>;
	/** The date on which the current billing cycle started. */
	currentBillingPeriodStartDate?: Maybe<Scalars['Date']>;
	/** If true, this account is billed on specific day of a regular cycle. If false, the billing schedule is flexible, depending on when meter readings are submitted. */
	isFixed: Scalars['Boolean'];
	/** The next date on which this account will next be billed. This is the same as the start date for their next bill cycle. Null if the account is on flexible billing. */
	nextBillingDate?: Maybe<Scalars['Date']>;
	periodLength?: Maybe<AccountBillingOptionsPeriodLength>;
	periodLengthMultiplier?: Maybe<Scalars['Int']>;
	/** The day of the month on which the account's billing period should start. */
	periodStartDay?: Maybe<Scalars['Int']>;
};

export enum BillsOrderBy {
	FromDateDesc = 'FROM_DATE_DESC',
	IssuedDateDesc = 'ISSUED_DATE_DESC',
}

/**
 * Block a repayment from being submitted.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3944: Account repayment does not exist.
 * - KT-CT-3946: Unable to block a repayment from being submitted.
 * - KT-CT-3950: The provided reason text is too long.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type BlockRepaymentSubmission = {
	__typename?: 'BlockRepaymentSubmission';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** The repayment ID. */
	repaymentId?: Maybe<Scalars['ID']>;
	/** Resulting Repayment Intervention details. */
	repaymentIntervention?: Maybe<RepaymentInterventionType>;
};

/** An enumeration. */
export enum BrandChoices {
	/** Octopus Energy Japan. */
	OctopusEnergyJapan = 'OCTOPUS_ENERGY_JAPAN',
}

export enum BuildingType {
	Apartment = 'APARTMENT',
	House = 'HOUSE',
}

export type BusinessType = {
	__typename?: 'BusinessType';
	/** The company type of a business account. */
	businessType?: Maybe<BusinessTypeOptions>;
	/** Company name in Kana. */
	kanaCompanyName?: Maybe<Scalars['String']>;
	/** Company name in Kanji. */
	kanjiCompanyName?: Maybe<Scalars['String']>;
	name: Scalars['String'];
	number: Scalars['String'];
};

/** An enumeration. */
export enum BusinessTypeOptions {
	/** A business account where the company type is charity. */
	Charity = 'CHARITY',
	/** A business account for a church or other religious organisation. */
	Church = 'CHURCH',
	/** A business account for a government institution. */
	Government = 'GOVERNMENT',
	/** A business account where the company type is limited. */
	Limited = 'LIMITED',
	/** A business account where the company type is limited liability partnership. */
	LimitedLiabilityPartnership = 'LIMITED_LIABILITY_PARTNERSHIP',
	/** A business account for a non-profit organisation. */
	NonProfit = 'NON_PROFIT',
	/** A business account where the company type is partnership. */
	Partnership = 'PARTNERSHIP',
	/** A business account where the company type is proprietary limited. */
	ProprietaryLimitedCompany = 'PROPRIETARY_LIMITED_COMPANY',
	/** A business account where the company type is public limited. */
	PublicLimitedCompany = 'PUBLIC_LIMITED_COMPANY',
	/** A business account where the company type is sole trader. */
	SoleTrader = 'SOLE_TRADER',
	/** A business account where the company has a trading name to carry out its business activities. */
	TradingAs = 'TRADING_AS',
	/** A business account where the company type is a trust. */
	Trust = 'TRUST',
}

/** The button interface. */
export type ButtonInterface = {
	/** The action to perform when the button is pressed. */
	buttonAction: ActionType;
	/** The button style. */
	buttonStyle?: Maybe<ButtonStyle>;
	/** Title text of the button. */
	title: Scalars['String'];
};

export enum ButtonStyle {
	Primary = 'PRIMARY',
	Secondary = 'SECONDARY',
}

export enum ButtonVariance {
	Filled = 'FILLED',
	Outlined = 'OUTLINED',
	TextOnly = 'TEXT_ONLY',
}

/**
 *
 *     The type of rejection created by our "Can Supply" checks during the equipment enquiry
 *
 *     Values:
 *     INVALID_GRID_OPERATOR: The grid operator is not one of those that
 *         can be serviced
 *     INVALID_VOLTAGE_FLAG: The voltage flag (low-voltage or high-voltage)
 *         indicates a type of supply point that cannot be serviced
 *     INVALID_CONTRACT_DETERMINATION_METHOD: The contract determination method
 *         (e.g. appliance basis, actual volume basis) is not one of
 *         those that can be supplied
 *     INVALID_CONTRACT_CODE: The contract type code (e.g. lighting, power)
 *         is not one of those that can be supplied
 *     UNIVERSAL_SERVICE_SUPPLY: The supply point is in a remote area and
 *         therefore eligible for "universal service" obligations, which means
 *         it cannot be supplied
 *     SUPPLY_POINT_USES_KW: The supply contract value is kW, but for
 *         the MVP these cannot be supplied
 *     INVALID_DECOMMISSIONING_STATUS: The supply point has been decommissioned,
 *         i.e. it is not on supply with another supplier
 *     POWER_TRANSMISSION_SERVICE_MENU_NOT_EMPTY: The power transmission
 *         service menu (setsuzokuSodenServiceMenu) field is not empty,
 *         which means the supply point is already on supply with us.
 *     ALREADY_ON_SUPPLY: A supply point is already on supply in the system,
 *         or is in the process of being on supply.
 *
 */
export enum CanSupplyRejectionType {
	AlreadyOnSupply = 'ALREADY_ON_SUPPLY',
	InvalidContractDeterminationMethod = 'INVALID_CONTRACT_DETERMINATION_METHOD',
	InvalidContractTypeCode = 'INVALID_CONTRACT_TYPE_CODE',
	InvalidDecommissioningStatus = 'INVALID_DECOMMISSIONING_STATUS',
	InvalidGridOperator = 'INVALID_GRID_OPERATOR',
	InvalidVoltageFlag = 'INVALID_VOLTAGE_FLAG',
	OnContractWithOutstandingMoveOut = 'ON_CONTRACT_WITH_OUTSTANDING_MOVE_OUT',
	PowerTransmissionServiceMenuEmpty = 'POWER_TRANSMISSION_SERVICE_MENU_EMPTY',
	PowerTransmissionServiceMenuNotEmpty = 'POWER_TRANSMISSION_SERVICE_MENU_NOT_EMPTY',
	SupplyPointUsesKw = 'SUPPLY_POINT_USES_KW',
	UniversalServiceSupply = 'UNIVERSAL_SERVICE_SUPPLY',
}

/**
 * Cancel an amperage change request.
 *
 * This mutation is used for cancelling amperage change requests by organizations.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4501: Unauthorized.
 * - KT-JP-4501: Unauthorized.
 * - KT-JP-4502: Unauthorized.
 * - KT-JP-4511: Invalid data.
 * - KT-JP-4520: Unable to process request.
 * - KT-JP-4515: Amperage change process not found.
 * - KT-JP-4516: Unable to cancel amperage change process.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CancelAmperageChangeRequest = {
	__typename?: 'CancelAmperageChangeRequest';
	/** Success message. */
	message?: Maybe<Scalars['String']>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CancelAmperageChangeRequestInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The supply point identification number. */
	spin: Scalars['String'];
};

/** Input required to cancel a LeaveSupplier journey. */
export type CancelLeaveSupplierInput = {
	/** The ID of the LeaveSupplier process to cancel. */
	leaveSupplierProcessId: Scalars['ID'];
	/** The reason for the cancellation. */
	reason?: InputMaybe<Scalars['String']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-JP-4537: Move out cancel input data is invalid.
 * - KT-JP-4501: Unauthorized.
 * - KT-JP-4536: Move out active process not found.
 * - KT-JP-4535: Move out cancel cannot self-service.
 * - KT-JP-4101: Unauthorized.
 * - KT-JP-4520: Unable to process request.
 * - KT-JP-4538: Move out process not withdrawable.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CancelMoveOut = {
	__typename?: 'CancelMoveOut';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** Supply point identification number. */
	spin: Scalars['String'];
};

/** Input required to self-service a move-out cancellation. */
export type CancelMoveOutInput = {
	/** Supply Point Identification Number to cancel the move-out. */
	spin: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3924: Unauthorized.
 * - KT-CT-3954: Payment cancellation failed.
 * - KT-CT-3955: Payment cannot be cancelled.
 * - KT-CT-3956: Temporary error occurred.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CancelPayment = {
	__typename?: 'CancelPayment';
	/** The cancelled payment. */
	payment?: Maybe<AccountPaymentType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CancelPaymentInput = {
	/** The account number. */
	accountNumber: Scalars['ID'];
	/** The ID of the payment to cancel. */
	paymentId: Scalars['ID'];
	/** Reason for cancelling the payment. */
	reason?: InputMaybe<Scalars['String']>;
};

/**
 * Cancel a repayment or refund request.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4231: Unauthorized.
 * - KT-CT-3930: The repayment or refund request does not exist.
 * - KT-CT-3931: This repayment or refund request cannot be cancelled.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type CancelRepaymentRequest = {
	__typename?: 'CancelRepaymentRequest';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** The cancelled repayment/refund request. */
	repaymentRequest?: Maybe<CancelRepaymentRequestOutputType>;
};

export type CancelRepaymentRequestInputType = {
	/** The id of the request to be cancelled. */
	requestId: Scalars['String'];
};

/** Output from cancelling a repayment or refund request. */
export type CancelRepaymentRequestOutputType = {
	__typename?: 'CancelRepaymentRequestOutputType';
	/** The ID of the cancelled request. */
	requestId?: Maybe<Scalars['String']>;
	/** The current status of the cancelled request. */
	status?: Maybe<RepaymentRequestStatus>;
};

/** A card containing a list of items */
export type CardComponentType = AccessibilityInterface &
	IdentifiableInterface & {
		__typename?: 'CardComponentType';
		/** Whether the element is hidden from view. */
		accessibilityHidden?: Maybe<Scalars['Boolean']>;
		/** Accessible description of the element. */
		accessibilityLabel?: Maybe<Scalars['String']>;
		/** Unique identifier of the object. */
		id?: Maybe<Scalars['ID']>;
		/** The list of components. */
		items: Array<Maybe<CardItemType>>;
		/** The name of the object's type. */
		typename?: Maybe<Scalars['String']>;
	};

/** Items are sections making up a card. */
export type CardItemType =
	| AnimationType
	| ImageType
	| PillButtonType
	| RectangularButtonType
	| TextType;

export type CarouselItemType = IdentifiableInterface & {
	__typename?: 'CarouselItemType';
	/** The list of cards. */
	cards: Array<Maybe<CardComponentType>>;
	/** Unique identifier of the object. */
	id?: Maybe<Scalars['ID']>;
	/** The name of the object's type. */
	typename?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum Category {
	ProofOfSolarOwnership = 'PROOF_OF_SOLAR_OWNERSHIP',
}

/** A charge to the customer. */
export type Charge = TransactionType & {
	__typename?: 'Charge';
	/** Unique identifier of the account the transaction belongs to. */
	accountNumber?: Maybe<Scalars['String']>;
	/**
	 * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
	 * @deprecated The 'amount' field is deprecated.
	 *
	 * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
	 *
	 * - Marked as deprecated on 2023-12-06.
	 * - Will be removed on 2024-06-01.
	 */
	amount?: Maybe<Scalars['Int']>;
	/** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
	amounts?: Maybe<TransactionAmountType>;
	/** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
	balanceCarriedForward?: Maybe<Scalars['Int']>;
	/** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
	billingDocumentIdentifier?: Maybe<Scalars['ID']>;
	/** If this charge is for consumption of a good or service, this field will contain details of how much was consumed. Omitted in cases where the charge is not for consumption, or where consumption information is not available (e.g. for some older records). */
	consumption?: Maybe<Consumption>;
	/** The date time when the transaction is created. */
	createdAt?: Maybe<Scalars['DateTime']>;
	/** Returns True if the transaction is linked with a statement. */
	hasStatement?: Maybe<Scalars['Boolean']>;
	id?: Maybe<Scalars['ID']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isAccountCharge' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isAccountCharge?: Maybe<Scalars['Boolean']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isAccountPayment' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isAccountPayment?: Maybe<Scalars['Boolean']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isCredit' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isCredit?: Maybe<Scalars['Boolean']>;
	/** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
	isHeld?: Maybe<Scalars['Boolean']>;
	/** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
	isIssued?: Maybe<Scalars['Boolean']>;
	isReversed: Scalars['Boolean'];
	/** The line items that make up the charge. */
	lineItems?: Maybe<LineItemConnectionTypeConnection>;
	/** Returns the note field value for the transaction, which contains additional info. */
	note?: Maybe<Scalars['String']>;
	postedDate?: Maybe<Scalars['Date']>;
	/** The reading ID associated with the charge. */
	readingId?: Maybe<Scalars['String']>;
	/**
	 * Returns None if a statement is not linked with the transaction.
	 * @deprecated The 'statementId' field is deprecated.
	 *
	 * Use `billingDocumentIdentifier` instead.
	 *
	 * - Marked as deprecated on 2023-11-30.
	 * - Will be removed on 2024-06-01.
	 */
	statementId?: Maybe<Scalars['ID']>;
	title?: Maybe<Scalars['String']>;
};

/** A charge to the customer. */
export type ChargeLineItemsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type ChargeReasonType = {
	__typename?: 'ChargeReasonType';
	/** The charge reason code. */
	code?: Maybe<Scalars['String']>;
	/** The charge reason display text. */
	display?: Maybe<Scalars['String']>;
	/** The group the charge reason belongs to (if applicable). */
	group?: Maybe<Scalars['String']>;
	/** Whether the charge reason is deprecated. */
	isDeprecated?: Maybe<Scalars['Boolean']>;
	/** Whether the charge reason is hidden. */
	isHidden?: Maybe<Scalars['Boolean']>;
	/** Whether the charge reason is sales tax exempt. */
	isTaxExempt?: Maybe<Scalars['Boolean']>;
};

export type ChargeType = {
	__typename?: 'ChargeType';
	/** The gross amount charged. */
	grossAmount?: Maybe<Scalars['BigInt']>;
	/** The line items for the charge. */
	lineItems?: Maybe<Array<Maybe<LineItemType>>>;
	/** The sales tax amount charged. */
	salesTaxAmount?: Maybe<Scalars['BigInt']>;
};

/** Closes the screen. */
export type CloseActionType = AccessibilityInterface &
	ActionInterface &
	IdentifiableInterface & {
		__typename?: 'CloseActionType';
		/** Whether the element is hidden from view. */
		accessibilityHidden?: Maybe<Scalars['Boolean']>;
		/** Accessible description of the element. */
		accessibilityLabel?: Maybe<Scalars['String']>;
		/** Unique identifier of the object. */
		id?: Maybe<Scalars['ID']>;
		/** The name of the action object's type. */
		typeName?: Maybe<Scalars['String']>;
		/** The name of the object's type. */
		typename?: Maybe<Scalars['String']>;
	};

/**
 * Close the Open Print Batch if any.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-9010: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CloseOpenPrintBatch = {
	__typename?: 'CloseOpenPrintBatch';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	printBatch?: Maybe<PrintBatchType>;
};

/**
 * Collect deposit for the given account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-5711: No collection is required.
 * - KT-CT-5712: Deposit agreement does not exist or has not been accepted.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CollectDeposit = {
	__typename?: 'CollectDeposit';
	payment?: Maybe<CollectDepositOutput>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CollectDepositInput = {
	accountNumber: Scalars['String'];
	depositKey: Scalars['String'];
	idempotencyKey: Scalars['String'];
};

export type CollectDepositOutput = {
	__typename?: 'CollectDepositOutput';
	paymentDate?: Maybe<Scalars['Date']>;
	status?: Maybe<CollectDepositStatusChoices>;
};

export enum CollectDepositStatusChoices {
	Approved = 'APPROVED',
	Cancelled = 'CANCELLED',
	Cleared = 'CLEARED',
	Failed = 'FAILED',
	HeldForReview = 'HELD_FOR_REVIEW',
	None = 'NONE',
	Pending = 'PENDING',
	Requested = 'REQUESTED',
	Scheduled = 'SCHEDULED',
}

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3932: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CollectPayment = {
	__typename?: 'CollectPayment';
	/** Details about the collected payment. Note that we might not be able to collect the payment (e.g. if there is no usable payment instruction), in which case the status of the returned payment might be failed or cancelled. */
	payment?: Maybe<AccountPaymentType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CollectPaymentInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The payment amount (in yen). */
	amount: Scalars['Int'];
	/** The type of the payment instruction. */
	collectionMethod?: InputMaybe<PaymentType>;
	/** The reason a payment is being collected, for internal audit purposes. */
	description: Scalars['String'];
	idempotencyKey: Scalars['String'];
	/** The ID of the ledger on which to collect the payment. */
	ledgerId: Scalars['ID'];
	/** The date to attempt to take the payment. Cannot be a date in the past. Payment will be collected on the requested date or as soon as possible after that date. */
	paymentDate: Scalars['Date'];
};

export enum CollectionCampaignType {
	None = 'NONE',
}

export enum CollectionMethod {
	Card = 'CARD',
	DirectDebit = 'DIRECT_DEBIT',
}

export type CombinedRateLimitInformation = {
	__typename?: 'CombinedRateLimitInformation';
	/** Information about points-allowance rate limit for viewer. */
	pointsAllowanceRateLimit?: Maybe<PointsAllowanceRateLimitInformation>;
};

/** A Common Error Type. Should be used for general application or lower level errors. */
export type CommonError = {
	__typename?: 'CommonError';
	/** The field that for which this error should be associated. */
	field?: Maybe<Scalars['String']>;
	/** The error message to display to the user. */
	message: Scalars['String'];
};

export type CommonSupplyPointInterface = {
	/** The external identifier of the supply point. */
	externalIdentifier?: Maybe<Scalars['String']>;
	/** The ID of the supply point. */
	id: Scalars['ID'];
	/** The market this supply point belongs to. */
	marketName: Scalars['String'];
};

/** The method the account has specified they prefer we contact them */
export enum CommsDeliveryPreference {
	Email = 'EMAIL',
	PostalMail = 'POSTAL_MAIL',
}

/**
 * A list of components which comprise a screen.
 *
 * This is a legacy type; GenericBackendScreen should be preferred. This is because API clients
 * should not need to explicitly query for screen attributes like `items` - these fields are
 * embedded in the screenData field.
 */
export type ComponentListType = AccessibilityInterface &
	BackendScreenInterface &
	IdentifiableInterface & {
		__typename?: 'ComponentListType';
		/** Whether the element is hidden from view. */
		accessibilityHidden?: Maybe<Scalars['Boolean']>;
		/** Accessible description of the element. */
		accessibilityLabel?: Maybe<Scalars['String']>;
		/** Unique identifier of the object. */
		id?: Maybe<Scalars['ID']>;
		/**
		 * The list of components.
		 * @deprecated The 'items' field is deprecated.
		 *
		 * Access `items` via `screenData` instead.
		 *
		 * - Marked as deprecated on 2024-07-02.
		 * - Will be removed on 2024-08-01.
		 */
		items: Array<Maybe<ItemType>>;
		/** The name of the screen. */
		name: Scalars['String'];
		/** The refresh / polling frequency in milliseconds. */
		refreshFrequency?: Maybe<Scalars['Int']>;
		/** Serialized JSON representation of the screen. */
		screenData?: Maybe<Scalars['String']>;
		/** The name of the object's type. */
		typename?: Maybe<Scalars['String']>;
	};

export type Consumption = {
	__typename?: 'Consumption';
	endDate?: Maybe<Scalars['Date']>;
	quantity?: Maybe<Scalars['Decimal']>;
	startDate?: Maybe<Scalars['Date']>;
	supplyCharge?: Maybe<Scalars['Int']>;
	unit?: Maybe<ConsumptionUnit>;
	usageCost?: Maybe<Scalars['Int']>;
};

export type ConsumptionChargeRate = {
	__typename?: 'ConsumptionChargeRate';
	/** The product rate band information. */
	band?: Maybe<Scalars['String']>;
	/** The price per unit including tax. */
	pricePerUnitIncTax: Scalars['Decimal'];
	/** The step end for the consumption tier for the charge. */
	stepEnd?: Maybe<Scalars['Int']>;
	/** The step start for the consumption tier for the charge. */
	stepStart?: Maybe<Scalars['Int']>;
};

export type ConsumptionRate = {
	__typename?: 'ConsumptionRate';
	/** The product rate band information. */
	band?: Maybe<Scalars['String']>;
	/** Duration in months for discount product rates. */
	durationMonths?: Maybe<Scalars['Int']>;
	/** Grid operator. */
	gridOperatorCode?: Maybe<Scalars['String']>;
	/** Price per kWh without tax. */
	pricePerUnit: Scalars['Decimal'];
	/** Price per kWh including tax. */
	pricePerUnitIncTax: Scalars['Decimal'];
	/** Region of operation. */
	regionOfOperation?: Maybe<Scalars['String']>;
	/** Time of use label for the rate. */
	timeOfUse?: Maybe<Scalars['String']>;
	/** What are we charging for (kWhs consumed, Days on supply, etc). */
	unitType?: Maybe<UnitType>;
	/** Rate validity start. */
	validFrom?: Maybe<Scalars['DateTime']>;
	/** Rate validity end. */
	validTo?: Maybe<Scalars['DateTime']>;
};

/** An enumeration. */
export enum ConsumptionUnit {
	Mj = 'MJ',
	KWh = 'kWh',
}

/** The kind of contract capacity pattern. */
export enum ContractCapacityPattern {
	NonTiered = 'NON_TIERED',
	Oepc = 'OEPC',
	TieredHigh = 'TIERED_HIGH',
	TieredLow = 'TIERED_LOW',
}

/** A single Contribution Agreement. */
export type ContributionAgreementType = {
	__typename?: 'ContributionAgreementType';
	/** The start datetime of the agreement. */
	activeFrom?: Maybe<Scalars['DateTime']>;
	/** The end datetime of the agreement, if any. */
	activeTo?: Maybe<Scalars['DateTime']>;
	/** The amount contributed per interval. Note, this is in the smallest domination that the currecy support. e.g. Pence, Cents, Yen, etc. */
	amount?: Maybe<Scalars['Int']>;
	/** The scheme to which the agreement contributes. */
	contributionScheme?: Maybe<ContributionSchemeType>;
	id: Scalars['ID'];
	/** The frequency of contributions. */
	interval?: Maybe<Interval>;
	/** The periods over which contributions have been made. */
	periods?: Maybe<ContributionPeriodConnection>;
};

/** A single Contribution Agreement. */
export type ContributionAgreementTypePeriodsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

/** Pagination for contribution periods. */
export type ContributionPeriodConnection = {
	__typename?: 'ContributionPeriodConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<ContributionPeriodEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ContributionPeriod` and its cursor. */
export type ContributionPeriodEdge = {
	__typename?: 'ContributionPeriodEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<ContributionPeriodType>;
};

/** A single Contribution Period. */
export type ContributionPeriodType = {
	__typename?: 'ContributionPeriodType';
	agreement: ContributionAgreementType;
	/** When the contribution was fulfilled */
	fulfilledAt?: Maybe<Scalars['DateTime']>;
	id: Scalars['ID'];
	/** The datetime the marks the beginning of the period. */
	periodFrom?: Maybe<Scalars['DateTime']>;
	/** The datetime the marks the end of the period. */
	periodTo?: Maybe<Scalars['DateTime']>;
};

/** A single Contribution Scheme. */
export type ContributionSchemeType = {
	__typename?: 'ContributionSchemeType';
	/** Is this scheme currently accepting contributions? */
	acceptingContributions: Scalars['Boolean'];
	code: Scalars['String'];
	displayName: Scalars['String'];
	id: Scalars['ID'];
	/** Are contributions to this scheme taxable? */
	taxable: Scalars['Boolean'];
};

export type CoordinatesType = {
	__typename?: 'CoordinatesType';
	latitude?: Maybe<Scalars['Float']>;
	longitude?: Maybe<Scalars['Float']>;
};

export type CosGainProcess = {
	__typename?: 'CosGainProcess';
	/** Available self-service actions. */
	availableActions?: Maybe<Array<Maybe<SelfServiceAction>>>;
	/** The name of the customer. */
	customerName?: Maybe<Scalars['String']>;
	/** The name of the customer, in kana. */
	customerNameKana?: Maybe<Scalars['String']>;
	/** The forecasted supply start date provided by OCCTO. Subject to change. */
	forecastedSupplyStartDate?: Maybe<Scalars['Date']>;
	/** The ID of the switch-in request. */
	requestId?: Maybe<Scalars['String']>;
};

export type CosLossProcess = {
	__typename?: 'CosLossProcess';
	/** The requested supply end date provided by the customer. */
	requestedSupplyEndDate?: Maybe<Scalars['Date']>;
	/** The status of the switch-out request. */
	status?: Maybe<CosLossProcessStatus>;
};

/** An enumeration. */
export enum CosLossProcessStatus {
	AwaitingManualDecision = 'AWAITING_MANUAL_DECISION',
	CancellationApplicationAccepted = 'CANCELLATION_APPLICATION_ACCEPTED',
	CancellationApplicationRejected = 'CANCELLATION_APPLICATION_REJECTED',
	CheckingMatchingStatus = 'CHECKING_MATCHING_STATUS',
	CheckingSwitchingResult = 'CHECKING_SWITCHING_RESULT',
	CompletedAfterFailedToWithdraw = 'COMPLETED_AFTER_FAILED_TO_WITHDRAW',
	IngestionFailed = 'INGESTION_FAILED',
	ReadyToSendDecision = 'READY_TO_SEND_DECISION',
	Replaced = 'REPLACED',
	RequestBuilderError = 'REQUEST_BUILDER_ERROR',
	RequestError = 'REQUEST_ERROR',
	SwitchOutClosed = 'SWITCH_OUT_CLOSED',
	SwitchOutCompleted = 'SWITCH_OUT_COMPLETED',
	SwitchOutFailed = 'SWITCH_OUT_FAILED',
	SwitchOutFailedToWithdraw = 'SWITCH_OUT_FAILED_TO_WITHDRAW',
	SwitchOutWithdrawn = 'SWITCH_OUT_WITHDRAWN',
}

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7803: Received an invalid apiExceptionId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateApiCall = {
	__typename?: 'CreateAPICall';
	/** The created APICall. */
	apiCall?: Maybe<ApiCallType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateApiCallInput = {
	/** The ID of the associated API exception, if any. */
	apiExceptionId?: InputMaybe<Scalars['Int']>;
	/** Any optional useful context involved in the API call. */
	context?: InputMaybe<Scalars['JSONString']>;
	/** The correlation id header from the HTTP request. */
	correlationId: Scalars['String'];
	/** The input data provided to the API, if any. */
	inputData?: InputMaybe<Scalars['JSONString']>;
	/** The name of the operation associated with this call. */
	operationName: Scalars['String'];
	/** The response returned by the API. */
	response: Scalars['JSONString'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7801: Received an invalid operationsTeamId.
 * - KT-CT-7802: The external identifier already exists.
 * - KT-CT-7805: Too many tags associated with this API Exception.
 * - KT-CT-7806: Cannot create duplicate tags for the same API exception.
 * - KT-CT-7811: Received an invalid assignedUserId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateApiException = {
	__typename?: 'CreateAPIException';
	/** The created APIException. */
	apiException?: Maybe<ApiExceptionType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7803: Received an invalid apiExceptionId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateApiExceptionEvent = {
	__typename?: 'CreateAPIExceptionEvent';
	/** The created APIExceptionEvent. */
	apiExceptionEvent?: Maybe<ApiExceptionEventType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateApiExceptionEventInput = {
	/** The ID of the associated API exception, if any. */
	apiExceptionId?: InputMaybe<Scalars['Int']>;
	/** The event category. */
	category: Scalars['String'];
	/** Any optional useful context involved in the event. */
	context?: InputMaybe<Scalars['JSONString']>;
	/** Any useful event description. */
	description: Scalars['String'];
	/** The event type. */
	eventType: Scalars['String'];
};

export type CreateApiExceptionInput = {
	/** The account number associated with the exception, if available. */
	accountNumber?: InputMaybe<Scalars['ID']>;
	/** The ID of the user assigned to handle this exception.If no user is provided, no user will be assigned to the exception. */
	assignedUserId?: InputMaybe<Scalars['Int']>;
	/** Category associated with this exception. Uses the default category if not provided. */
	category?: InputMaybe<ApiExceptionCategories>;
	/** The API client channel where the exception was triggered from. */
	channel: Scalars['String'];
	/** Contextual information about the exception, if any. */
	context?: InputMaybe<Scalars['JSONString']>;
	/** The customer contact associated with the exception, if available. */
	customerContact?: InputMaybe<Scalars['String']>;
	/** External identifier mapping an entity on the client's database. */
	externalIdentifier: Scalars['String'];
	/** The key date associated with the exception, if available. */
	keyDate?: InputMaybe<Scalars['Date']>;
	/** The ID of an operations team to handle this exception. If no team is provided, no team will be assigned to the exception. */
	operationsTeamId?: InputMaybe<Scalars['Int']>;
	/** The priority. Defaults to LOW if not provided. */
	priority?: InputMaybe<ApiExceptionPriority>;
	/** The resolution status. Defaults to UNASSIGNED if not provided. */
	resolutionStatus?: InputMaybe<ApiExceptionResolutionStatus>;
	/** The resolution type. Defaults to UNASSIGNED if not provided. */
	resolutionType?: InputMaybe<ApiExceptionResolutionType>;
	/** The supply point identifier associated with the exception, if available. */
	supplyPointIdentifier?: InputMaybe<Scalars['String']>;
	/** Tags associated with this exception if any. */
	tags?: InputMaybe<Array<InputMaybe<ApiExceptionTags>>>;
	/** The user ID associated with the exception, if available. */
	userId?: InputMaybe<Scalars['Int']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7803: Received an invalid apiExceptionId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateApiExceptionNote = {
	__typename?: 'CreateAPIExceptionNote';
	/** The created APIExceptionNote. */
	apiException?: Maybe<ApiExceptionType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateApiExceptionNoteInput = {
	/** The ID of the associated API exception. */
	apiExceptionId: Scalars['ID'];
	/** The body of the note. */
	body: Scalars['String'];
};

/**
 * Add charges to an account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-5211: The charge reason with the requested code is deprecated.
 * - KT-CT-5212: The charge reason with the requested code does not exist.
 * - KT-CT-5213: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAccountCharge = {
	__typename?: 'CreateAccountCharge';
	accountCharge?: Maybe<AccountChargeType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for the account charge. */
export type CreateAccountChargeInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** Optional short note about account charge for customer display. */
	displayNote?: InputMaybe<Scalars['String']>;
	/** The gross amount of the charge to be added. */
	grossAmount: Scalars['Int'];
	/** Any extra data that will be associated with account charge. */
	metadata?: InputMaybe<Scalars['JSONString']>;
	/** Optional short note about account charge for internal use. */
	note?: InputMaybe<Scalars['String']>;
	/** The reason why the charge is added to the account. This should be a valid charge reason code. */
	reason: Scalars['String'];
};

/**
 * Add credits to an account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-5315: Invalid data.
 * - KT-CT-5314: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAccountCredit = {
	__typename?: 'CreateAccountCredit';
	accountCredit?: Maybe<AccountCreditType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for the account credit. */
export type CreateAccountCreditInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The gross amount of the credit to be created. */
	grossAmount: Scalars['Int'];
	/** Any extra data that will be associated with account credit. */
	metadata?: InputMaybe<Scalars['JSONString']>;
	/** The net amount of the credit to be created. */
	netAmount: Scalars['Int'];
	/** Optional short note about account credit. */
	note?: InputMaybe<Scalars['String']>;
	/** The reason why the credit is added to the account. */
	reason: AccountCreditReasonType;
	/** The sales tax amount of the credit to be created. */
	salesTaxAmount: Scalars['Int'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-4180: Account note must be a valid string.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAccountNote = {
	__typename?: 'CreateAccountNote';
	/** Account, which has the added note. */
	account?: Maybe<Account>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateAccountNoteInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** Pin the note to account. */
	isPinned: Scalars['Boolean'];
	/** The note to add. */
	note: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-3815: No active payment schedule found for this account.
 * - KT-CT-3822: Unauthorized.
 * - KT-CT-3923: Unauthorized.
 * - KT-CT-3941: Invalid data.
 * - KT-CT-3942: An unexpected error occurred.
 * - KT-CT-3947: An unexpected error occurred.
 * - KT-CT-3960: Invalid value for payment day.
 * - KT-CT-3961: Cannot update plan-associated payment schedule.
 * - KT-CT-3962: No new value provided to update payment schedule.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAccountPaymentSchedule = {
	__typename?: 'CreateAccountPaymentSchedule';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** New payment schedule. */
	schedule: PaymentScheduleType;
};

/** Input type for updating the payment schedule on a ledger. Requires an `account_number`, `ledger_id` and one of `payment_day` or `payment_amount` to be provided. */
export type CreateAccountPaymentScheduleInput = {
	/** Number of the account for which to update the schedule. */
	accountNumber: Scalars['String'];
	/** Number of the ledger associated with the current payment schedule. */
	ledgerNumber: Scalars['String'];
	/** The new fixed payment amount. */
	paymentAmount?: InputMaybe<Scalars['Int']>;
	/** The new day of the month at which to take payment; ranges from 1 to 28. */
	paymentDay?: InputMaybe<Scalars['Int']>;
};

/**
 * Create an account reference.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-8310: Invalid data.
 * - KT-CT-8311: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAccountReference = {
	__typename?: 'CreateAccountReference';
	accountReference?: Maybe<AccountReferenceType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1401: Invalid data.
 * - KT-CT-1402: Unable to create account reminder.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAccountReminder = {
	__typename?: 'CreateAccountReminder';
	/** Account reminder. */
	accountReminder?: Maybe<AccountReminder>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateAccountReminderInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** Reminder content. */
	content: Scalars['String'];
	/** When the reminder is due. */
	dueAt: Scalars['DateTime'];
	/** The reminder type. */
	reminderType: AccountReminderTypes;
};

/**
 * Create an affiliate link for a new sales agent.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7711: Invalid data.
 * - KT-CT-7713: Invalid data.
 * - KT-CT-7714: Invalid data.
 * - KT-CT-7715: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAffiliateLink = {
	__typename?: 'CreateAffiliateLink';
	affiliateLink?: Maybe<AffiliateLinkType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateAffiliateLinkInputType = {
	contactEmail: Scalars['String'];
	contactName: Scalars['String'];
	/** The organisation for whom to create the affiliate link for. */
	organisationId: Scalars['ID'];
	/**
	 *
	 * Will be validated as follows:
	 *
	 * - should be at least two characters
	 * - should only contain (letters, numbers, and Hyphen)
	 * - should not contain bad words
	 * - should not contain any of the reserved words including:
	 *  affiliates, api, business, click, consul, developer, friends, kraken, mail, sendgrid, tech, webhooks, www, www2
	 */
	subdomain: Scalars['String'];
};

/**
 * Create an affiliate organisation.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7716: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateAffiliateOrganisation = {
	__typename?: 'CreateAffiliateOrganisation';
	affiliateOrganisation?: Maybe<AffiliateOrganisationType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateAffiliateOrganisationInputType = {
	/** Is this partner allowed to specify payment methods other than Direct Debit in the import csv or API. */
	allowAlternativePaymentMethods?: InputMaybe<Scalars['Boolean']>;
	/** Are meter point registrations limited for profile classes 1 and 2 for registrations from csv or API. */
	canRegisterBusinessMeterPoints?: InputMaybe<Scalars['Boolean']>;
	/** Allow registration requests with customers without an email address. */
	canRegisterCustomersWithoutEmailAddress?: InputMaybe<Scalars['Boolean']>;
	/** Allow registration requests with exiting account user emails to add to the portfolio belonging to the account user. */
	canRegisterPortfolioAccounts?: InputMaybe<Scalars['Boolean']>;
	/** Allow performing tariff renewals via API. */
	canRenewTariffs?: InputMaybe<Scalars['Boolean']>;
	/** Allow this partner access to the IVR support API (modify their own IVR handling through third party 'IVR Flow Editor'). */
	canUseIvrSupportApi?: InputMaybe<Scalars['Boolean']>;
	/** Default Account Type. */
	defaultAccountType: AccountTypeChoices;
	/** Restrict to field-sales-only products? This is only allowed for the 'field-sales' and 'events' sales channels. */
	isFieldSalesOnlyProduct?: InputMaybe<Scalars['Boolean']>;
	name: Scalars['String'];
	/** Sales Channel. */
	salesChannel: SalesChannelChoices;
	/** Allow this partner to skip validation that ensures all meter points belong to the same address. */
	skipMeterPointAddressValidation?: InputMaybe<Scalars['Boolean']>;
};

/** Create a session for an affiliate link. */
export type CreateAffiliateSession = {
	__typename?: 'CreateAffiliateSession';
	affiliateSession?: Maybe<AffiliateSessionType>;
};

export type CreateAffiliateSessionInputType = {
	/** The IP Address of the user. */
	ipAddress?: InputMaybe<Scalars['String']>;
	/** The affiliate link for whom to create the session for. */
	linkId: Scalars['ID'];
	/** Additional query parameters to attach to this session. */
	queryParams?: InputMaybe<Scalars['JSONString']>;
	/** The quote share that led to this session. */
	quoteShareId?: InputMaybe<Scalars['ID']>;
	/** The HTTP user agent. */
	userAgent?: InputMaybe<Scalars['String']>;
};

export type CreateBankTransferPaymentMethodInput = {
	/** A code that uniquely identifies the account. */
	accountNumber?: InputMaybe<Scalars['String']>;
};

/** Returned when a customer creates a bank transfer payment method from their MyPage. */
export type CreateBankTransferPaymentMethodResponse =
	| BankTransferPaymentMethodCreated
	| CommonError;

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-9601: Invalid data.
 * - KT-CT-9602: Unable to create contribution agreement.
 * - KT-CT-9605: Contribution amount cannot be 0 or negative.
 * - KT-CT-9606: Scheme is not accepting contributions at this time.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateContributionAgreement = {
	__typename?: 'CreateContributionAgreement';
	/** The created contribution agreement. */
	contributionAgreement?: Maybe<ContributionAgreementType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateContributionAgreementInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The start datetime of the agreement. */
	activeFrom: Scalars['DateTime'];
	/** The end datetime of the agreement, if any. */
	activeTo?: InputMaybe<Scalars['DateTime']>;
	/** The amount contributed per interval. Note, this is in the smallest domination that the currency supports. e.g. Pence, Cents, Yen, etc. */
	amount: Scalars['Int'];
	/** The frequency of contributions. */
	interval: Interval;
	/** The code of the scheme to contribute to. */
	schemeCode: Scalars['String'];
};

/**
 * Create a new deposit agreement for the account if it needs one.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateDepositAgreement = {
	__typename?: 'CreateDepositAgreement';
	depositAgreement?: Maybe<CreateDepositAgreementOutput>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateDepositAgreementInput = {
	accountNumber: Scalars['String'];
	depositKey: Scalars['String'];
	reason: Scalars['String'];
};

export type CreateDepositAgreementOutput = {
	__typename?: 'CreateDepositAgreementOutput';
	depositAmount?: Maybe<Scalars['Int']>;
	depositRequired?: Maybe<Scalars['Boolean']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-JP-4608: Unable to get matched products.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateElectricityQuote = {
	__typename?: 'CreateElectricityQuote';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	quoteRequest?: Maybe<Quote>;
};

export type CreateElectricityQuoteInput = {
	/** Optional Affiliate Session ID for associating a quote with an affiliate session. */
	affiliateSessionId?: InputMaybe<Scalars['String']>;
	/** Amperage of meter. */
	amperage?: InputMaybe<Amperage>;
	/** Generation point params. */
	generationPointParams?: InputMaybe<GenerationPointParamsInput>;
	/** Code for 電力会社. */
	gridOperatorCode: Scalars['String'];
	/** The kVA of meter. */
	kva?: InputMaybe<Scalars['Int']>;
	/** Extra params for tracking sales channel. */
	params?: InputMaybe<Param>;
	postalArea: PostalAreaInput;
	/** Optional tags for filtering products by params. */
	productParams?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	/** Electricity usage (kW) for 1 month. */
	usageAmount: Scalars['Int'];
	/** Month where usage amount take from. */
	usageMonth: Scalars['Int'];
};

/**
 * Create an external account event.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7123: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateExternalAccountEvent = {
	__typename?: 'CreateExternalAccountEvent';
	/** The new event data. */
	event?: Maybe<ExternalAccountEvent>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateExternalAccountEventInput = {
	/** The number of the account that the event should be created for. */
	accountNumber: Scalars['String'];
	/** The category of the event. */
	category: ExternalAccountEventCategory;
	/** An array of content data associated with the event. */
	content: Array<InputMaybe<ExternalAccountEventContent>>;
	/** A human-readable description of the event. */
	description?: InputMaybe<Scalars['String']>;
	/** The time the event occurred. */
	occurredAt?: InputMaybe<Scalars['DateTime']>;
	/** The subcategory of the event. */
	subcategory?: InputMaybe<ExternalAccountEventSubCategory>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8206: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateGoodsPurchase = {
	__typename?: 'CreateGoodsPurchase';
	/** Goods purchase created. */
	goodsPurchase?: Maybe<GoodsPurchase>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8202: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateGoodsQuote = {
	__typename?: 'CreateGoodsQuote';
	/** Goods quote created for the customer. */
	goodsQuote?: Maybe<GoodsQuote>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateGoodsQuoteInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** A JSON object containing client parameters to store on the quote. */
	clientParams?: InputMaybe<Scalars['JSONString']>;
	/** A JSON object containing market parameters to store on the quote. */
	marketParams?: InputMaybe<Scalars['JSONString']>;
	/** Products to get a quote for. */
	productsToQuote: Array<InputMaybe<ProductToQuoteInput>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8202: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateGoodsQuoteWithoutAccount = {
	__typename?: 'CreateGoodsQuoteWithoutAccount';
	/** Goods quote created for the customer. */
	goodsQuote?: Maybe<GoodsQuote>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateGoodsQuoteWithoutAccountInput = {
	/** A JSON object containing client parameters to store on the quote. */
	clientParams?: InputMaybe<Scalars['JSONString']>;
	/** Customer profile. */
	customerProfile: CustomerProfileInput;
	/** A JSON object containing market parameters to store on the quote. */
	marketParams?: InputMaybe<Scalars['JSONString']>;
	/** Products to get a quote for. */
	productsToQuote: Array<InputMaybe<ProductToQuoteInput>>;
};

export type CreateInkInboundMessageInput = {
	channel: InkCommunicationChannel;
	clientMutationId?: InputMaybe<Scalars['String']>;
	/**
	 *
	 * An optional parameter where we can pass the generic message headers if it has one
	 *
	 * Email channel tries to get the value `conversation-relay-id` from this
	 * parameter
	 *
	 */
	messageHeaders?: InputMaybe<Scalars['JSONString']>;
	/**
	 *
	 * An arbitrary, unique ID for this message.
	 *
	 * This must be unique for each message that is supplied
	 * using the same organisation; collisions between messages
	 * provided by different organisations are tolerated.
	 *
	 * Stored as vendor_id.
	 *
	 */
	messageId: Scalars['String'];
	newMessage: InkMessageInput;
	/** When the message occurred in the system of origin. */
	occurredAt?: InputMaybe<Scalars['DateTime']>;
	/**
	 *
	 * An optional vendor value to denote which system it originated from.
	 *
	 * If no vendor is passed, we will get the default generic vendor from
	 * the setting called INK_DEFAULT_GENERIC_MESSAGE_API_VENDOR.
	 *
	 */
	vendor?: InputMaybe<Scalars['String']>;
};

/** Register an Ink inbound message. */
export type CreateInkInboundMessagePayload = {
	__typename?: 'CreateInkInboundMessagePayload';
	clientMutationId?: Maybe<Scalars['String']>;
	/** The Ink message that was created. */
	message: InkMessage;
};

export type CreateJpnFileAttachmentInput = {
	/** Account number. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** Description of uploaded file type. */
	category: Category;
	clientMutationId?: InputMaybe<Scalars['String']>;
	/** Filename. */
	filename: Scalars['String'];
	/** Generation Point Identification Number. */
	gpin?: InputMaybe<Scalars['String']>;
};

/** Upload a file with account number, gpin or both. */
export type CreateJpnFileAttachmentPayload = {
	__typename?: 'CreateJPNFileAttachmentPayload';
	clientMutationId?: Maybe<Scalars['String']>;
	/** Presigned S3 post request. */
	postRequest?: Maybe<UploadPostRequest>;
};

export type CreateKonbiniPaymentInput = {
	/** The Kraken account number. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** The amount to be collected. */
	amount: Scalars['Int'];
	/** A description of the purpose of the payment. */
	description: Scalars['String'];
};

export type CreateKonbiniPaymentMethodInput = {
	/** A code that uniquely identifies the account. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** Payment day for the fixed payment schedule. */
	paymentDay?: InputMaybe<Scalars['Int']>;
};

/** Returned when a customer creates a Konbini Payment method from their MyPage. */
export type CreateKonbiniPaymentMethodResponse =
	| CommonError
	| KonbiniPaymentMethodCreated;

/** Returned when a customer creates a Konbini Payment from their MyPage. */
export type CreateKonbiniPaymentResponse = CommonError | KonbiniPaymentCreated;

/**
 * Mutation to accept T&Cs for customers that are onboarded through Lifeline Agencies.
 * This mutation calls the generate_quote_for_lifeline_agency_sign_up use case
 * to create the quote and also accept T&Cs.
 */
export type CreateLifelineAgencyQuote = {
	__typename?: 'CreateLifelineAgencyQuote';
	/** Boolean indicator that indicated quotes are created. */
	isCreated?: Maybe<Scalars['Boolean']>;
};

export type CreateLifelineAgencyQuoteInput = {
	/** The ID of LifelineAgencySignUpsFileRecord. */
	lifelineAgencySignUpsFileRecordId: Scalars['Int'];
};

/**
 * Create metadata on an object.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-8412: Invalid data.
 * - KT-CT-8414: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateMetadata = {
	__typename?: 'CreateMetadata';
	metadata?: Maybe<Metadata>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Create a Lead instance.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-8901: Unable to create lead.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateNewLead = {
	__typename?: 'CreateNewLead';
	/** Lead created. */
	lead?: Maybe<NewLeadType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreateOrUpdateLoyaltyCardInput = {
	/** The id of the account user. */
	accountUserId?: InputMaybe<Scalars['String']>;
	/** The number of the loyalty card. */
	number?: InputMaybe<Scalars['String']>;
	/** The scheme of the loyalty card. */
	scheme?: InputMaybe<Scalars['String']>;
};

/**
 * Create a Loyalty Card for the given account user.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-5412: No account user exists with the given id.
 * - KT-CT-8610: Invalid data.
 * - KT-CT-8611: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateOrUpdateLoyaltyCardMutation = {
	__typename?: 'CreateOrUpdateLoyaltyCardMutation';
	/** Created or updated loyalty card. */
	loyaltyCard?: Maybe<LoyaltyCardType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Creates a new customer in GMO, a PaymentInstruction, and PaymentSchedule for an existing user.
 *
 * This is called when creating a new CREDIT CARD payment method. Direct debit uses the
 * IncomingGMOCreationExistingAccountCallback webhook.
 *
 * This endpoint is designed to be used on an account with no existing payment method.
 */
export type CreatePaymentMethod = {
	__typename?: 'CreatePaymentMethod';
	/** GMO payment instruction reference. */
	reference: Scalars['String'];
};

export type CreatePaymentMethodInput = {
	/** The Kraken account number. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** Payment information. */
	payment?: InputMaybe<PaymentInput>;
	/** Payment day for the fixed payment schedule. */
	paymentDay?: InputMaybe<Scalars['Int']>;
};

/** Returned when a customer creates a fixed payment schedule from their MyPage. */
export type CreatePaymentScheduleResponse =
	| CommonError
	| PaymentScheduleCreated;

/**
 * Create a new portfolio.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-9402: Received an invalid brandCode.
 * - KT-CT-9401: Received an invalid operationsTeamId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreatePortfolio = {
	__typename?: 'CreatePortfolio';
	/** The created portfolio. */
	portfolio?: Maybe<PortfolioType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreatePortfolioInput = {
	/** The brand to associate with this portfolio, if not provided the default brand will be used. */
	brandCode?: InputMaybe<Scalars['String']>;
	/** Whether collective bills should be issued for the portfolio's accounts. The default value is False. */
	collectiveBilling?: InputMaybe<Scalars['Boolean']>;
	/** The ID of the operations team to associate with this portfolio.If no team is provided, no team will be assigned to the portfolio. */
	operationsTeamId?: InputMaybe<Scalars['ID']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-9403: Received an invalid portfolioId.
 * - KT-CT-9404: Received an invalid accountUserId.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreatePortfolioUserRole = {
	__typename?: 'CreatePortfolioUserRole';
	/** The created role for a user in association with a portfolio. */
	portfolioUserRole?: Maybe<PortfolioUserRoleType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type CreatePortfolioUserRoleInput = {
	/** The user to associate with the portfolio. */
	accountUserId: Scalars['ID'];
	/** The portfolio to associate the user with. */
	portfolioId: Scalars['ID'];
	/** The role to assign to the user. If not provided the default role will be used. */
	role?: InputMaybe<Scalars['RoleString']>;
};

export type CreatePurchaseInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** A JSON object containing client parameters to store on the purchase. */
	clientParams?: InputMaybe<Scalars['JSONString']>;
	/** A JSON object containing client parameters to store on the purchase. */
	marketParams?: InputMaybe<Scalars['JSONString']>;
	/** Products being purchased. */
	saleItems: Array<InputMaybe<ProductToPurchaseInput>>;
};

/**
 * Use a referral code to create a referral and trigger a referral reward.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-6723: Unauthorized.
 * - KT-CT-6710: Unable to create referral.
 * - KT-CT-6711: Accounts may not self-refer.
 * - KT-CT-6713: Referring and referred account brands do not match.
 * - KT-CT-6712: Invalid reference.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type CreateReferral = {
	__typename?: 'CreateReferral';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** The reward amount to be issued to the referred account, in smallest currency subunits. */
	referredAccountRewardAmount?: Maybe<Scalars['Int']>;
};

/** Required information for creating a referral */
export type CreateReferralInput = {
	/** The account number for the referred account. */
	accountNumber: Scalars['String'];
	/** An email address, link or code, referencing the referring account. */
	reference: Scalars['String'];
};

export type CreateShellAccountInput = {
	billingAddressLine1?: InputMaybe<Scalars['String']>;
	billingAddressLine2?: InputMaybe<Scalars['String']>;
	billingAddressLine3?: InputMaybe<Scalars['String']>;
	billingAddressLine4?: InputMaybe<Scalars['String']>;
	billingAddressLine5?: InputMaybe<Scalars['String']>;
	billingName?: InputMaybe<Scalars['String']>;
	/** Day to fixed bill on if billing_period_length set. */
	billingPeriodDay?: InputMaybe<Scalars['Int']>;
	/** For fixed billing accounts only, the length of their billing period. Can be MONTHLY or QUARTERLY. */
	billingPeriodLength?: InputMaybe<Scalars['String']>;
	/** Month to start billing from if billing_period_length set to QUARTERLY or the multiplier is > 1. */
	billingPeriodMonth?: InputMaybe<Scalars['Int']>;
	/** For fixed billing accounts only, the number the period length is to be multiplied by to get the total period length, i.e. for billing every second month, select 2 combined with a billing period length MONTHLY. Can't be > 1 for quarterly billing. */
	billingPeriodMultiplier?: InputMaybe<Scalars['Int']>;
	billingPostcode?: InputMaybe<Scalars['String']>;
	billingRichAddress?: InputMaybe<Scalars['String']>;
	brand?: InputMaybe<Scalars['String']>;
	businessType?: InputMaybe<Scalars['String']>;
	clientMutationId?: InputMaybe<Scalars['String']>;
	companyName?: InputMaybe<Scalars['String']>;
	companyNumber?: InputMaybe<Scalars['String']>;
	dateOfBirth?: InputMaybe<Scalars['Date']>;
	email: Scalars['String'];
	familyName: Scalars['String'];
	givenName: Scalars['String'];
	isBusinessAccount?: InputMaybe<Scalars['Boolean']>;
	landline?: InputMaybe<Scalars['String']>;
	mobile?: InputMaybe<Scalars['String']>;
	password?: InputMaybe<Scalars['String']>;
	passwordUpdateToken?: InputMaybe<Scalars['String']>;
	portfolioNumber?: InputMaybe<Scalars['String']>;
	urn?: InputMaybe<Scalars['String']>;
};

/** Create a shell account (a billable account with no property/energy supply). */
export type CreateShellAccountPayload = {
	__typename?: 'CreateShellAccountPayload';
	account?: Maybe<AccountInterface>;
	billingAddressLine1?: Maybe<Scalars['String']>;
	billingAddressLine2?: Maybe<Scalars['String']>;
	billingAddressLine3?: Maybe<Scalars['String']>;
	billingAddressLine4?: Maybe<Scalars['String']>;
	billingAddressLine5?: Maybe<Scalars['String']>;
	billingName?: Maybe<Scalars['String']>;
	/** Day to fixed bill on if billing_period_length set. */
	billingPeriodDay?: Maybe<Scalars['Int']>;
	/** For fixed billing accounts only, the length of their billing period. Can be MONTHLY or QUARTERLY. */
	billingPeriodLength?: Maybe<Scalars['String']>;
	/** Month to start billing from if billing_period_length set to QUARTERLY or the multiplier is > 1. */
	billingPeriodMonth?: Maybe<Scalars['Int']>;
	/** For fixed billing accounts only, the number the period length is to be multiplied by to get the total period length, i.e. for billing every second month, select 2 combined with a billing period length MONTHLY. Can't be > 1 for quarterly billing. */
	billingPeriodMultiplier?: Maybe<Scalars['Int']>;
	billingPostcode?: Maybe<Scalars['String']>;
	billingRichAddress?: Maybe<Scalars['String']>;
	brand?: Maybe<Scalars['String']>;
	businessType?: Maybe<Scalars['String']>;
	clientMutationId?: Maybe<Scalars['String']>;
	companyName?: Maybe<Scalars['String']>;
	companyNumber?: Maybe<Scalars['String']>;
	dateOfBirth?: Maybe<Scalars['Date']>;
	email: Scalars['String'];
	errors?: Maybe<Array<Maybe<ErrorType>>>;
	familyName: Scalars['String'];
	givenName: Scalars['String'];
	isBusinessAccount?: Maybe<Scalars['Boolean']>;
	landline?: Maybe<Scalars['String']>;
	mobile?: Maybe<Scalars['String']>;
	password?: Maybe<Scalars['String']>;
	passwordUpdateToken?: Maybe<Scalars['String']>;
	portfolioNumber?: Maybe<Scalars['String']>;
	urn?: Maybe<Scalars['String']>;
};

/** A credit to the customer from the energy retailer. */
export type Credit = TransactionType & {
	__typename?: 'Credit';
	/** Unique identifier of the account the transaction belongs to. */
	accountNumber?: Maybe<Scalars['String']>;
	/**
	 * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
	 * @deprecated The 'amount' field is deprecated.
	 *
	 * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
	 *
	 * - Marked as deprecated on 2023-12-06.
	 * - Will be removed on 2024-06-01.
	 */
	amount?: Maybe<Scalars['Int']>;
	/** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
	amounts?: Maybe<TransactionAmountType>;
	/** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
	balanceCarriedForward?: Maybe<Scalars['Int']>;
	/** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
	billingDocumentIdentifier?: Maybe<Scalars['ID']>;
	/** The date time when the transaction is created. */
	createdAt?: Maybe<Scalars['DateTime']>;
	/** Returns True if the transaction is linked with a statement. */
	hasStatement?: Maybe<Scalars['Boolean']>;
	id?: Maybe<Scalars['ID']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isAccountCharge' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isAccountCharge?: Maybe<Scalars['Boolean']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isAccountPayment' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isAccountPayment?: Maybe<Scalars['Boolean']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isCredit' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isCredit?: Maybe<Scalars['Boolean']>;
	/** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
	isHeld?: Maybe<Scalars['Boolean']>;
	/** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
	isIssued?: Maybe<Scalars['Boolean']>;
	isReversed: Scalars['Boolean'];
	/** Returns the note field value for the transaction, which contains additional info. */
	note?: Maybe<Scalars['String']>;
	postedDate?: Maybe<Scalars['Date']>;
	/**
	 * Returns None if a statement is not linked with the transaction.
	 * @deprecated The 'statementId' field is deprecated.
	 *
	 * Use `billingDocumentIdentifier` instead.
	 *
	 * - Marked as deprecated on 2023-11-30.
	 * - Will be removed on 2024-06-01.
	 */
	statementId?: Maybe<Scalars['ID']>;
	title?: Maybe<Scalars['String']>;
};

export type CreditReasonType = {
	__typename?: 'CreditReasonType';
	/** The credit reason code. */
	code?: Maybe<Scalars['String']>;
	/** The credit reason display text. */
	display?: Maybe<Scalars['String']>;
	/** The group the credit reason belongs to (if applicable). */
	group?: Maybe<Scalars['String']>;
	/** Whether the credit reason is deprecated. */
	isDeprecated?: Maybe<Scalars['Boolean']>;
	/** Whether the credit reason is hidden. */
	isHidden?: Maybe<Scalars['Boolean']>;
	/** Whether the credit reason is sales tax exempt. */
	isTaxExempt?: Maybe<Scalars['Boolean']>;
};

/** Details about the customer. */
export type CustomerDetailsInput = {
	/** The customer's date of birth. */
	dateOfBirth?: InputMaybe<Scalars['Date']>;
	/** Account email. */
	email?: InputMaybe<Scalars['String']>;
	/** Family name. */
	familyName: Scalars['String'];
	/** Given name. */
	givenName: Scalars['String'];
	/** Account landline number. */
	landline?: InputMaybe<Scalars['String']>;
	/** Account mobile phone number. */
	mobile?: InputMaybe<Scalars['String']>;
	/** The customer's communication preferences. */
	preferences?: InputMaybe<UpdateAccountUserCommsPreferencesInput>;
	/** The customer's pronouns. */
	pronouns?: InputMaybe<Scalars['String']>;
	/** The customer's title. */
	title?: InputMaybe<Scalars['String']>;
};

export type CustomerFeedbackInputType = {
	accountNumber: Scalars['String'];
	answer?: InputMaybe<Scalars['String']>;
	feedbackId: Scalars['Int'];
	formId: Scalars['Int'];
	issueResolved: Scalars['Boolean'];
};

export type CustomerFeedbackType = {
	__typename?: 'CustomerFeedbackType';
	id: Scalars['ID'];
	/** The value attached to the source */
	rawScore?: Maybe<Scalars['Int']>;
	/** The datetime the feedback was submitted */
	submittedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerProfileInput = {
	/** Line 1 of customer's address. */
	addressLine1: Scalars['String'];
	/** Line 2 of customer's address. */
	addressLine2?: InputMaybe<Scalars['String']>;
	/** Line 3 of customer's address. */
	addressLine3?: InputMaybe<Scalars['String']>;
	/** Line 4 of customer's address. */
	addressLine4?: InputMaybe<Scalars['String']>;
	/** Line 5 of customer's address. */
	addressLine5?: InputMaybe<Scalars['String']>;
	/** Customer's email. */
	email: Scalars['String'];
	/** Customer's family name. */
	familyName: Scalars['String'];
	/** Customer's given name. */
	givenName: Scalars['String'];
	/** Customer's phone number. */
	phoneNumber: Scalars['String'];
	/** Customer's postcode. */
	postcode: Scalars['String'];
};

/** A list of components which comprise a dashboard screen. */
export type Dashboard = IdentifiableInterface & {
	__typename?: 'Dashboard';
	/** The list of sections for a dashboard. */
	dashboardItems: Array<Maybe<SectionType>>;
	/** Unique identifier of the object. */
	id?: Maybe<Scalars['ID']>;
	/** The serialised dashboard items. */
	serialisedDashboardItems: Scalars['String'];
	/** The name of the object's type. */
	typename?: Maybe<Scalars['String']>;
};

export type DebtCollectionAgencyType = {
	__typename?: 'DebtCollectionAgencyType';
	code: Scalars['String'];
	isActive: Scalars['Boolean'];
	name: Scalars['String'];
};

/** An enumeration. */
export enum DebtCollectionProceedingStopReason {
	/** Arrears to client */
	ArrearsToClient = 'ARREARS_TO_CLIENT',
	/** Bankrupt */
	Bankrupt = 'BANKRUPT',
	/** Confirmed empty */
	ConfirmedEmpty = 'CONFIRMED_EMPTY',
	/** COS */
	Cos = 'COS',
	/** COT / COS */
	CotCos = 'COT_COS',
	/** COT no proof */
	CotNoProof = 'COT_NO_PROOF',
	/** COT proof seen */
	CotProofSeen = 'COT_PROOF_SEEN',
	/** Deceased */
	Deceased = 'DECEASED',
	/** Demolished */
	Demolished = 'DEMOLISHED',
	/** Enrolled in Error */
	EnrolledInError = 'ENROLLED_IN_ERROR',
	/** Expired */
	Exp = 'EXP',
	/** Gone away */
	GoneAway = 'GONE_AWAY',
	/** High level complaint */
	HighLevelComplaint = 'HIGH_LEVEL_COMPLAINT',
	/** Insolvencies dealing */
	InsolvenciesDealing = 'INSOLVENCIES_DEALING',
	/** In prison */
	InPrison = 'IN_PRISON',
	/** Live to final */
	LiveToFinal = 'LIVE_TO_FINAL',
	/** Low balance */
	LowBalance = 'LOW_BALANCE',
	/** Negative trace */
	NegativeTrace = 'NEGATIVE_TRACE',
	/** Paid in full */
	PaidInFull = 'PAID_IN_FULL',
	/** Partial settlement */
	PartialSettlement = 'PARTIAL_SETTLEMENT',
	/** Part payment */
	PartPayment = 'PART_PAYMENT',
	/** Payment arrangement */
	PaymentArrangement = 'PAYMENT_ARRANGEMENT',
	/** Payment plan agreed */
	PaymentPlanAgreed = 'PAYMENT_PLAN_AGREED',
	/** PPM arrangement */
	PpmArrangement = 'PPM_ARRANGEMENT',
	/** PPM fitted */
	PpmFitted = 'PPM_FITTED',
	/** PPM requested */
	PpmRequested = 'PPM_REQUESTED',
	/** Process exhausted */
	ProcessExhausted = 'PROCESS_EXHAUSTED',
	/** Process exhausted - contact */
	ProcessExhaustedContact = 'PROCESS_EXHAUSTED_CONTACT',
	/** Process exhausted - no contact */
	ProcessExhaustedNoContact = 'PROCESS_EXHAUSTED_NO_CONTACT',
	/** Query */
	Query = 'QUERY',
	/** Reduced settlement */
	ReducedSettlement = 'REDUCED_SETTLEMENT',
	/** Refused to deal */
	RefusedToDeal = 'REFUSED_TO_DEAL',
	/** Unable to locate property */
	UnableToLocateProperty = 'UNABLE_TO_LOCATE_PROPERTY',
	/** Unable to trace */
	UnableToTrace = 'UNABLE_TO_TRACE',
	/** Vulnerable */
	Vulnerable = 'VULNERABLE',
	/** Withdrawn */
	Withdrawn = 'WITHDRAWN',
	/** Write off */
	WriteOff = 'WRITE_OFF',
}

export type DebtCollectionProceedingType = {
	__typename?: 'DebtCollectionProceedingType';
	/** The agency responsible for the collection proceedings. */
	agency?: Maybe<DebtCollectionAgencyType>;
	amount?: Maybe<Scalars['Int']>;
	/** The campaign type of the collection proceedings. */
	campaign?: Maybe<CollectionCampaignType>;
	startedAt: Scalars['DateTime'];
	stopReason?: Maybe<DebtCollectionProceedingStopReason>;
	stoppedAt?: Maybe<Scalars['DateTime']>;
};

/** An action which navigates to the URL of another backend screen. */
export type DeeplinkActionType = ActionInterface &
	IdentifiableInterface & {
		__typename?: 'DeeplinkActionType';
		/** Unique identifier of the object. */
		id?: Maybe<Scalars['ID']>;
		/** The name of the action object's type. */
		typeName?: Maybe<Scalars['String']>;
		/** The name of the object's type. */
		typename?: Maybe<Scalars['String']>;
		/** The URL to navigate to. */
		url: Scalars['String'];
	};

/**
 * Delete a reference for a particular account and namespace.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-8310: Invalid data.
 * - KT-CT-8312: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type DeleteAccountReference = {
	__typename?: 'DeleteAccountReference';
	accountReference?: Maybe<DeleteAccountReferenceType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type DeleteAccountReferenceInput = {
	/** The account number associated with the removed AccountReference. */
	accountNumber: Scalars['String'];
	/** The namespace associated with the removed AccountReference. */
	namespace: Scalars['String'];
};

export type DeleteAccountReferenceType = {
	__typename?: 'DeleteAccountReferenceType';
	/** The namespace associated with the removed AccountReference. */
	namespace: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-5411: Invalid token or no push notification binding found for the given account user.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type DeletePushNotificationBinding = {
	__typename?: 'DeletePushNotificationBinding';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	status?: Maybe<DeletePushNotificationBindingOutput>;
};

export type DeletePushNotificationBindingInput = {
	/** Device push notification token. */
	token: Scalars['String'];
};

export enum DeletePushNotificationBindingOutput {
	Failed = 'FAILED',
	Successful = 'SUCCESSFUL',
}

export type DemandResponseCampaignParticipantCommsPreferencesResponse =
	| CommonError
	| DemandResponseCampaignParticipantCommsPreferencesUpdated;

export type DemandResponseCampaignParticipantCommsPreferencesUpdated = {
	__typename?: 'DemandResponseCampaignParticipantCommsPreferencesUpdated';
	/** Opt-in or out of future events. */
	isOptedIntoDemandResponseEvents?: Maybe<Scalars['Boolean']>;
	/** Opt-in or out to email communications. */
	isOptedIntoEmails?: Maybe<Scalars['Boolean']>;
	/** Opt-in or out to line communications. */
	isOptedIntoLine?: Maybe<Scalars['Boolean']>;
};

export type DemandResponseCampaignParticipantTipUpdated = {
	__typename?: 'DemandResponseCampaignParticipantTipUpdated';
	/** Campaign participant tips. */
	tips?: Maybe<Scalars['JSONString']>;
	/** Get the number of entries the participant has for the next prize draw. */
	totalNumberOfEntries?: Maybe<Scalars['Int']>;
};

/**
 * Type representing the fields in the model representing a customer's results for the latest
 * demand response campaign.
 */
export type DemandResponseCampaignParticipantType = {
	__typename?: 'DemandResponseCampaignParticipantType';
	/** The average percentage difference across all demand response days. */
	averagePercentageDifference?: Maybe<Scalars['Float']>;
	/** The end date of this campaign. */
	campaignEnd?: Maybe<Scalars['DateTime']>;
	/** The campaign that the participant belongs to. */
	campaignName?: Maybe<Scalars['String']>;
	/** The start date of this campaign. */
	campaignStart?: Maybe<Scalars['DateTime']>;
	/** Fetch a list of each demand response date the customer participated in. */
	demandResponseDailyResults: Array<DemandResponseDailyResultType>;
	/** A participant is eligible for events if they have been more than a few days on supply. */
	isEligibleForEvents?: Maybe<Scalars['Boolean']>;
	/** Whether the participant is opted in to `email` communications. */
	isOptedIntoEmails?: Maybe<Scalars['Boolean']>;
	/** Whether the participant is opted in to future events. */
	isOptedIntoEvents?: Maybe<Scalars['Boolean']>;
	/** Whether the participant is opted in to `line` communications. */
	isOptedIntoLine?: Maybe<Scalars['Boolean']>;
	/** Get the number of entries the participant has for the next prize draw. */
	numberOfEntries?: Maybe<Scalars['Int']>;
	/** The timestamp when the account opted in for this campaign. */
	optedInAt?: Maybe<Scalars['DateTime']>;
	/** Signed up supply point identification number. */
	spin?: Maybe<Scalars['String']>;
	/** The number of demand response event days where a customer achieved the target. */
	successfulDaysCount?: Maybe<Scalars['Int']>;
	/** Get the campaign tips and whether they've been completed by the participant. */
	tips?: Maybe<Scalars['JSONString']>;
	/** The total CO2 that a customer saved or increased due to their consumption change. */
	totalDiffCarbon?: Maybe<Scalars['Float']>;
	/** The total in kWh that a customer's consumption changed by participating in the latest campaign. */
	totalDiffKwh?: Maybe<Scalars['Float']>;
	/** The total CO2 that a customer saved converted into trees. */
	totalTreesSaved?: Maybe<Scalars['Float']>;
};

/**
 * Type representing a single day's results for a customer attempting
 * a demand response event.
 */
export type DemandResponseDailyResultType = {
	__typename?: 'DemandResponseDailyResultType';
	/** The amount in yen that have been saved in this challenge. */
	amountSaved?: Maybe<Scalars['Float']>;
	/** The expected consumption of a customer with responding to a demand response event. */
	baselineConsumptionKwh?: Maybe<Scalars['Float']>;
	/** The date of a particular demand response event. */
	eventDate?: Maybe<Scalars['Date']>;
	/** Get the number of entries the participant has got from this daily result. */
	numberOfEntries?: Maybe<Scalars['Int']>;
	/** The actual consumption of a customer, having responded to an event. */
	outturnConsumptionKwh?: Maybe<Scalars['Float']>;
	/** The percentage difference between the target and actual consumption. */
	percentageDifference?: Maybe<Scalars['Float']>;
	/** The start time of a particular demand response event. */
	validFrom?: Maybe<Scalars['DateTime']>;
	/** The end time of a particular demand response event. */
	validTo?: Maybe<Scalars['DateTime']>;
};

export type DepositAgreementInput = {
	accountNumber: Scalars['String'];
	depositKey: Scalars['String'];
};

export type DepositAgreementOutput = {
	__typename?: 'DepositAgreementOutput';
	acceptedAt?: Maybe<Scalars['DateTime']>;
	depositAmount?: Maybe<Scalars['Int']>;
	depositKey?: Maybe<Scalars['String']>;
	fulfilledAt?: Maybe<Scalars['DateTime']>;
};

export type DetailsInputType = {
	/** Namespace of the detail. */
	namespace: Scalars['String'];
	/** Value of the detail. */
	value?: InputMaybe<Scalars['String']>;
};

export type DirectDebitInstructionConnectionTypeConnection = {
	__typename?: 'DirectDebitInstructionConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<DirectDebitInstructionConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `DirectDebitInstructionConnectionType` and its cursor. */
export type DirectDebitInstructionConnectionTypeEdge = {
	__typename?: 'DirectDebitInstructionConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<DirectDebitInstructionType>;
};

/** An enumeration. */
export enum DirectDebitInstructionStatus {
	/** The account for this instruction was closed. */
	AccountClosed = 'ACCOUNT_CLOSED',
	/** The instruction is active and can be used to take payments. */
	Active = 'ACTIVE',
	/** The instruction could not be set up with the vendor. */
	Failed = 'FAILED',
	/** The instruction has not yet been set up. */
	Provisional = 'PROVISIONAL',
}

/** Direct Debit Instructions */
export type DirectDebitInstructionType = {
	__typename?: 'DirectDebitInstructionType';
	accountHolder: Scalars['String'];
	cardExpiryMonth?: Maybe<Scalars['Int']>;
	cardExpiryYear?: Maybe<Scalars['Int']>;
	cardPaymentNetwork?: Maybe<Scalars['String']>;
	iban: Scalars['String'];
	id: Scalars['ID'];
	instructionType: Scalars['String'];
	/**
	 * The last four digits of the account number.
	 * @deprecated The 'lastFourDigitsOfAccountNumber' field is deprecated.
	 *
	 * Use 'maskedAccountIdentifier' for a masked reference to the instruction.
	 *
	 * - Marked as deprecated on 2021-12-23.
	 * - Will be removed on 2024-01-01.
	 */
	lastFourDigitsOfAccountNumber?: Maybe<Scalars['String']>;
	/** A masked reference to a recurring payment method. */
	maskedAccountIdentifier?: Maybe<Scalars['String']>;
	/** A masked version of the IBAN. */
	maskedIban?: Maybe<Scalars['String']>;
	sortCode: Scalars['String'];
	status: Scalars['String'];
};

export type DisconnectionProcess = {
	__typename?: 'DisconnectionProcess';
	/** The status of the disconnection process. */
	status: DisconnectionProcessStatus;
	/** The date when the supply is scheduled to be disconnected. */
	supplyEndDate?: Maybe<Scalars['Date']>;
};

/** An enumeration. */
export enum DisconnectionProcessStatus {
	Closed = 'CLOSED',
	Completed = 'COMPLETED',
	CompletedAfterFailedToUpdate = 'COMPLETED_AFTER_FAILED_TO_UPDATE',
	FailedToUpdate = 'FAILED_TO_UPDATE',
	IngestionFailed = 'INGESTION_FAILED',
	InProgress = 'IN_PROGRESS',
	Rejected = 'REJECTED',
	RequestBuilderError = 'REQUEST_BUILDER_ERROR',
	RequestError = 'REQUEST_ERROR',
	WaitingToApply = 'WAITING_TO_APPLY',
	Withdrawn = 'WITHDRAWN',
}

/** An enumeration. */
export enum DocumentAccessibilityChoices {
	/** Audio-accessible documents are requested for this account. */
	Audio = 'AUDIO',
	/** Braille documents are requested for this account. */
	Braille = 'BRAILLE',
	/** Large print documents are requested for this account. */
	LargePrint = 'LARGE_PRINT',
}

export type DunningAction = {
	__typename?: 'DunningAction';
	actionedAt?: Maybe<Scalars['DateTime']>;
	createdAt: Scalars['DateTime'];
	dueDate: Scalars['Date'];
	stepName: Scalars['String'];
	usedChannel?: Maybe<DunningActionUsedChannel>;
};

/** An enumeration. */
export enum DunningActionUsedChannel {
	/** Email */
	Email = 'EMAIL',
	/** Print */
	Print = 'PRINT',
	/** SMS */
	Sms = 'SMS',
}

export type DunningProcess = {
	__typename?: 'DunningProcess';
	/** Actions associated with a DunningProces. */
	actions?: Maybe<Array<Maybe<DunningAction>>>;
	/** Delinquent debt for an Account. */
	badDebt?: Maybe<Scalars['Int']>;
	/** The date and time where the process reached a terminal state, such as complete or withdrawn. */
	concludedAt?: Maybe<Scalars['DateTime']>;
	createdAt: Scalars['DateTime'];
	/** Created by SupportUser reference. */
	createdBy?: Maybe<Scalars['String']>;
	createdNote: Scalars['String'];
	/** The next step in the DunningProcess. */
	nextStep?: Maybe<Scalars['String']>;
	pathName: Scalars['String'];
	/** The previous step in the DunningProcess. */
	previousStep?: Maybe<Scalars['String']>;
	startDate: Scalars['Date'];
	status: DunningProcessStatus;
	updatedAt: Scalars['DateTime'];
	/** Withdrawn by SupportUser reference. */
	withdrawnBy?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum DunningProcessStatus {
	/** Active */
	Active = 'ACTIVE',
	/** Complete */
	Complete = 'COMPLETE',
	/** Withdrawing */
	Withdrawing = 'WITHDRAWING',
	/** Withdrawn */
	Withdrawn = 'WITHDRAWN',
}

/**
 *
 * Filter measurements by electricity parameters.
 *
 */
export type ElectricityFiltersInput = {
	deviceId?: InputMaybe<Scalars['String']>;
	marketSupplyPointId?: InputMaybe<Scalars['String']>;
	/** Reading direction is based on the utility generated or consumed by the customer. */
	readingDirection?: InputMaybe<ReadingDirectionType>;
	readingFrequencyType?: InputMaybe<ReadingFrequencyType>;
	readingQuality?: InputMaybe<ReadingQualityType>;
	registerId?: InputMaybe<Scalars['String']>;
};

export type ElectricityFiltersOutput = {
	__typename?: 'ElectricityFiltersOutput';
	deviceId?: Maybe<Scalars['String']>;
	marketSupplyPointId?: Maybe<Scalars['String']>;
	/** Reading direction is based on the utility generated or consumed by the customer. */
	readingDirection?: Maybe<ReadingDirectionType>;
	readingFrequencyType?: Maybe<ReadingFrequencyType>;
	readingQuality?: Maybe<ReadingQualityType>;
	registerId?: Maybe<Scalars['String']>;
};

/** Product that has the FiT tariff. */
export type ElectricityFitProduct = {
	__typename?: 'ElectricityFitProduct';
	/** Product code. */
	code: Scalars['String'];
	/** Human friendly name to be displayed in interfaces. */
	displayName: Scalars['String'];
	/** Generation credit rate information. */
	generationCredits: Array<Maybe<GenerationCreditRate>>;
	/** A JSON object containing extra information. */
	params?: Maybe<Scalars['GenericScalar']>;
};

export type ElectricityGenerationPoint = {
	__typename?: 'ElectricityGenerationPoint';
	/** The Agreements linked to this electricity generation point. */
	agreements?: Maybe<Array<Maybe<GenerationAgreement>>>;
	/** The generation point identification number. */
	gpin: Scalars['String'];
	/** Fetch Half Hourly Readings for this GPIN from and to a given datetime of any version. Restricted by the Agreement Periods. */
	halfHourlyReadings: Array<ElectricityHalfHourReading>;
	/** Electricity generation point id. */
	id?: Maybe<Scalars['ID']>;
	/** Return the monthly confirmed Export readings associated with a given Electricity Generation Point.Restricted by the Agreement Periods. */
	intervalReadings: Array<ExportIntervalReadings>;
	/** The most recent Export Cos Gain Process. */
	latestExportCosGainProcess?: Maybe<ExportCosGainProcess>;
	/** The generation point status. */
	status: GenerationPointStatus;
};

export type ElectricityGenerationPointHalfHourlyReadingsArgs = {
	fromDatetime?: InputMaybe<Scalars['DateTime']>;
	productCode?: InputMaybe<Scalars['String']>;
	toDatetime?: InputMaybe<Scalars['DateTime']>;
};

export type ElectricityGenerationPointIntervalReadingsArgs = {
	productCode?: InputMaybe<Scalars['String']>;
};

/** Half Hourly Interval Reading - can be a variety of different versions. */
export type ElectricityHalfHourReading = {
	__typename?: 'ElectricityHalfHourReading';
	/** The tariff's consumption product rate band that this reading falls into. */
	consumptionRateBand: Scalars['String'];
	/** Placeholder for FED type check, will remove it soon. */
	consumptionStep: Scalars['Int'];
	/** Estimated cost for amount of Electricity used. */
	costEstimate: Scalars['Decimal'];
	/** End of Interval Period - always 30mins later. */
	endAt: Scalars['DateTime'];
	/** Start of Interval Period. */
	startAt: Scalars['DateTime'];
	/** Consumed amount of Electricity during period, in kWh. */
	value: Scalars['Decimal'];
	/** The version of the half hourly reading. */
	version: HalfHourlyReadingsVersions;
};

/** Monthly Interval Reading - which may have associated 30min Readings */
export type ElectricityIntervalReading = {
	__typename?: 'ElectricityIntervalReading';
	/** The month with which the monthly interval reading is associated. */
	billableMonth: Scalars['String'];
	/** Estimated cost for amount of Electricity used. */
	costEstimate: Scalars['Decimal'];
	/** End of Interval Period. */
	endAt: Scalars['DateTime'];
	/** Fetch Half Hourly Readings associated with this interval reading. Will return an empty list if has_half_hourly_data_for_period is False. */
	halfHourlyReadings: Array<ElectricityHalfHourReading>;
	/** Tracks whether there are Half Hourly Readings associated with this Interval. */
	hasHalfHourlyDataForPeriod: Scalars['Boolean'];
	id?: Maybe<Scalars['ID']>;
	/** The interval period length, in days. */
	periodLengthDays: Scalars['Int'];
	/** Date when the reading was taken. */
	readingDate?: Maybe<Scalars['Date']>;
	/** Start of Interval Period. */
	startAt: Scalars['DateTime'];
	/** Consumed amount of Electricity during period, in kWh. */
	value: Scalars['Decimal'];
};

/** Product with a single unit rate. */
export type ElectricitySingleStepProduct = ProductInterface & {
	__typename?: 'ElectricitySingleStepProduct';
	/** Product code. */
	code: Scalars['String'];
	consumptionCharges: Array<Maybe<ConsumptionRate>>;
	/** Human friendly name to be displayed in interfaces. */
	displayName: Scalars['String'];
	/** Fuel cost adjustment rate information. */
	fuelCostAdjustment?: Maybe<FuelCostAdjustmentRate>;
	/** A JSON object containing extra information. */
	params?: Maybe<Scalars['GenericScalar']>;
	/** Price per day for standing charge. */
	standingChargePricePerDay?: Maybe<Scalars['Decimal']>;
	/** Unit type for standing charge. */
	standingChargeUnitType?: Maybe<Scalars['String']>;
	/** List of standing charge product rates. */
	standingCharges: Array<Maybe<StandingCharge>>;
};

/** Product where kilowatt-hour rate varies according to the level of consumption during the period specified in the rates. */
export type ElectricitySteppedProduct = ProductInterface & {
	__typename?: 'ElectricitySteppedProduct';
	/** Product code. */
	code: Scalars['String'];
	/** List of consumption product rates. */
	consumptionCharges: Array<Maybe<SteppedConsumptionRate>>;
	/** Human friendly name to be displayed in interfaces. */
	displayName: Scalars['String'];
	/** Fuel cost adjustment rate information. */
	fuelCostAdjustment?: Maybe<FuelCostAdjustmentRate>;
	/** A JSON object containing extra information. */
	params?: Maybe<Scalars['GenericScalar']>;
	/** Price per day for standing charge. */
	standingChargePricePerDay?: Maybe<Scalars['Decimal']>;
	/** Unit type for standing charge. */
	standingChargeUnitType?: Maybe<Scalars['String']>;
	/** List of standing charge product rates. */
	standingCharges: Array<Maybe<StandingCharge>>;
};

export type ElectricitySupplyPoint = {
	__typename?: 'ElectricitySupplyPoint';
	/** A currently active amperage change process. */
	activeAmperageChangeProcess?: Maybe<AmperageChangeProcess>;
	activeCosLossProcess?: Maybe<CosLossProcess>;
	activeMoveOutProcess?: Maybe<MoveOutProcess>;
	/** The Agreements linked to this electricity supply point. */
	agreements: Array<Maybe<Agreement>>;
	/** The supply point capacity in Amperes. */
	amperage?: Maybe<Scalars['Int']>;
	/** An indication of whether a customer can apply for the change themselves. */
	canApplyAmperageChange?: Maybe<Scalars['Boolean']>;
	/** An indication of whether the move-in date can be updated. */
	canUpdateMoveInDate?: Maybe<Scalars['Boolean']>;
	/** An indication of whether the move-out date can be updated. */
	canUpdateMoveOutDate?: Maybe<Scalars['Boolean']>;
	/** The earliest date in which an amperage change can be performed. */
	earliestAmperageChangeDate: Scalars['Date'];
	/** Fetch Half Hourly Readings for this SPIN from and to a given datetime of any version. Restricted by the Agreement Periods. */
	halfHourlyReadings: Array<ElectricityHalfHourReading>;
	id?: Maybe<Scalars['ID']>;
	/** Return the monthly confirmed readings associated with a given Supply Point. Restricted by the Agreement Periods. */
	intervalReadings: Array<ElectricityIntervalReading>;
	/** The supply point capacity in kVA. */
	kva?: Maybe<Scalars['Int']>;
	/** The supply point capacity in kW. */
	kw?: Maybe<Scalars['Decimal']>;
	latestCosGainProcess?: Maybe<CosGainProcess>;
	/** The latest switch-out process active on this account. */
	latestCosLossProcess?: Maybe<CosLossProcess>;
	latestMoveInProcess?: Maybe<MoveInProcess>;
	/** The latest move-out process active on this account. */
	latestMoveOutProcess?: Maybe<MoveOutProcess>;
	/** A list of the possible amperage values to select. */
	newAmperageOptions: Array<Maybe<Scalars['Int']>>;
	/** Date of when the reading after the next reading will be collected by the grid operator. */
	nextNextReadingDate?: Maybe<Scalars['Date']>;
	/** Date of when the next reading will be collected by the grid operator. */
	nextReadingDate?: Maybe<Scalars['Date']>;
	/** The day of the month on which the reading is collected. */
	readingDateDayOfMonth?: Maybe<Scalars['Int']>;
	/** References for the electricity supply point. */
	references?: Maybe<Array<Maybe<ElectricitySupplyPointReference>>>;
	/** The supply point identification number. */
	spin: Scalars['String'];
	/** The meter point status. */
	status: SupplyPointStatus;
	/** Historical supply details list for this account, ordered by the valid_from date in descending order. */
	supplyDetails?: Maybe<Array<Maybe<SupplyDetails>>>;
	/** The supply periods for the supply point. */
	supplyPeriods?: Maybe<Array<Maybe<SupplyPeriodType>>>;
};

export type ElectricitySupplyPointAgreementsArgs = {
	onlyActive?: InputMaybe<Scalars['Boolean']>;
};

export type ElectricitySupplyPointHalfHourlyReadingsArgs = {
	fromDatetime?: InputMaybe<Scalars['DateTime']>;
	productCode?: InputMaybe<Scalars['String']>;
	toDatetime?: InputMaybe<Scalars['DateTime']>;
};

export type ElectricitySupplyPointIntervalReadingsArgs = {
	productCode?: InputMaybe<Scalars['String']>;
	sortOrder?: InputMaybe<IntervalReadingsSortOrder>;
};

export type ElectricitySupplyPointReference = {
	__typename?: 'ElectricitySupplyPointReference';
	/** Key to identify the reference. */
	namespace?: Maybe<Scalars['String']>;
	/** Value for this namespace. */
	value?: Maybe<Scalars['String']>;
};

/** Input required to initiate a LeaveSupplier journey for the electricity market. */
export type ElectricityTerminationInput = {
	/** The requested date of demolition. */
	demolitionDate?: InputMaybe<Scalars['Date']>;
	/** Time slot requested by the customer for demolition. */
	demolitionTime?: InputMaybe<TimeOptions>;
	/** Whether demolition required. */
	isDemolition: Scalars['Boolean'];
	/** Whether on-site attendance is required. */
	onSiteAttendance: Scalars['Boolean'];
	/** The requested date of on-site attendance. */
	requestedOnSiteDate?: InputMaybe<Scalars['Date']>;
	/** Time slot requested by the customer for on-site attendance. */
	requestedOnSiteTimeSlot?: InputMaybe<TimeOptions>;
	/** Date when electricity will be disconnected. */
	requestedSupplyEndDate: Scalars['Date'];
	/** The market supply point identification number. */
	supplyPointIdentifier: Scalars['String'];
};

/** Output data related to a LeaveSupplier journey for the electricity market. */
export type ElectricityTerminationOutput = {
	__typename?: 'ElectricityTerminationOutput';
	/** The requested date of demolition. */
	demolitionDate?: Maybe<Scalars['Date']>;
	/** Time slot requested by the customer for demolition. */
	demolitionTime?: Maybe<TimeOptions>;
	/** Whether demolition required. */
	isDemolition: Scalars['Boolean'];
	/** Whether on-site attendance is required. */
	onSiteAttendance: Scalars['Boolean'];
	/** The requested date of on-site attendance. */
	requestedOnSiteDate?: Maybe<Scalars['Date']>;
	/** Time slot requested by the customer for on-site attendance. */
	requestedOnSiteTimeSlot?: Maybe<TimeOptions>;
	/** Date when supply point is disconnected. */
	supplyEndDate: Scalars['Date'];
	/** The market supply point identification number. */
	supplyPointIdentifier: Scalars['String'];
};

/** Represents a file to attach to a email message. */
export type EmailAttachmentType = {
	__typename?: 'EmailAttachmentType';
	filename: Scalars['String'];
	id: Scalars['ID'];
	/** Temporary URL at which the attachment is available. This URL will expire after approximately an hour. It is intended for redirection purposes, NOT persistence in any form (e.g. inclusion in emails or the body of a web page). */
	temporaryUrl?: Maybe<Scalars['String']>;
};

export type EmailEventType = Node & {
	__typename?: 'EmailEventType';
	eventType: Scalars['String'];
	/** The ID of the object */
	id: Scalars['ID'];
	/** Email message of the email event. Returns null for message's sent/received by other user's on the account. */
	message?: Maybe<EmailType>;
	occurredAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum EmailFormats {
	Html = 'HTML',
	Text = 'TEXT',
}

/**
 * The possible errors that can be raised are:
 *
 * - KT-JP-4609: Terms and conditions not found.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type EmailQuote = {
	__typename?: 'EmailQuote';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	sent?: Maybe<Scalars['Boolean']>;
};

export type EmailQuoteInput = {
	/** The number of seconds to wait before sending the email. */
	delayInSeconds?: InputMaybe<Scalars['Int']>;
	/** Email address. */
	email: Scalars['String'];
	/** Quote code. */
	quoteCode: Scalars['String'];
};

/** Represents an email communication */
export type EmailType = {
	__typename?: 'EmailType';
	/** Attachments of the email message. */
	attachments?: Maybe<Array<Maybe<EmailAttachmentType>>>;
	channel: MessageChannel;
	createdAt: Scalars['DateTime'];
	fromEmail: Scalars['String'];
	fromNumber: Scalars['String'];
	htmlBody?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	/** Email recipient. */
	recipient?: Maybe<Scalars['String']>;
	/** Email sender. */
	sender?: Maybe<Scalars['String']>;
	sentAt?: Maybe<Scalars['DateTime']>;
	/** Subject line of the email message. */
	subject?: Maybe<Scalars['String']>;
	templateCode: Scalars['String'];
	textBody?: Maybe<Scalars['String']>;
	toAddress: Scalars['String'];
	toNumber: Scalars['String'];
};

export type EmbeddedElectricityFiltersOutput = {
	__typename?: 'EmbeddedElectricityFiltersOutput';
	deviceId?: Maybe<Scalars['String']>;
	marketSupplyPointId?: Maybe<Scalars['String']>;
	/** Reading direction is based on the utility generated or consumed by the customer. */
	readingDirection?: Maybe<ReadingDirectionType>;
	readingFrequencyType?: Maybe<ReadingFrequencyType>;
	readingQuality?: Maybe<ReadingQualityType>;
	registerId?: Maybe<Scalars['String']>;
};

/** Represents an embedded network that holds multiple embedded properties. */
export type EmbeddedNetworkType = {
	__typename?: 'EmbeddedNetworkType';
	/** Get details about properties in an embedded network. */
	embeddedProperties?: Maybe<Array<Maybe<EmbeddedPropertyType>>>;
	id: Scalars['ID'];
	/** A unique name/code for the network */
	name: Scalars['String'];
};

/** Represents an embedded property in an embedded network. */
export type EmbeddedPropertyType = {
	__typename?: 'EmbeddedPropertyType';
	embeddedNetwork: EmbeddedNetworkType;
	id: Scalars['ID'];
	/** The id of the physical property related to this embedded property type. */
	propertyId?: Maybe<Scalars['ID']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-9603: Unable to find contribution agreement.
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-9604: Unable to end contribution agreement.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type EndContributionAgreement = {
	__typename?: 'EndContributionAgreement';
	/** The created contribution agreement. */
	contributionAgreement?: Maybe<ContributionAgreementType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type EndContributionAgreementInput = {
	/** The ID of the Contribution Agreement to end. */
	contributionAgreementId: Scalars['ID'];
	/** The future end datetime of the agreement. If not given, terminate now. */
	endAt?: InputMaybe<Scalars['DateTime']>;
};

/** Energy mix data can include the latest carbon intensity index in a region. */
export type EnergyMixDataType = {
	__typename?: 'EnergyMixDataType';
	/** Current carbon intensity index. */
	carbonIntensityIndex?: Maybe<Scalars['String']>;
};

/** Enrollment was successfully initiated. */
export type EnrollmentInitiated = {
	__typename?: 'EnrollmentInitiated';
	/** The Enrollment processes that were initiated. */
	enrollmentProcesses: Array<Maybe<EnrollmentProcess>>;
	/** The message to display to the user on Enrollment initiation. */
	message: Scalars['String'];
};

export type EnrollmentInput = {
	/** The Kraken account number. */
	accountNumber: Scalars['String'];
	/** Bank details for setting up a payment instruction as part of the enrollment. */
	bankDetails?: InputMaybe<BankDetailsInput>;
	/** Details of an externally created payment instruction to be recorded in Kraken. */
	instructionDetails?: InputMaybe<InstructionDetailsInput>;
	/** Details of the payment schedule(s) to be created as part of enrollment. */
	paymentSchedule?: InputMaybe<PaymentScheduleDetailsInput>;
	/** Sales info for this enrollment. */
	salesInfo: SalesInformationInput;
};

export type EnrollmentProcess =
	| JoinSupplierProcessType
	| OccupyPropertyProcessType;

export type ErrorType = {
	__typename?: 'ErrorType';
	field: Scalars['String'];
	messages: Array<Scalars['String']>;
};

export type ErrorTypeUnion = SerializerErrorType | SerializerFieldErrorsType;

/**
 * Estimate average monthly electricity usage in kwh
 * based on EstimateUsageInputType fields.
 */
export type EstimateUsage = {
	__typename?: 'EstimateUsage';
	usageEstimate?: Maybe<Scalars['Int']>;
};

export type EstimateUsageInputType = {
	/** The type of building where the customer lives. */
	buildingType: BuildingType;
	/** The month for which to perform the usage estimation. */
	month: Scalars['Int'];
	/** The number of people living together with customer in the building. */
	numberOfPeople: Scalars['Int'];
};

export type EstimatedMoneyType = {
	__typename?: 'EstimatedMoneyType';
	/** Monetary currency of the statistic in ISO-4217 format. */
	costCurrency: Scalars['String'];
	/**
	 *
	 *             Monetary cost of the statistic. This is the smallest unit of currency,
	 *             e.g. cents for USD or yen for JPY. Because electricity is priced as a commodity, we must
	 *             account for fractional cents and this field must be a Decimal. Values from this field should
	 *             likely not be used for accounting purposes.
	 *
	 */
	estimatedAmount: Scalars['Decimal'];
	/** Net price per unit of the statistic if applicable. */
	pricePerUnit?: Maybe<PricePerUnit>;
};

/** An enumeration. */
export enum ExpiringTokenScope {
	/** Scope that enables account user to accept the terms and conditions for a product. */
	AcceptTermsAndConditions = 'ACCEPT_TERMS_AND_CONDITIONS',
	/** Scope that enables account user to book smart meter appointments. */
	BookSmartMeterAppointments = 'BOOK_SMART_METER_APPOINTMENTS',
	/** Scope that enables account user to checkout a quote (validate terms & conds and provide a payment detail). */
	CheckoutQuote = 'CHECKOUT_QUOTE',
	/** Edit Customer Marketing Preference */
	EditCustomerMarketingPreference = 'EDIT_CUSTOMER_MARKETING_PREFERENCE',
	/** Scope that enables account user to join campaigns. */
	JoinCampaigns = 'JOIN_CAMPAIGNS',
	/** Scope that enables account user to join campaign events. */
	JoinCampaignEvents = 'JOIN_CAMPAIGN_EVENTS',
	/** Scope that enables account user to generate a renewal quote and renew agreements. */
	ManageAccountRenewals = 'MANAGE_ACCOUNT_RENEWALS',
	/** Scope that enables account user to manage security deposit payments for business accounts. */
	ManageBusinessSecurityDeposit = 'MANAGE_BUSINESS_SECURITY_DEPOSIT',
	/** Scope that enables account user to accept goods quotes and process goods purchases. */
	ManageGoodsPurchases = 'MANAGE_GOODS_PURCHASES',
	/** Scope that enables account user to do a self-serve product switch through the Dashboard. */
	ManageProductSwitch = 'MANAGE_PRODUCT_SWITCH',
	/** Scope that enables account user to redeem loyalty points */
	RedeemLoyaltyPoints = 'REDEEM_LOYALTY_POINTS',
	/** Scope that enables account user to report a property move-out. */
	ReportMoveOut = 'REPORT_MOVE_OUT',
	/** Scope that enables user to send a loss objection for a change of supplier process. */
	SendLossObjectionForChangeOfSupplier = 'SEND_LOSS_OBJECTION_FOR_CHANGE_OF_SUPPLIER',
	/** Scope that enables account user to submit customer feedback. */
	SubmitCustomerFeedback = 'SUBMIT_CUSTOMER_FEEDBACK',
	/** Scope that enables account user to submit meter readings. */
	SubmitMeterReadings = 'SUBMIT_METER_READINGS',
	/** Scope that enables account user to update their blackhole email address. */
	UpdateBlackholeEmail = 'UPDATE_BLACKHOLE_EMAIL',
	/** Update Sensitive Customer Information */
	UpdateSensitiveCustomerInformation = 'UPDATE_SENSITIVE_CUSTOMER_INFORMATION',
	/** Scope that enables account user to visit campaign dashboard. */
	ViewCampaignDashboards = 'VIEW_CAMPAIGN_DASHBOARDS',
	/** Scope that enables account user to visit detailed property usage pages. */
	ViewDetailedUsage = 'VIEW_DETAILED_USAGE',
}

export type ExportCosGainProcess = {
	__typename?: 'ExportCosGainProcess';
	/** Available self-service actions. */
	availableActions?: Maybe<Array<Maybe<SelfServiceAction>>>;
	/** The name of the customer. */
	customerName?: Maybe<Scalars['String']>;
	/** The name of the customer, in kana. */
	customerNameKana?: Maybe<Scalars['String']>;
	/** The forecasted export start date provided by OCCTO. Subject to change. */
	forecastedExportStartDate?: Maybe<Scalars['Date']>;
	/** The ID of the Export Switch-In Request. */
	requestId?: Maybe<Scalars['String']>;
};

/** Monthly Interval Reading - which may have associated 30min Readings */
export type ExportIntervalReadings = {
	__typename?: 'ExportIntervalReadings';
	/** The month with which the monthly interval reading is associated. */
	billableMonth: Scalars['String'];
	/** Estimated cost for amount of Electricity generated. */
	costEstimate: Scalars['Decimal'];
	/** End of Interval Period. */
	endAt: Scalars['DateTime'];
	/** Fetch Half Hourly Readings associated with this interval reading. Will return an empty list if has_half_hourly_data_for_period is False. */
	halfHourlyReadings: Array<ElectricityHalfHourReading>;
	/** Tracks whether there are Half Hourly Readings associated with this Interval. */
	hasHalfHourlyDataForPeriod: Scalars['Boolean'];
	/** The interval period length, in days. */
	periodLengthDays: Scalars['Int'];
	/** Date when the reading was taken. */
	readingDate?: Maybe<Scalars['Date']>;
	/** Start of Interval Period. */
	startAt: Scalars['DateTime'];
	/** Generated amount of Electricity during period, in kWh. */
	value: Scalars['Decimal'];
};

export type ExternalAccountEvent = {
	__typename?: 'ExternalAccountEvent';
	category: Scalars['String'];
	content?: Maybe<Scalars['JSONString']>;
	description?: Maybe<Scalars['String']>;
	eventType: Scalars['String'];
	id: Scalars['ID'];
	occurredAt: Scalars['DateTime'];
	subcategory: Scalars['String'];
};

/** Enum of allowable event type categories for external account events. */
export enum ExternalAccountEventCategory {
	Communications = 'COMMUNICATIONS',
	Debt = 'DEBT',
	Messaging = 'MESSAGING',
	Mobile = 'MOBILE',
	Web = 'WEB',
}

/** A piece of content associated with an external account event. */
export type ExternalAccountEventContent = {
	/** The content type of the content. */
	contentType: ExternalAccountEventContentType;
	/** A human-readable description of the content. */
	description: Scalars['String'];
	/** The value of the content. */
	value: Scalars['String'];
};

/**
 * Enum of allowable content types for external account events.
 *
 * The content type field is used to determine how to display the content in the account event
 * description.
 */
export enum ExternalAccountEventContentType {
	Html = 'HTML',
	Link = 'LINK',
	Plaintext = 'PLAINTEXT',
	S3 = 'S3',
}

/** Enum of allowable event type subcategories for external account events. */
export enum ExternalAccountEventSubCategory {
	ClickToCall = 'CLICK_TO_CALL',
	Dunning = 'DUNNING',
	Email = 'EMAIL',
	Feedback = 'FEEDBACK',
	Print = 'PRINT',
	PushNotification = 'PUSH_NOTIFICATION',
	Sms = 'SMS',
	SocialMedia = 'SOCIAL_MEDIA',
	Telephone = 'TELEPHONE',
	ThirdParty = 'THIRD_PARTY',
	Whatsapp = 'WHATSAPP',
}

/**
 * The possible errors that can be raised are:
 *
 * - KT-JP-4602: Quoted electricity supply points cannot be found.
 * - KT-JP-4606: GPIN does not exist on OCCTO.
 * - KT-JP-4603: OCCTO could not process the request.
 * - KT-JP-4607: An unexpected error occurred when trying to fetch GPIN details.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type FetchElectricityGenerationPointDetails = {
	__typename?: 'FetchElectricityGenerationPointDetails';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** The updated quote request. */
	quoteRequest?: Maybe<Quote>;
	/** The quoted electricity generation point. */
	quotedSupplyPoint?: Maybe<QuotedElectricityGenerationPoint>;
};

export type FetchElectricityGenerationPointDetailsInput = {
	/** The generation point identification number. */
	gpin: Scalars['String'];
	/** The quote code for the QuotedSupplyPoint. */
	quoteRequestCode: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-JP-4602: Quoted electricity supply points cannot be found.
 * - KT-JP-4604: SPIN does not exist on OCCTO.
 * - KT-JP-4603: OCCTO could not process the request.
 * - KT-JP-4605: An unexpected error occurred when trying to fetch SPIN details.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type FetchElectricitySupplyPointDetails = {
	__typename?: 'FetchElectricitySupplyPointDetails';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	quoteRequest?: Maybe<Quote>;
	/** The quoted electricity supply point. */
	quotedSupplyPoint?: Maybe<QuotedElectricitySupplyPoint>;
};

export type FetchElectricitySupplyPointDetailsInput = {
	/** The quote code for the QuotedSupplyPoint. */
	quoteRequestCode: Scalars['String'];
	/** The supply point identification number. */
	spin: Scalars['String'];
};

export type FileAttachment = {
	fetchUrl?: Maybe<Scalars['String']>;
	/** Is the file ready for use / downloadable? */
	isReady?: Maybe<Scalars['Boolean']>;
	/** Is the file uploaded to S3? */
	isUploaded?: Maybe<Scalars['Boolean']>;
	sizeInBytes?: Maybe<Scalars['Int']>;
};

export type FixedPaymentAmountSuggested = {
	__typename?: 'FixedPaymentAmountSuggested';
	/** The suggested fixed payment amount. */
	amount: Scalars['Int'];
};

export type ForceReauthentication = {
	__typename?: 'ForceReauthentication';
	/** The time at which forced reauthentication is effective.  Kraken and refresh tokens issued before this time will be invalid. */
	effectiveAt: Scalars['DateTime'];
	/** Reports whether the mutation applied successfully.  Should always be 'true'. */
	tokensInvalidated: Scalars['Boolean'];
};

/** The input type for repudiating previously issued Kraken Tokens and refresh tokens. */
export type ForceReauthenticationInput = {
	/** Also force third-party applications you have authorized to use your account to reauthenticate. */
	includeThirdParties: Scalars['Boolean'];
};

export type FormSubmissionInput = {
	accountNumber: Scalars['String'];
	/** Form content. */
	content: Scalars['JSONString'];
	/** Form type. */
	formType?: InputMaybe<FormType>;
};

export type FormSubmissionOuput = {
	__typename?: 'FormSubmissionOuput';
	content?: Maybe<Scalars['JSONString']>;
	errors?: Maybe<Array<Maybe<SerializerFieldErrorsType>>>;
	id?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum FormType {
	/** Covid-19 Financial Energy Assessment */
	Covid_19FinancialEnergyAssessment = 'COVID_19_FINANCIAL_ENERGY_ASSESSMENT',
	/** Covid-19 Gas Prices Financial Energy Assessment */
	Covid_19GasPricesFinancialEnergyAssessment = 'COVID_19_GAS_PRICES_FINANCIAL_ENERGY_ASSESSMENT',
}

/** A fractional measurement. */
export type FractionSizeType = IdentifiableInterface & {
	__typename?: 'FractionSizeType';
	/** The fractional value. */
	fraction: Scalars['Decimal'];
	/** Unique identifier of the object. */
	id?: Maybe<Scalars['ID']>;
	/** The name of the object's type. */
	typename?: Maybe<Scalars['String']>;
};

export type FraudMeterPointCheckType = {
	__typename?: 'FraudMeterPointCheckType';
	/** Returns True if the meter point ID is in the list of suspicious meter point IDs. */
	isInSuspiciousList?: Maybe<Scalars['Boolean']>;
	/** The meter point ID. */
	meterPointId?: Maybe<Scalars['String']>;
};

/** Fuel cost adjustment product rate. */
export type FuelCostAdjustmentRate = {
	__typename?: 'FuelCostAdjustmentRate';
	/** The product rate band information. */
	band?: Maybe<Scalars['String']>;
	/** Duration in months for discount product rates. */
	durationMonths?: Maybe<Scalars['Int']>;
	/** Grid operator. */
	gridOperatorCode?: Maybe<Scalars['String']>;
	/** Price per kWh without tax. */
	pricePerUnit: Scalars['Decimal'];
	/** Price per kWh including tax. */
	pricePerUnitIncTax: Scalars['Decimal'];
	/** Region of operation. */
	regionOfOperation?: Maybe<Scalars['String']>;
	/** What are we charging for (kWhs consumed, Days on supply, etc). */
	unitType?: Maybe<UnitType>;
	/** Fuel cost adjustment rate valid from datetime. */
	validFrom: Scalars['DateTime'];
	/** Fuel cost adjustment rate valid to datetime. */
	validTo: Scalars['DateTime'];
};

/**
 *
 * Filter measurements by gas parameters.
 *
 */
export type GasFiltersOutput = {
	__typename?: 'GasFiltersOutput';
	deviceId?: Maybe<Scalars['String']>;
	marketSupplyPointId?: Maybe<Scalars['String']>;
	readingFrequencyType?: Maybe<ReadingFrequencyType>;
	registerId?: Maybe<Scalars['String']>;
};

/** Gas Interval Reading */
export type GasIntervalReading = {
	__typename?: 'GasIntervalReading';
	/** Number of days of gas usage. */
	daysOfGasUsage: Scalars['Int'];
	/** End of interval period. */
	endAt: Scalars['DateTime'];
	/** Kraken gas reading id. */
	id?: Maybe<Scalars['ID']>;
	/** Date reading was taken. */
	readingDate: Scalars['Date'];
	/** Reason for reading. */
	readingReason: Scalars['String'];
	/** Start of interval period. */
	startAt: Scalars['DateTime'];
	/** Gas usage in cubic meters. */
	usage: Scalars['Int'];
};

export type GasProductInterface = {
	/** Product code. */
	code: Scalars['String'];
	/** List of consumption product rates. */
	consumptionCharges: Array<Maybe<TieredConsumptionRate>>;
	/** Human friendly name to be displayed in interfaces. */
	displayName: Scalars['String'];
	/** A JSON object containing extra information. */
	params?: Maybe<Scalars['GenericScalar']>;
	/** Unit type for standing charge. */
	standingChargeUnitType?: Maybe<Scalars['String']>;
	/** List of standing charge product rates. */
	standingCharges: Array<Maybe<TieredStandingCharge>>;
};

export type GasSupplyPoint = {
	__typename?: 'GasSupplyPoint';
	/** The latest active disconnection process for the supply point. */
	activeDisconnectionProcess?: Maybe<DisconnectionProcess>;
	/** The Agreements linked to this gas supply point. */
	agreements: Array<Maybe<Agreement>>;
	/** The meter readings for the supply point. */
	intervalReadings?: Maybe<Array<Maybe<GasIntervalReading>>>;
	/** The meter capacity of the supply point. */
	meterCapacity?: Maybe<Scalars['String']>;
	/** Date of when the reading after the next reading will be collected by the grid operator. */
	nextNextReadingDate?: Maybe<Scalars['Date']>;
	/** Date of when the next reading will be collected by the grid operator. */
	nextReadingDate?: Maybe<Scalars['Date']>;
	physicalMeterNumber: Scalars['String'];
	/** Date of when the previous reading was collected by the grid operator. */
	previousReadingDate?: Maybe<Scalars['Date']>;
	readingBatch: Scalars['String'];
	readingDateType: Scalars['String'];
	readingMethodType: Scalars['String'];
	/** The supply point identification number. */
	spin: Scalars['String'];
	/** The meter point status. */
	status: GasSupplyPointStatus;
	/** The supply periods for the supply point. */
	supplyPeriods?: Maybe<Array<Maybe<SupplyPeriodType>>>;
};

export type GasSupplyPointAgreementsArgs = {
	onlyActive?: InputMaybe<Scalars['Boolean']>;
};

export type GasSupplyPointIntervalReadingsArgs = {
	fromDatetime?: InputMaybe<Scalars['DateTime']>;
	sortOrder?: InputMaybe<IntervalReadingsSortOrder>;
	toDatetime?: InputMaybe<Scalars['DateTime']>;
};

/** An enumeration. */
export enum GasSupplyPointStatus {
	DisconnectionPending = 'DISCONNECTION_PENDING',
	Lost = 'LOST',
	OffSupply = 'OFF_SUPPLY',
	OnSupply = 'ON_SUPPLY',
	SwitchOutPending = 'SWITCH_OUT_PENDING',
}

/** Input required to initiate a LeaveSupplier journey for the gas market. */
export type GasTerminationInput = {
	/** Attendant name. */
	attendantName?: InputMaybe<Scalars['String']>;
	/** Attendant phone number. */
	attendantPhoneNumber?: InputMaybe<Scalars['String']>;
	/** Attendant phone number type. */
	attendantPhoneNumberType?: InputMaybe<PhoneNumberType>;
	/** The relationship of the on-site attendant to the Account holder. */
	attendantType?: InputMaybe<AttendantType>;
	/** Memo. */
	memo?: InputMaybe<Scalars['String']>;
	/** Whether on-site attendance is required. */
	onSiteAttendance: Scalars['Boolean'];
	/** Whether customer phone contact is required for on-site attendance. */
	phoneContactRequired: Scalars['Boolean'];
	/** Hour requested by the customer for on-site attendance. */
	requestedOnSiteHour?: InputMaybe<Hour>;
	/** Time slot requested by the customer for on-site attendance. */
	requestedOnSiteTimeSlot?: InputMaybe<SupplyEndScheduledTime>;
	/** Date when Gas will be disconnected. */
	requestedSupplyEndDate: Scalars['Date'];
	/** The market supply point identification number. */
	supplyPointIdentifier: Scalars['String'];
};

/** Output data related to a LeaveSupplier journey for the gas market. */
export type GasTerminationOutput = {
	__typename?: 'GasTerminationOutput';
	/** Attendant name. */
	attendantName?: Maybe<Scalars['String']>;
	/** Attendant phone number. */
	attendantPhoneNumber?: Maybe<Scalars['String']>;
	/** Attendant phone number type. */
	attendantPhoneNumberType?: Maybe<PhoneNumberType>;
	/** The relationship of the on-site attendant to the Account holder. */
	attendantType?: Maybe<AttendantType>;
	/** Memo. */
	memo?: Maybe<Scalars['String']>;
	/** Whether on-site attendance is required. */
	onSiteAttendance: Scalars['Boolean'];
	/** Whether customer phone contact is required for on-site attendance. */
	phoneContactRequired: Scalars['Boolean'];
	/** Hour requested by the customer for on-site attendance. */
	requestedOnSiteHour?: Maybe<Hour>;
	/** Time slot requested by the customer for on-site attendance. */
	requestedOnSiteTimeSlot?: Maybe<SupplyEndScheduledTime>;
	/** Date when supply point is disconnected. */
	supplyEndDate: Scalars['Date'];
	/** The market supply point identification number. */
	supplyPointIdentifier: Scalars['String'];
};

/** Product for the gas market. */
export type GasTieredProduct = GasProductInterface & {
	__typename?: 'GasTieredProduct';
	/** Product code. */
	code: Scalars['String'];
	/** List of consumption product rates. */
	consumptionCharges: Array<Maybe<TieredConsumptionRate>>;
	/** Human friendly name to be displayed in interfaces. */
	displayName: Scalars['String'];
	/** A JSON object containing extra information. */
	params?: Maybe<Scalars['GenericScalar']>;
	/** Unit type for standing charge. */
	standingChargeUnitType?: Maybe<Scalars['String']>;
	/** List of standing charge product rates. */
	standingCharges: Array<Maybe<TieredStandingCharge>>;
};

/**
 * Generate a credit card token in GMO, which can be used for storing credit card details
 * against it, using GMO's JavaScript SDK.
 */
export type GenerateCreditCardToken = {
	__typename?: 'GenerateCreditCardToken';
	/** GMO credit card token. */
	creditCardToken?: Maybe<Scalars['String']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7620: Channel not supported.
 * - KT-CT-7618: Unable to process message.
 * - KT-CT-7624: Error when generating the presigned URL.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type GenerateInkPresignedUrl = {
	__typename?: 'GenerateInkPresignedUrl';
	/** Presigned post fields required to upload the file. */
	fields: Scalars['JSONString'];
	/** The key for the item. */
	key: Scalars['String'];
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/**
	 * Presigned post fields; key, awsAccessKeyId, xAmzSecurityToken, policy, signature.
	 * @deprecated Replaced in favor of fields.
	 */
	presignedPostFields?: Maybe<PresignedPostFields>;
	/** A presigned URL for the user to upload to the quarantine bucket. */
	uploadUrl: Scalars['String'];
};

export type GenerateInkPresignedUrlInput = {
	/** The channel of the contact. */
	channel: InkCommunicationChannel;
	/** The name of the file. */
	filename: Scalars['String'];
};

/**
 * Mutation to generate a pre-signed token.
 *
 * The pre-signed, expiring and opaque tokens will be swapped
 * for a limited scope JWT (Kraken Token).
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1128: Unauthorized.
 * - KT-CT-1120: The Kraken Token has expired.
 * - KT-CT-1131: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type GeneratePreSignedToken = {
	__typename?: 'GeneratePreSignedToken';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	scope?: Maybe<PreSignedTokenScope>;
	token?: Maybe<Scalars['String']>;
	tokenExpiryDatetime?: Maybe<Scalars['DateTime']>;
};

export type GenerationAgreement = {
	__typename?: 'GenerationAgreement';
	/** The product for the generation agreement. */
	product?: Maybe<Product>;
	/** Agreement valid-from datetime. */
	validFrom?: Maybe<Scalars['DateTime']>;
	/** Agreement valid-to datetime. */
	validTo?: Maybe<Scalars['DateTime']>;
};

export type GenerationCreditRate = {
	__typename?: 'GenerationCreditRate';
	/** The product rate band information. */
	band?: Maybe<Scalars['String']>;
	/** Duration in months for discount product rates. */
	durationMonths?: Maybe<Scalars['Int']>;
	/** Grid operator. */
	gridOperatorCode?: Maybe<Scalars['String']>;
	/** Price per kWh without tax. */
	pricePerUnit: Scalars['Decimal'];
	/** Price per kWh including tax. */
	pricePerUnitIncTax: Scalars['Decimal'];
	/** Region of operation. */
	regionOfOperation?: Maybe<Scalars['String']>;
	/** What are we charging for (kWhs consumed, Days on supply, etc). */
	unitType?: Maybe<UnitType>;
	/** Rate validity start. */
	validFrom?: Maybe<Scalars['DateTime']>;
	/** Rate validity end. */
	validTo?: Maybe<Scalars['DateTime']>;
};

export type GenerationPointInput = {
	/** Current supplier code. */
	currentSupplierCode?: InputMaybe<Scalars['String']>;
	/** Customer data. */
	customer?: InputMaybe<SwitchInCustomerInput>;
	/** Generation point identifier number. */
	generationPointId?: InputMaybe<Scalars['String']>;
	/** Product code. */
	productCode?: InputMaybe<Scalars['String']>;
};

export type GenerationPointParamsInput = {
	/** The generated electricity for the last year. */
	electricityGeneratedLastYear: Scalars['Int'];
	/** Whether has accumulator or not. */
	hasAccumulator: Scalars['Boolean'];
};

/** An enumeration. */
export enum GenerationPointStatus {
	Lost = 'LOST',
	MoveInPending = 'MOVE_IN_PENDING',
	MoveOutPending = 'MOVE_OUT_PENDING',
	NeverUnderContract = 'NEVER_UNDER_CONTRACT',
	SwitchInPending = 'SWITCH_IN_PENDING',
	SwitchOutPending = 'SWITCH_OUT_PENDING',
	UnderContract = 'UNDER_CONTRACT',
}

/** A generic backend screen that can be used to define any type of screen. */
export type GenericBackendScreen = BackendScreenInterface & {
	__typename?: 'GenericBackendScreen';
	/** The name of the screen. */
	name: Scalars['String'];
	/** The refresh / polling frequency in milliseconds. */
	refreshFrequency?: Maybe<Scalars['Int']>;
	/** Serialized JSON representation of the screen. */
	screenData?: Maybe<Scalars['String']>;
};

/**
 * Get the client secret needed to create a new payment instruction using an embedded form.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type GetEmbeddedSecretForNewPaymentInstruction = {
	__typename?: 'GetEmbeddedSecretForNewPaymentInstruction';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	secretKey?: Maybe<Scalars['String']>;
};

/** The input for getting the client secret for an embedded new card payment method form. */
export type GetEmbeddedSecretForNewPaymentInstructionInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The type of the new payment instruction. */
	instructionType: PaymentType;
	/**
	 * **WARNING: Will be mandatory in future versions**
	 *
	 *  The ledger ID.
	 */
	ledgerId?: InputMaybe<Scalars['String']>;
};

/**
 * Get external URL where the user can set up a payment instruction.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1128: Unauthorized.
 * - KT-CT-3822: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type GetHostedUrlForNewPaymentInstruction = {
	__typename?: 'GetHostedUrlForNewPaymentInstruction';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** URL at which payment instruction can be set up. */
	url?: Maybe<Scalars['String']>;
};

/** The input needed for getting the external URL for setting up a payment instruction. */
export type GetHostedUrlForNewPaymentInstructionInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The type of the new payment instruction. */
	instructionType: PaymentType;
	/** The ledger number. */
	ledgerNumber: Scalars['String'];
	/** The URL to redirect the user to after the action was cancelled. */
	returnUrlCancel?: InputMaybe<Scalars['String']>;
	/** The URL to redirect the user to after the action resulted in an error. */
	returnUrlError?: InputMaybe<Scalars['String']>;
	/** The URL to redirect the user to after the action resulted in a failure. */
	returnUrlFailure?: InputMaybe<Scalars['String']>;
	/** The URL to redirect the user to after the action was completed successfuly. */
	returnUrlSuccess?: InputMaybe<Scalars['String']>;
};

export type GoodsGrant = {
	__typename?: 'GoodsGrant';
	/** Currency. */
	currency?: Maybe<Scalars['String']>;
	/** Gross amount. */
	grossAmount?: Maybe<Scalars['Float']>;
	/** Net amount. */
	netAmount?: Maybe<Scalars['Float']>;
	/** Grant type. */
	type?: Maybe<Scalars['String']>;
};

export type GoodsProduct = {
	__typename?: 'GoodsProduct';
	/** Product available from. */
	availableFrom?: Maybe<Scalars['Date']>;
	/** Product available to. */
	availableTo?: Maybe<Scalars['Date']>;
	/** Product code. */
	code?: Maybe<Scalars['String']>;
	/** Currency. */
	currency?: Maybe<Scalars['String']>;
	/** Product customer name. */
	customerName?: Maybe<Scalars['String']>;
	/** Product description. */
	description?: Maybe<Scalars['String']>;
	/** Gross price per unit. */
	grossPricePerUnit?: Maybe<Scalars['Float']>;
	/** Product ID. */
	id?: Maybe<Scalars['Int']>;
	/** Product internal name. */
	internalName?: Maybe<Scalars['String']>;
	/** Market of the product. */
	marketName?: Maybe<Scalars['String']>;
	/** Product notes. */
	notes?: Maybe<Scalars['String']>;
	/** Price per unit. */
	pricePerUnit?: Maybe<Scalars['Float']>;
	/** Type of the product. */
	productType?: Maybe<GoodsProductType>;
};

export type GoodsProductConnectionTypeConnection = {
	__typename?: 'GoodsProductConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<GoodsProductConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `GoodsProductConnectionType` and its cursor. */
export type GoodsProductConnectionTypeEdge = {
	__typename?: 'GoodsProductConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<GoodsProduct>;
};

export type GoodsProductType = {
	__typename?: 'GoodsProductType';
	/** Product type code. */
	code?: Maybe<Scalars['String']>;
	/** Product type name. */
	internalName?: Maybe<Scalars['String']>;
};

export type GoodsPurchase = {
	__typename?: 'GoodsPurchase';
	/** Client parameters of the purchase. */
	clientParams?: Maybe<Scalars['JSONString']>;
	/** Purchase code. */
	code?: Maybe<Scalars['String']>;
	/** Grants that apply in this purchase. */
	goodsGrants?: Maybe<Array<Maybe<GoodsGrant>>>;
	/** Sale items in this purchase. */
	goodsSaleItems?: Maybe<Array<Maybe<GoodsSaleItem>>>;
	/** Ledger ID associated to the purchase. */
	ledgerId?: Maybe<Scalars['String']>;
	/** Market parameters of the purchase. */
	marketParams?: Maybe<Scalars['JSONString']>;
};

export type GoodsQuote = {
	__typename?: 'GoodsQuote';
	/** Code of the quote. */
	code?: Maybe<Scalars['String']>;
	/** Products of this quote. */
	goodsQuotedProducts?: Maybe<Array<Maybe<GoodsQuotedProduct>>>;
	/** Indicates whether or not the quote is expired. */
	hasQuoteExpired?: Maybe<Scalars['Boolean']>;
	/** ID of the quote. */
	id?: Maybe<Scalars['Int']>;
	/** Date and time when the quote was created. */
	quotedAt?: Maybe<Scalars['DateTime']>;
	/** Total net amount of the quote in cents. */
	totalNetAmount?: Maybe<Scalars['Int']>;
};

export type GoodsQuoteShare = {
	__typename?: 'GoodsQuoteShare';
	/** The ID of the quote share. */
	id?: Maybe<Scalars['Int']>;
};

export type GoodsQuotedProduct = {
	__typename?: 'GoodsQuotedProduct';
	/** Currency. */
	currency?: Maybe<Scalars['String']>;
	/** Net amount. */
	netAmount?: Maybe<Scalars['Int']>;
	/** Number of units. */
	numberOfUnits?: Maybe<Scalars['Int']>;
	/** Price per unit. */
	pricePerUnit?: Maybe<Scalars['Int']>;
	/** Product code. */
	product?: Maybe<Scalars['String']>;
};

export type GoodsSaleItem = {
	__typename?: 'GoodsSaleItem';
	/** Currency. */
	currency?: Maybe<Scalars['String']>;
	/** Gross amount. */
	grossAmount?: Maybe<Scalars['Float']>;
	/** Net amount. */
	netAmount?: Maybe<Scalars['Float']>;
	/** Number of units. */
	numberOfUnits?: Maybe<Scalars['Int']>;
	/** Price per unit. */
	pricePerUnit?: Maybe<Scalars['Float']>;
	/** Product code. */
	product?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum HalfHourlyReadingsVersions {
	Daily = 'DAILY',
	Monthly = 'MONTHLY',
	Updated = 'UPDATED',
}

/** An enumeration. */
export enum HardshipAgreementHardshipEntryReason {
	/** Financial counsellor or external agent referral */
	ExternalReference = 'EXTERNAL_REFERENCE',
	/** Retailer referral */
	RetailerReferral = 'RETAILER_REFERRAL',
	/** Customer self-identified as being in hardship */
	SelfIdentified = 'SELF_IDENTIFIED',
}

/** An enumeration. */
export enum HardshipAgreementHardshipType {
	/** Death in the family */
	DeathInFamily = 'DEATH_IN_FAMILY',
	/** Family violence */
	FamilyViolence = 'FAMILY_VIOLENCE',
	/** Household illness */
	HouseholdIllness = 'HOUSEHOLD_ILLNESS',
	/** Other */
	Other = 'OTHER',
	/** Reduced income */
	ReducedIncome = 'REDUCED_INCOME',
	/** Unemployment */
	Unemployment = 'UNEMPLOYMENT',
}

/** Represents a Hardship Agreement for a particular Account. */
export type HardshipAgreementType = {
	__typename?: 'HardshipAgreementType';
	/** These are internal notes detailing the hardship. */
	hardshipDetails: Scalars['String'];
	hardshipEntryReason?: Maybe<HardshipAgreementHardshipEntryReason>;
	hardshipType: HardshipAgreementHardshipType;
	id: Scalars['ID'];
	paymentPlanDetails: Scalars['String'];
	startDate: Scalars['Date'];
};

export type HeldStatus = {
	__typename?: 'HeldStatus';
	/** Whether a statement is currently held. */
	isHeld?: Maybe<Scalars['Boolean']>;
	/** Reason for statement being held. */
	reason?: Maybe<Scalars['String']>;
};

/** Hour requested by the customer for on-site attendance. */
export enum Hour {
	'0000' = '_0000',
	'0100' = '_0100',
	'0200' = '_0200',
	'0300' = '_0300',
	'0400' = '_0400',
	'0500' = '_0500',
	'0600' = '_0600',
	'0700' = '_0700',
	'0800' = '_0800',
	'0900' = '_0900',
	'1000' = '_1000',
	'1100' = '_1100',
	'1200' = '_1200',
	'1300' = '_1300',
	'1400' = '_1400',
	'1500' = '_1500',
	'1600' = '_1600',
	'1700' = '_1700',
	'1800' = '_1800',
	'1900' = '_1900',
	'2000' = '_2000',
	'2100' = '_2100',
	'2200' = '_2200',
	'2300' = '_2300',
}

/** The identity of an object. */
export type IdentifiableInterface = {
	/** Unique identifier of the object. */
	id?: Maybe<Scalars['ID']>;
	/** The name of the object's type. */
	typename?: Maybe<Scalars['String']>;
};

/** A media element containing an image. */
export type ImageType = AccessibilityInterface &
	IdentifiableInterface &
	MediaInterface &
	SizedItemInterface & {
		__typename?: 'ImageType';
		/** Whether the element is hidden from view. */
		accessibilityHidden?: Maybe<Scalars['Boolean']>;
		/** Accessible description of the element. */
		accessibilityLabel?: Maybe<Scalars['String']>;
		/** The horizontal alignment of the media. */
		horizontalAlignment?: Maybe<Alignment>;
		/** Unique identifier of the object. */
		id?: Maybe<Scalars['ID']>;
		/** The resource URL of the media. */
		mediaUrl: Scalars['String'];
		/** The name of the object's type. */
		typename?: Maybe<Scalars['String']>;
		/** The measurement of the element. */
		width?: Maybe<ItemSizeType>;
	};

/** Input required to initiate an Amperage Change from MyPage. */
export type InitiateAmperageChangeInput = {
	/** Account number. */
	accountNumber: Scalars['String'];
	/** A date string in the format YYYYMMDDD indicating when the change should be made. */
	amperageChangeDate: Scalars['Date'];
	/** Supply Point identification number. */
	amperageChangeSpin: Scalars['String'];
	/** The new amperage value requested. */
	requestedAmperageValue: Scalars['Int'];
};

/** Returned when a customer initiates an Amperage Change from their MyPage. */
export type InitiateAmperageChangeResponse =
	| AmperageChangeDateInvalid
	| AmperageChangeInitiated
	| CommonError;

/**
 * Initiate a standalone payment and return the client secret required to complete it.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1128: Unauthorized.
 * - KT-CT-3822: Unauthorized.
 * - KT-CT-3943: Invalid ledger.
 * - KT-CT-3957: No collection method provided.
 * - KT-CT-3958: Provide either ledger ID or ledger number.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InitiateHostedStandalonePayment = {
	__typename?: 'InitiateHostedStandalonePayment';
	/** The details required to refer to and complete a hosted payment. */
	payment?: Maybe<InitiateHostedStandalonePaymentOutput>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Input fields for initiating a hosted standalone payment.
 *
 * The amount should always be provided in the minor unit of currency (e.g., pence not pounds,
 * cents not dollars, etc.).
 *
 * A standalone payment can be made against a specific ledger (e.g., a debt ledger) by
 * providing the ledger id. Accounts have a default ledger that will be used if not provided.
 */
export type InitiateHostedStandalonePaymentInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The amount to be collected in the minor unit of currency. */
	amount: Scalars['Int'];
	/** The method by which the payment is being collected. */
	collectionMethod: CollectionMethod;
	/** A description of the purpose of the payment. */
	description: Scalars['String'];
	/** The id of the specific ledger against which this payment should be applied. Please provide either ledger number or ID. */
	ledgerId?: InputMaybe<Scalars['ID']>;
	/** The number of the specific ledger against which this payment should be applied. Please provide either ledger number or ID. */
	ledgerNumber?: InputMaybe<Scalars['String']>;
	/** The URL to redirect the user to after the action was cancelled. */
	returnUrlCancel?: InputMaybe<Scalars['String']>;
	/** The URL to redirect the user to after the action resulted in an error. */
	returnUrlError?: InputMaybe<Scalars['String']>;
	/** The URL to redirect the user to if the url is not longer valid. */
	returnUrlExpired?: InputMaybe<Scalars['String']>;
	/** The URL to redirect the user to after the action resulted in a failure. */
	returnUrlFailure?: InputMaybe<Scalars['String']>;
	/** The URL to redirect the user to after the action was completed but the payment is still being processed. */
	returnUrlPending?: InputMaybe<Scalars['String']>;
	/** The URL to redirect the user to after the action was completed successfuly. */
	returnUrlSuccess?: InputMaybe<Scalars['String']>;
};

/** Tokens required to collect and retrieve a standalone payment. */
export type InitiateHostedStandalonePaymentOutput = {
	__typename?: 'InitiateHostedStandalonePaymentOutput';
	/** The retrieval token for this standalone payment. */
	retrievalToken: Scalars['String'];
	/** The url for the customer to complete the payment. */
	url: Scalars['String'];
};

/** Input to initiate a move in process. */
export type InitiateMoveInInput = {
	affiliateLinkSubdomain?: InputMaybe<Scalars['String']>;
	/** Optional ID of an audio recording object that will be linked to the quote's affiliate session. */
	audioRecordingId?: InputMaybe<Scalars['String']>;
	/** Billing information. */
	billing?: InputMaybe<BillingInput>;
	/** Customer information. */
	customer: MoveInCustomerInput;
	/** Target Move In Date. */
	moveInDate: Scalars['Date'];
	/** Payment information. */
	payment?: InputMaybe<PaymentInput>;
	productCode?: InputMaybe<Scalars['String']>;
	/** The address where the customer is moving. */
	property: MoveInPropertyInput;
	quoteCode?: InputMaybe<Scalars['String']>;
	referralCode?: InputMaybe<Scalars['String']>;
	referralSchemeCode?: InputMaybe<Scalars['String']>;
};

export type InitiateMoveInResponse = CommonError | MoveInSuccess;

/** Input required to initiate a MOMI from MyPage. */
export type InitiateMoveOutAndMoveInInput = {
	/** Information required for initiating a move-out. */
	moveOut: InitiateMoveOutInput;
	/** Information required for initiating a synchronous move-in. */
	syncMoveIn?: InputMaybe<SynchronousMoveInInput>;
};

/** Returned when a customer initiates a Move Out and MoveIn from their MyPage. */
export type InitiateMoveOutAndMoveInResponse =
	| CommonError
	| MoveInDateInvalid
	| MoveOutAndMoveInInitiated
	| MoveOutDateInvalid;

/** Input required to initiate a MoveOut from MyPage. */
export type InitiateMoveOutInput = {
	/** Kraken account number. */
	accountNumber: Scalars['String'];
	/** A date string in the format YYYYMMDDD indicating when electricity supply should end. */
	moveOutDate: Scalars['Date'];
	/** Kraken property id. */
	propertyId: Scalars['ID'];
};

/** Returned when a customer initiates a Move Out from their MyPage. */
export type InitiateMoveOutResponse =
	| CommonError
	| MoveOutDateInvalid
	| MoveOutInitiated;

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-4619: Quote with given code not found.
 * - KT-CT-4624: Unable to accept the given product code.
 * - KT-CT-4626: No product selected for the given quote code.
 * - KT-CT-4719: No supply point found for identifier provided.
 * - KT-CT-4922: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InitiateProductSwitch = {
	__typename?: 'InitiateProductSwitch';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** The selected product for a specific product switch. */
	productCode: Scalars['String'];
	/** The date at which the product switch becomes effective. */
	switchDate: Scalars['Date'];
};

export type InitiateProductSwitchInput = {
	/** Identification of the account that requesting the product switch. */
	accountNumber: Scalars['String'];
	/** ID of the selected quoted product, obtain from quoting that supply point. */
	quotedProductId: Scalars['ID'];
	/** The date at which the product switch becomes effective. */
	switchDate: Scalars['Date'];
	/** The user for whom to perform the update. This is only needed when using an Organisation role. */
	userId?: InputMaybe<Scalars['ID']>;
};

/**
 * Initiate a standalone payment and return the client secret required to complete it.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-3943: Invalid ledger.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InitiateStandalonePayment = {
	__typename?: 'InitiateStandalonePayment';
	payment?: Maybe<InitiateStandalonePaymentOutput>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Input fields for initiating a standalone payment.
 *
 * The amount should always be provided in the minor unit of currency (e.g., pence not pounds,
 * cents not dollars, etc.).
 *
 * A standalone payment can be made against a specific ledger (e.g., a debt ledger) by
 * providing the ledger id. Accounts have a default ledger that will be used if not provided.
 */
export type InitiateStandalonePaymentInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The amount to be collected in the minor unit of currency. */
	amount: Scalars['Int'];
	/** The method by which the payment is being collected. */
	collectionMethod?: InputMaybe<CollectionMethod>;
	/** A description of the purpose of the payment. */
	description: Scalars['String'];
	/** The id of the specific ledger against which this payment should be applied. */
	ledgerId?: InputMaybe<Scalars['ID']>;
};

/** Tokens required to collect and retrieve a standalone payment. */
export type InitiateStandalonePaymentOutput = {
	__typename?: 'InitiateStandalonePaymentOutput';
	/** The retrieval token for this standalone payment. */
	retrievalToken: Scalars['String'];
	/** The secret used to collect the payment. */
	secretToken: Scalars['String'];
};

/** Creates a domestic account and initiates a switch-in for that account. */
export type InitiateSwitchIn = {
	__typename?: 'InitiateSwitchIn';
	/** Account number for the resolved account. */
	accountNumber: Scalars['String'];
	/** Indicates if the user has a usable password and can log in immediately. */
	hasUsablePassword: Scalars['Boolean'];
	/** Token for the resolved user. */
	token?: Maybe<Scalars['String']>;
	/** ID of the resolved user. */
	userId?: Maybe<Scalars['String']>;
};

export type InitiateSwitchInInput = {
	affiliateLinkSubdomain?: InputMaybe<Scalars['String']>;
	/** Optional ID of an audio recording object that will be linked to the quote's affiliate session. */
	audioRecordingId?: InputMaybe<Scalars['String']>;
	/** Billing information. */
	billing?: InputMaybe<BillingInput>;
	/** Customer data. */
	customer?: InputMaybe<SwitchInCustomerInput>;
	/** Generation point data. */
	generationPoint?: InputMaybe<GenerationPointInput>;
	/** Payment information. */
	payment?: InputMaybe<PaymentInput>;
	productCode?: InputMaybe<Scalars['String']>;
	/** Property data. */
	property?: InputMaybe<SwitchInPropertyInput>;
	quoteCode?: InputMaybe<Scalars['String']>;
	referralCode?: InputMaybe<Scalars['String']>;
	referralSchemeCode?: InputMaybe<Scalars['String']>;
};

export type InkBucket = Node & {
	__typename?: 'InkBucket';
	/** The icon code point. */
	icon?: Maybe<Scalars['String']>;
	/** The ID of the object */
	id: Scalars['ID'];
	/** The ink bucket name. */
	name: Scalars['String'];
};

/** An enumeration. */
export enum InkCommunicationChannel {
	Email = 'EMAIL',
	GenericApi = 'GENERIC_API',
	Line = 'LINE',
	Post = 'POST',
	Sms = 'SMS',
}

export type InkContactChannelIdentities = {
	__typename?: 'InkContactChannelIdentities';
	/** All contacts for this conversation. */
	all: Array<InkContactChannelIdentity>;
	/** The default contact for this conversation. */
	default?: Maybe<InkContactChannelIdentity>;
};

export type InkContactChannelIdentity = {
	__typename?: 'InkContactChannelIdentity';
	/** The channel of the contact. */
	channel: InkCommunicationChannel;
	/** The name to display to the user. */
	displayName: Scalars['String'];
	/** The handle. */
	handle: Scalars['String'];
};

export type InkConversation = {
	__typename?: 'InkConversation';
	/** The account users on the conversation. */
	accountUsers?: Maybe<Array<AccountUser>>;
	/** The buckets the conversation is currently in. */
	buckets?: Maybe<Array<InkBucket>>;
	/** The contact channel identities associated with this conversation. */
	contactChannelIdentities: InkContactChannelIdentities;
	/** Conversation events. */
	events: InkConversationEventsConnection;
	id: Scalars['ID'];
	/** The status of the conversation. */
	status: InkConversationStatus;
};

export type InkConversationEventsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type InkConversationEvent = InkNewMessage;

export type InkConversationEventInterface = {
	/** The time the conversation event occurred. */
	occurredAt: Scalars['DateTime'];
};

export type InkConversationEventsConnection = {
	__typename?: 'InkConversationEventsConnection';
	/** Contains the nodes in this connection. */
	edges: Array<InkConversationEventsEdge>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
};

/** A Relay edge containing a `InkConversationEvents` and its cursor. */
export type InkConversationEventsEdge = {
	__typename?: 'InkConversationEventsEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node: InkConversationEvent;
};

export enum InkConversationStatus {
	Closed = 'CLOSED',
	Open = 'OPEN',
	OpenCustomerReplied = 'OPEN_CUSTOMER_REPLIED',
	OpenNew = 'OPEN_NEW',
	OpenReminded = 'OPEN_REMINDED',
	Snoozed = 'SNOOZED',
}

/** This type wraps around the `Message` type for emails. */
export type InkEmail = {
	__typename?: 'InkEmail';
	/** Attachments on the message. */
	attachments: Array<InkMessageAttachment>;
	/** CC recipients on the message. */
	cc: Array<Scalars['String']>;
	/** The contact channel identity. */
	contactChannelIdentity: InkContactChannelIdentity;
	/** The delivery status of the message. */
	delivery: InkMessageDelivery;
	/** The direction of the email. */
	direction: InkMessageDirection;
	/** The content of the message. */
	displayContent: Scalars['String'];
	/** From email address. */
	fromHandle: Scalars['String'];
	/** Is this an message an email. */
	isChannelEmail: Scalars['Boolean'];
	/** The time the message was sent/received. */
	occurredAt: Scalars['DateTime'];
	/** The email subject. */
	subject: Scalars['String'];
	/** All Tags associated with a message. */
	tags: Array<InkTag>;
	/** The addresses that the message was sent to. */
	toHandles?: Maybe<Array<Scalars['String']>>;
};

/** This type is used to create an inbound email. */
export type InkEmailMessageInput = {
	/** Message attachments. */
	attachments?: InputMaybe<Array<InkGenericMessageAttachmentInput>>;
	/** The carbon copy (cc) email addresses the message was sent to. */
	ccAddresses?: InputMaybe<Array<Scalars['Email']>>;
	/** The email address the message was sent from. */
	fromAddress: Scalars['Email'];
	/** The content of the message, as plain text. */
	plainTextContent: Scalars['String'];
	/** The S3 bucket in which the original email is stored. */
	s3Bucket?: InputMaybe<Scalars['String']>;
	/** The S3 key of the original email. */
	s3Key?: InputMaybe<Scalars['String']>;
	/** The email subject/title. */
	subject: Scalars['String'];
	/** The email addresses the message was sent to. */
	toAddresses: Array<Scalars['Email']>;
};

/**
 * This message type is used for messages that belong to contact
 * channels without a more granular message type.
 */
export type InkGenericMessage = Node & {
	__typename?: 'InkGenericMessage';
	/** The contact channel identity. */
	contactChannelIdentity: InkContactChannelIdentity;
	/** The delivery status. */
	delivery: InkMessageDelivery;
	/** The direction of the message. */
	direction: InkMessageDirection;
	/** The content of the message. */
	displayContent: Scalars['String'];
	/** The identity the message was sent from. */
	fromHandle: Scalars['String'];
	/** The ID of the object */
	id: Scalars['ID'];
	/** The time the message was sent/received at. */
	occurredAt: Scalars['DateTime'];
	/** The identity the message was sent to. */
	toHandle: Scalars['String'];
};

export type InkGenericMessageAttachmentInput = {
	/** The S3 bucket of the attachment. */
	s3Bucket: Scalars['String'];
	/** The S3 key of the attachment. */
	s3Key: Scalars['String'];
};

/** This type is used to create an generic message. */
export type InkGenericMessageInput = {
	/** Message attachments. */
	attachments?: InputMaybe<Array<InkGenericMessageAttachmentInput>>;
	/** The identity the message was sent from. */
	fromHandle: Scalars['String'];
	/** The content of the message, as plain text. */
	plainTextContent: Scalars['String'];
	/** The identity the message was sent to. */
	toHandle: Scalars['String'];
};

/** This type wraps around the `Message` type for LINE message. */
export type InkLine = {
	__typename?: 'InkLine';
	/** Attachments on the LINE message. */
	attachments: Array<InkMessageAttachment>;
	/** The contact channel identity. */
	contactChannelIdentity: InkContactChannelIdentity;
	/** The delivery status of the message. */
	delivery: InkMessageDelivery;
	/** The direction of the message. */
	direction: InkMessageDirection;
	/** From LINE id. */
	fromHandle: Scalars['String'];
	/** Is this a LINE message. */
	isChannelLine: Scalars['Boolean'];
	/** The line message content. */
	lineMessage: LineMessage;
	/** The time the message was sent/received. */
	occurredAt: Scalars['DateTime'];
	/** All Tags associated with a message. */
	tags: Array<InkTag>;
	/** To LINE id. */
	toHandle: Scalars['String'];
};

export type InkMessage =
	| InkEmail
	| InkGenericMessage
	| InkLine
	| InkPost
	| InkSms
	| InkWhatsApp;

export type InkMessageAttachment = {
	__typename?: 'InkMessageAttachment';
	/** The url for fetching the attachment. */
	fetchUrl?: Maybe<Scalars['String']>;
	/** The filename. */
	filename: Scalars['String'];
	/** The size in bytes. */
	sizeInBytes?: Maybe<Scalars['Int']>;
};

export type InkMessageDelivery = {
	__typename?: 'InkMessageDelivery';
	/** Message delivery status. */
	status: InkMessageDeliveryStatus;
};

export enum InkMessageDeliveryStatus {
	Delivered = 'DELIVERED',
	Failed = 'FAILED',
	OutsideReplyWindow = 'OUTSIDE_REPLY_WINDOW',
	Pending = 'PENDING',
	Sent = 'SENT',
}

export enum InkMessageDirection {
	Inbound = 'INBOUND',
	Outbound = 'OUTBOUND',
}

/**
 * An Ink message used as an input.
 *
 * This is intended to be morally equivalent to a tagged union; exactly
 * one of the properties provided here is expected to be provided.
 *
 * At current, only the generic message type is provided, because only
 * the generic message type is currently supported as an input type.
 * This is intended to be a backwards-compatible extension point to
 * allow other message input types to be added in the future.
 */
export type InkMessageInput = {
	email?: InputMaybe<InkEmailMessageInput>;
	generic?: InputMaybe<InkGenericMessageInput>;
	post?: InputMaybe<InkPostMessageInput>;
};

/** This types is used for both the message-received and message-sent conversation events. */
export type InkNewMessage = InkConversationEventInterface & {
	__typename?: 'InkNewMessage';
	/** The message. */
	message: InkMessage;
	/** The time the conversation event occurred. */
	occurredAt: Scalars['DateTime'];
};

/** This type wraps around the `Message` type for Post. */
export type InkPost = {
	__typename?: 'InkPost';
	/** The contact channel identity. */
	contactChannelIdentity: InkContactChannelIdentity;
	/** The notes left when a message was uploaded. */
	displayContent: Scalars['String'];
	/** The from property id. */
	fromHandle: Scalars['String'];
	/** Determine if the message is a post message. */
	isChannelPost: Scalars['Boolean'];
	/** The content of the message. */
	rawPlainTextContent: Scalars['String'];
	/** The to property id. */
	toHandle: Scalars['String'];
};

/** This type is used to create an inbound post. */
export type InkPostMessageInput = {
	/** The account number that the letter was sent from. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** Message attachments. */
	attachments?: InputMaybe<Array<InkGenericMessageAttachmentInput>>;
	/** Notes on the letter. */
	notes?: InputMaybe<Scalars['String']>;
	/** The content of the message, as plain text. */
	plainTextContent: Scalars['String'];
};

/** This type wraps around the `Message` type for SMS. */
export type InkSms = {
	__typename?: 'InkSMS';
	/** The contact channel identity. */
	contactChannelIdentity: InkContactChannelIdentity;
	/** The delivery status. */
	delivery: InkMessageDelivery;
	/** The direction of the message. */
	direction: InkMessageDirection;
	/** The content of the message. */
	displayContent: Scalars['String'];
	/** The phone number the message was sent from. */
	fromHandle: Scalars['String'];
	/** Is this an SMS message. */
	isChannelSms: Scalars['Boolean'];
	/** The time the message was sent/received at. */
	occurredAt: Scalars['DateTime'];
	/** All Tags associated with a message. */
	tags: Array<InkTag>;
	/** The phone number the message was sent to. */
	toHandle: Scalars['String'];
};

export type InkTag = Node & {
	__typename?: 'InkTag';
	/** The ID of the object */
	id: Scalars['ID'];
	/** Tag for a message. */
	name: Scalars['String'];
};

/** This type wraps around the `Message` type for WhatsApp message. */
export type InkWhatsApp = {
	__typename?: 'InkWhatsApp';
	/** Whatsapp message contact channel identity. */
	contactChannelIdentity: InkContactChannelIdentity;
	/** Whatsapp message delivery status. */
	delivery: InkMessageDelivery;
	/** The direction of the message. */
	direction: InkMessageDirection;
	/** From WhatsApp phone number. */
	fromHandle: Scalars['String'];
	/** Whether or not the message is a whatsapp message. */
	isChannelWhatsapp: Scalars['Boolean'];
	/** Date when the conversation event was created. */
	occurredAt: Scalars['DateTime'];
	/** All Tags associated with a message. */
	tags: Array<InkTag>;
	/** Whatsapp contact phone number. */
	toHandle: Scalars['String'];
	/** The vendor id. */
	vendorId?: Maybe<Scalars['String']>;
	/** Whatsapp message content. */
	whatsappContent: WhatsAppTextMessage;
};

/**
 * Input type for instruction details that was created using the embedded process.
 *
 * This means it was set up in the vendor system, and we only need to store a representation
 * of the instruction in Kraken, but not make any calls to the vendor.
 * This method of instruction creation must always be used for creating card instructions.
 */
export type InstructionDetailsInput = {
	/** The type of the payment instruction. */
	instructionType: PaymentType;
	/** The datetime from which the instruction is vaild. */
	validFrom: Scalars['DateTime'];
	/** The vendor's reference for this payment instruction. */
	vendorReference: Scalars['String'];
};

export type Integration = {
	__typename?: 'Integration';
	status?: Maybe<IntegrationStatus>;
	type?: Maybe<IntegrationType>;
};

export type IntegrationResponse = Integration | LineIntegration;

export enum IntegrationStatus {
	Active = 'ACTIVE',
	Inactive = 'INACTIVE',
}

export enum IntegrationType {
	Line = 'LINE',
}

/**
 *
 *     The frequency at which contributations are made
 *
 */
export enum Interval {
	Monthly = 'MONTHLY',
	Quarterly = 'QUARTERLY',
}

export type IntervalMeasurementType = MeasurementInterface & {
	__typename?: 'IntervalMeasurementType';
	accumulation?: Maybe<Scalars['Decimal']>;
	durationInSeconds: Scalars['Int'];
	endAt: Scalars['DateTime'];
	/** This type will return more granular data about the measurement. */
	metaData?: Maybe<MeasurementsMetadataOutput>;
	readAt: Scalars['DateTime'];
	source: Scalars['String'];
	startAt: Scalars['DateTime'];
	unit: Scalars['String'];
	value: Scalars['Decimal'];
};

export enum IntervalReadingsSortOrder {
	Asc = 'ASC',
	Desc = 'DESC',
}

/**
 * Invalidates a payment instruction.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3926: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InvalidatePaymentInstruction = {
	__typename?: 'InvalidatePaymentInstruction';
	instruction?: Maybe<InvalidatePaymentInstructionOutput>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input for invalidating an arbitrary payment instruction. */
export type InvalidatePaymentInstructionInput = {
	accountNumber: Scalars['String'];
	/** The id of the payment instruction to be invalidated. */
	id: Scalars['String'];
};

/** Output for invalidating an arbitrary payment instruction. */
export type InvalidatePaymentInstructionOutput = {
	__typename?: 'InvalidatePaymentInstructionOutput';
	id?: Maybe<Scalars['Int']>;
};

/**
 * Invalidate a previously issued expiring/pre-signed token.
 *
 * This mutation can be used to invalidate the token itself.
 *
 * To invalidate tokens issued to a particular user, use
 * InvalidatePreSignedTokensForUser mutation.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1129: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InvalidatePreSignedToken = {
	__typename?: 'InvalidatePreSignedToken';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	token?: Maybe<PreSignedToken>;
};

/** Input type for the InvalidatePreSignedToken mutation. */
export type InvalidatePreSignedTokenInput = {
	token: Scalars['String'];
};

/**
 * Invalidate pre-signed tokens previously issued to a particular user.
 *
 * This mutation can invalidate all pre-signed tokens issued to a
 * customer, or only tokens of a given scope.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1129: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InvalidatePreSignedTokensForUser = {
	__typename?: 'InvalidatePreSignedTokensForUser';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	tokens?: Maybe<Array<Maybe<PreSignedToken>>>;
};

/** Input type for the InvalidatePreSignedTokensForUser mutation. */
export type InvalidatePreSignedTokensForUserInput = {
	/** The email address of the user whose tokens should be invalidated. */
	email: Scalars['String'];
	/** The scope of the token to invalidate.  If this argument is not specified, all pre-signed tokens issued to the user are invalidated. */
	scope?: InputMaybe<PreSignedTokenScope>;
};

/**
 * Invalidate a previously issued refresh token.
 *
 * This mutation can be used to invalidate the token itself.  To
 * invalidate tokens issued to a particular user, use
 * InvalidateRefreshTokensForUser.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1130: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InvalidateRefreshToken = {
	__typename?: 'InvalidateRefreshToken';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	token?: Maybe<RefreshToken>;
};

/** Input type for the InvalidateRefreshToken mutation. */
export type InvalidateRefreshTokenInput = {
	refreshToken: Scalars['String'];
};

/**
 * Invalidate refresh tokens previously issued to a particular user.
 *
 * This mutation will invalidate all refresh tokens issued to a
 * customer.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1128: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type InvalidateRefreshTokensForUser = {
	__typename?: 'InvalidateRefreshTokensForUser';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	tokens?: Maybe<Array<Maybe<RefreshToken>>>;
};

/** Input type for the InvalidateRefreshTokensForUser mutation. */
export type InvalidateRefreshTokensForUserInput = {
	/** The email address of the user whose tokens should be invalidated. */
	email: Scalars['String'];
};

/** An invoice is a bill that contains individual transactions  (i.e. charges, credits, payments, and repayments). These may come from any period of time. */
export type InvoiceBillingDocumentConnectionTypeConnection = {
	__typename?: 'InvoiceBillingDocumentConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<InvoiceBillingDocumentConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `InvoiceBillingDocumentConnectionType` and its cursor. */
export type InvoiceBillingDocumentConnectionTypeEdge = {
	__typename?: 'InvoiceBillingDocumentConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<InvoiceBillingDocumentType>;
};

/** An invoice is a bill that contains individual transactions  (i.e. charges, credits, payments, and repayments). These may come from any period of time. */
export type InvoiceBillingDocumentType = {
	__typename?: 'InvoiceBillingDocumentType';
	/** Billing document that annuls this invoice. */
	annulledBy?: Maybe<AnnulmentBillingDocumentType>;
	/** First time the invoice was issued. */
	firstIssued?: Maybe<Scalars['DateTime']>;
	id?: Maybe<Scalars['Int']>;
	/** The invoiced amount of the billing document. */
	invoicedAmount?: Maybe<Scalars['Int']>;
	/** The unique billing document's reference that can be used for identifying it externally. */
	number?: Maybe<Scalars['String']>;
	/** URL to the PDF of the Invoice. */
	pdfUrl?: Maybe<Scalars['String']>;
};

export type InvoiceType = BillInterface & {
	__typename?: 'InvoiceType';
	billType?: Maybe<BillTypeEnum>;
	fromDate?: Maybe<Scalars['Date']>;
	/** This field returns the total gross amount of the bill in pence. */
	grossAmount?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['ID']>;
	/** The date the bill was sent to the customer. */
	issuedDate?: Maybe<Scalars['Date']>;
	/**
	 * Requesting this field generates a temporary URL at which bill is available.
	 *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
	 *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
	 *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
	 *         been created/issued yet.
	 *
	 */
	temporaryUrl?: Maybe<Scalars['String']>;
	toDate?: Maybe<Scalars['Date']>;
};

export type ItemSizeType = FractionSizeType | PointsSizeType;

/** Items are sections making up a screen. They can be different types, hence Union. */
export type ItemType =
	| AnimationType
	| CardComponentType
	| ImageType
	| PillButtonType
	| RectangularButtonType
	| TextType;

export type JoinSupplierAcceptTermsAndConditionsResult = {
	__typename?: 'JoinSupplierAcceptTermsAndConditionsResult';
	/** Indicator that the mutation has completed successfully. */
	result?: Maybe<Scalars['Boolean']>;
};

export type JoinSupplierProcessConnectionTypeConnection = {
	__typename?: 'JoinSupplierProcessConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<JoinSupplierProcessConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `JoinSupplierProcessConnectionType` and its cursor. */
export type JoinSupplierProcessConnectionTypeEdge = {
	__typename?: 'JoinSupplierProcessConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<JoinSupplierProcessType>;
};

/** Represents a Join Supplier process. */
export type JoinSupplierProcessType = AbstractSupplyPointProcessInterface & {
	__typename?: 'JoinSupplierProcessType';
	/** The ID or the primary key of the lifecycle process. */
	id?: Maybe<Scalars['ID']>;
	/** The status of the process. */
	status?: Maybe<LifecycleSupplyPointProcessStatus>;
	/** The supply points associated with the process. */
	supplyPoints: SupplyPointConnectionTypeConnection;
};

/** Represents a Join Supplier process. */
export type JoinSupplierProcessTypeSupplyPointsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type JpnRichAddressInput = {
	/** 都道府県 (prefecture). */
	administrativeArea: Scalars['String'];
	/** The country code. */
	country?: Scalars['String'];
	/**
	 *
	 * Identifier used by the local postal service for this
	 * address, e.g. AU DPID, GB postcode + Delivery Point Suffix,
	 * US Zip-9 + Delivery Point.
	 *
	 * This is the value that gets encoded in the barcode printed
	 * on the envelope by large-volume bulk mail providers.
	 *
	 */
	deliveryPointIdentifier?: InputMaybe<Scalars['String']>;
	/**
	 *
	 * UK dependent localities, or neighbourhoods or boroughs in
	 * some other locations.
	 *
	 */
	dependentLocality?: InputMaybe<Scalars['String']>;
	/** 市町村 (locality). */
	locality: Scalars['String'];
	/** A personal name. */
	name?: InputMaybe<Scalars['String']>;
	/** The name of a business or organisation. */
	organization?: InputMaybe<Scalars['String']>;
	/** 郵便番号 (postcode). */
	postalCode: Scalars['String'];
	/**
	 *
	 * Sorting code, e.g. FR CEDEX code. This field is not used in many countries.
	 *
	 */
	sortingCode?: InputMaybe<Scalars['String']>;
	/**
	 *
	 * At most one of this field and `structured_street_address`
	 * can be supplied.
	 *
	 * This is a divergence from `RichAddressType.street_address`,
	 * where the field is always supplied; if
	 * `structured_street_address` is present, it's generated from
	 * that.
	 *
	 */
	streetAddress?: InputMaybe<Scalars['String']>;
	/**
	 *
	 * At most one of this field and `street_address` can be
	 * supplied.
	 *
	 * ### `JP`: Japan
	 *
	 * The following keys may be present; all are optional.
	 * If keys are empty, they may be omitted from the response entirely.
	 *
	 * - `chome`
	 * - `banchi`
	 * - `go`
	 * - `edaban`
	 * - `kana_building_name`
	 * - `kanji_building_name`
	 * - `building_number`
	 * - `room_number`
	 * - `address_code`
	 * - `physical_location_identifier`
	 *
	 */
	structuredStreetAddress?: InputMaybe<JpnRichStructuredAddressInput>;
};

export type JpnRichStructuredAddressInput = {
	/** 住所コード (address code). */
	addressCode: Scalars['String'];
	/** 番地 (banchi). */
	banchi?: InputMaybe<Scalars['String']>;
	/** 棟番 (building number). */
	buildingNumber?: InputMaybe<Scalars['String']>;
	/** 丁目 (chome). */
	chome?: InputMaybe<Scalars['String']>;
	/** 号枝番 (edaban). */
	edaban?: InputMaybe<Scalars['String']>;
	/** 号 (go). */
	go?: InputMaybe<Scalars['String']>;
	/** 建物（カナ） (building name in kana). */
	kanaBuildingName?: InputMaybe<Scalars['String']>;
	/** 建物（漢字） (building name in kanji). */
	kanjiBuildingName?: InputMaybe<Scalars['String']>;
	/** 宛先補足情報 (physical location identifier). */
	physicalLocationIdentifier?: InputMaybe<Scalars['String']>;
	/** 部屋番 (room number). */
	roomNumber?: InputMaybe<Scalars['String']>;
};

export type KonbiniPaymentCreated = {
	__typename?: 'KonbiniPaymentCreated';
	/** The konbini payment expiration time. */
	expireAt: Scalars['DateTime'];
	/** The konbini payment voucher email is sent to this email address. */
	receiptEmail: Scalars['String'];
	/** The konbini payment voucher url. */
	voucherUrl: Scalars['String'];
};

export type KonbiniPaymentMethodCreated = {
	__typename?: 'KonbiniPaymentMethodCreated';
	/** Stripe Konbini Payment reference. */
	reference: Scalars['String'];
};

/** Information about what version of Kraken is being executed by this service. */
export type KrakenVersionType = {
	__typename?: 'KrakenVersionType';
	/** The git commit SHA that is being executed. */
	SHA?: Maybe<Scalars['String']>;
	/** The version number that is being executed. */
	number?: Maybe<Scalars['String']>;
};

export type LatestKonbiniPaymentUrl = {
	__typename?: 'LatestKonbiniPaymentURL';
	/** THe payment deadline for the most recent 'Scheduled' Konbini payment. */
	paymentDate?: Maybe<Scalars['Date']>;
	/** The payment voucher URL of the most recent 'Scheduled' Konbini payment. */
	url?: Maybe<Scalars['String']>;
};

export type LeavePropertyProcessConnectionTypeConnection = {
	__typename?: 'LeavePropertyProcessConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<LeavePropertyProcessConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `LeavePropertyProcessConnectionType` and its cursor. */
export type LeavePropertyProcessConnectionTypeEdge = {
	__typename?: 'LeavePropertyProcessConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<LeavePropertyProcessType>;
};

/** Represents a Leave Property process. */
export type LeavePropertyProcessType = AbstractSupplyPointProcessInterface & {
	__typename?: 'LeavePropertyProcessType';
	/** The ID or the primary key of the lifecycle process. */
	id?: Maybe<Scalars['ID']>;
	/** The status of the process. */
	status?: Maybe<LifecycleSupplyPointProcessStatus>;
	/** The supply points associated with the process. */
	supplyPoints: SupplyPointConnectionTypeConnection;
};

/** Represents a Leave Property process. */
export type LeavePropertyProcessTypeSupplyPointsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

/** Output of a LeaveSupplier journey cancellation. */
export type LeaveSupplierCancelled = {
	__typename?: 'LeaveSupplierCancelled';
	/** The message to display to the user on cancellation. */
	message: Scalars['String'];
};

export type LeaveSupplierInput = {
	/** The Kraken account number. */
	accountNumber: Scalars['String'];
	/** Future billing address. */
	futureBillingAddress?: InputMaybe<JpnRichAddressInput>;
	marketData?: InputMaybe<LeaveSupplierMarketInputType>;
	/** The requested last day of supply. */
	requestedSupplyEndDate: Scalars['Date'];
};

/** Termination was successfully initiated. */
export type LeaveSupplierInstigated = {
	__typename?: 'LeaveSupplierInstigated';
	/** The ID of the newly created or existing leave supplier process. */
	leaveSupplierProcessId: Scalars['ID'];
	/** The message to display to the user on termination initiation. */
	message: Scalars['String'];
};

export type LeaveSupplierMarketInputType = {
	/** A list of Electricity leave supplier details. */
	electricitySupplyPointData?: InputMaybe<
		Array<InputMaybe<ElectricityTerminationInput>>
	>;
	/** A list of Gas leave supplier details. */
	gasSupplyPointData?: InputMaybe<Array<InputMaybe<GasTerminationInput>>>;
};

export type LeaveSupplierMarketOutputType = {
	__typename?: 'LeaveSupplierMarketOutputType';
	/** A list of Electricity leave supplier details. */
	electricitySupplyPointData?: Maybe<
		Array<Maybe<ElectricityTerminationOutput>>
	>;
	/** A list of Gas leave supplier details. */
	gasSupplyPointData?: Maybe<Array<Maybe<GasTerminationOutput>>>;
};

export type LeaveSupplierProcessConnectionTypeConnection = {
	__typename?: 'LeaveSupplierProcessConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<LeaveSupplierProcessConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `LeaveSupplierProcessConnectionType` and its cursor. */
export type LeaveSupplierProcessConnectionTypeEdge = {
	__typename?: 'LeaveSupplierProcessConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<LeaveSupplierProcessType>;
};

/** Represents data associated with a Leave Supplier process. */
export type LeaveSupplierProcessData = LeaveSupplierProcessDataInterface & {
	__typename?: 'LeaveSupplierProcessData';
	/** Detailed reason for leaving the supplier. */
	detailedReason: LeaveSupplierProcessDataDetailedReason;
	/** The address where final payment bill is sent. */
	finalBillPayerAddress?: Maybe<RichAddressType>;
	/** The future billing address for the customers account. */
	futureBillingAddress?: Maybe<RichAddressType>;
	/** The market data associated with the process. */
	marketData?: Maybe<LeaveSupplierMarketOutputType>;
	/** Additional market-agnostic parameters for the leave supplier process. */
	params: Scalars['JSONString'];
	/** Reason for leaving the supplier. */
	reason: LeaveSupplierProcessDataReason;
	/** The time at which the process was initiated. */
	requestedAt?: Maybe<Scalars['DateTime']>;
	/** The requested supply end date and time. */
	requestedSupplyEndAt?: Maybe<Scalars['DateTime']>;
	/** Whether the exit fee is to be waived. */
	waiveExitFee?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum LeaveSupplierProcessDataDetailedReason {
	/** Dunning */
	Dunning = 'DUNNING',
	/** Moving */
	Moving = 'MOVING',
	/** Moving initiated from outside Kraken */
	MovingInitiatedOutsideKraken = 'MOVING_INITIATED_OUTSIDE_KRAKEN',
	/** Not in use */
	NotInUse = 'NOT_IN_USE',
	/** One stop switching */
	OneStopSwitching = 'ONE_STOP_SWITCHING',
	/** Two stop switching */
	TwoStopSwitching = 'TWO_STOP_SWITCHING',
}

/**
 * Represents data associated with a LeaveSupplier journey.
 *
 * i.e. concrete implementation of AbstractLeaveSupplierProcessData.
 */
export type LeaveSupplierProcessDataInterface = {
	/** The future billing address for the customers account. */
	futureBillingAddress?: Maybe<RichAddressType>;
	/** The market data associated with the process. */
	marketData?: Maybe<LeaveSupplierMarketOutputType>;
	/** The time at which the process was initiated. */
	requestedAt?: Maybe<Scalars['DateTime']>;
	/** The requested supply end date and time. */
	requestedSupplyEndAt?: Maybe<Scalars['DateTime']>;
	/** Whether the exit fee is to be waived. */
	waiveExitFee?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum LeaveSupplierProcessDataReason {
	/** Consumer request */
	ConsumerRequest = 'CONSUMER_REQUEST',
	/** Retailer request */
	RetailerRequest = 'RETAILER_REQUEST',
}

/** Represents a Leave Supplier process. Conceptually, it contains information related to supply points that are associated with a Leave Supplier journey. */
export type LeaveSupplierProcessType = AbstractSupplyPointProcessInterface & {
	__typename?: 'LeaveSupplierProcessType';
	/** The ID or the primary key of the lifecycle process. */
	id?: Maybe<Scalars['ID']>;
	/** Data associated with the Leave Supplier process. */
	processData?: Maybe<LeaveSupplierProcessData>;
	/** The status of the process. */
	status?: Maybe<LifecycleSupplyPointProcessStatus>;
	/** The supply points associated with the process. */
	supplyPoints: SupplyPointConnectionTypeConnection;
};

/** Represents a Leave Supplier process. Conceptually, it contains information related to supply points that are associated with a Leave Supplier journey. */
export type LeaveSupplierProcessTypeSupplyPointsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type LedgerInterface = {
	/** Whether this ledger's balance contributes to the account's balance. */
	affectsAccountBalance?: Maybe<Scalars['Boolean']>;
	/** The amount owed from the customer perspective. A positive value implies the customer owes the business, while a negative amount implies the customer is in credit. */
	amountOwedByCustomer?: Maybe<Scalars['Int']>;
	/** The current balance on the ledger in minor units of currency. */
	balance?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['ID']>;
	/** An invoice is a bill that contains individual transactions  (i.e. charges, credits, payments, and repayments). These may come from any period of time. */
	invoices?: Maybe<InvoiceBillingDocumentConnectionTypeConnection>;
	ledgerType?: Maybe<Scalars['String']>;
	/** The display name of the ledger. */
	name?: Maybe<Scalars['String']>;
	/** The canonical name of the ledger. */
	number?: Maybe<Scalars['String']>;
	paymentAdequacy?: Maybe<PaymentAdequacyDetailsType>;
	/** Refund requests for a given ledger. */
	refundRequests?: Maybe<RefundRequestConnectionTypeConnection>;
	/** Repayment requests for a given ledger. */
	repaymentRequests?: Maybe<RepaymentRequestConnectionTypeConnection>;
	/** A statement is a billing document that contains all entries on a ledger during a period of time. A customer can understand how their ledger's balance has changed by looking at each statement in series. */
	statements?: Maybe<StatementBillingDocumentConnectionTypeConnection>;
	transactions?: Maybe<TransactionConnectionTypeConnection>;
};

export type LedgerInterfaceInvoicesArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type LedgerInterfaceRefundRequestsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type LedgerInterfaceRepaymentRequestsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type LedgerInterfaceStatementsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type LedgerInterfaceTransactionsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	transactionTypes?: InputMaybe<Array<InputMaybe<TransactionTypeFilter>>>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerType = LedgerInterface & {
	__typename?: 'LedgerType';
	/** Whether this ledger's balance contributes to the account's balance. */
	affectsAccountBalance?: Maybe<Scalars['Boolean']>;
	/** The amount owed from the customer perspective. A positive value implies the customer owes the business, while a negative amount implies the customer is in credit. */
	amountOwedByCustomer?: Maybe<Scalars['Int']>;
	/** The current balance on the ledger in minor units of currency. */
	balance?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['ID']>;
	/** An invoice is a bill that contains individual transactions  (i.e. charges, credits, payments, and repayments). These may come from any period of time. */
	invoices?: Maybe<InvoiceBillingDocumentConnectionTypeConnection>;
	/** The ledger type code. */
	ledgerType?: Maybe<Scalars['String']>;
	/** The display name of the ledger. */
	name?: Maybe<Scalars['String']>;
	/** The canonical name of the ledger. */
	number?: Maybe<Scalars['String']>;
	paymentAdequacy?: Maybe<PaymentAdequacyDetailsType>;
	/** Refund requests for a given ledger. */
	refundRequests?: Maybe<RefundRequestConnectionTypeConnection>;
	/** Repayment requests for a given ledger. */
	repaymentRequests?: Maybe<RepaymentRequestConnectionTypeConnection>;
	/** A statement is a billing document that contains all entries on a ledger during a period of time. A customer can understand how their ledger's balance has changed by looking at each statement in series. */
	statements?: Maybe<StatementBillingDocumentConnectionTypeConnection>;
	transactions?: Maybe<TransactionConnectionTypeConnection>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeInvoicesArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	invoiceId?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeRefundRequestsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeRepaymentRequestsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeStatementsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	statementId?: InputMaybe<Scalars['Int']>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type LedgerTypeTransactionsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	transactionTypes?: InputMaybe<Array<InputMaybe<TransactionTypeFilter>>>;
};

export type LifecycleAddressInput = {
	/** Country code. */
	countryCode?: InputMaybe<Scalars['String']>;
	/** Line 1 of address. */
	line1: Scalars['String'];
	/** Line 2 of address. */
	line2?: InputMaybe<Scalars['String']>;
	/** Line 3 of address. */
	line3?: InputMaybe<Scalars['String']>;
	/** Line 4 of address. */
	line4?: InputMaybe<Scalars['String']>;
	/** Line 5 of address. */
	line5?: InputMaybe<Scalars['String']>;
	/** Postal code. */
	postalCode: Scalars['String'];
};

export type LifecycleProcessesType = {
	__typename?: 'LifecycleProcessesType';
	/** List of JoinSupplierProcess for an account. */
	joinSupplierProcesses?: Maybe<JoinSupplierProcessConnectionTypeConnection>;
	/** List of LeavePropertyProcess for an account. */
	leavePropertyProcesses?: Maybe<LeavePropertyProcessConnectionTypeConnection>;
	/** List of LeaveSupplierProcess for an account. */
	leaveSupplierProcesses?: Maybe<LeaveSupplierProcessConnectionTypeConnection>;
	/** List of OccupyPropertyProcess for an account. */
	occupyPropertyProcesses?: Maybe<OccupyPropertyProcessConnectionTypeConnection>;
};

export type LifecycleProcessesTypeJoinSupplierProcessesArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type LifecycleProcessesTypeLeavePropertyProcessesArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type LifecycleProcessesTypeLeaveSupplierProcessesArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type LifecycleProcessesTypeOccupyPropertyProcessesArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

/** The status of the lifecycle process. */
export enum LifecycleSupplyPointProcessStatus {
	Cancelled = 'CANCELLED',
	Completed = 'COMPLETED',
	Errored = 'ERRORED',
	Failed = 'FAILED',
	InProgress = 'IN_PROGRESS',
	PartiallyCancelled = 'PARTIALLY_CANCELLED',
	PartiallyCompleted = 'PARTIALLY_COMPLETED',
	PartiallyReversed = 'PARTIALLY_REVERSED',
	Pending = 'PENDING',
	Reversed = 'REVERSED',
	ReverseInProgress = 'REVERSE_IN_PROGRESS',
	Stalled = 'STALLED',
}

export type LifelineAgencyApplication = {
	__typename?: 'LifelineAgencyApplication';
	/** Lifeline Agency Application status. */
	status: LifelineAgencySignUpsFileRecordStatus;
};

export type LifelineAgencyBranchInfo = {
	__typename?: 'LifelineAgencyBranchInfo';
	/** The address of the LLA Branch. */
	address: Scalars['String'];
	/** The business start time of the LLA Branch. */
	businessHoursFrom: Scalars['Time'];
	/** The business end time of the LLA Branch. */
	businessHoursTo: Scalars['Time'];
	/** The name of the LLA Branch. */
	name: Scalars['String'];
	/** The name of the LLA Branch representative. */
	representativeName: Scalars['String'];
	/** The phone number of the LLA Branch representative. */
	representativePhoneNumber: Scalars['String'];
	/** The weekend business start time of the LLA Branch. */
	weekendBusinessHoursFrom?: Maybe<Scalars['Time']>;
	/** The weekend business end time of the LLA Branch. */
	weekendBusinessHoursTo?: Maybe<Scalars['Time']>;
};

/**
 *
 *     AWAITING_CUSTOMER_DATA_VALIDATION:
 *         Initial status when the record is created. The record's customer data
 *         is awaiting validation.
 *     AWAITING_TERMS_AND_CONDITIONS_OR_SPIN:
 *         Terms and conditions have not been accepted by the customer or, SPIN has
 *         not been provided and validated for the record.
 *          - If the record has an Application with a QuoteRequest and, the record
 *            has an Account with ACCOUNT_CONSENT_DATA_TERMS_AGREED_AT_KEY_NAME
 *            consent data, we know that the customer has accepted the Ts&Cs.
 *          - If the record has valid_spin_provided set to True, we know that the
 *            record has a valid (correct format) SPIN.
 *     INDUSTRY_APPLICATION_IN_PROGRESS:
 *         There is SPIN, so the industry application will have
 *         started, but it has not yet completed. On the happy path, the
 *         application will stay in this status until the move-in date.
 *     COMPLETED_SUCCESSFULLY:
 *         The industry process completed successfully and the customer came on
 *         supply.
 *     CANCELLED:
 *         Customer has requested cancellation.
 *     CLOSED:
 *         Move in process has been closed, or withdrawn by reason other than customers' contact
 *     DUPLICATE:
 *         A duplicate application.
 *     ACTION_REQUIRED_EMAIL_BOUNCED:
 *         The initial action required email bounced.
 *     ACTION_REQUIRED_INDUSTRY_REJECTION:
 *         The move-in application was rejected by the industry body but the deadline has not
 *         been reached, so this is recoverable.
 *     ACTION_REQUIRED_STILL_UNDER_CONTRACT:
 *         The application's OCCTO status is 契約中に再点申込あり but the user action deadline
 *         has not yet been reached.
 *     REJECTED_VALIDATION_ERROR:
 *         Kraken could not process the record because of a validation error
 *     REJECTED_MOVE_IN_TOO_SOON:
 *         The application was rejected upon processing the customer information
 *         file because the move-in date was less than 3 business days in the future
 *     REJECTED_CANT_SUPPLY:
 *         The move-in application was rejected by TGOE because the type of supply
 *         point is one that TGOE does not supply
 *     REJECTED_TERMS_AND_CONDITIONS_OR_SPIN_NOT_FOUND:
 *         The customer has not yet accepted Ts&Cs or a valid SPIN was not yet provided.
 *     REJECTED_DUPLICATE:
 *         Rejected as application with exact same data was already been made
 *     REJECTED_EMAIL_ASSOCIATED_WITH_ANOTHER_USER:
 *         The provided email is associated with another AccountUser with a different name.
 *     EXPIRED_NO_PAYMENT_DETAILS:
 *         DEPRECATED
 *         The application expired because the customer did not provide payment
 *         details by the deadline
 *     EXPIRED_EMAIL_BOUNCED:
 *         DEPRECATED
 *         The application expired because the customer did not provide payment
 *         details by the deadline after the email bounced
 *     EXPIRED_STILL_UNDER_CONTRACT:
 *         The application's OCCTO status is still 契約中に再点申込あり by the user action
 *         deadline.
 *
 */
export enum LifelineAgencySignUpsFileRecordStatus {
	ActionRequiredEmailBounced = 'ACTION_REQUIRED_EMAIL_BOUNCED',
	ActionRequiredIndustryRejection = 'ACTION_REQUIRED_INDUSTRY_REJECTION',
	ActionRequiredStillUnderContract = 'ACTION_REQUIRED_STILL_UNDER_CONTRACT',
	AwaitingCustomerDataValidation = 'AWAITING_CUSTOMER_DATA_VALIDATION',
	AwaitingTermsAndConditionsOrSpin = 'AWAITING_TERMS_AND_CONDITIONS_OR_SPIN',
	Cancelled = 'CANCELLED',
	Closed = 'CLOSED',
	CompletedSuccessfully = 'COMPLETED_SUCCESSFULLY',
	Duplicate = 'DUPLICATE',
	ExpiredEmailBounced = 'EXPIRED_EMAIL_BOUNCED',
	ExpiredNoPaymentDetails = 'EXPIRED_NO_PAYMENT_DETAILS',
	ExpiredStillUnderContract = 'EXPIRED_STILL_UNDER_CONTRACT',
	IndustryApplicationInProgress = 'INDUSTRY_APPLICATION_IN_PROGRESS',
	RejectedCantSupply = 'REJECTED_CANT_SUPPLY',
	RejectedDuplicate = 'REJECTED_DUPLICATE',
	RejectedEmailAssociatedWithAnotherUser = 'REJECTED_EMAIL_ASSOCIATED_WITH_ANOTHER_USER',
	RejectedMoveInTooSoon = 'REJECTED_MOVE_IN_TOO_SOON',
	RejectedTermsAndConditionsOrSpinNotFound = 'REJECTED_TERMS_AND_CONDITIONS_OR_SPIN_NOT_FOUND',
	RejectedValidationError = 'REJECTED_VALIDATION_ERROR',
}

/** A base error type. Should be used for general application or lower level errors. */
export type LineCommonError = {
	__typename?: 'LineCommonError';
	/** The field that for which this error should be associated. */
	field?: Maybe<Scalars['String']>;
	/** The error message to display to the user. */
	message: Scalars['String'];
};

/**
 * A LINE specific emoji object.
 * refs: https://developers.line.biz/en/reference/messaging-api/#text-message
 */
export type LineEmoji = {
	__typename?: 'LineEmoji';
	/** The emoji id. */
	emojiId: Scalars['String'];
	/** The location of the emoji in the message. */
	index: Scalars['Int'];
	/** The length of the emoji string placeholder. */
	length?: Maybe<Scalars['Int']>;
	/** The product id. */
	productId: Scalars['String'];
};

export type LineImageMessage = {
	__typename?: 'LineImageMessage';
	id: Scalars['ID'];
};

export type LineIntegration = {
	__typename?: 'LineIntegration';
	displayName: Scalars['String'];
	status?: Maybe<IntegrationStatus>;
	type?: Maybe<IntegrationType>;
};

/** Paginator of LineItemType. */
export type LineItemConnectionTypeConnection = {
	__typename?: 'LineItemConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<LineItemConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `LineItemConnectionType` and its cursor. */
export type LineItemConnectionTypeEdge = {
	__typename?: 'LineItemConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<LineItemType>;
};

export type LineItemType = {
	__typename?: 'LineItemType';
	/** The currency of the charge. */
	currency?: Maybe<Scalars['String']>;
	/** The electricity supply point of the charge. */
	electricitySupplyPoint?: Maybe<ElectricitySupplyPoint>;
	/** The gas supply point of the charge. */
	gasSupplyPoint?: Maybe<GasSupplyPoint>;
	/** The gross amount charged. */
	grossAmount?: Maybe<Scalars['Decimal']>;
	/** The net amount charged. */
	netAmount?: Maybe<Scalars['Decimal']>;
	/** The supplied quantity. */
	numberOfUnits?: Maybe<Scalars['Decimal']>;
	/** The end of the period the charge covers. */
	periodEndAt?: Maybe<Scalars['DateTime']>;
	/** The start of the period the charge covers. */
	periodStartAt?: Maybe<Scalars['DateTime']>;
	/** The product code of the charge. */
	productCode?: Maybe<Scalars['String']>;
	/** The type of charge. Based on Product Rates registered in the support site. Will resolve to JPN_{MARKET}_{RATE_NAME}, for example JPN_ELECTRICITY_CONSUMPTION_STEPPED_03_01. */
	supplyType?: Maybe<Scalars['String']>;
};

export enum LineLinkErrorType {
	AlreadyLinked = 'ALREADY_LINKED',
	NoMatchingLineLink = 'NO_MATCHING_LINE_LINK',
}

/** Link Successful. Complete link process with LINE. */
export type LineLinkRedirectResponse = {
	__typename?: 'LineLinkRedirectResponse';
	redirectUrl: Scalars['String'];
};

export type LineMessage =
	| LineImageMessage
	| LineStickerMessage
	| LineTextMessage;

export type LineStickerMessage = {
	__typename?: 'LineStickerMessage';
	/** Keywords describing the sticker. */
	keywords: Array<Scalars['String']>;
	/** Sticker package id. */
	packageId: Scalars['String'];
	/** Sticker resource type. */
	resourceType: Scalars['String'];
	/** Sticker id. */
	stickerId: Scalars['String'];
	/** Text used to customize some stickers. */
	text: Scalars['String'];
};

export type LineTextMessage = {
	__typename?: 'LineTextMessage';
	/** The display content. */
	displayContent: Scalars['String'];
	/** The emojis in the message. */
	emojis?: Maybe<Array<LineEmoji>>;
};

export type LineUnlinkedResponse = {
	__typename?: 'LineUnlinkedResponse';
	message: Scalars['String'];
};

/** An action which navigates to any URL. */
export type LinkActionType = ActionInterface &
	IdentifiableInterface & {
		__typename?: 'LinkActionType';
		/** Unique identifier of the object. */
		id?: Maybe<Scalars['ID']>;
		/** The name of the action object's type. */
		typeName?: Maybe<Scalars['String']>;
		/** The name of the object's type. */
		typename?: Maybe<Scalars['String']>;
		/** The URL to navigate to. */
		url: Scalars['String'];
	};

/** Returned when no LineAccountLink record matching the parameters exists. */
export type LinkTokenNotFound = {
	__typename?: 'LinkTokenNotFound';
	/** The type of error that occurred. */
	type: LineLinkErrorType;
};

/** An enumeration. */
export enum LinkTrainingStatus {
	/** In training */
	InTraining = 'IN_TRAINING',
	/** Not applicable */
	NotApplicable = 'NOT_APPLICABLE',
	/** Qualified */
	Qualified = 'QUALIFIED',
}

/** Link an AccountUser to a LINE account. */
export type LinkUserToLineInput = {
	linkToken: Scalars['String'];
};

export type LinkUserToLineResponse =
	| AlreadyLinkedError
	| LineLinkRedirectResponse
	| LinkTokenNotFound;

export enum LinkedObjectType {
	Account = 'ACCOUNT',
	AccountUser = 'ACCOUNT_USER',
}

/** A loyalty card. */
export type LoyaltyCardType = {
	__typename?: 'LoyaltyCardType';
	id: Scalars['ID'];
	/** The number of the loyalty card. */
	number?: Maybe<Scalars['String']>;
	/** The scheme of the loyalty card. */
	scheme?: Maybe<Scalars['String']>;
	/** The status of the loyalty card. */
	status?: Maybe<Scalars['String']>;
};

/** A Loyalty Point ledger entry. */
export type LoyaltyPointLedgerEntryType = {
	__typename?: 'LoyaltyPointLedgerEntryType';
	/** The account number associated with the entry. */
	accountNumber?: Maybe<Scalars['String']>;
	/** Equal to the `balance_carried_forward` from the previous ledger entry or zero if this is the first one. */
	balanceBroughtForward?: Maybe<Scalars['String']>;
	/** Equal to the `balance_brought_forward` plus or minus the value depending on the ledger_type. */
	balanceCarriedForward?: Maybe<Scalars['String']>;
	id: Scalars['ID'];
	/** The `LedgerEntryType`. Either CHARGE or CREDIT. */
	ledgerType?: Maybe<Scalars['String']>;
	/** The date the points were added to the ledger. */
	postedAt?: Maybe<Scalars['DateTime']>;
	/** The reason the entry was being added. */
	reasonCode?: Maybe<Scalars['String']>;
	/** The value of the charge or credit. */
	value?: Maybe<Scalars['String']>;
};

/**
 * Mark a closed print batch as Processed
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-9011: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type MarkPrintBatchAsProcessed = {
	__typename?: 'MarkPrintBatchAsProcessed';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	printBatch?: Maybe<PrintBatchType>;
};

export type MasqueradeAuthentication = {
	__typename?: 'MasqueradeAuthentication';
	/** A list of any errors that occurred while running this mutation. */
	errors?: Maybe<Array<Maybe<ErrorType>>>;
	/** A Kraken Token that can be used to authenticate to the API, masquerading as the desired user. */
	token?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum MaximumRefundReasonChoices {
	/** Maximum refund is equal to the maximum refund amount allowed to be requested via the dashboard. */
	MaxAllowedToRequestViaDashboard = 'MAX_ALLOWED_TO_REQUEST_VIA_DASHBOARD',
	/** Maximum refund is equal to the current balance minus the account recommended balance. */
	MaxAvailableAmount = 'MAX_AVAILABLE_AMOUNT',
	/** Maximum refund is equal to the total amount the customer has paid using the current account Direct Debit instruction. */
	TotalAmountPaidViaActiveDdi = 'TOTAL_AMOUNT_PAID_VIA_ACTIVE_DDI',
}

export type MaximumRefundType = {
	__typename?: 'MaximumRefundType';
	/** The maximum amount available to be requested as a refund. */
	amount?: Maybe<Scalars['Int']>;
	/** The reason why a specific amount is the maximum available to be requested as a refund. */
	reasonToRecommendAmount?: Maybe<MaximumRefundReasonChoices>;
	/** The recommended minimum balance an account should have when asking for a refund. */
	recommendedBalance?: Maybe<Scalars['Int']>;
};

/** Pagination for measurements. */
export type MeasurementConnection = {
	__typename?: 'MeasurementConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<MeasurementEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `Measurement` and its cursor. */
export type MeasurementEdge = {
	__typename?: 'MeasurementEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<MeasurementInterface>;
};

export type MeasurementInterface = {
	/** This type will return more granular data about the measurement. */
	metaData?: Maybe<MeasurementsMetadataOutput>;
	readAt: Scalars['DateTime'];
	source: Scalars['String'];
	unit: Scalars['String'];
	value: Scalars['Decimal'];
};

export type MeasurementType = MeasurementInterface & {
	__typename?: 'MeasurementType';
	/** This type will return more granular data about the measurement. */
	metaData?: Maybe<MeasurementsMetadataOutput>;
	readAt: Scalars['DateTime'];
	source: Scalars['String'];
	unit: Scalars['String'];
	value: Scalars['Decimal'];
};

export type MeasurementsMetadataOutput = {
	__typename?: 'MeasurementsMetadataOutput';
	/** Statistics relating to the parent measurement node. */
	statistics?: Maybe<Array<Maybe<StatisticOutput>>>;
	/**
	 * The source information relating to the parent measurement node.
	 * @deprecated The 'typedSource' type is deprecated.
	 *
	 *
	 * Please use 'utilityFilters' and the corresponding filter output instead.
	 *
	 *
	 * - Marked as deprecated on 2024-06-17.
	 * - Will be removed on 2026-01-30.
	 */
	typedSource?: Maybe<TypedSourceOutput>;
	/** The source information relating to the parent measurement node. */
	utilityFilters?: Maybe<UtilityFiltersOutput>;
};

/** The media interface. */
export type MediaInterface = {
	/** The horizontal alignment of the media. */
	horizontalAlignment?: Maybe<Alignment>;
	/** The resource URL of the media. */
	mediaUrl: Scalars['String'];
};

/** An enumeration. */
export enum MessageChannel {
	/** Email */
	Email = 'EMAIL',
	/** Intercom */
	Intercom = 'INTERCOM',
	/** Junifer */
	Junifer = 'JUNIFER',
	/** Print */
	Print = 'PRINT',
	/** Push Notification */
	PushNotification = 'PUSH_NOTIFICATION',
	/** SMS */
	Sms = 'SMS',
}

export type Metadata = {
	__typename?: 'Metadata';
	/** The key for the metadata. */
	key: Scalars['String'];
	/** The metadata value. */
	value?: Maybe<Scalars['JSONString']>;
};

/** The metadata input type for mutations. */
export type MetadataInput = {
	/** An identifier for the associated object, e.g. account_number for the Account linked object type. */
	identifier: Scalars['String'];
	/** The key for the metadata. */
	key: Scalars['String'];
	/** The object that the metadata is associated with. */
	linkedObjectType: LinkedObjectType;
	/** The metadata value which should be a valid JSON string. */
	value: Scalars['JSONString'];
};

export type MoveInContext = {
	__typename?: 'MoveInContext';
	/** Earliest possible move-in date. */
	earliestMoveInDate: Scalars['Date'];
};

export type MoveInContextEarliestMoveInDateArgs = {
	isSpinKnown: Scalars['Boolean'];
};

export type MoveInCustomerInput = {
	dateOfBirth?: InputMaybe<Scalars['Date']>;
	email?: InputMaybe<Scalars['String']>;
	familyName?: InputMaybe<Scalars['String']>;
	familyNameKana?: InputMaybe<Scalars['String']>;
	givenName?: InputMaybe<Scalars['String']>;
	givenNameKana?: InputMaybe<Scalars['String']>;
	marketingCommunicationsOptIn?: InputMaybe<Scalars['Boolean']>;
	mobile?: InputMaybe<Scalars['String']>;
};

/** Returned when a supplied move in date is invalid */
export type MoveInDateInvalid = {
	__typename?: 'MoveInDateInvalid';
	/** The last day that a customer may request a move in. */
	endDate: Scalars['Date'];
	/** The field that for which this error should be associated. */
	field?: Maybe<Scalars['String']>;
	/** The error message to display to the user. */
	message: Scalars['String'];
	/** The first day that a customer may request a move in. */
	startDate: Scalars['Date'];
};

export type MoveInProcess = {
	__typename?: 'MoveInProcess';
	/** The requested move out date provided by the customer. */
	requestedMoveInDate?: Maybe<Scalars['Date']>;
};

export type MoveInPropertyInput = {
	addressLine1?: InputMaybe<Scalars['String']>;
	addressLine2?: InputMaybe<Scalars['String']>;
	postcode?: InputMaybe<Scalars['String']>;
};

/** Returned upon a successful Move In request. */
export type MoveInSuccess = {
	__typename?: 'MoveInSuccess';
	/** Kraken account number. */
	accountNumber: Scalars['String'];
	/** Indicates if the user has a usable password and can log in immediately. */
	hasUsablePassword: Scalars['Boolean'];
	/** Kraken token. */
	token?: Maybe<Scalars['String']>;
	/** Kraken user id. */
	userId?: Maybe<Scalars['String']>;
};

/** MoveOut was successfully initiated. */
export type MoveOutAndMoveInInitiated = {
	__typename?: 'MoveOutAndMoveInInitiated';
	/** The account number for the newly created account for the property the customer is moving in to. */
	accountNumber: Scalars['String'];
	/** Indicates if the user has a usable password and can log in immediately. */
	hasUsablePassword: Scalars['Boolean'];
	/** The message to display to the user on move out and move in initiation. */
	message: Scalars['String'];
	token?: Maybe<Scalars['String']>;
};

/** Returned when a supplied move out date is outside of the OCCTO accepted range */
export type MoveOutDateInvalid = {
	__typename?: 'MoveOutDateInvalid';
	/** The last day that a customer may request a move out. Always today + 31 days. */
	endDate: Scalars['Date'];
	/** The field that for which this error should be associated. */
	field?: Maybe<Scalars['String']>;
	/** The error message to display to the user. */
	message: Scalars['String'];
	/** The first day that a customer may request a move out. Always today. */
	startDate: Scalars['Date'];
};

/** MoveOut was successfully initiated. */
export type MoveOutInitiated = {
	__typename?: 'MoveOutInitiated';
	/** The message to display to the user on move out initiation. */
	message: Scalars['String'];
};

export type MoveOutProcess = {
	__typename?: 'MoveOutProcess';
	/** Whether the move out request can be cancelled. */
	canBeCancelled?: Maybe<Scalars['Boolean']>;
	/** The requested move out date provided by the customer. */
	requestedMoveOutDate?: Maybe<Scalars['Date']>;
	/** The status of the move out request. */
	status?: Maybe<MoveOutProcessStatus>;
};

/** An enumeration. */
export enum MoveOutProcessStatus {
	CompletedAfterFailedToWithdraw = 'COMPLETED_AFTER_FAILED_TO_WITHDRAW',
	DuplicateApplication = 'DUPLICATE_APPLICATION',
	FailedToWithdraw = 'FAILED_TO_WITHDRAW',
	IngestionFailed = 'INGESTION_FAILED',
	MoveOutClosed = 'MOVE_OUT_CLOSED',
	MoveOutCompleted = 'MOVE_OUT_COMPLETED',
	MoveOutFailed = 'MOVE_OUT_FAILED',
	MoveOutPending = 'MOVE_OUT_PENDING',
	MoveOutRequested = 'MOVE_OUT_REQUESTED',
	MoveOutWithdrawn = 'MOVE_OUT_WITHDRAWN',
	ReadyToApply = 'READY_TO_APPLY',
	Replaced = 'REPLACED',
	RequestBuilderError = 'REQUEST_BUILDER_ERROR',
	RequestError = 'REQUEST_ERROR',
	WaitingToApply = 'WAITING_TO_APPLY',
}

export type Mutation = {
	__typename?: 'Mutation';
	/**
	 * Accept a goods quote.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-8223: Unauthorized.
	 * - KT-CT-8201: Received an invalid quoteId.
	 * - KT-CT-8224: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	acceptGoodsQuote?: Maybe<AcceptGoodsQuote>;
	/**
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4123: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	addCampaignToAccount?: Maybe<AddCampaignToAccount>;
	/**
	 * Allow a repayment to be submitted.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-3944: Account repayment does not exist.
	 * - KT-CT-3945: Unable to allow a repayment to be submitted.
	 * - KT-CT-3950: The provided reason text is too long.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	allowRepaymentSubmission?: Maybe<AllowRepaymentSubmission>;
	/**
	 * Amend an existing payment.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-3924: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	amendPayment?: Maybe<AmendPayment>;
	/**
	 * Approve a repayment.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-3934: Repayment request already approved.
	 * - KT-CT-3935: Repayment request cannot be paid.
	 * - KT-CT-3959: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	approveRepayment?: Maybe<ApproveRepayment>;
	assignInkBucket?: Maybe<AssignInkBucketPayload>;
	/**
	 * Look up an event to perform from its event_id, and return the next action to perform.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-8002: No event found.
	 * - KT-CT-8003: Event has no execute function.
	 * - KT-CT-8004: Error executing event in the backend.
	 * - KT-CT-8007: Incorrect or missing parameters for backend screen event.
	 * - KT-GB-9310: Account ineligible for joining Octoplus.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	backendScreenEvent?: Maybe<BackendScreenEvent>;
	/**
	 * Block a repayment from being submitted.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-3944: Account repayment does not exist.
	 * - KT-CT-3946: Unable to block a repayment from being submitted.
	 * - KT-CT-3950: The provided reason text is too long.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	blockRepaymentSubmission?: Maybe<BlockRepaymentSubmission>;
	/**
	 * Cancel amperage change request.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4501: Unauthorized.
	 * - KT-JP-4501: Unauthorized.
	 * - KT-JP-4502: Unauthorized.
	 * - KT-JP-4511: Invalid data.
	 * - KT-JP-4520: Unable to process request.
	 * - KT-JP-4515: Amperage change process not found.
	 * - KT-JP-4516: Unable to cancel amperage change process.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	cancelAmperageChangeRequest?: Maybe<CancelAmperageChangeRequest>;
	/**
	 * Cancel a leave supplier process.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-10304: Mutation not enabled in this environment.
	 * - KT-CT-10302: Invalid data.
	 * - KT-CT-10305: Failed to cancel leave supplier process - market actions are no longer cancellable.
	 * - KT-CT-10306: Failed to cancel leave supplier process - the cancellation workflow has not been configured.
	 * - KT-CT-10307: Failed to cancel leave supplier process - failed to cancel market actions.
	 * - KT-CT-10308: Failed to cancel leave supplier process.
	 * - KT-CT-10311: Failed to cancel leave supplier process. The process status is not in cancellable status.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	cancelLeaveSupplier: LeaveSupplierCancelled;
	/**
	 * Cancel an ongoing move out.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-4537: Move out cancel input data is invalid.
	 * - KT-JP-4501: Unauthorized.
	 * - KT-JP-4536: Move out active process not found.
	 * - KT-JP-4535: Move out cancel cannot self-service.
	 * - KT-JP-4101: Unauthorized.
	 * - KT-JP-4520: Unable to process request.
	 * - KT-JP-4538: Move out process not withdrawable.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	cancelMoveOut?: Maybe<CancelMoveOut>;
	/**
	 * Cancel an in-flight payment.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-3924: Unauthorized.
	 * - KT-CT-3954: Payment cancellation failed.
	 * - KT-CT-3955: Payment cannot be cancelled.
	 * - KT-CT-3956: Temporary error occurred.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	cancelPayment?: Maybe<CancelPayment>;
	/**
	 * Cancel a repayment or refund request.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4231: Unauthorized.
	 * - KT-CT-3930: The repayment or refund request does not exist.
	 * - KT-CT-3931: This repayment or refund request cannot be cancelled.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	cancelRepaymentRequest?: Maybe<CancelRepaymentRequest>;
	/**
	 * Close the Open Print Batch if any.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-9010: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	closeOpenPrintBatch: CloseOpenPrintBatch;
	/**
	 * Collect deposit for the given account.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4177: Unauthorized.
	 * - KT-CT-5711: No collection is required.
	 * - KT-CT-5712: Deposit agreement does not exist or has not been accepted.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	collectDeposit?: Maybe<CollectDeposit>;
	/**
	 * Attempt to collect a one-off payment. If an instruction type is provided and there is an existing payment instruction, the payment can be collected immediately. A request to collect a payment at a future date can also be made, in which case the instruction input type is not necessary, but an instruction must exist at the specified collection date for the payment to be collected successfully.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-3932: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	collectPayment?: Maybe<CollectPayment>;
	/**
	 * Add charge to an account.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-5211: The charge reason with the requested code is deprecated.
	 * - KT-CT-5212: The charge reason with the requested code does not exist.
	 * - KT-CT-5213: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createAccountCharge?: Maybe<CreateAccountCharge>;
	/**
	 * Add credit to an account.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-5315: Invalid data.
	 * - KT-CT-5314: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 * @deprecated The 'createAccountCredit' field is deprecated.
	 *
	 * Use postCredit mutation as it is ledger aware.
	 *
	 * - Marked as deprecated on 2022-07-04.
	 * - Will be removed on 2024-01-01.
	 */
	createAccountCredit?: Maybe<CreateAccountCredit>;
	/**
	 * Add a note to an account.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4123: Unauthorized.
	 * - KT-CT-4180: Account note must be a valid string.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createAccountNote?: Maybe<CreateAccountNote>;
	/**
	 * Replace an existing payment schedule with a new one that updates either the payment amount or payment day.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-3815: No active payment schedule found for this account.
	 * - KT-CT-3822: Unauthorized.
	 * - KT-CT-3923: Unauthorized.
	 * - KT-CT-3941: Invalid data.
	 * - KT-CT-3942: An unexpected error occurred.
	 * - KT-CT-3947: An unexpected error occurred.
	 * - KT-CT-3960: Invalid value for payment day.
	 * - KT-CT-3961: Cannot update plan-associated payment schedule.
	 * - KT-CT-3962: No new value provided to update payment schedule.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createAccountPaymentSchedule?: Maybe<CreateAccountPaymentSchedule>;
	/**
	 * Create an account reference.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4123: Unauthorized.
	 * - KT-CT-8310: Invalid data.
	 * - KT-CT-8311: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createAccountReference?: Maybe<CreateAccountReference>;
	/**
	 * Create an account reminder.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1401: Invalid data.
	 * - KT-CT-1402: Unable to create account reminder.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createAccountReminder?: Maybe<CreateAccountReminder>;
	/**
	 * Create an affiliate link for a new sales agent.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-7711: Invalid data.
	 * - KT-CT-7713: Invalid data.
	 * - KT-CT-7714: Invalid data.
	 * - KT-CT-7715: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createAffiliateLink: CreateAffiliateLink;
	/**
	 * Create an affiliate organisation.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-7716: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createAffiliateOrganisation: CreateAffiliateOrganisation;
	/** Create a session for an affiliate link. */
	createAffiliateSession: CreateAffiliateSession;
	/**
	 * Mutation to create a new APICall instance.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-7803: Received an invalid apiExceptionId.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createApiCall?: Maybe<CreateApiCall>;
	/**
	 * Mutation to create a new APIException instance.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-7801: Received an invalid operationsTeamId.
	 * - KT-CT-7802: The external identifier already exists.
	 * - KT-CT-7805: Too many tags associated with this API Exception.
	 * - KT-CT-7806: Cannot create duplicate tags for the same API exception.
	 * - KT-CT-7811: Received an invalid assignedUserId.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createApiException?: Maybe<CreateApiException>;
	/**
	 * Mutation to create a new APIExceptionEvent instance.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-7803: Received an invalid apiExceptionId.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createApiExceptionEvent?: Maybe<CreateApiExceptionEvent>;
	/**
	 * Mutation to create a new APIExceptionNote instance.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-7803: Received an invalid apiExceptionId.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createApiExceptionNote?: Maybe<CreateApiExceptionNote>;
	/** Creates a new bank transfer payment method for a business account. */
	createBankTransferPaymentMethod: CreateBankTransferPaymentMethodResponse;
	/**
	 * Create a contribution agreement for an account.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4123: Unauthorized.
	 * - KT-CT-9601: Invalid data.
	 * - KT-CT-9602: Unable to create contribution agreement.
	 * - KT-CT-9605: Contribution amount cannot be 0 or negative.
	 * - KT-CT-9606: Scheme is not accepting contributions at this time.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createContributionAgreement?: Maybe<CreateContributionAgreement>;
	/**
	 * Create a new deposit agreement for the account if it needs one.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4177: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createDepositAgreement?: Maybe<CreateDepositAgreement>;
	/**
	 * Create a quote.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-4608: Unable to get matched products.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createElectricityQuote?: Maybe<CreateElectricityQuote>;
	/**
	 * Create an external account event.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-7123: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createExternalAccountEvent?: Maybe<CreateExternalAccountEvent>;
	/**
	 * Create a "form submission" entity. This is only meant to be used as a quick way of putting together a form and submit data for it, in the form of JSON - it is not expected that all form submissions will come through this path.
	 *
	 * This field requires the `Authorization` header to be set.
	 *
	 *
	 */
	createFormSubmission?: Maybe<FormSubmissionOuput>;
	/**
	 * Create a goods purchase.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-8206: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createGoodsPurchase?: Maybe<CreateGoodsPurchase>;
	/**
	 * Create a goods quote.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-8202: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createGoodsQuote?: Maybe<CreateGoodsQuote>;
	/**
	 * Create a goods quote without an account.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-8202: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createGoodsQuoteWithoutAccount?: Maybe<CreateGoodsQuoteWithoutAccount>;
	/** Register an Ink inbound message. */
	createInkInboundMessage?: Maybe<CreateInkInboundMessagePayload>;
	/** Upload file with account number, gpin or both. */
	createJpnFileAttachment: CreateJpnFileAttachmentPayload;
	/** Creates a one-time konbini payment. */
	createKonbiniPayment: CreateKonbiniPaymentResponse;
	/** Creates a new Stripe Konbini payment method in kraken. */
	createKonbiniPaymentMethod: CreateKonbiniPaymentMethodResponse;
	/** Mutation to accept T&Cs for customers that are onboarded through Lifeline Agencies. */
	createLifelineAgencyQuote?: Maybe<CreateLifelineAgencyQuote>;
	/**
	 * Create metadata on an object.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-8412: Invalid data.
	 * - KT-CT-8414: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createMetadata?: Maybe<CreateMetadata>;
	/**
	 * Create a lead.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-8901: Unable to create lead.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createNewLead?: Maybe<CreateNewLead>;
	/**
	 * Create or update a loyalty card for the given account user.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-5412: No account user exists with the given id.
	 * - KT-CT-8610: Invalid data.
	 * - KT-CT-8611: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createOrUpdateLoyaltyCard?: Maybe<CreateOrUpdateLoyaltyCardMutation>;
	/** Creates a new payment method in Kraken as well as a new customer in GMO. */
	createPaymentMethod?: Maybe<CreatePaymentMethod>;
	/** Creates a new fixed payment schedule. */
	createPaymentSchedule: CreatePaymentScheduleResponse;
	/**
	 * Mutation to create a new Portfolio instance.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-9402: Received an invalid brandCode.
	 * - KT-CT-9401: Received an invalid operationsTeamId.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createPortfolio?: Maybe<CreatePortfolio>;
	/**
	 * Mutation to create a new portfolio user role. This will effectively link the user to the portfolio giving them all the permissions enabled for the specific role.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-9403: Received an invalid portfolioId.
	 * - KT-CT-9404: Received an invalid accountUserId.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createPortfolioUserRole?: Maybe<CreatePortfolioUserRole>;
	/**
	 * Create a referral using an email address, personal link or code.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-6723: Unauthorized.
	 * - KT-CT-6710: Unable to create referral.
	 * - KT-CT-6711: Accounts may not self-refer.
	 * - KT-CT-6713: Referring and referred account brands do not match.
	 * - KT-CT-6712: Invalid reference.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	createReferral?: Maybe<CreateReferral>;
	/** Create a shell/payment account. */
	createShellAccount?: Maybe<CreateShellAccountPayload>;
	/**
	 * Delete an account reference.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4123: Unauthorized.
	 * - KT-CT-8310: Invalid data.
	 * - KT-CT-8312: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	deleteAccountReference?: Maybe<DeleteAccountReference>;
	/**
	 * Delete a device token used for push notifications.
	 *
	 *
	 *
	 * This field requires the `Authorization` header to be set.
	 *
	 *
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-5411: Invalid token or no push notification binding found for the given account user.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	deletePushNotificationBinding?: Maybe<DeletePushNotificationBinding>;
	/**
	 * Email quote
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-4609: Terms and conditions not found.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	emailQuote?: Maybe<EmailQuote>;
	/**
	 * End a contribution agreement for an account.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-9603: Unable to find contribution agreement.
	 * - KT-CT-4123: Unauthorized.
	 * - KT-CT-9604: Unable to end contribution agreement.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	endContributionAgreement?: Maybe<EndContributionAgreement>;
	/**
	 * Initiate an enrollment for an account and a set of supply points.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-10312: Mutation not enabled in this environment.
	 * - KT-CT-4501: Unauthorized.
	 * - KT-CT-1602: Serializer validation error.
	 * - KT-CT-10301: Unable to instigate leave supplier process.
	 * - KT-CT-10309: Failed to update leave supplier process - the service is not enabled.
	 * - KT-CT-10310: Failed to update leave supplier process. The process status is not in updatable status.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	enrollment: EnrollmentInitiated;
	/** Returns an estimation of average monthly electricity usage in kWh */
	estimateUsage?: Maybe<EstimateUsage>;
	/**
	 * Poll OCCTO using the Generation Point Identification Number.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-4602: Quoted electricity supply points cannot be found.
	 * - KT-JP-4606: GPIN does not exist on OCCTO.
	 * - KT-JP-4603: OCCTO could not process the request.
	 * - KT-JP-4607: An unexpected error occurred when trying to fetch GPIN details.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	fetchElectricityGenerationPointDetails?: Maybe<FetchElectricityGenerationPointDetails>;
	/**
	 * Poll OCCTO using the Supply Point Identification Number.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-4602: Quoted electricity supply points cannot be found.
	 * - KT-JP-4604: SPIN does not exist on OCCTO.
	 * - KT-JP-4603: OCCTO could not process the request.
	 * - KT-JP-4605: An unexpected error occurred when trying to fetch SPIN details.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	fetchElectricitySupplyPointDetails?: Maybe<FetchElectricitySupplyPointDetails>;
	/**
	 * Force users of Kraken Tokens and refresh tokens issued to the viewer to reauthenticate.
	 *
	 * Calling this mutation will cause all Kraken Tokens and refresh tokens issued to the authenticated viewer before the mutation was called to become invalid.
	 */
	forceReauthentication?: Maybe<ForceReauthentication>;
	/** Generate a token for storing credit card details. */
	generateCreditCardToken?: Maybe<GenerateCreditCardToken>;
	/**
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-7620: Channel not supported.
	 * - KT-CT-7618: Unable to process message.
	 * - KT-CT-7624: Error when generating the presigned URL.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	generateInkPresignedUrl?: Maybe<GenerateInkPresignedUrl>;
	/**
	 * Generate a pre-signed token with a set expiry time.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1128: Unauthorized.
	 * - KT-CT-1120: The Kraken Token has expired.
	 * - KT-CT-1131: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	generatePreSignedToken?: Maybe<GeneratePreSignedToken>;
	/**
	 * Get the client secret needed to create a new payment instruction using an embedded form.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4177: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	getEmbeddedSecretForNewPaymentInstruction?: Maybe<GetEmbeddedSecretForNewPaymentInstruction>;
	/**
	 * Get the external URL where the user can set up a payment instruction.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1128: Unauthorized.
	 * - KT-CT-3822: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	getHostedUrlForNewPaymentInstruction?: Maybe<GetHostedUrlForNewPaymentInstruction>;
	/**
	 * Initiate amperage change procedure.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-4542: Amperage change date cannot be in the past.
	 * - KT-JP-4101: Unauthorized.
	 * - KT-JP-4501: Unauthorized.
	 * - KT-JP-4102: Unauthorized.
	 * - KT-JP-4543: Initiate amperage change input data is invalid.
	 * - KT-JP-4544: Initiate amperage change cannot self-service.
	 * - KT-JP-4545: Unavailable amperage value.
	 * - KT-JP-4546: Process already exists.
	 * - KT-JP-1501: Active agreement not found.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	initiateAmperageChange: InitiateAmperageChangeResponse;
	/**
	 * Initiate a standalone payment and return the url where the customer can complete it.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1128: Unauthorized.
	 * - KT-CT-3822: Unauthorized.
	 * - KT-CT-3943: Invalid ledger.
	 * - KT-CT-3957: No collection method provided.
	 * - KT-CT-3958: Provide either ledger ID or ledger number.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	initiateHostedStandalonePayment?: Maybe<InitiateHostedStandalonePayment>;
	/** Initiate move in procedure. */
	initiateMoveIn: InitiateMoveInResponse;
	/**
	 * Initiate move out procedure.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-4551: Move out can't be processed.
	 * - KT-JP-6601: Active move-in request already exists.
	 * - KT-JP-1501: Active agreement not found.
	 * - KT-JP-7801: Unknown error occurred.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	initiateMoveOut: InitiateMoveOutResponse;
	/**
	 * Initiate a synchronous move-out and move-in.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-4101: Unauthorized.
	 * - KT-JP-6601: Active move-in request already exists.
	 * - KT-JP-1501: Active agreement not found.
	 * - KT-JP-7801: Unknown error occurred.
	 * - KT-CT-4411: Address in db is structured, cannot accept unstructured address fields.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	initiateMoveOutAndMoveIn: InitiateMoveOutAndMoveInResponse;
	/**
	 * Do a product switch for a user.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-4619: Quote with given code not found.
	 * - KT-CT-4624: Unable to accept the given product code.
	 * - KT-CT-4626: No product selected for the given quote code.
	 * - KT-CT-4719: No supply point found for identifier provided.
	 * - KT-CT-4922: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	initiateProductSwitch?: Maybe<InitiateProductSwitch>;
	/**
	 * Initiate a standalone payment and return the client secret required to complete it.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4177: Unauthorized.
	 * - KT-CT-3943: Invalid ledger.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	initiateStandalonePayment?: Maybe<InitiateStandalonePayment>;
	/** Create an account and initiate a switch-in for the account. */
	initiateSwitchIn?: Maybe<InitiateSwitchIn>;
	/**
	 * Instigate a leave supplier process or update an existing process.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-10304: Mutation not enabled in this environment.
	 * - KT-CT-4501: Unauthorized.
	 * - KT-CT-1602: Serializer validation error.
	 * - KT-JP-8801: Supply point not found.
	 * - KT-CT-10301: Unable to instigate leave supplier process.
	 * - KT-CT-10309: Failed to update leave supplier process - the service is not enabled.
	 * - KT-CT-10310: Failed to update leave supplier process. The process status is not in updatable status.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	instigateLeaveSupplier: LeaveSupplierInstigated;
	/**
	 * Invalidate an existing instruction.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-3926: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	invalidatePaymentInstruction?: Maybe<InvalidatePaymentInstruction>;
	/**
	 * Invalidate a previously-issued pre-signed token.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1129: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	invalidatePreSignedToken?: Maybe<InvalidatePreSignedToken>;
	/**
	 * Invalidate pre-signed tokens issued to a particular user.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1129: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	invalidatePreSignedTokensForUser?: Maybe<InvalidatePreSignedTokensForUser>;
	/**
	 * Invalidate a previously-issued refresh token.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1130: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	invalidateRefreshToken?: Maybe<InvalidateRefreshToken>;
	/**
	 * Invalidate refresh tokens issued to a particular user.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1128: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	invalidateRefreshTokensForUser?: Maybe<InvalidateRefreshTokensForUser>;
	/** Accept terms and conditions for a join supplier process. */
	joinSupplierAcceptTermsAndConditions?: Maybe<JoinSupplierAcceptTermsAndConditionsResult>;
	/** Link an account user and line user together. */
	linkUserToLine: LinkUserToLineResponse;
	/**
	 * Mark the print batch as processed.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-9011: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	markPrintBatchAsProcessed: MarkPrintBatchAsProcessed;
	/** Provide a temporary token to get an auth token. This is intended to allow support users to view customer data through the brand interface. */
	masqueradeAuthentication?: Maybe<MasqueradeAuthentication>;
	/**
	 * Create a Kraken Token (JWT) for authentication.
	 *
	 * Provide the required input fields to obtain the token.
	 *
	 * The token should be used as the `Authorization` header for any authenticated requests.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1135: Invalid data.
	 * - KT-CT-1134: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	obtainKrakenToken?: Maybe<ObtainKrakenJsonWebToken>;
	/**
	 * For authorized third-party organizations only.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1120: The Kraken Token has expired.
	 * - KT-CT-1121: Please use Kraken Token to issue long-lived refresh tokens.
	 * - KT-CT-1132: Unauthorized.
	 * - KT-CT-1122: Long-lived refresh tokens can only be issued for account users.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	obtainLongLivedRefreshToken?: Maybe<ObtainLongLivedRefreshToken>;
	/**
	 * Post credit to a ledger.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-5316: Invalid data.
	 * - KT-CT-5311: The credit reason with the requested code is deprecated.
	 * - KT-CT-5312: The credit reason with the requested code does not exist.
	 * - KT-CT-5313: An error occurred whilst posting the credit.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	postCredit?: Maybe<PostCredit>;
	/**
	 * Prepare account for sign up. Returns the existing account and/or user if matching datafound for the provided input, otherwise creates a new account and account user.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-10303: Mutation not enabled in this environment.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	prepareAccount: PrepareAccountResult;
	/**
	 * Publish a trigger within the transactional messaging service.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-9901: Invalid trigger type code.
	 * - KT-CT-9902: Invalid trigger type params.
	 * - KT-CT-9903: Trigger type cannot be published externally.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	publishTransactionalMessagingTrigger?: Maybe<PublishTransactionalMessagingTrigger>;
	/**
	 * Record the customer's acceptance of a deposit agreement.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4177: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	recordDepositAgreementAccepted?: Maybe<RecordDepositAgreementAccepted>;
	/**
	 * Redeem the passed number of Loyalty Points as account credit.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-9201: No Loyalty Point ledger found for the user.
	 * - KT-CT-9202: Loyalty Points adapter not configured.
	 * - KT-CT-9203: No ledger entries for the ledger.
	 * - KT-CT-9205: Insufficient Loyalty Points.
	 * - KT-CT-9206: Indivisible points.
	 * - KT-CT-9204: Negative or zero points set.
	 * - KT-CT-9208: Invalid posted at datetime.
	 * - KT-CT-9209: Negative Loyalty Points balance.
	 * - KT-CT-9210: Unhandled Loyalty Points exception.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	redeemLoyaltyPointsForAccountCredit?: Maybe<RedeemLoyaltyPointsForAccountCredit>;
	/**
	 * Redeem the referral claim code from certain referral scheme.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-6723: Unauthorized.
	 * - KT-CT-6724: Referral claim code not found.
	 * - KT-CT-6725: Referral claim code redeeming error.
	 * - KT-CT-6726: Referral claim code has already been redeemed.
	 * - KT-CT-6727: Referral claim code is not available.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	redeemReferralClaimCode?: Maybe<RedeemReferralClaimCode>;
	/**
	 * Refund a cleared payment.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-3924: Unauthorized.
	 * - KT-CT-3928: Idempotency key used for another repayment request.
	 * - KT-CT-3929: The payment is not in a refundable state.
	 * - KT-CT-3933: Refund amount greater than payment amount.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	refundPayment?: Maybe<RefundPayment>;
	/** Regenerate the live secret key for the authenticated user. */
	regenerateSecretKey?: Maybe<RegenerateSecretKey>;
	/** Register the account for participating in a Demand Response campaign. */
	registerDemandResponseCampaignParticipant: RegisterDemandResponseCampaignParticipantResponse;
	/**
	 * Register a device token to be used for push notifications for an app.
	 *
	 *
	 *
	 * This field requires the `Authorization` header to be set.
	 *
	 *
	 */
	registerPushNotificationBinding?: Maybe<RegisterPushNotificationBinding>;
	/**
	 * Request amperage change as an organization.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-4511: Invalid data.
	 * - KT-CT-4501: Unauthorized.
	 * - KT-JP-4501: Unauthorized.
	 * - KT-JP-4502: Unauthorized.
	 * - KT-JP-4512: Unable to process amperage change request.
	 * - KT-JP-4513: Unable to process amperage change request.
	 * - KT-JP-4520: Unable to process request.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	requestAmperageChange?: Maybe<RequestAmperageChange>;
	/**
	 * Provide the email address of an account user to send them an email with instructions on how to reset their password.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1133: Unable to request password reset email.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	requestPasswordReset?: Maybe<RequestPasswordResetOutputType>;
	/** Reset the password of an account user indicated by the userId to the value supplied. */
	resetPassword?: Maybe<ResetPasswordMutationPayload>;
	/**
	 * Reset the password of an account user.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4125: Unauthorized.
	 * - KT-CT-1132: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	resetUserPassword?: Maybe<ResetUserPasswordOutput>;
	/** Revert the product switch. */
	revertProductSwitch?: Maybe<RevertProductSwitch>;
	/**
	 * Revoke an agreement.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4123: Unauthorized.
	 * - KT-CT-1501: Agreement not found.
	 * - KT-CT-1502: Billed agreements cannot be revoked.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	revokeAgreement?: Maybe<RevokeAgreement>;
	/**
	 * Mutation to save onboarding journey progress.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4122: Invalid email.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	saveOnboardingJourneyProgress?: Maybe<SaveOnboardingJourneyProgress>;
	/**
	 * Schedule a quote follow-up to the provided recipient.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4619: Quote with given code not found.
	 * - KT-CT-4632: Invalid recipient information.
	 * - KT-CT-4633: Mutation not enabled in this environment.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	scheduleQuoteFollowUp?: Maybe<ScheduleQuoteFollowUp>;
	/**
	 * Self-serve switch-in correction.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-4547: Switch-in correction input data is invalid.
	 * - KT-JP-4548: Switch-in request not found.
	 * - KT-JP-4101: Unauthorized.
	 * - KT-JP-4549: Switch-in correction action unavailable.
	 * - KT-JP-4550: Switch-in correction invalid request.
	 * - KT-JP-4520: Unable to process request.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	selfServeSwitchInCorrection: SwitchInCorrectionResponse;
	/**
	 * Mutation to send family switch in offer to the customer.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4123: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	sendFamilySwitchInOffer?: Maybe<SendFamilySwitchInOffer>;
	/**
	 * Send a quote summary to the provided recipient.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4619: Quote with given code not found.
	 * - KT-CT-4632: Invalid recipient information.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	sendQuoteSummary?: Maybe<SendQuoteSummary>;
	/**
	 * Set the Loyalty Point user for the account.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-9210: Unhandled Loyalty Points exception.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	setLoyaltyPointsUser?: Maybe<SetLoyaltyPointsUser>;
	/**
	 * Set up a new direct debit instruction.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-3940: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	setUpDirectDebitInstruction?: Maybe<SetUpDirectDebitInstruction>;
	/**
	 * Share a goods quote.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4122: Invalid email.
	 * - KT-CT-8203: Received an invalid quote code.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	shareGoodsQuote?: Maybe<ShareGoodsQuote>;
	/** Store new bank account details. */
	storeBankAccountDetails?: Maybe<StoreBankAccountDetails>;
	/**
	 * Store a new payment instruction created through the embedded process.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4177: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	storePaymentInstruction?: Maybe<StorePaymentInstruction>;
	/** Stores bank account details for refunds. */
	storeRefundBankAccount: StoreRefundBankAccountResponse;
	/**
	 * Submit customer feedback.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-5514: Unable to submit feedback.
	 * - KT-CT-5511: The feedback_id should be provided for feedback source.
	 * - KT-CT-5512: The feedback doesn't match the account.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	submitCustomerFeedback?: Maybe<SubmitCustomerFeedback>;
	/**
	 * Submit a repayment request.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1132: Unauthorized.
	 * - KT-CT-3927: Invalid Amount.
	 * - KT-CT-3928: Idempotency key used for another repayment request.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	submitRepaymentRequest?: Maybe<SubmitRepaymentRequest>;
	/** Suggests a fixed payment schedule payment amount. */
	suggestFixedPaymentAmount: SuggestFixedPaymentAmountResponse;
	/**
	 * Switch to the given product code on the next reading date.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-4901: Invalid data.
	 * - KT-JP-4902: Unable to update agreement product.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	switchToProductOnNextReadingDate?: Maybe<SwitchToProductOnNextReadingDate>;
	/** Switch to the product with fixed fuel cost adjustment tariff. */
	switchToProductWithFixedFuelCostAdjustment: SwitchToProductWithFixedFuelCostAdjustmentResponse;
	/**
	 * Transfer value from a source ledger to a destination ledger. This decreases the balance of the source ledger by the given amount and increases the balance of the destination ledger by the same amount. If the amount is negative, the effect is reversed (the source ledger's balance increases and the destination ledger's balance decreases).
	 *
	 *
	 *
	 * This field requires the `Authorization` header to be set.
	 *
	 *
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-3822: Unauthorized.
	 * - KT-CT-3823: Unauthorized.
	 * - KT-CT-9701: Balance transfer to same account is not allowed.
	 * - KT-CT-9702: Balance transfer is not support for debit account with Zero balance.
	 * - KT-CT-9703: Balance transfer is not supported for debit account.
	 * - KT-CT-9704: Balance transfer amount should be non-zero.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	transferLedgerBalance?: Maybe<TransferLedgerBalance>;
	/**
	 * Transfer Loyalty Point from one account user to another.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-9205: Insufficient Loyalty Points.
	 * - KT-CT-9204: Negative or zero points set.
	 * - KT-CT-9208: Invalid posted at datetime.
	 * - KT-CT-9209: Negative Loyalty Points balance.
	 * - KT-CT-9210: Unhandled Loyalty Points exception.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	transferLoyaltyPointsBetweenUsers?: Maybe<TransferLoyaltyPointsBetweenUsers>;
	/**
	 * Send file to virus scanner on upload.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-7802: File cannot be found with given s3 key.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	triggerPostUploadOperationsJpn: TriggerPostUploadOperationsJpn;
	/** Unlink an account user and line together. */
	unlinkUserFromLine: UnlinkUserFromLineResponse;
	/**
	 * Update the account billing address.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4145: Invalid address.
	 * - KT-CT-7123: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateAccountBillingAddress?: Maybe<UpdateAccountBillingAddress>;
	/**
	 * Update account billing email.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4123: Unauthorized.
	 * - KT-CT-4122: Invalid email.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateAccountBillingEmail?: Maybe<UpdateAccountBillingEmail>;
	/** Update the account user consent data. */
	updateAccountConsentData?: Maybe<UpdateAccountConsentData>;
	/**
	 * Update an account reference.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4123: Unauthorized.
	 * - KT-CT-8310: Invalid data.
	 * - KT-CT-8311: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateAccountReference?: Maybe<UpdateAccountReference>;
	/**
	 * Update the account user details of the authenticated user. Only one field can be updated per day. This prevents users from switching accounts to someone else (usually when moving homes) All account changes should be handled by operations or the move out journey. New customers are exempt from this rule for the first 31 days.
	 *
	 *
	 *
	 * This field requires the `Authorization` header to be set.
	 *
	 *
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-5414: Invalid data.
	 * - KT-JP-5401: Account user details not found.
	 * - KT-JP-5402: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateAccountUser?: Maybe<UpdateAccountUser>;
	/**
	 * Update the account user details of the user with the given user id. This mutation does not have the one-field-per-day limitation, like the updateAccountUser does, and it requires a special permission to be used.
	 *
	 *
	 *
	 * This field requires the `Authorization` header to be set.
	 *
	 *
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-5414: Invalid data.
	 * - KT-JP-5401: Account user details not found.
	 * - KT-JP-5402: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateAccountUserByUserId?: Maybe<UpdateAccountUserByUserId>;
	/**
	 * Update an active purchase.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-8225: Received an invalid purchaseId.
	 * - KT-CT-8226: The provided purchase is not active.
	 * - KT-CT-8206: Invalid data.
	 * - KT-CT-8227: Available grants could not be applied.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateActivePurchase?: Maybe<UpdateActivePurchase>;
	/**
	 * Update an existing affiliate link.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-7711: Invalid data.
	 * - KT-CT-7713: Invalid data.
	 * - KT-CT-7714: Invalid data.
	 * - KT-CT-7715: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateAffiliateLink: UpdateAffiliateLink;
	/**
	 * Update an existing affiliate organisation.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-7717: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateAffiliateOrganisation: UpdateAffiliateOrganisation;
	/**
	 * Update the period of an agreement.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4178: No account found with given account number.
	 * - KT-CT-1501: Agreement not found.
	 * - KT-CT-1503: Agreement valid_to date must be later than valid_from date.
	 * - KT-CT-1504: Account does not match with the agreement.
	 * - KT-CT-1505: Unable to edit agreement.
	 * - KT-CT-1506: Agreement period is not within the supply and property period.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateAgreementPeriod?: Maybe<UpdateAgreementPeriod>;
	/**
	 * Update an amperage change request as an organization.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-4511: Invalid data.
	 * - KT-CT-4501: Unauthorized.
	 * - KT-JP-4501: Unauthorized.
	 * - KT-JP-4502: Unauthorized.
	 * - KT-JP-4515: Amperage change process not found.
	 * - KT-JP-4517: Unable to update amperage change process.
	 * - KT-JP-4503: Unable to communicate with industry organization.
	 * - KT-JP-4520: Unable to process request.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateAmperageChangeRequest?: Maybe<UpdateAmperageChangeRequest>;
	/**
	 * Mutation to update an existing APIException instance.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-7804: No fields present in the input for updating the APIException.
	 * - KT-CT-7803: Received an invalid apiExceptionId.
	 * - KT-CT-7809: Update results in no changes to API Exception.
	 * - KT-CT-7805: Too many tags associated with this API Exception.
	 * - KT-CT-7806: Cannot create duplicate tags for the same API exception.
	 * - KT-CT-7801: Received an invalid operationsTeamId.
	 * - KT-CT-7811: Received an invalid assignedUserId.
	 * - KT-CT-7812: Support user is inactive.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateApiException?: Maybe<UpdateApiException>;
	/**
	 * Mutation to update an existing APIExceptionNote instance.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-7807: Received an invalid apiExceptionNoteId.
	 * - KT-CT-7808: Unauthorized.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateApiExceptionNote?: Maybe<UpdateApiExceptionNote>;
	/**
	 * Change the auto top up amount for the payment schedule.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-3815: No active payment schedule found for this account.
	 * - KT-CT-3941: Invalid data.
	 * - KT-CT-3942: An unexpected error occurred.
	 * - KT-CT-3947: An unexpected error occurred.
	 * - KT-CT-3953: The payment schedule is not a balance triggered schedule.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateAutoTopUpAmount?: Maybe<UpdateAutoTopUpAmount>;
	/** Store updated bank account details. */
	updateBankAccountDetails?: Maybe<UpdateBankAccountDetails>;
	/**
	 * Update account communication delivery preference.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-4123: Unauthorized.
	 * - KT-CT-4136: Cannot set comms preference to email when account has no email.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateCommsDeliveryPreference?: Maybe<UpdateCommsDeliveryPreference>;
	/** Update the comms preferences of the account user (the authenticated user). */
	updateCommsPreferences?: Maybe<UpdateAccountUserCommsPreferencesMutationPayload>;
	/** Updates credit card details for an account. */
	updateCreditCardDetails?: Maybe<UpdateCreditCardDetails>;
	/** Update comms preferences for a campaign participant. */
	updateDemandResponseCampaignParticipantCommsPreferences: DemandResponseCampaignParticipantCommsPreferencesResponse;
	/** Update a tip value for a campaign participant. */
	updateDemandResponseCampaignParticipantTip: UpdateDemandResponseCampaignParticipantTipResponse;
	updateMessageTags?: Maybe<UpdateMessageTagsPayload>;
	/**
	 * Update metadata on an object.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-8413: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateMetadata?: Maybe<UpdateMetadata>;
	/** Update a supply point's move-in date. */
	updateMoveInDate?: Maybe<UpdateMoveInDate>;
	/**
	 * Update a supply point's move-out date.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-4539: Move out date update input data is invalid.
	 * - KT-JP-4501: Unauthorized.
	 * - KT-JP-4536: Move out active process not found.
	 * - KT-JP-4540: Move out date update cannot self-service.
	 * - KT-JP-4101: Unauthorized.
	 * - KT-JP-4541: Not allowed to update move out process.
	 * - KT-JP-4520: Unable to process request.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	updateMoveOutDate?: Maybe<UpdateMoveOutDate>;
	/**
	 * Update password of the authenticated user
	 *
	 *
	 *
	 * This field requires the `Authorization` header to be set.
	 *
	 *
	 */
	updatePassword?: Maybe<UpdatePassword>;
	/**
	 * Update the account user details of the authenticated user.
	 *
	 * Only one name field can be updated per day, other fields can be updated freely. This prevents users from switching accounts to someone else (usually when moving homes). All account changes should be handled by operations or the move out journey. New customers are exempt from this rule for the first 31 days.
	 *
	 *
	 *
	 * This field requires the `Authorization` header to be set.
	 *
	 *
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-5413: Invalid data.
	 * - KT-CT-5414: Invalid data.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	updateUser?: Maybe<UpdateUserMutation>;
	/**
	 * **DEPRECATED: Please use updateUser instead**
	 *
	 * Update the account user details of the authenticated user. Only one field can be updated per day. This prevents users from switching accounts to someone else (usually when moving homes) All account changes should be handled by operations or the move out journey. New customers are exempt from this rule for the first 31 days.
	 * @deprecated The 'updateUserDetails' field is deprecated.
	 *
	 *
	 * Please use the 'updateUser' mutation instead.
	 *
	 *
	 * - Marked as deprecated on 2020-10-02.
	 * - Will be removed on 2023-04-06.
	 */
	updateUserDetails?: Maybe<UpdateAccountUserMutationPayload>;
	/** Validate user's email address. */
	validateEmail?: Maybe<ValidateEmail>;
	/** Validate user's phone number. */
	validatePhone?: Maybe<ValidatePhone>;
	/**
	 * Verify user's email address.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 * - KT-CT-1111: Unauthorized.
	 * - KT-CT-1112: 'Authorization' header not provided.
	 *
	 */
	verifyEmail?: Maybe<VerifyEmail>;
	/**
	 * Provide identifying information about an account and user to get a scoped token that will permit access to associate an email address with the account’s user.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-CT-1145: Account/user details do not match.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	verifyIdentity?: Maybe<VerifyIdentity>;
	/**
	 * Withdraw an account.
	 *
	 * The possible errors that can be raised are:
	 *
	 * - KT-JP-7801: Unknown error occurred.
	 * - KT-CT-1113: Disabled GraphQL field requested.
	 *
	 */
	withdrawAccount?: Maybe<WithdrawAccount>;
};

export type MutationAcceptGoodsQuoteArgs = {
	input: AcceptGoodsQuoteInput;
};

export type MutationAddCampaignToAccountArgs = {
	input: AddCampaignToAccountInput;
};

export type MutationAllowRepaymentSubmissionArgs = {
	input: RepaymentInput;
};

export type MutationAmendPaymentArgs = {
	input: AmendPaymentInput;
};

export type MutationApproveRepaymentArgs = {
	input: ApproveRepaymentInput;
};

export type MutationAssignInkBucketArgs = {
	input: AssignInkBucketInput;
};

export type MutationBackendScreenEventArgs = {
	input: BackendScreenEventInput;
};

export type MutationBlockRepaymentSubmissionArgs = {
	input: RepaymentInput;
};

export type MutationCancelAmperageChangeRequestArgs = {
	input?: InputMaybe<CancelAmperageChangeRequestInput>;
};

export type MutationCancelLeaveSupplierArgs = {
	input: CancelLeaveSupplierInput;
};

export type MutationCancelMoveOutArgs = {
	input: CancelMoveOutInput;
};

export type MutationCancelPaymentArgs = {
	input: CancelPaymentInput;
};

export type MutationCancelRepaymentRequestArgs = {
	input: CancelRepaymentRequestInputType;
};

export type MutationCollectDepositArgs = {
	input: CollectDepositInput;
};

export type MutationCollectPaymentArgs = {
	input: CollectPaymentInput;
};

export type MutationCreateAccountChargeArgs = {
	input: CreateAccountChargeInput;
};

export type MutationCreateAccountCreditArgs = {
	input: CreateAccountCreditInput;
};

export type MutationCreateAccountNoteArgs = {
	input: CreateAccountNoteInput;
};

export type MutationCreateAccountPaymentScheduleArgs = {
	input: CreateAccountPaymentScheduleInput;
};

export type MutationCreateAccountReferenceArgs = {
	input: AccountReferenceInput;
};

export type MutationCreateAccountReminderArgs = {
	input: CreateAccountReminderInput;
};

export type MutationCreateAffiliateLinkArgs = {
	input: CreateAffiliateLinkInputType;
};

export type MutationCreateAffiliateOrganisationArgs = {
	input: CreateAffiliateOrganisationInputType;
};

export type MutationCreateAffiliateSessionArgs = {
	input: CreateAffiliateSessionInputType;
};

export type MutationCreateApiCallArgs = {
	input: CreateApiCallInput;
};

export type MutationCreateApiExceptionArgs = {
	input: CreateApiExceptionInput;
};

export type MutationCreateApiExceptionEventArgs = {
	input: CreateApiExceptionEventInput;
};

export type MutationCreateApiExceptionNoteArgs = {
	input: CreateApiExceptionNoteInput;
};

export type MutationCreateBankTransferPaymentMethodArgs = {
	input: CreateBankTransferPaymentMethodInput;
};

export type MutationCreateContributionAgreementArgs = {
	input: CreateContributionAgreementInput;
};

export type MutationCreateDepositAgreementArgs = {
	input: CreateDepositAgreementInput;
};

export type MutationCreateElectricityQuoteArgs = {
	input: CreateElectricityQuoteInput;
};

export type MutationCreateExternalAccountEventArgs = {
	input: CreateExternalAccountEventInput;
};

export type MutationCreateFormSubmissionArgs = {
	input: FormSubmissionInput;
};

export type MutationCreateGoodsPurchaseArgs = {
	input: CreatePurchaseInput;
};

export type MutationCreateGoodsQuoteArgs = {
	input: CreateGoodsQuoteInput;
};

export type MutationCreateGoodsQuoteWithoutAccountArgs = {
	input: CreateGoodsQuoteWithoutAccountInput;
};

export type MutationCreateInkInboundMessageArgs = {
	input: CreateInkInboundMessageInput;
};

export type MutationCreateJpnFileAttachmentArgs = {
	input: CreateJpnFileAttachmentInput;
};

export type MutationCreateKonbiniPaymentArgs = {
	input: CreateKonbiniPaymentInput;
};

export type MutationCreateKonbiniPaymentMethodArgs = {
	input: CreateKonbiniPaymentMethodInput;
};

export type MutationCreateLifelineAgencyQuoteArgs = {
	input: CreateLifelineAgencyQuoteInput;
};

export type MutationCreateMetadataArgs = {
	input: MetadataInput;
};

export type MutationCreateNewLeadArgs = {
	input: NewLeadInputType;
};

export type MutationCreateOrUpdateLoyaltyCardArgs = {
	input: CreateOrUpdateLoyaltyCardInput;
};

export type MutationCreatePaymentMethodArgs = {
	input?: InputMaybe<CreatePaymentMethodInput>;
};

export type MutationCreatePaymentScheduleArgs = {
	input: PaymentScheduleInput;
};

export type MutationCreatePortfolioArgs = {
	input?: InputMaybe<CreatePortfolioInput>;
};

export type MutationCreatePortfolioUserRoleArgs = {
	input?: InputMaybe<CreatePortfolioUserRoleInput>;
};

export type MutationCreateReferralArgs = {
	input: CreateReferralInput;
};

export type MutationCreateShellAccountArgs = {
	input: CreateShellAccountInput;
};

export type MutationDeleteAccountReferenceArgs = {
	input: DeleteAccountReferenceInput;
};

export type MutationDeletePushNotificationBindingArgs = {
	input: DeletePushNotificationBindingInput;
};

export type MutationEmailQuoteArgs = {
	input: EmailQuoteInput;
};

export type MutationEndContributionAgreementArgs = {
	input: EndContributionAgreementInput;
};

export type MutationEnrollmentArgs = {
	input: EnrollmentInput;
};

export type MutationEstimateUsageArgs = {
	input: EstimateUsageInputType;
};

export type MutationFetchElectricityGenerationPointDetailsArgs = {
	input: FetchElectricityGenerationPointDetailsInput;
};

export type MutationFetchElectricitySupplyPointDetailsArgs = {
	input: FetchElectricitySupplyPointDetailsInput;
};

export type MutationForceReauthenticationArgs = {
	input: ForceReauthenticationInput;
};

export type MutationGenerateInkPresignedUrlArgs = {
	input?: InputMaybe<GenerateInkPresignedUrlInput>;
};

export type MutationGeneratePreSignedTokenArgs = {
	email: Scalars['String'];
	numberOfDaysAllowed: Scalars['Int'];
	scope: PreSignedTokenScope;
};

export type MutationGetEmbeddedSecretForNewPaymentInstructionArgs = {
	input: GetEmbeddedSecretForNewPaymentInstructionInput;
};

export type MutationGetHostedUrlForNewPaymentInstructionArgs = {
	input: GetHostedUrlForNewPaymentInstructionInput;
};

export type MutationInitiateAmperageChangeArgs = {
	input: InitiateAmperageChangeInput;
};

export type MutationInitiateHostedStandalonePaymentArgs = {
	input: InitiateHostedStandalonePaymentInput;
};

export type MutationInitiateMoveInArgs = {
	input: InitiateMoveInInput;
};

export type MutationInitiateMoveOutArgs = {
	input: InitiateMoveOutInput;
};

export type MutationInitiateMoveOutAndMoveInArgs = {
	input: InitiateMoveOutAndMoveInInput;
};

export type MutationInitiateProductSwitchArgs = {
	input: InitiateProductSwitchInput;
};

export type MutationInitiateStandalonePaymentArgs = {
	input: InitiateStandalonePaymentInput;
};

export type MutationInitiateSwitchInArgs = {
	input?: InputMaybe<InitiateSwitchInInput>;
};

export type MutationInstigateLeaveSupplierArgs = {
	input: LeaveSupplierInput;
};

export type MutationInvalidatePaymentInstructionArgs = {
	input: InvalidatePaymentInstructionInput;
};

export type MutationInvalidatePreSignedTokenArgs = {
	input: InvalidatePreSignedTokenInput;
};

export type MutationInvalidatePreSignedTokensForUserArgs = {
	input: InvalidatePreSignedTokensForUserInput;
};

export type MutationInvalidateRefreshTokenArgs = {
	input: InvalidateRefreshTokenInput;
};

export type MutationInvalidateRefreshTokensForUserArgs = {
	input: InvalidateRefreshTokensForUserInput;
};

export type MutationJoinSupplierAcceptTermsAndConditionsArgs = {
	joinSupplierProcessId: Scalars['Int'];
};

export type MutationLinkUserToLineArgs = {
	input: LinkUserToLineInput;
};

export type MutationMarkPrintBatchAsProcessedArgs = {
	printBatchId: Scalars['ID'];
};

export type MutationMasqueradeAuthenticationArgs = {
	masqueradeToken: Scalars['String'];
	userId: Scalars['String'];
};

export type MutationObtainKrakenTokenArgs = {
	input: ObtainJsonWebTokenInput;
};

export type MutationObtainLongLivedRefreshTokenArgs = {
	input: ObtainLongLivedRefreshTokenInput;
};

export type MutationPostCreditArgs = {
	input: PostCreditInput;
};

export type MutationPrepareAccountArgs = {
	input: PrepareAccountInput;
};

export type MutationPublishTransactionalMessagingTriggerArgs = {
	input: PublishTransactionalMessagingTriggerInput;
};

export type MutationRecordDepositAgreementAcceptedArgs = {
	input: DepositAgreementInput;
};

export type MutationRedeemLoyaltyPointsForAccountCreditArgs = {
	input: RedeemLoyaltyPointsInput;
};

export type MutationRedeemReferralClaimCodeArgs = {
	input: RedeemReferralClaimCodeInput;
};

export type MutationRefundPaymentArgs = {
	input: RefundPaymentInput;
};

export type MutationRegisterDemandResponseCampaignParticipantArgs = {
	input?: InputMaybe<RegisterDemandResponseCampaignParticipantInput>;
};

export type MutationRegisterPushNotificationBindingArgs = {
	input: RegisterPushNotificationBindingInput;
};

export type MutationRequestAmperageChangeArgs = {
	input?: InputMaybe<RequestAmperageChangeInput>;
};

export type MutationRequestPasswordResetArgs = {
	input: RequestPasswordResetInput;
};

export type MutationResetPasswordArgs = {
	input: ResetPasswordMutationInput;
};

export type MutationResetUserPasswordArgs = {
	input: ResetUserPasswordInput;
};

export type MutationRevertProductSwitchArgs = {
	input?: InputMaybe<RevertProductSwitchInput>;
};

export type MutationRevokeAgreementArgs = {
	input: RevokeAgreementInput;
};

export type MutationSaveOnboardingJourneyProgressArgs = {
	input: SaveOnboardingJourneyProgressInput;
};

export type MutationScheduleQuoteFollowUpArgs = {
	input: QuoteShareInput;
};

export type MutationSelfServeSwitchInCorrectionArgs = {
	input: SelfServeSwitchInCorrectionInput;
};

export type MutationSendFamilySwitchInOfferArgs = {
	input: SendFamilySwitchInOfferInput;
};

export type MutationSendQuoteSummaryArgs = {
	input: QuoteShareInput;
};

export type MutationSetLoyaltyPointsUserArgs = {
	input: SetLoyaltyPointsUserInput;
};

export type MutationSetUpDirectDebitInstructionArgs = {
	input: SetUpDirectDebitInstructionInput;
};

export type MutationShareGoodsQuoteArgs = {
	input: ShareGoodsQuoteInput;
};

export type MutationStoreBankAccountDetailsArgs = {
	input?: InputMaybe<NewBankAccountDetailsInput>;
};

export type MutationStorePaymentInstructionArgs = {
	input: StorePaymentInstructionInput;
};

export type MutationStoreRefundBankAccountArgs = {
	input?: InputMaybe<NewRefundBankAccountDetailsInput>;
};

export type MutationSubmitCustomerFeedbackArgs = {
	input: CustomerFeedbackInputType;
};

export type MutationSubmitRepaymentRequestArgs = {
	input: RequestRepaymentInputType;
};

export type MutationSuggestFixedPaymentAmountArgs = {
	input: SuggestFixedPaymentAmountInput;
};

export type MutationSwitchToProductOnNextReadingDateArgs = {
	input?: InputMaybe<SwitchToProductInput>;
};

export type MutationSwitchToProductWithFixedFuelCostAdjustmentArgs = {
	input?: InputMaybe<SwitchToProductWithFixedFuelCostAdjustmentInput>;
};

export type MutationTransferLedgerBalanceArgs = {
	input: TransferLedgerBalanceInputType;
};

export type MutationTransferLoyaltyPointsBetweenUsersArgs = {
	input: TransferLoyaltyPointsBetweenUsersInput;
};

export type MutationTriggerPostUploadOperationsJpnArgs = {
	s3Key: Scalars['String'];
};

export type MutationUpdateAccountBillingAddressArgs = {
	input: AccountBillingAddressInput;
};

export type MutationUpdateAccountBillingEmailArgs = {
	input: UpdateAccountBillingEmailInput;
};

export type MutationUpdateAccountConsentDataArgs = {
	input?: InputMaybe<UpdateAccountConsentDataInput>;
};

export type MutationUpdateAccountReferenceArgs = {
	input: AccountReferenceInput;
};

export type MutationUpdateAccountUserArgs = {
	input?: InputMaybe<UpdateAccountUserInput>;
};

export type MutationUpdateAccountUserByUserIdArgs = {
	input?: InputMaybe<UpdateAccountUserInput>;
};

export type MutationUpdateActivePurchaseArgs = {
	input: UpdatePurchaseInput;
};

export type MutationUpdateAffiliateLinkArgs = {
	input: UpdateAffiliateLinkInputType;
};

export type MutationUpdateAffiliateOrganisationArgs = {
	input: UpdateAffiliateOrganisationInputType;
};

export type MutationUpdateAgreementPeriodArgs = {
	input: UpdateAgreementPeriodInput;
};

export type MutationUpdateAmperageChangeRequestArgs = {
	input?: InputMaybe<UpdateAmperageChangeRequestInput>;
};

export type MutationUpdateApiExceptionArgs = {
	input: UpdateApiExceptionInput;
};

export type MutationUpdateApiExceptionNoteArgs = {
	input: UpdateApiExceptionNoteInput;
};

export type MutationUpdateAutoTopUpAmountArgs = {
	input: UpdateAutoTopUpAmountInput;
};

export type MutationUpdateBankAccountDetailsArgs = {
	input?: InputMaybe<UpdateBankAccountDetailsInput>;
};

export type MutationUpdateCommsDeliveryPreferenceArgs = {
	input: UpdateCommsDeliveryPreferenceInput;
};

export type MutationUpdateCommsPreferencesArgs = {
	input: UpdateAccountUserCommsPreferencesMutationInput;
};

export type MutationUpdateCreditCardDetailsArgs = {
	input?: InputMaybe<UpdateCreditCardDetailsInput>;
};

export type MutationUpdateDemandResponseCampaignParticipantCommsPreferencesArgs =
	{
		input?: InputMaybe<UpdateDemandResponseCampaignParticipantCommsPreferencesInput>;
	};

export type MutationUpdateDemandResponseCampaignParticipantTipArgs = {
	input?: InputMaybe<UpdateDemandResponseCampaignParticipantTipInput>;
};

export type MutationUpdateMessageTagsArgs = {
	input: UpdateMessageTagsInput;
};

export type MutationUpdateMetadataArgs = {
	input: MetadataInput;
};

export type MutationUpdateMoveInDateArgs = {
	input?: InputMaybe<UpdateMoveInDateInput>;
};

export type MutationUpdateMoveOutDateArgs = {
	input?: InputMaybe<UpdateMoveOutDateInput>;
};

export type MutationUpdatePasswordArgs = {
	input?: InputMaybe<UpdatePasswordInput>;
};

export type MutationUpdateUserArgs = {
	input: UpdateUserInput;
};

export type MutationUpdateUserDetailsArgs = {
	input: UpdateAccountUserMutationInput;
};

export type MutationValidateEmailArgs = {
	input: ValidateEmailInput;
};

export type MutationValidatePhoneArgs = {
	input: ValidatePhoneNumberInput;
};

export type MutationVerifyEmailArgs = {
	input: VerifyEmailInput;
};

export type MutationVerifyIdentityArgs = {
	input: VerifyIdentityInput;
};

export type MutationWithdrawAccountArgs = {
	input?: InputMaybe<WithdrawAccountInput>;
};

export type NewBankAccountDetailsInput = {
	/** Account holder name. */
	accountHolder?: InputMaybe<Scalars['String']>;
	/** Bank account number. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** Type of bank account. */
	accountType?: InputMaybe<BankAccountTypeChoices>;
	/** Bank code. */
	bankCode?: InputMaybe<Scalars['String']>;
	/** Branch code. */
	branchCode?: InputMaybe<Scalars['String']>;
	/** Kraken account number. */
	krakenAccountNumber?: InputMaybe<Scalars['String']>;
	/** Quote request code. */
	quoteRequestCode?: InputMaybe<Scalars['String']>;
};

export type NewLeadInputType = {
	/** Lead email. */
	email?: InputMaybe<Scalars['String']>;
	/** To know if the lead is domestic or business. */
	leadType?: InputMaybe<Scalars['String']>;
	/** The product the lead is interested in. */
	marketName?: InputMaybe<Scalars['String']>;
	/** Lead name. */
	name?: InputMaybe<Scalars['String']>;
	/** Lead phone. */
	phone?: InputMaybe<Scalars['String']>;
	/** The source where the lead comes from. */
	source?: InputMaybe<Scalars['String']>;
};

export type NewLeadType = {
	__typename?: 'NewLeadType';
	/** Lead email. */
	email?: Maybe<Scalars['String']>;
	/** ID of the lead. */
	id?: Maybe<Scalars['Int']>;
	/** To know if the lead is domestic or business. */
	leadType?: Maybe<Scalars['String']>;
	/** Lead name. */
	name?: Maybe<Scalars['String']>;
	/** Lead phone. */
	phone?: Maybe<Scalars['String']>;
	/** The source where the lead comes from. */
	source?: Maybe<Scalars['String']>;
};

export type NewRefundBankAccountDetailsInput = {
	/** A code that uniquely identifies the account. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** Account holder name. */
	bankAccountHolder?: InputMaybe<Scalars['String']>;
	/** Bank account number. */
	bankAccountNumber?: InputMaybe<Scalars['String']>;
	/** Type of bank account. */
	bankAccountType?: InputMaybe<RefundBankAccountTypeChoices>;
	/** Bank code. */
	bankCode?: InputMaybe<Scalars['String']>;
	/** Branch code. */
	branchCode?: InputMaybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
	/** The ID of the object */
	id: Scalars['ID'];
};

/** An enumeration. */
export enum NotifiableApplicationExternalProvider {
	/** AWS Pinpoint */
	Pinpoint = 'PINPOINT',
}

/** An enumeration. */
export enum NotifiableApplicationService {
	/** iOS (APNs) */
	Apns = 'APNS',
	/** iOS Sandbox (APNs Sandbox) */
	ApnsSandbox = 'APNS_SANDBOX',
	/** Android (GCM) */
	Gcm = 'GCM',
}

/** Represents an application that can receive push notifications. */
export type NotifiableApplicationType = {
	__typename?: 'NotifiableApplicationType';
	/** Bundle ID or package name of the app. */
	bundleId: Scalars['String'];
	description: Scalars['String'];
	/** Project ID used in push notification delivery service. (Currently: AWS Pinpoint) */
	externalProjectId: Scalars['String'];
	externalProvider: NotifiableApplicationExternalProvider;
	id: Scalars['ID'];
	/** Human readable name for the app. */
	name: Scalars['String'];
	pushNotificationBindings: Array<PushNotificationBindingType>;
	service: NotifiableApplicationService;
};

export type OejpTermsAndConditionsType = {
	__typename?: 'OEJPTermsAndConditionsType';
	brandCode?: Maybe<Scalars['String']>;
	createdAt: Scalars['DateTime'];
	effectiveFrom?: Maybe<Scalars['DateTime']>;
	/** The html of the terms and conditions document rendered as a JSON string. */
	html?: Maybe<Scalars['String']>;
	/** The markdown text of the terms and conditions. */
	markdown?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	pdfUrl?: Maybe<Scalars['String']>;
	text: Scalars['String'];
	version?: Maybe<Scalars['String']>;
};

/** The input type for obtaining a Kraken Token (JWT). */
export type ObtainJsonWebTokenInput = {
	/** API key of the account user. Use standalone, don't provide a second input field. */
	APIKey?: InputMaybe<Scalars['String']>;
	/** Email address of the account user. Use with 'password' field. */
	email?: InputMaybe<Scalars['String']>;
	/** Live secret key of an third-party organization. Use standalone, don't provide a second input field. */
	organizationSecretKey?: InputMaybe<Scalars['String']>;
	/** Password of the account user. Use with 'email' field. */
	password?: InputMaybe<Scalars['String']>;
	/** Short-lived, temporary key (that's pre-signed). Use standalone, don't provide a second input field. */
	preSignedKey?: InputMaybe<Scalars['String']>;
	/** The refresh token that can be used to extend the expiry claim of a Kraken token. Use standalone, don't provide a second input field. */
	refreshToken?: InputMaybe<Scalars['String']>;
};

/**
 * The unifying approach used to get a Kraken token (JWT: JSON Web Token) with
 * different types of input.
 *
 * The currently supported inputs are:
 * - account user email/password combination
 * - account user API key
 * - organization live secret key
 * - pre-signed key
 * - refresh token
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1135: Invalid data.
 * - KT-CT-1134: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type ObtainKrakenJsonWebToken = {
	__typename?: 'ObtainKrakenJSONWebToken';
	/** The body payload of the Kraken Token.  The same information can be obtained by using JWT decoding tools on the value of the `token` field. */
	payload: Scalars['GenericScalar'];
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** A Unix timestamp representing the point in time at which the refresh token will expire. */
	refreshExpiresIn?: Maybe<Scalars['Int']>;
	/** A token that can be used in a subsequent call to `obtainKrakenToken` to get a new Kraken Token with the same access conditions after the previous one has expired. */
	refreshToken?: Maybe<Scalars['String']>;
	/** The Kraken Token.  Can be used in the `Authorization` header for subsequent calls to the API to access protected resources. */
	token: Scalars['String'];
};

/**
 * Obtain a long-lived refresh token.
 *
 * This mutation is limited to authorized third-party organizations only.
 *
 * Account users can only generate short-lived refresh tokens.
 *
 * The short-lived refresh tokens (for account users) can be obtained from
 * the 'refreshToken' field in 'obtainKrakenToken' mutation.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1120: The Kraken Token has expired.
 * - KT-CT-1121: Please use Kraken Token to issue long-lived refresh tokens.
 * - KT-CT-1132: Unauthorized.
 * - KT-CT-1122: Long-lived refresh tokens can only be issued for account users.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type ObtainLongLivedRefreshToken = {
	__typename?: 'ObtainLongLivedRefreshToken';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	refreshExpiresIn: Scalars['Int'];
	refreshToken?: Maybe<Scalars['String']>;
};

/** The input type for obtaining a long-lived refresh token. */
export type ObtainLongLivedRefreshTokenInput = {
	/** The Kraken Token that will be used to generate the long-lived refresh token. */
	krakenToken: Scalars['String'];
};

/** An occupancy period for a property. */
export type OccupancyPeriodType = {
	__typename?: 'OccupancyPeriodType';
	/** Date the occupancy period is effective from. */
	effectiveFrom?: Maybe<Scalars['DateTime']>;
	/** Date the occupancy period is effective to. */
	effectiveTo?: Maybe<Scalars['DateTime']>;
	id?: Maybe<Scalars['ID']>;
	/** Whether the account associated with the occupancy period is an occupier account type. */
	isOccupier?: Maybe<Scalars['Boolean']>;
};

export type OccupyPropertyProcessConnectionTypeConnection = {
	__typename?: 'OccupyPropertyProcessConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<OccupyPropertyProcessConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `OccupyPropertyProcessConnectionType` and its cursor. */
export type OccupyPropertyProcessConnectionTypeEdge = {
	__typename?: 'OccupyPropertyProcessConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<OccupyPropertyProcessType>;
};

/** Represents a Occupy Property process. */
export type OccupyPropertyProcessType = AbstractSupplyPointProcessInterface & {
	__typename?: 'OccupyPropertyProcessType';
	/** The ID or the primary key of the lifecycle process. */
	id?: Maybe<Scalars['ID']>;
	occupyAt: Scalars['DateTime'];
	/** The status of the process. */
	status?: Maybe<LifecycleSupplyPointProcessStatus>;
	/** The supply points associated with the process. */
	supplyPoints: SupplyPointConnectionTypeConnection;
};

/** Represents a Occupy Property process. */
export type OccupyPropertyProcessTypeSupplyPointsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type OnboardingJourneyAblyJwt = {
	__typename?: 'OnboardingJourneyAblyJWT';
	token?: Maybe<Scalars['String']>;
};

export type OperationsTeamType = {
	__typename?: 'OperationsTeamType';
	/** ID for the Operations Team. */
	id?: Maybe<Scalars['ID']>;
	/** Name for the Operations Team. */
	name?: Maybe<Scalars['String']>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
	__typename?: 'PageInfo';
	/** When paginating forwards, the cursor to continue. */
	endCursor?: Maybe<Scalars['String']>;
	/** When paginating forwards, are there more items? */
	hasNextPage: Scalars['Boolean'];
	/** When paginating backwards, are there more items? */
	hasPreviousPage: Scalars['Boolean'];
	/** When paginating backwards, the cursor to continue. */
	startCursor?: Maybe<Scalars['String']>;
};

export type Param = {
	/** The subdomain of the affiliate link. */
	affiliateLinkSubdomain?: InputMaybe<Scalars['String']>;
	/** An optional audio recording id. */
	audioRecordingId?: InputMaybe<Scalars['String']>;
	/** The friend referral code. */
	referralCode?: InputMaybe<Scalars['String']>;
	/** The bespoke referral scheme code. */
	referralSchemeCode?: InputMaybe<Scalars['String']>;
};

/** A payment from the customer to the energy supplier. */
export type Payment = TransactionType & {
	__typename?: 'Payment';
	/** Unique identifier of the account the transaction belongs to. */
	accountNumber?: Maybe<Scalars['String']>;
	/**
	 * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
	 * @deprecated The 'amount' field is deprecated.
	 *
	 * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
	 *
	 * - Marked as deprecated on 2023-12-06.
	 * - Will be removed on 2024-06-01.
	 */
	amount?: Maybe<Scalars['Int']>;
	/** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
	amounts?: Maybe<TransactionAmountType>;
	/** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
	balanceCarriedForward?: Maybe<Scalars['Int']>;
	/** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
	billingDocumentIdentifier?: Maybe<Scalars['ID']>;
	/** The date time when the transaction is created. */
	createdAt?: Maybe<Scalars['DateTime']>;
	/** Returns True if the transaction is linked with a statement. */
	hasStatement?: Maybe<Scalars['Boolean']>;
	id?: Maybe<Scalars['ID']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isAccountCharge' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isAccountCharge?: Maybe<Scalars['Boolean']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isAccountPayment' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isAccountPayment?: Maybe<Scalars['Boolean']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isCredit' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isCredit?: Maybe<Scalars['Boolean']>;
	/** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
	isHeld?: Maybe<Scalars['Boolean']>;
	/** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
	isIssued?: Maybe<Scalars['Boolean']>;
	isReversed: Scalars['Boolean'];
	/** Returns the note field value for the transaction, which contains additional info. */
	note?: Maybe<Scalars['String']>;
	postedDate?: Maybe<Scalars['Date']>;
	/**
	 * Returns None if a statement is not linked with the transaction.
	 * @deprecated The 'statementId' field is deprecated.
	 *
	 * Use `billingDocumentIdentifier` instead.
	 *
	 * - Marked as deprecated on 2023-11-30.
	 * - Will be removed on 2024-06-01.
	 */
	statementId?: Maybe<Scalars['ID']>;
	title?: Maybe<Scalars['String']>;
};

/** Payment adequacy adjusts fixed payment schedules to maintain a healthy ledger balance over a year. */
export type PaymentAdequacyDetailsType = {
	__typename?: 'PaymentAdequacyDetailsType';
	/** This ledger will be exempt from default Payment Adequacy. This may mean that it is completely exempt, or handled with special rules. */
	isCurrentlyExempt?: Maybe<Scalars['Boolean']>;
};

export enum PaymentDayDirectionType {
	/** The payment day is calculated backwards from the last day of the month. */
	BeforeEndOfMonth = 'BEFORE_END_OF_MONTH',
	/** The payment day is calculated forward. */
	OfMonth = 'OF_MONTH',
}

export type PaymentForecastConnectionTypeConnection = {
	__typename?: 'PaymentForecastConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<PaymentForecastConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PaymentForecastConnectionType` and its cursor. */
export type PaymentForecastConnectionTypeEdge = {
	__typename?: 'PaymentForecastConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<PaymentForecastType>;
};

export type PaymentForecastType = {
	__typename?: 'PaymentForecastType';
	amount?: Maybe<Scalars['Int']>;
	date?: Maybe<Scalars['Date']>;
	/** The payment method used for the forecasted payment. */
	method?: Maybe<ScheduleType>;
	paymentNumber?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum PaymentFrequencyOptions {
	/** Monthly */
	Monthly = 'Monthly',
	/** Planned */
	Planned = 'Planned',
	/** Weekly */
	Weekly = 'Weekly',
}

export type PaymentInput = {
	/** Account holder name. */
	accountHolder?: InputMaybe<Scalars['String']>;
	/** Bank account number. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** GMO bank account token. */
	bankAccountToken?: InputMaybe<Scalars['String']>;
	/** GMO credit card token. */
	creditCardToken?: InputMaybe<Scalars['String']>;
	/** Type for provided payment information. */
	paymentType?: InputMaybe<PaymentTypeChoices>;
};

export type PaymentInstructionConnection = {
	__typename?: 'PaymentInstructionConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<PaymentInstructionEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PaymentInstruction` and its cursor. */
export type PaymentInstructionEdge = {
	__typename?: 'PaymentInstructionEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<PaymentInstructionType>;
};

/** An enumeration. */
export enum PaymentInstructionStatus {
	/** The account for this instruction was closed. */
	AccountClosed = 'ACCOUNT_CLOSED',
	/** The instruction is active and can be used to take payments. */
	Active = 'ACTIVE',
	/** The instruction could not be set up with the vendor. */
	Failed = 'FAILED',
	/** The instruction has not yet been set up. */
	Provisional = 'PROVISIONAL',
}

/** Direct Debit Instructions */
export type PaymentInstructionType = {
	__typename?: 'PaymentInstructionType';
	accountHolder: Scalars['String'];
	cardExpiryMonth?: Maybe<Scalars['Int']>;
	cardExpiryYear?: Maybe<Scalars['Int']>;
	cardPaymentNetwork?: Maybe<Scalars['String']>;
	iban: Scalars['String'];
	id: Scalars['ID'];
	/** Payment instruction type. */
	instructionType?: Maybe<Scalars['String']>;
	/**
	 * The last four digits of the account number.
	 * @deprecated The 'lastFourDigitsOfAccountNumber' field is deprecated.
	 *
	 * Use 'maskedAccountIdentifier' for a masked reference to the instruction.
	 *
	 * - Marked as deprecated on 2021-12-23.
	 * - Will be removed on 2024-01-01.
	 */
	lastFourDigitsOfAccountNumber?: Maybe<Scalars['String']>;
	/** A masked reference to a recurring payment method. */
	maskedAccountIdentifier?: Maybe<Scalars['String']>;
	/** A masked version of the IBAN. */
	maskedIban?: Maybe<Scalars['String']>;
	sortCode: Scalars['String'];
	status: Scalars['String'];
};

export enum PaymentReasonOptions {
	BalanceThresholdCrossed = 'BALANCE_THRESHOLD_CROSSED',
	BillIssued = 'BILL_ISSUED',
	PaymentPlan = 'PAYMENT_PLAN',
	RegularSchedule = 'REGULAR_SCHEDULE',
}

export type PaymentScheduleConnectionTypeConnection = {
	__typename?: 'PaymentScheduleConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<PaymentScheduleConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PaymentScheduleConnectionType` and its cursor. */
export type PaymentScheduleConnectionTypeEdge = {
	__typename?: 'PaymentScheduleConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<PaymentScheduleType>;
};

export type PaymentScheduleCreated = {
	__typename?: 'PaymentScheduleCreated';
	/** The create schedules payment amount. */
	paymentAmount: Scalars['Int'];
	/** The created schedules payment day. */
	paymentDay: Scalars['Int'];
};

/**
 * Input type for payment schedule details.
 *
 * This enables us to collect information to create 4 different types of schedules during enrollment:
 * - Payment on receipt of bill
 * - Payment at a fixed day of the month once a receipt of bill has been issued
 * - Payment when a ledger balance drops below a certain value
 * - Payment at regular intervals for a fixed amount
 *
 * This is intended to be morally equivalent to a "union" input type.
 */
export type PaymentScheduleDetailsInput = {
	balanceTriggered?: InputMaybe<BalanceTriggeredScheduleInput>;
	billTriggered?: InputMaybe<BillTriggeredScheduleInput>;
	regularTriggered?: InputMaybe<RegularTriggeredScheduleInput>;
};

export type PaymentScheduleInput = {
	/** Input a customer account number. */
	accountNumber: Scalars['String'];
	/** Payment amount must be inputted in yen. */
	paymentAmount: Scalars['Int'];
	/** Input a direct debit payment day. */
	paymentDay: Scalars['Int'];
};

export type PaymentSchedulePaymentDayDetailsType = {
	__typename?: 'PaymentSchedulePaymentDayDetailsType';
	/** A human-readable description of the value. */
	description?: Maybe<Scalars['String']>;
	/** Direction of payment day. */
	direction?: Maybe<PaymentDayDirectionType>;
};

/** An enumeration. */
export enum PaymentScheduleReasonOptions {
	/** A payment schedule created to take payments to pay back a debt. These schedules typically expire once the debt has been re-payed. */
	DebtRepaymentPlan = 'DEBT_REPAYMENT_PLAN',
	/** A payment schedule created to take the final payment when an account is closed. */
	FinalPayment = 'FINAL_PAYMENT',
	/** The default value for usual account payments. */
	GeneralAccountPayment = 'GENERAL_ACCOUNT_PAYMENT',
	/** A payment schedule created to take a payment around the supply start date of a meterpoint to help prevent accounts accruing debt. */
	SsdPayment = 'SSD_PAYMENT',
}

/** An object that represents when we have agreed to take payments from a payment instruction. */
export type PaymentScheduleType = {
	__typename?: 'PaymentScheduleType';
	id: Scalars['ID'];
	isPaymentHoliday?: Maybe<Scalars['Boolean']>;
	isVariablePaymentAmount: Scalars['Boolean'];
	paymentAdequacyAdjustment?: Maybe<Scalars['Int']>;
	paymentAdequacyAdjustmentExpiryDate?: Maybe<Scalars['Date']>;
	paymentAmount: Scalars['Int'];
	paymentDay?: Maybe<Scalars['Int']>;
	/** Details of the payment_day value. */
	paymentDayDetails?: Maybe<PaymentSchedulePaymentDayDetailsType>;
	/** The frequency of the payment schedule. */
	paymentFrequency?: Maybe<PaymentFrequencyOptions>;
	paymentFrequencyMultiplier: Scalars['Int'];
	paymentHolidayReason: Scalars['String'];
	/** The reason the payment schedule was created. */
	reason?: Maybe<PaymentScheduleReasonOptions>;
	/** The method of payment for the schedule. */
	scheduleType?: Maybe<ScheduleType>;
	/** The supplementary ledger for this payment schedule, if it is on one. */
	supplementaryLedger?: Maybe<SupplementaryLedgerType>;
	/** The sum of the payment adequacy contributions on the payment schedule that are expected to be taken before the debt repayment is complete. */
	totalDebtAmount?: Maybe<Scalars['Int']>;
	validFrom: Scalars['Date'];
	validTo?: Maybe<Scalars['Date']>;
};

/** An enumeration. */
export enum PaymentType {
	Bpay = 'BPAY',
	Card = 'CARD',
	DirectDebit = 'DIRECT_DEBIT',
	GmoRefund = 'GMO_REFUND',
	PaymentSlip = 'PAYMENT_SLIP',
}

export enum PaymentTypeChoices {
	BankAccount = 'BANK_ACCOUNT',
	BankTransfer = 'BANK_TRANSFER',
	CreditCard = 'CREDIT_CARD',
	Konbini = 'KONBINI',
}

export type PeriodBasedDocumentType = BillInterface & {
	__typename?: 'PeriodBasedDocumentType';
	billType?: Maybe<BillTypeEnum>;
	fromDate?: Maybe<Scalars['Date']>;
	id?: Maybe<Scalars['ID']>;
	/**
	 *
	 * The unique identifier for the billing document. Note: a pending billing document will not have an identifier yet;
	 * and not all finalized billing documents will have an identifier assigned to them, in which case this will be null.
	 *
	 */
	identifier?: Maybe<Scalars['ID']>;
	/** Whether the billing document has been annulled. */
	isAnnulled: Scalars['Boolean'];
	/** The date the bill was sent to the customer. */
	issuedDate?: Maybe<Scalars['Date']>;
	/**
	 * Requesting this field generates a temporary URL at which bill is available.
	 *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
	 *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
	 *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
	 *         been created/issued yet.
	 *
	 */
	temporaryUrl?: Maybe<Scalars['String']>;
	toDate?: Maybe<Scalars['Date']>;
	/** The total amounts for all charges on the billing document. */
	totalCharges?: Maybe<StatementTotalType>;
	/** The total amounts for all credits on the statement. */
	totalCredits?: Maybe<StatementTotalType>;
};

/** Attendant phone number type. */
export enum PhoneNumberType {
	Home = 'HOME',
	Mobile = 'MOBILE',
	Other = 'OTHER',
	Work = 'WORK',
}

export type PillButtonType = ButtonInterface &
	IdentifiableInterface & {
		__typename?: 'PillButtonType';
		/** The action to perform when the button is pressed. */
		buttonAction: ActionType;
		/** The button style. */
		buttonStyle?: Maybe<ButtonStyle>;
		/** Unique identifier of the object. */
		id?: Maybe<Scalars['ID']>;
		/** Title text of the button. */
		title: Scalars['String'];
		/** The name of the object's type. */
		typename?: Maybe<Scalars['String']>;
	};

export type PointsAllowanceRateLimitInformation = {
	__typename?: 'PointsAllowanceRateLimitInformation';
	/** Whether the viewer has been blocked due to spending all its allowed points. */
	isBlocked?: Maybe<Scalars['Boolean']>;
	/** The maximum number of points the viewer gets for requests per hour. */
	limit?: Maybe<Scalars['Int']>;
	/** The remaining points for the viewer in one hour time limit. */
	remainingPoints?: Maybe<Scalars['Int']>;
	/** Time To Live: UNIX timestamp when the viewer will get a new allowance of points. */
	ttl?: Maybe<Scalars['Int']>;
	/** The points used so far in one hour time limit. */
	usedPoints?: Maybe<Scalars['Int']>;
};

/** A measurement in points. */
export type PointsSizeType = IdentifiableInterface & {
	__typename?: 'PointsSizeType';
	/** Unique identifier of the object. */
	id?: Maybe<Scalars['ID']>;
	/** The points value. */
	points: Scalars['Int'];
	/** The name of the object's type. */
	typename?: Maybe<Scalars['String']>;
};

/** Paginator of Operations Team */
export type PortfolioConnectionTypeConnection = {
	__typename?: 'PortfolioConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<PortfolioConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PortfolioConnectionType` and its cursor. */
export type PortfolioConnectionTypeEdge = {
	__typename?: 'PortfolioConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<PortfolioType>;
};

export type PortfolioType = {
	__typename?: 'PortfolioType';
	billingName?: Maybe<Scalars['String']>;
	collectiveBilling: Scalars['Boolean'];
	createdAt: Scalars['DateTime'];
	id: Scalars['ID'];
	/** The lead account for this portfolio. */
	leadAccountNumber?: Maybe<Scalars['String']>;
	number: Scalars['String'];
	/** Operations team for this portfolio. */
	operationsTeam?: Maybe<OperationsTeamType>;
	updatedAt: Scalars['DateTime'];
};

/** The role a user has in association with one portfolio. */
export type PortfolioUserRoleType = {
	__typename?: 'PortfolioUserRoleType';
	id: Scalars['ID'];
	/** Portfolio object. */
	portfolio: PortfolioType;
	/** The portfolio role. */
	role?: Maybe<Scalars['RoleString']>;
	user: AccountUser;
};

/**
 * The GraphQL error type for displaying information about
 * GraphQL errors that might be raised from the API.
 */
export type PossibleErrorType = {
	__typename?: 'PossibleErrorType';
	/** The error code that might be returned from the query/mutation. */
	code?: Maybe<Scalars['String']>;
	/** The error description that might be returned from the query/mutation. */
	description?: Maybe<Scalars['String']>;
	/** The error message that might be returned from the query/mutation. */
	message?: Maybe<Scalars['String']>;
	/** The error type that might be returned from the query/mutation. */
	type?: Maybe<Scalars['String']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-5316: Invalid data.
 * - KT-CT-5311: The credit reason with the requested code is deprecated.
 * - KT-CT-5312: The credit reason with the requested code does not exist.
 * - KT-CT-5313: An error occurred whilst posting the credit.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type PostCredit = {
	__typename?: 'PostCredit';
	/** Posted account credit. */
	credit?: Maybe<Credit>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type PostCreditInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** Optional short note about the credit, to be displayed to the user. */
	displayNote?: InputMaybe<Scalars['String']>;
	/** The ID of the ledger where the credit will be posted. */
	ledgerId: Scalars['ID'];
	/** The net amount of the credit to be posted. Amount should be posted in the smallest unit of currency. */
	netAmount: Scalars['Int'];
	/** Optional short note about the credit, to be displayed to internal systems. */
	note?: InputMaybe<Scalars['String']>;
	/** The reason why the credit is posted. This should be a valid credit reason code. */
	reason: Scalars['String'];
	/** The tax amount of the credit to be posted. Amount should be posted in the smallest unit of currency. */
	taxAmount: Scalars['Int'];
};

export type PostalArea = {
	__typename?: 'PostalArea';
	/** Area. */
	area: Scalars['String'];
	/** City. */
	city: Scalars['String'];
	/** Postcode. */
	postcode: Scalars['String'];
	/** Prefecture. */
	prefecture: Scalars['String'];
};

export type PostalAreaInput = {
	/** Area. */
	area: Scalars['String'];
	/** City. */
	city: Scalars['String'];
	/** Postcode. */
	postcode: Scalars['String'];
	/** Prefecture. */
	prefecture: Scalars['String'];
};

export type PreKrakenBillType = BillInterface & {
	__typename?: 'PreKrakenBillType';
	billType?: Maybe<BillTypeEnum>;
	fromDate?: Maybe<Scalars['Date']>;
	/** The gross amount of the historical bill. */
	grossAmount?: Maybe<Scalars['BigInt']>;
	id?: Maybe<Scalars['ID']>;
	/** The date the bill was sent to the customer. */
	issuedDate?: Maybe<Scalars['Date']>;
	/** The params associated with the historical bill. */
	params?: Maybe<Scalars['JSONString']>;
	/**
	 * Requesting this field generates a temporary URL at which bill is available.
	 *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
	 *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
	 *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
	 *         been created/issued yet.
	 *
	 */
	temporaryUrl?: Maybe<Scalars['String']>;
	toDate?: Maybe<Scalars['Date']>;
};

/** A pre-signed, expiring and opaque tokens that can be swapped for a limited scope JWT (Kraken Token). */
export type PreSignedToken = {
	__typename?: 'PreSignedToken';
	isValid?: Maybe<Scalars['Boolean']>;
	key: Scalars['String'];
	/** The scope that the token will grant to the account user. */
	scope: ExpiringTokenScope;
};

/**
 *
 *     Choices class for the pre-signed expiring tokens.
 *
 *     These choices must have a certain format:
 *
 *     {ACTION-VERB}_{DEFINING-NOUN}
 *
 *     They should start with an action verb. It should be a single word.
 *     The action verb enables the account user to do the thing (defining noun)
 *     that comes after the action verb. Together they represent a task.
 *
 *     The defining noun could be longer than a single word.
 *     Preferably, it should be kept short and simple as much as possible.
 *
 */
export enum PreSignedTokenScope {
	/** Scope that enables account user to accept the terms and conditions for a product. */
	AcceptTermsAndConditions = 'ACCEPT_TERMS_AND_CONDITIONS',
	/** Scope that enables account user to book smart meter appointments. */
	BookSmartMeterAppointments = 'BOOK_SMART_METER_APPOINTMENTS',
	/** Scope that enables account user to checkout a quote (validate terms & conds and provide a payment detail). */
	CheckoutQuote = 'CHECKOUT_QUOTE',
	/** Edit Customer Marketing Preference */
	EditCustomerMarketingPreference = 'EDIT_CUSTOMER_MARKETING_PREFERENCE',
	/** Scope that enables account user to join campaigns. */
	JoinCampaigns = 'JOIN_CAMPAIGNS',
	/** Scope that enables account user to join campaign events. */
	JoinCampaignEvents = 'JOIN_CAMPAIGN_EVENTS',
	/** Scope that enables account user to generate a renewal quote and renew agreements. */
	ManageAccountRenewals = 'MANAGE_ACCOUNT_RENEWALS',
	/** Scope that enables account user to manage security deposit payments for business accounts. */
	ManageBusinessSecurityDeposit = 'MANAGE_BUSINESS_SECURITY_DEPOSIT',
	/** Scope that enables account user to accept goods quotes and process goods purchases. */
	ManageGoodsPurchases = 'MANAGE_GOODS_PURCHASES',
	/** Scope that enables account user to do a self-serve product switch through the Dashboard. */
	ManageProductSwitch = 'MANAGE_PRODUCT_SWITCH',
	/** Scope that enables account user to redeem loyalty points */
	RedeemLoyaltyPoints = 'REDEEM_LOYALTY_POINTS',
	/** Scope that enables account user to report a property move-out. */
	ReportMoveOut = 'REPORT_MOVE_OUT',
	/** Scope that enables user to send a loss objection for a change of supplier process. */
	SendLossObjectionForChangeOfSupplier = 'SEND_LOSS_OBJECTION_FOR_CHANGE_OF_SUPPLIER',
	/** Scope that enables account user to submit customer feedback. */
	SubmitCustomerFeedback = 'SUBMIT_CUSTOMER_FEEDBACK',
	/** Scope that enables account user to submit meter readings. */
	SubmitMeterReadings = 'SUBMIT_METER_READINGS',
	/** Scope that enables account user to update their blackhole email address. */
	UpdateBlackholeEmail = 'UPDATE_BLACKHOLE_EMAIL',
	/** Update Sensitive Customer Information */
	UpdateSensitiveCustomerInformation = 'UPDATE_SENSITIVE_CUSTOMER_INFORMATION',
	/** Scope that enables account user to visit campaign dashboard. */
	ViewCampaignDashboards = 'VIEW_CAMPAIGN_DASHBOARDS',
	/** Scope that enables account user to visit detailed property usage pages. */
	ViewDetailedUsage = 'VIEW_DETAILED_USAGE',
}

export type PrepareAccountInput = {
	/** The type of account to create. */
	accountType?: InputMaybe<AccountTypeChoices>;
	/** The billing address. */
	billingAddress: LifecycleAddressInput;
	/** The billing name. */
	billingName: Scalars['String'];
	/** The brand of the created account. */
	brandCode: Scalars['String'];
	/** The chosen payment day. */
	chosenPaymentDay?: InputMaybe<Scalars['Int']>;
	/** The customer's details. */
	customerDetails: CustomerDetailsInput;
	/** The date of sale, defaults to today if not provided. */
	dateOfSale?: InputMaybe<Scalars['Date']>;
	/** The preferred supply start date. */
	preferredSsd?: InputMaybe<Scalars['Date']>;
	/** Sales information. */
	salesInfo: SalesInformationInput;
};

export type PrepareAccountResult = {
	__typename?: 'PrepareAccountResult';
	/** The account number of the newly created account or the existing account to be re-used. */
	accountNumber?: Maybe<Scalars['String']>;
	/** Was a new account created. */
	isNewAccount?: Maybe<Scalars['Boolean']>;
	/** Was a new user created. */
	isNewUser?: Maybe<Scalars['Boolean']>;
	/** The ID of the newly created or existing account user. */
	userId?: Maybe<Scalars['ID']>;
};

/** Fields returned by generate_presigned_post. */
export type PresignedPostFields = {
	__typename?: 'PresignedPostFields';
	/** The AWS access key ID. */
	awsAccessKeyId: Scalars['String'];
	/** The S3 bucket key. */
	key: Scalars['String'];
	/** The S3 policy. */
	policy: Scalars['String'];
	/** AWS Signature Version 4 Authentication. */
	signature: Scalars['String'];
	/** The AMZ security token. */
	xAmzSecurityToken: Scalars['String'];
};

export type PricePerUnit = {
	__typename?: 'PricePerUnit';
	/**
	 *
	 *             Monetary value of a single unit of the measurement.
	 *             This is the smallest unit of currency e.g. cents for USD or yen for JPY.
	 *
	 */
	amount: Scalars['Decimal'];
	/** Unit that monetary amount relates to eg. 27 cents per kwh. */
	unit?: Maybe<Unit>;
};

/** Represents a print attachment */
export type PrintAttachmentType = {
	__typename?: 'PrintAttachmentType';
	filename: Scalars['String'];
	id: Scalars['ID'];
	s3Bucket: Scalars['String'];
	s3Key: Scalars['String'];
	/** Temporary URL at which the attachment is available. This URL will expire after approximately an hour. It is intended for redirection purposes, NOT persistence in any form (e.g. inclusion in emails or the body of a web page). */
	temporaryUrl?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PrintBatchStatus {
	Closed = 'CLOSED',
	Open = 'OPEN',
	Processed = 'PROCESSED',
}

/** Represents print batch details */
export type PrintBatchType = {
	__typename?: 'PrintBatchType';
	id: Scalars['ID'];
	/** Messages in a print batch. */
	messages?: Maybe<PrintMessageTypeConnection>;
	/** The status of the print batch. */
	status?: Maybe<PrintBatchStatus>;
};

/** Represents print batch details */
export type PrintBatchTypeMessagesArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	isHighPriority?: InputMaybe<Scalars['Boolean']>;
	last?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
};

export type PrintEventType = Node & {
	__typename?: 'PrintEventType';
	eventType: Scalars['String'];
	/** The ID of the object */
	id: Scalars['ID'];
	/** Print message of the print event. */
	message?: Maybe<PrintMessageType>;
	occurredAt: Scalars['DateTime'];
};

/** Represents a print communication. */
export type PrintMessageType = Node & {
	__typename?: 'PrintMessageType';
	account?: Maybe<Account>;
	/** Attachments of the message. */
	attachments?: Maybe<Array<Maybe<PrintAttachmentType>>>;
	/** Comms that are marked as high priority. */
	highPriority?: Maybe<Scalars['Boolean']>;
	/** The ID of the object */
	id: Scalars['ID'];
	templateCode: Scalars['String'];
};

export type PrintMessageTypeConnection = {
	__typename?: 'PrintMessageTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<PrintMessageTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PrintMessageType` and its cursor. */
export type PrintMessageTypeEdge = {
	__typename?: 'PrintMessageTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<PrintMessageType>;
};

export type Product =
	| ElectricityFitProduct
	| ElectricitySingleStepProduct
	| ElectricitySteppedProduct
	| GasTieredProduct;

/** Flags for the user to switch to the product with FCA rate. */
export type ProductEligibleStatus = {
	__typename?: 'ProductEligibleStatus';
	/** Check if the product reaches to the maximum number of active agreements. */
	isCampaignAvailable: Scalars['Boolean'];
	/** Check if the user is from allowed areas and has the campaign registered to account. */
	isUserEligible: Scalars['Boolean'];
	/** Check if the user has switched to the product with FCA rate. */
	isUserOptedIn: Scalars['Boolean'];
};

export type ProductInterface = {
	/** Product code. */
	code: Scalars['String'];
	/** Human friendly name to be displayed in interfaces. */
	displayName: Scalars['String'];
	/** Fuel cost adjustment rate information. */
	fuelCostAdjustment?: Maybe<FuelCostAdjustmentRate>;
	/** A JSON object containing extra information. */
	params?: Maybe<Scalars['GenericScalar']>;
	/** Price per day for standing charge. */
	standingChargePricePerDay?: Maybe<Scalars['Decimal']>;
	/** Unit type for standing charge. */
	standingChargeUnitType?: Maybe<Scalars['String']>;
	/** List of standing charge product rates. */
	standingCharges: Array<Maybe<StandingCharge>>;
};

export type ProductRate = {
	__typename?: 'ProductRate';
	/** The product rate band information. */
	band?: Maybe<Scalars['String']>;
	/** Duration in months for discount product rates. */
	durationMonths?: Maybe<Scalars['Int']>;
	/** Grid operator. */
	gridOperatorCode?: Maybe<Scalars['String']>;
	/** Price per unit. */
	pricePerUnit?: Maybe<Scalars['Decimal']>;
	/** Region of operation. */
	regionOfOperation?: Maybe<Scalars['String']>;
	/** What are we charging for (kWhs consumed, Days on supply, etc). */
	unitType?: Maybe<UnitType>;
	/** Rate validity start. */
	validFrom?: Maybe<Scalars['DateTime']>;
	/** Rate validity end. */
	validTo?: Maybe<Scalars['DateTime']>;
};

export type ProductToPurchaseInput = {
	/** Number of units. */
	numberOfUnits: Scalars['Int'];
	/** Products code to purchase. */
	productCode: Scalars['String'];
};

/** Represents a product and the quantity to quote for a customer. */
export type ProductToQuoteInput = {
	/** Currency. */
	currency: Scalars['String'];
	/** Number of units. */
	numberOfUnits: Scalars['Int'];
	/** Price per unit in smallest sub-unit of the currency. */
	pricePerUnit?: InputMaybe<Scalars['Int']>;
	/** ID of the product to quote. */
	productId: Scalars['Int'];
};

export type ProductWithFixedFuelCostAdjustmentSwitched = {
	__typename?: 'ProductWithFixedFuelCostAdjustmentSwitched';
	/** Check if the product reaches to the maximum number of active agreements. */
	isCampaignAvailable: Scalars['Boolean'];
	/** Check if the user is from allowed areas and has the campaign registered to account. */
	isUserEligible: Scalars['Boolean'];
	/** Check if the user has switched to the product with FCA rate. */
	isUserOptedIn: Scalars['Boolean'];
};

export type Property = PropertyInterface & {
	__typename?: 'Property';
	/** The address of the property, formatted into a single string. */
	address?: Maybe<Scalars['String']>;
	/** Coordinates for the property, useful for displaying the property on a map. */
	coordinates?: Maybe<CoordinatesType>;
	/** All the electricity generation points at this property. */
	electricityGenerationPoints?: Maybe<Array<Maybe<ElectricityGenerationPoint>>>;
	/** All the electricity supply points at this property. */
	electricitySupplyPoints?: Maybe<Array<Maybe<ElectricitySupplyPoint>>>;
	/** The embedded network this property belongs to, if any. */
	embeddedNetwork?: Maybe<EmbeddedNetworkType>;
	/** All the gas supply points at this property. */
	gasSupplyPoints?: Maybe<Array<Maybe<GasSupplyPoint>>>;
	id?: Maybe<Scalars['String']>;
	/** Measurements at a property */
	measurements?: Maybe<MeasurementConnection>;
	/** Time periods during which the property is associated with an account. Useful to display information about house-moves, as performing a move out of a property will set the end date for the occupancy period. */
	occupancyPeriods?: Maybe<Array<Maybe<OccupancyPeriodType>>>;
	/** The postcode of the property. */
	postcode?: Maybe<Scalars['String']>;
	/** Property rich address. */
	richAddress?: Maybe<PropertyRichAddressType>;
	/** List of address lines. */
	splitAddress?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PropertyMeasurementsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	endAt?: InputMaybe<Scalars['DateTime']>;
	endOn?: InputMaybe<Scalars['Date']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	startAt?: InputMaybe<Scalars['DateTime']>;
	startOn?: InputMaybe<Scalars['Date']>;
	timezone?: InputMaybe<Scalars['String']>;
	utilityFilters?: InputMaybe<Array<InputMaybe<UtilityFiltersInput>>>;
};

export type PropertyInterface = {
	/** The address of the property, formatted into a single string. */
	address?: Maybe<Scalars['String']>;
	/** Coordinates for the property, useful for displaying the property on a map. */
	coordinates?: Maybe<CoordinatesType>;
	/** The embedded network this property belongs to, if any. */
	embeddedNetwork?: Maybe<EmbeddedNetworkType>;
	id?: Maybe<Scalars['String']>;
	/** Measurements at a property */
	measurements?: Maybe<MeasurementConnection>;
	/** Time periods during which the property is associated with an account. Useful to display information about house-moves, as performing a move out of a property will set the end date for the occupancy period. */
	occupancyPeriods?: Maybe<Array<Maybe<OccupancyPeriodType>>>;
	/** Property rich address. */
	richAddress?: Maybe<PropertyRichAddressType>;
	/** List of address lines. */
	splitAddress?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PropertyInterfaceMeasurementsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	endAt?: InputMaybe<Scalars['DateTime']>;
	endOn?: InputMaybe<Scalars['Date']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	startAt?: InputMaybe<Scalars['DateTime']>;
	startOn?: InputMaybe<Scalars['Date']>;
	timezone?: InputMaybe<Scalars['String']>;
	utilityFilters?: InputMaybe<Array<InputMaybe<UtilityFiltersInput>>>;
};

export type PropertyRichAddressType = {
	__typename?: 'PropertyRichAddressType';
	/**
	 *
	 * Top-level administrative subdivision, e.g. US state, AU
	 * state/territory, IT region, JP prefecture.
	 *
	 * ### `AU`: Australia
	 *
	 * This must be one of `NSW`, `VIC`, `QLD`, `TAS`, `ACT`, `SA`,
	 * `NT`, `WA`. For addresses not within these locations, use
	 * the value that Australia Post uses, e.g. `ACT` for the
	 * Jervis Bay Territory or `WA` for Christmas Island.
	 *
	 */
	administrativeArea?: Maybe<Scalars['String']>;
	/**
	 *
	 * ISO 3166-1 alpha-2 code of the country this address belongs
	 * to, e.g. `AU`, `GB`.
	 *
	 */
	country?: Maybe<Scalars['String']>;
	/**
	 *
	 * Identifier used by the local postal service for this
	 * address, e.g. AU DPID, GB postcode + Delivery Point Suffix,
	 * US Zip-9 + Delivery Point.
	 *
	 * This is the value that gets encoded in the barcode printed
	 * on the envelope by large-volume bulk mail providers.
	 *
	 */
	deliveryPointIdentifier?: Maybe<Scalars['String']>;
	/**
	 *
	 * UK dependent localities, or neighbourhoods or boroughs in
	 * some other locations.
	 *
	 */
	dependentLocality?: Maybe<Scalars['String']>;
	/**
	 *
	 * City or town portion of an address, e.g. US city, AU
	 * suburb/town, IT comune, UK post town.
	 *
	 */
	locality?: Maybe<Scalars['String']>;
	/** A personal name. */
	name?: Maybe<Scalars['String']>;
	/** The name of a business or organisation. */
	organization?: Maybe<Scalars['String']>;
	/**
	 *
	 * Postal code (ZIP code in the US).
	 *
	 */
	postalCode?: Maybe<Scalars['String']>;
	/**
	 *
	 * Sorting code, e.g. FR CEDEX code. This field is not used in many countries.
	 *
	 */
	sortingCode?: Maybe<Scalars['String']>;
	/**
	 *
	 * The 'street address' component.
	 *
	 * This value can (and often will) contain newline characters
	 * when appropriate.
	 *
	 * In some cases, data may appear in this field instead of the
	 * below fields; e.g. a UK post town name may appear here
	 * instead of in the `dependent_locality` field. This happens
	 * when data has been migrated from a legacy format, and that
	 * format had insufficient metadata to determine the
	 * appropriate field.
	 *
	 * If `structured_street_address` is also set, the value of
	 * this field will be a string generated from that value.
	 *
	 */
	streetAddress?: Maybe<Scalars['String']>;
	/**
	 *
	 * The 'street address' component, in a structured format.
	 *
	 * This field stores the same value as `street_address`, but
	 * with more detail; for instance, instead of `123 Example
	 * Street` it might be `{'street_number': '123',
	 * 'street_name': 'Example', 'street_type': 'Street'}`. In
	 * many cases this will be blank; we only use this field for
	 * Krakens where we need to supply this level of granularity
	 * to some third-party service, like a bulk mail provider.
	 *
	 * The exact structure of this value depends on the country _of
	 * the address_, which is not necessarily the same as the
	 * country this Kraken is configured to serve. For addresses
	 * outside of the countries listed below, this field will be
	 * left blank.
	 *
	 * ### `AU`: Australia
	 *
	 * The following keys may be present; all are optional. All
	 * keys have string values, and their meaning is the same as
	 * their aseXML counterparts. (Note that, unlike aseXML, all
	 * keys are provided at the top level, rather than being
	 * nested.)
	 *
	 * - `flat_or_unit_type`
	 * - `flat_or_unit_number`
	 * - `floor_or_level_type`
	 * - `floor_or_level_number`
	 * - `building_or_property_name`
	 * - `location_descriptor`
	 * - `lot_number`
	 * - `house_number_1`
	 * - `house_number_suffix_1`
	 * - `house_number_2`
	 * - `house_number_suffix_2`
	 * - `street_name`
	 * - `street_type`
	 * - `street_suffix`
	 * - `postal_delivery_type`
	 * - `postal_delivery_number_prefix`
	 * - `postal_delivery_number_value`
	 * - `postal_delivery_number_suffix`
	 *
	 * ### `JP`: Japan
	 *
	 * The following keys may be present; all are optional.
	 * If keys are empty, they may be omitted from the response entirely.
	 *
	 * - `chome`
	 * - `banchi`
	 * - `go`
	 * - `edaban`
	 * - `kana_building_name`
	 * - `kanji_building_name`
	 * - `building_number`
	 * - `room_number`
	 * - `address_code`
	 * - `physical_location_identifier`
	 *
	 */
	structuredStreetAddress?: Maybe<Scalars['GenericScalar']>;
};

export type PropertySearchResult = {
	__typename?: 'PropertySearchResult';
	/** The matched property. */
	property: Property;
	/** A score representing the degree of confidence for a match. */
	score: Scalars['Decimal'];
};

export type ProvisionalTransactionConnectionTypeConnection = {
	__typename?: 'ProvisionalTransactionConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<ProvisionalTransactionConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ProvisionalTransactionConnectionType` and its cursor. */
export type ProvisionalTransactionConnectionTypeEdge = {
	__typename?: 'ProvisionalTransactionConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<ProvisionalTransactionType>;
};

/** A provisional transaction represents some debit or credit to or from a customer's account which we cannot yet finalise for some reason, but which is still useful to keep a note of, and display to the customer. Provisional transactions are purely to give guidance in the absence of finalised information. We therefore only return provisional transactions that have not been finalised. When a transaction is finalised, it is available through the `transactions` field. */
export type ProvisionalTransactionType = {
	__typename?: 'ProvisionalTransactionType';
	/** The amount in pence for this provisional transaction. It will be negative for charges, positive for credits. */
	amount?: Maybe<Scalars['Int']>;
	/** The date at which the charge should be applied to the account. */
	date?: Maybe<Scalars['Date']>;
	id: Scalars['ID'];
	/** A user readable string that indicates what this transaction relates to. */
	title?: Maybe<Scalars['String']>;
};

/**
 * Publish a trigger within the transactional messaging service.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-9901: Invalid trigger type code.
 * - KT-CT-9902: Invalid trigger type params.
 * - KT-CT-9903: Trigger type cannot be published externally.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type PublishTransactionalMessagingTrigger = {
	__typename?: 'PublishTransactionalMessagingTrigger';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** The trigger that has been published. */
	trigger?: Maybe<TriggerType>;
};

export type PublishTransactionalMessagingTriggerInput = {
	/** The params of the trigger type, as a JSON string. These are defined in the Params class for a trigger type. */
	params: Scalars['JSONString'];
	/** The code of the trigger type to be published. */
	triggerTypeCode: Scalars['String'];
};

/** Represents a pairing of a single app installation to an account user. */
export type PushNotificationBindingType = {
	__typename?: 'PushNotificationBindingType';
	application: NotifiableApplicationType;
	expiresAt: Scalars['DateTime'];
	id: Scalars['ID'];
	messages: PrintMessageTypeConnection;
	registeredAt: Scalars['DateTime'];
	token: Scalars['String'];
	user: AccountUser;
};

/** Represents a pairing of a single app installation to an account user. */
export type PushNotificationBindingTypeMessagesArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
};

export type Query = {
	__typename?: 'Query';
	/** Get details about an account. */
	account?: Maybe<Account>;
	/** Available reasons for use in account charge mutations. */
	accountChargeReasons?: Maybe<Array<Maybe<ChargeReasonType>>>;
	/** Available reasons for use in account credit mutations. */
	accountCreditReasons?: Maybe<Array<Maybe<CreditReasonType>>>;
	/** List of matching account references. */
	accountReference?: Maybe<Array<Maybe<AccountReferenceType>>>;
	/** Get details about multiple accounts. */
	accounts?: Maybe<Array<Maybe<Account>>>;
	/** Search for account that are already in Kraken and match the search terms. */
	accountsSearch?: Maybe<Array<Maybe<AccountSearchItemType>>>;
	/** Return the current active referral reward scheme of a given affiliate organisation, if any exists. */
	activeAffiliateReferralScheme?: Maybe<ReferralSchemeType>;
	/** Return the current active signup referral reward scheme with the given code, if any exists. */
	activeDomesticSignupRewardScheme?: Maybe<ReferralSchemeType>;
	/** Link object for an affiliate organization. */
	affiliateLink: AffiliateLinkType;
	/** Return the details of a given affiliate organization, if any exists. */
	affiliateOrganisation?: Maybe<AffiliateOrganisationType>;
	/** Get an agreement by id. */
	agreement?: Maybe<Agreement>;
	/** Get brownouts by status. */
	apiBrownouts?: Maybe<ApiBrownoutConnection>;
	/** Get a connection containing API Exceptions. */
	apiExceptions?: Maybe<ApiExceptionConnectionTypeConnection>;
	/** Get mobile screen details to render. */
	backendScreen?: Maybe<BackendScreenType>;
	/** Get all registered backend screen event IDs. */
	backendScreenEventIds?: Maybe<Array<Maybe<Scalars['String']>>>;
	/** Get all registered backend screen IDs. */
	backendScreenIds?: Maybe<Array<Maybe<Scalars['String']>>>;
	/** Charges for the given billing document. */
	charges?: Maybe<Array<Maybe<ChargeType>>>;
	/** Get contribution schemes. */
	contributionSchemes?: Maybe<Array<Maybe<ContributionSchemeType>>>;
	/** Get a dashboard screen to render in the form of a json list of sections containing cards or grouped cards each with an order attribute. */
	dashboardScreen?: Maybe<Dashboard>;
	/** Get the default payment instruction for the account's main ledger. */
	defaultPaymentInstruction?: Maybe<PaymentInstructionType>;
	/** Get default raw score for a customer feedback form. */
	defaultRawScore?: Maybe<Scalars['Int']>;
	/** Get deposit agreements for a given account. */
	depositAgreements?: Maybe<Array<Maybe<DepositAgreementOutput>>>;
	/** Return a referral reward scheme for the given account referral code. */
	domesticAccountReferralRewardScheme?: Maybe<ReferralSchemeType>;
	/** Return a joining reward scheme with the given code, if it's active. A joining reward can be a signup reward or a promotional reward. */
	domesticJoiningRewardScheme?: Maybe<ReferralSchemeType>;
	/** Return a signup referral reward scheme with the given code, if it's active. */
	domesticSignupRewardScheme?: Maybe<ReferralSchemeType>;
	/** Get details about a dunning process. */
	dunningProcess?: Maybe<DunningProcess>;
	/** Get details about an embedded network. */
	embeddedNetwork?: Maybe<EmbeddedNetworkType>;
	/** The current energy generation mix. */
	energyMixData?: Maybe<EnergyMixDataType>;
	/** Check if a given meter point ID is in suspicious meter point IDs list. */
	fraudMeterPointChecks?: Maybe<FraudMeterPointCheckType>;
	/** List Goods products given a market. */
	goodsProducts?: Maybe<GoodsProductConnectionTypeConnection>;
	/** List purchases for an account. */
	goodsPurchases?: Maybe<Array<Maybe<GoodsPurchase>>>;
	/** List quotes given an account number or retrieve a Goods quote given a quote code. */
	goodsQuotes?: Maybe<Array<Maybe<GoodsQuote>>>;
	/** Get the Ink conversation for a given account. */
	inkConversation: InkConversation;
	/** Get the content for a given message. */
	inkMessage: InkMessage;
	integrations: Array<IntegrationResponse>;
	/** Check validity of a password reset token. */
	isPasswordResetTokenValid?: Maybe<Scalars['Boolean']>;
	/** The current version of kraken. */
	krakenVersion?: Maybe<KrakenVersionType>;
	/** The url for the Stripe payment voucher of the latest Konbini payment on this account. */
	latestKonbiniPaymentLink?: Maybe<LatestKonbiniPaymentUrl>;
	/** Details associated with a LeaveSupplier process. */
	leaveSupplierProcess?: Maybe<LeaveSupplierProcessType>;
	/** Get all lifecycle processes associated with an account. */
	lifecycleProcesses?: Maybe<LifecycleProcessesType>;
	/** Get the Lifeline Agency Branch information for a given LifelineAgencySignUpsFileRecord ID. */
	lifelineAgencyBranchInfo: LifelineAgencyBranchInfo;
	/** Get all loyalty cards for the given account user. */
	loyaltyCards?: Maybe<Array<Maybe<LoyaltyCardType>>>;
	/** Get the Loyalty Point ledger entries for the passed user. */
	loyaltyPointLedgers?: Maybe<Array<Maybe<LoyaltyPointLedgerEntryType>>>;
	/** Metadata for a linked object. */
	metadata?: Maybe<Array<Maybe<Metadata>>>;
	/** Metadata for a linked object with key. */
	metadataForKey?: Maybe<Metadata>;
	moveInContext: MoveInContext;
	node?: Maybe<Node>;
	onboardingJourneyAblyJwt: OnboardingJourneyAblyJwt;
	/** The help text of all configured password validators as plain-text or html. Defaults to plain-text. */
	passwordValidatorHelpTexts?: Maybe<Array<Maybe<Scalars['String']>>>;
	postalAreas: Array<Maybe<PostalArea>>;
	/** Get print batch details, including messages in the batch. */
	printBatch: PrintBatchType;
	/** Retrieve the context for switching to the product with fixed fuel cost adjustment. */
	productEligibleStatus?: Maybe<ProductEligibleStatus>;
	/** Search for properties that are already in Kraken and match the search term. */
	propertiesSearch: Array<PropertySearchResult>;
	/** A property with the given ID. Usually associated with supply points. */
	property?: Maybe<Property>;
	/**
	 * Search for properties that are already in Kraken and match the search term.
	 * @deprecated The 'propertySearch' field is deprecated.
	 *
	 * This query is being deprecated in favour of `propertiesSearch`. The latter returns not only the matched properties but the level of confidence in the results through the `score` field.
	 *
	 * - Marked as deprecated on 2023-05-23.
	 * - Will be removed on 2024-01-01.
	 */
	propertySearch?: Maybe<Array<Maybe<Property>>>;
	/** Get the complexity of a query. */
	queryComplexity?: Maybe<QueryComplexityOutputType>;
	/** Get the customer feedback survey question. */
	question?: Maybe<Scalars['String']>;
	/** Retrieve a quote. */
	quoteRequest?: Maybe<Quote>;
	/**
	 * Information about rate limit for viewer.
	 * @deprecated The 'rateLimit' field is deprecated.
	 *
	 * `rateLimit` has been replaced by 'rateLimitInfo', which contains more detailed info about rate limit than the former one
	 *
	 * - Marked as deprecated on 2024-07-17.
	 * - Will be removed on 2025-01-01.
	 */
	rateLimit?: Maybe<RateLimitInformation>;
	/** Combined information about points-allowance rate limiting and request-specific rate limiting. */
	rateLimitInfo?: Maybe<CombinedRateLimitInformation>;
	/** All the energy retailers in Japan. */
	retailers?: Maybe<Array<Maybe<Retailer>>>;
	/** Retrieve a summary of available tariffs. */
	tariffSummary?: Maybe<Array<TariffSummary>>;
	/** Get the status of a background task. */
	taskResult?: Maybe<TaskResult>;
	/** Get the active terms and conditions for a product. */
	termsAndConditionsForProduct?: Maybe<TermsAndConditionsType>;
	/** Returns the Terms and conditions based on the code and version */
	termsandconditions?: Maybe<OejpTermsAndConditionsType>;
	/**
	 *  The currently authenticated third party.
	 *
	 *
	 *
	 * This field requires the `Authorization` header to be set.
	 *
	 *
	 */
	thirdPartyViewer?: Maybe<ThirdPartyOrganizationType>;
	/** Validate a Generation Point Identification Number. */
	validateGpin: ValidateGpin;
	/**
	 *  The currently authenticated user.
	 *
	 *
	 *
	 * This field requires the `Authorization` header to be set.
	 *
	 *
	 */
	viewer?: Maybe<AccountUser>;
};

export type QueryAccountArgs = {
	accountNumber: Scalars['String'];
};

export type QueryAccountReferenceArgs = {
	value?: InputMaybe<Scalars['String']>;
};

export type QueryAccountsArgs = {
	phoneNumber?: InputMaybe<Scalars['String']>;
	portfolioNumber?: InputMaybe<Scalars['String']>;
};

export type QueryAccountsSearchArgs = {
	maxResults?: InputMaybe<Scalars['Int']>;
	searchTerms?: InputMaybe<AccountSearchInputType>;
};

export type QueryActiveAffiliateReferralSchemeArgs = {
	subdomain: Scalars['String'];
};

export type QueryAffiliateLinkArgs = {
	subdomain: Scalars['String'];
};

export type QueryAffiliateOrganisationArgs = {
	id: Scalars['Int'];
};

export type QueryAgreementArgs = {
	id: Scalars['ID'];
};

export type QueryApiBrownoutsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	input?: InputMaybe<ApiBrownoutInput>;
	last?: InputMaybe<Scalars['Int']>;
};

export type QueryApiExceptionsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	input?: InputMaybe<ApiExceptionQueryInput>;
	last?: InputMaybe<Scalars['Int']>;
};

export type QueryBackendScreenArgs = {
	maxVersionSupported?: InputMaybe<Scalars['Int']>;
	params?: InputMaybe<Array<InputMaybe<BackendScreenParamInputType>>>;
	screenId: Scalars['ID'];
};

export type QueryChargesArgs = {
	accountNumber: Scalars['String'];
	billingDocumentIdentifier: Scalars['String'];
};

export type QueryDashboardScreenArgs = {
	accountNumber: Scalars['String'];
	dashboardId: Scalars['ID'];
	ledgerId?: InputMaybe<Scalars['String']>;
	maxVersionSupported?: Scalars['Int'];
	params?: InputMaybe<Array<InputMaybe<BackendScreenParamInputType>>>;
	propertyId?: InputMaybe<Scalars['String']>;
};

export type QueryDefaultPaymentInstructionArgs = {
	accountNumber: Scalars['String'];
	instructionType?: InputMaybe<PaymentType>;
};

export type QueryDefaultRawScoreArgs = {
	formId: Scalars['Int'];
};

export type QueryDepositAgreementsArgs = {
	accountNumber: Scalars['String'];
};

export type QueryDomesticAccountReferralRewardSchemeArgs = {
	code: Scalars['String'];
};

export type QueryDomesticJoiningRewardSchemeArgs = {
	code: Scalars['String'];
};

export type QueryDomesticSignupRewardSchemeArgs = {
	code: Scalars['String'];
};

export type QueryDunningProcessArgs = {
	accountNumber: Scalars['String'];
};

export type QueryEmbeddedNetworkArgs = {
	id: Scalars['ID'];
};

export type QueryFraudMeterPointChecksArgs = {
	meterPointId: Scalars['String'];
};

export type QueryGoodsProductsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	code?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
	marketName: Scalars['String'];
	productType?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type QueryGoodsPurchasesArgs = {
	accountNumber: Scalars['String'];
};

export type QueryGoodsQuotesArgs = {
	accountNumber?: InputMaybe<Scalars['String']>;
	quoteCode?: InputMaybe<Scalars['String']>;
};

export type QueryInkConversationArgs = {
	accountNumber?: InputMaybe<Scalars['String']>;
	conversationRelayId?: InputMaybe<Scalars['String']>;
};

export type QueryInkMessageArgs = {
	messageRelayId: Scalars['String'];
};

export type QueryIsPasswordResetTokenValidArgs = {
	token: Scalars['String'];
	userId: Scalars['String'];
};

export type QueryLatestKonbiniPaymentLinkArgs = {
	accountNumber: Scalars['String'];
};

export type QueryLeaveSupplierProcessArgs = {
	leaveSupplierProcessId: Scalars['ID'];
};

export type QueryLifecycleProcessesArgs = {
	accountNumber: Scalars['String'];
	onlyActive?: InputMaybe<Scalars['Boolean']>;
};

export type QueryLifelineAgencyBranchInfoArgs = {
	lifelineAgencySignUpsFileRecordId: Scalars['Int'];
};

export type QueryLoyaltyCardsArgs = {
	accountUserId: Scalars['String'];
};

export type QueryMetadataArgs = {
	identifier: Scalars['String'];
	linkedObjectType?: InputMaybe<LinkedObjectType>;
};

export type QueryMetadataForKeyArgs = {
	identifier: Scalars['String'];
	key: Scalars['String'];
	linkedObjectType?: InputMaybe<LinkedObjectType>;
};

export type QueryNodeArgs = {
	id: Scalars['ID'];
};

export type QueryOnboardingJourneyAblyJwtArgs = {
	clientId: Scalars['String'];
};

export type QueryPasswordValidatorHelpTextsArgs = {
	asHtml?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPostalAreasArgs = {
	postcode: Scalars['String'];
};

export type QueryPrintBatchArgs = {
	batchId?: InputMaybe<Scalars['ID']>;
};

export type QueryProductEligibleStatusArgs = {
	accountNumber: Scalars['String'];
	productCode: Scalars['String'];
	spin: Scalars['String'];
};

export type QueryPropertiesSearchArgs = {
	searchTerm: Scalars['String'];
};

export type QueryPropertyArgs = {
	id: Scalars['ID'];
};

export type QueryPropertySearchArgs = {
	searchTerm: Scalars['String'];
};

export type QueryQueryComplexityArgs = {
	input: QueryComplexityInputType;
};

export type QueryQuestionArgs = {
	formId: Scalars['Int'];
};

export type QueryQuoteRequestArgs = {
	code: Scalars['String'];
};

export type QueryTariffSummaryArgs = {
	gridOperatorCode: Scalars['String'];
	productCode?: InputMaybe<Scalars['String']>;
};

export type QueryTaskResultArgs = {
	accountNumber: Scalars['String'];
	taskId: Scalars['String'];
};

export type QueryTermsAndConditionsForProductArgs = {
	productCode: Scalars['String'];
};

export type QueryTermsandconditionsArgs = {
	brandCode?: InputMaybe<Scalars['String']>;
	versionMajor?: InputMaybe<Scalars['Int']>;
	versionMinor?: InputMaybe<Scalars['Int']>;
};

export type QueryValidateGpinArgs = {
	gpin: Scalars['String'];
};

/** Information about the complexity of the query. */
export type QueryComplexityInputType = {
	/** The operation name of the query to calculate complexity for if more than one is provided. */
	operationName?: InputMaybe<Scalars['String']>;
	/** The query to calculate complexity for. */
	query: Scalars['String'];
	/** Any variables to include for the query. Pagination variables should be included as they will affect the overall weight of the query. */
	variables?: InputMaybe<Scalars['JSONString']>;
};

export type QueryComplexityOutputType = {
	__typename?: 'QueryComplexityOutputType';
	/** The complexity of the query. */
	complexityValue?: Maybe<Scalars['Int']>;
};

/** DEPRECATED - A set of products return to a prospective customer for quote supply points. */
export type Quote = {
	__typename?: 'Quote';
	/** The quote code that identifies the quote. */
	code: Scalars['String'];
	/** Quote-related params that are permitted to be fetched with the quote-code alone. */
	params?: Maybe<QuoteRequestParams>;
	/** Quoted generation points. */
	quotedGenerationPoints?: Maybe<Array<Maybe<QuotedSupplyPoint>>>;
	/** Quoted supply points. */
	quotedSupplyPoints: Array<Maybe<QuotedSupplyPoint>>;
};

/** Quote-related params that are permitted to be fetched with the quote-code alone. */
export type QuoteRequestParams = {
	__typename?: 'QuoteRequestParams';
	/** The subdomain of the affiliate link. */
	affiliateLinkSubdomain?: Maybe<Scalars['String']>;
	/** An optional audio recording id. */
	audioRecordingId?: Maybe<Scalars['String']>;
	/** The friend referral code. */
	referralCode?: Maybe<Scalars['String']>;
	/** The bespoke referral scheme code. */
	referralSchemeCode?: Maybe<Scalars['String']>;
};

export type QuoteShareInput = {
	/** Quote code. */
	quoteCode: Scalars['String'];
	/** Recipient information. */
	recipient: Recipient;
};

/** DEPRECATED - A single quoted generation point for a customers quote request. */
export type QuotedElectricityGenerationPoint = {
	__typename?: 'QuotedElectricityGenerationPoint';
	/** Annual estimated electricity generation. */
	annualEstimatedGeneration?: Maybe<Array<Maybe<Scalars['String']>>>;
	/** Whether we can connect the generation point or not, according to our business logic. */
	canSupply: Scalars['Boolean'];
	/** Grid operator code. */
	gridOperatorCode?: Maybe<Scalars['String']>;
	/** Whether the property of the generation point has multiple generation points. */
	hasMultipleSpins?: Maybe<Scalars['Boolean']>;
	/** Postal area for the quote. */
	postalArea?: Maybe<PostalArea>;
	/** Quoted products. */
	quotedProducts: Array<Maybe<QuotedElectricityProduct>>;
	/** If we cannot connect a generation point, this list of rejection reasons will show what errors fired. */
	rejectionReasons: Array<Maybe<CanSupplyRejectionType>>;
};

/** DEPRECATED - Customer's quoted product, for the quoted supply point. */
export type QuotedElectricityProduct = {
	__typename?: 'QuotedElectricityProduct';
	estimatedCosts: QuotedProductEstimatedCosts;
	product: Product;
};

/** DEPRECATED - Customer's quoted product, for the quoted supply point. */
export type QuotedElectricityProductProductArgs = {
	gridOperatorCode?: InputMaybe<Scalars['String']>;
};

/** DEPRECATED - A single quoted supply point for a customers quote request. */
export type QuotedElectricitySupplyPoint = {
	__typename?: 'QuotedElectricitySupplyPoint';
	amperage?: Maybe<Scalars['Int']>;
	annualEstimatedUsage: Array<Maybe<Scalars['String']>>;
	/** Whether we can supply the supply point or not, according to our business logic. */
	canSupply: Scalars['Boolean'];
	gridOperatorCode?: Maybe<Scalars['String']>;
	/** Whether the property of the supply point has multiple supply points. */
	hasMultipleSpins?: Maybe<Scalars['Boolean']>;
	kva?: Maybe<Scalars['Int']>;
	postalArea?: Maybe<PostalArea>;
	quotedProducts: Array<Maybe<QuotedElectricityProduct>>;
	/** If we cannot supply a supply point, this list of rejection reasons will show what errors fired. */
	rejectionReasons: Array<Maybe<CanSupplyRejectionType>>;
	supplyPointDetails?: Maybe<SupplyPointDetailsRaw>;
};

export type QuotedProductEstimatedCosts = {
	__typename?: 'QuotedProductEstimatedCosts';
	monthlyBreakdown: Array<Maybe<Scalars['Int']>>;
	quotedMonth: QuotedProductEstimatedCostsQuotedMonth;
	standingChargePricePerDay?: Maybe<Scalars['Decimal']>;
	standingChargeUnitType?: Maybe<Scalars['String']>;
};

export type QuotedProductEstimatedCostsQuotedMonth = {
	__typename?: 'QuotedProductEstimatedCostsQuotedMonth';
	cost: Scalars['String'];
	month: Scalars['String'];
	usageAmount?: Maybe<Scalars['String']>;
};

/** DEPRECATED - A single quoted supply point for a customers quote request. */
export type QuotedSupplyPoint =
	| QuotedElectricityGenerationPoint
	| QuotedElectricitySupplyPoint;

export type RateLimitInformation = {
	__typename?: 'RateLimitInformation';
	/** The maximum number of points the viewer gets for requests per hour. */
	limit?: Maybe<Scalars['Int']>;
	/** The remaining points for the viewer in one hour time limit. */
	remainingPoints?: Maybe<Scalars['Int']>;
	/** The points used so far in one hour time limit. */
	usedPoints?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum ReadingDirectionType {
	/** Reading is based on the customer's usage of the utility. */
	Consumption = 'CONSUMPTION',
	/**
	 *
	 * Reading is based on the utility generated by the customer.
	 *
	 * For example: This will return solar readings if a customer has solar panels installed at their location.
	 *
	 */
	Generation = 'GENERATION',
}

/** The frequency of the reading. */
export enum ReadingFrequencyType {
	/** Readings taken on a day to day basis. */
	Daily = 'DAILY',
	DayInterval = 'DAY_INTERVAL',
	/** Readings taken in every 15 minute intervals. */
	FifteenMinInterval = 'FIFTEEN_MIN_INTERVAL',
	/** Readings taken in every 5 minute intervals. */
	FiveMinInterval = 'FIVE_MIN_INTERVAL',
	HourInterval = 'HOUR_INTERVAL',
	MonthInterval = 'MONTH_INTERVAL',
	/** Readings taken at a point in time. */
	PointInTime = 'POINT_IN_TIME',
	QuarterInterval = 'QUARTER_INTERVAL',
	/** Interval Readings as provided, may be variable in length. */
	RawInterval = 'RAW_INTERVAL',
	/** Readings taken in every 30 minute intervals. */
	ThirtyMinInterval = 'THIRTY_MIN_INTERVAL',
	WeekInterval = 'WEEK_INTERVAL',
}

/** An enumeration. */
export enum ReadingQualityType {
	Actual = 'ACTUAL',
	Combined = 'COMBINED',
	Estimate = 'ESTIMATE',
}

/** The type of statistic for the reading interval. */
export enum ReadingStatisticTypeEnum {
	/** The estimated carbon cost of the interval. */
	CarbonCost = 'CARBON_COST',
	/** The calculated cost of consumption for the interval. */
	ConsumptionCost = 'CONSUMPTION_COST',
	/** The calculated monetary value of generation for the interval */
	GenerationValue = 'GENERATION_VALUE',
	/** The calculated cost of standing charges for the interval. */
	StandingChargeCost = 'STANDING_CHARGE_COST',
	/** The apportion cost of a time of use bucket for the interval. */
	TouBucketCost = 'TOU_BUCKET_COST',
}

export type Recipient = {
	/** Email address of the recipient. */
	email?: InputMaybe<Scalars['String']>;
	/** Family name of the recipient. */
	familyName?: InputMaybe<Scalars['String']>;
	/** Given name of the recipient. */
	givenName?: InputMaybe<Scalars['String']>;
	/** Mobile number of the recipient. */
	mobile?: InputMaybe<Scalars['String']>;
};

/**
 * Record the customer's acceptance of a deposit agreement.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type RecordDepositAgreementAccepted = {
	__typename?: 'RecordDepositAgreementAccepted';
	isRecorded?: Maybe<Scalars['Boolean']>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type RectangularButtonType = ButtonInterface &
	IdentifiableInterface & {
		__typename?: 'RectangularButtonType';
		/** The action to perform when the button is pressed. */
		buttonAction: ActionType;
		/** The button style. */
		buttonStyle?: Maybe<ButtonStyle>;
		/** Unique identifier of the object. */
		id?: Maybe<Scalars['ID']>;
		/** Title text of the button. */
		title: Scalars['String'];
		/** The name of the object's type. */
		typename?: Maybe<Scalars['String']>;
		/** Colour style of button eg. filled, outlined, text_only. */
		variant?: Maybe<ButtonVariance>;
	};

/**
 * Redeem Loyalty Points as account credit.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-9201: No Loyalty Point ledger found for the user.
 * - KT-CT-9202: Loyalty Points adapter not configured.
 * - KT-CT-9203: No ledger entries for the ledger.
 * - KT-CT-9205: Insufficient Loyalty Points.
 * - KT-CT-9206: Indivisible points.
 * - KT-CT-9204: Negative or zero points set.
 * - KT-CT-9208: Invalid posted at datetime.
 * - KT-CT-9209: Negative Loyalty Points balance.
 * - KT-CT-9210: Unhandled Loyalty Points exception.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type RedeemLoyaltyPointsForAccountCredit = {
	__typename?: 'RedeemLoyaltyPointsForAccountCredit';
	/** The number of OctoPoints that were redeemed. */
	pointsRedeemed?: Maybe<Scalars['Int']>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for redeeming Loyalty Points. */
export type RedeemLoyaltyPointsInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The number of Loyalty Points to redeem. */
	points: Scalars['Int'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-6723: Unauthorized.
 * - KT-CT-6724: Referral claim code not found.
 * - KT-CT-6725: Referral claim code redeeming error.
 * - KT-CT-6726: Referral claim code has already been redeemed.
 * - KT-CT-6727: Referral claim code is not available.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type RedeemReferralClaimCode = {
	__typename?: 'RedeemReferralClaimCode';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Required payload to redeem the benefit for partner reward referral scheme */
export type RedeemReferralClaimCodeInput = {
	/** The account number for the referred account. */
	accountNumber: Scalars['String'];
	/** Referral scheme claim code value. */
	code: Scalars['String'];
};

export type ReferralConnectionTypeConnection = {
	__typename?: 'ReferralConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<ReferralConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `ReferralConnectionType` and its cursor. */
export type ReferralConnectionTypeEdge = {
	__typename?: 'ReferralConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<ReferralType>;
};

export type ReferralInterface = {
	code?: Maybe<Scalars['String']>;
	/** The payment amount in yen received by the referrer and the referee combined. */
	combinedPaymentAmount?: Maybe<Scalars['Int']>;
	paymentDate?: Maybe<Scalars['Date']>;
	paymentStatus?: Maybe<Scalars['String']>;
	referredUserJoinDate?: Maybe<Scalars['DateTime']>;
	referredUserName?: Maybe<Scalars['String']>;
	/** Payment amount given to the referred account in yen. */
	referredUserPaymentAmount?: Maybe<Scalars['Int']>;
	/** Payment amount given to the referring account in yen. */
	referringUserPaymentAmount?: Maybe<Scalars['Int']>;
	/** The type of reward scheme. */
	schemeType?: Maybe<ReferralSchemeTypeChoices>;
};

/** A referral scheme is a way for one account to earn a reward for referring another. This is achieved by the referred account using a url (provided by the referring account) to sign up. */
export type ReferralSchemeType = {
	__typename?: 'ReferralSchemeType';
	/** Whether the current account is eligible to be referred under this scheme. */
	canBeReferred?: Maybe<Scalars['Boolean']>;
	/** The unique code for the scheme. */
	code?: Maybe<Scalars['String']>;
	/** The reward amount received by the referrer and the referee combined. */
	combinedRewardAmount?: Maybe<Scalars['Int']>;
	/** The number of loyalty points to be awarded to the referrer in addition to the reward amount. */
	loyaltyPointsBonus?: Maybe<Scalars['Int']>;
	/** A referral url for display purposes. */
	referralDisplayUrl?: Maybe<Scalars['String']>;
	/** A fully qualified url give people to create accounts referred by this scheme. */
	referralUrl?: Maybe<Scalars['String']>;
	/** The reward amount received by the referred party. */
	referredRewardAmount?: Maybe<Scalars['Int']>;
	/**
	 * The family name of the person making the referral.
	 * @deprecated The 'referringFamilyName' field is deprecated.
	 *
	 * Only make use of the referrerGivenName for privacy reasons.
	 *
	 * - Marked as deprecated on 2022-11-07.
	 * - Will be removed on 2023-01-07.
	 */
	referrerFamilyName?: Maybe<Scalars['String']>;
	/** The given name of the person making the referral. */
	referrerGivenName?: Maybe<Scalars['String']>;
	/** The reward amount received by the referrer. */
	referrerRewardAmount?: Maybe<Scalars['Int']>;
	/** Scheme type of the referral scheme. */
	schemeType?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum ReferralSchemeTypeChoices {
	/** Legacy Referral. */
	LegacyReferral = 'LEGACY_REFERRAL',
	/** Partner Reward. */
	PartnerReward = 'PARTNER_REWARD',
	/** Promo Reward. */
	PromoReward = 'PROMO_REWARD',
	/** Referral Reward. */
	ReferralReward = 'REFERRAL_REWARD',
	/** Signup Reward. */
	SignupReward = 'SIGNUP_REWARD',
}

export type ReferralSchemeTypes = {
	__typename?: 'ReferralSchemeTypes';
	business?: Maybe<ReferralSchemeType>;
	domestic?: Maybe<ReferralSchemeType>;
	/**
	 * @deprecated The 'friendsAndFamily' field is deprecated.
	 *
	 * Please use domestic instead.
	 *
	 * - Marked as deprecated on 2020-03-05.
	 * - Will be removed on 2024-01-01.
	 */
	friendsAndFamily?: Maybe<ReferralSchemeType>;
};

/** An enumeration. */
export enum ReferralStatusChoices {
	/** Cancelled. */
	Cancelled = 'Cancelled',
	/** Paid. */
	Paid = 'Paid',
	/** Pending. */
	Pending = 'Pending',
}

/** Details of an account referral */
export type ReferralType = ReferralInterface & {
	__typename?: 'ReferralType';
	code?: Maybe<Scalars['String']>;
	/** The payment amount in yen received by the referrer and the referee combined. */
	combinedPaymentAmount?: Maybe<Scalars['Int']>;
	id: Scalars['ID'];
	paymentDate?: Maybe<Scalars['Date']>;
	paymentStatus?: Maybe<Scalars['String']>;
	referredUserJoinDate?: Maybe<Scalars['DateTime']>;
	referredUserName?: Maybe<Scalars['String']>;
	/** Payment amount given to the referred account in yen. */
	referredUserPaymentAmount?: Maybe<Scalars['Int']>;
	/** Payment amount given to the referring account in yen. */
	referringUserPaymentAmount?: Maybe<Scalars['Int']>;
	/** The type of reward scheme. */
	schemeType?: Maybe<ReferralSchemeTypeChoices>;
};

/** An opaque token that can be used to renew a Kraken Token. */
export type RefreshToken = {
	__typename?: 'RefreshToken';
	/** The datetime when the token will expire. */
	expiryDt: Scalars['DateTime'];
	isValid?: Maybe<Scalars['Boolean']>;
	key: Scalars['String'];
};

/** A refund to the customer from the energy supplier. */
export type Refund = TransactionType & {
	__typename?: 'Refund';
	/** Unique identifier of the account the transaction belongs to. */
	accountNumber?: Maybe<Scalars['String']>;
	/**
	 * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
	 * @deprecated The 'amount' field is deprecated.
	 *
	 * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
	 *
	 * - Marked as deprecated on 2023-12-06.
	 * - Will be removed on 2024-06-01.
	 */
	amount?: Maybe<Scalars['Int']>;
	/** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
	amounts?: Maybe<TransactionAmountType>;
	/** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
	balanceCarriedForward?: Maybe<Scalars['Int']>;
	/** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
	billingDocumentIdentifier?: Maybe<Scalars['ID']>;
	/** The date time when the transaction is created. */
	createdAt?: Maybe<Scalars['DateTime']>;
	/** Returns True if the transaction is linked with a statement. */
	hasStatement?: Maybe<Scalars['Boolean']>;
	id?: Maybe<Scalars['ID']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isAccountCharge' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isAccountCharge?: Maybe<Scalars['Boolean']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isAccountPayment' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isAccountPayment?: Maybe<Scalars['Boolean']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isCredit' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isCredit?: Maybe<Scalars['Boolean']>;
	/** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
	isHeld?: Maybe<Scalars['Boolean']>;
	/** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
	isIssued?: Maybe<Scalars['Boolean']>;
	isReversed: Scalars['Boolean'];
	/** Returns the note field value for the transaction, which contains additional info. */
	note?: Maybe<Scalars['String']>;
	postedDate?: Maybe<Scalars['Date']>;
	/**
	 * Returns None if a statement is not linked with the transaction.
	 * @deprecated The 'statementId' field is deprecated.
	 *
	 * Use `billingDocumentIdentifier` instead.
	 *
	 * - Marked as deprecated on 2023-11-30.
	 * - Will be removed on 2024-06-01.
	 */
	statementId?: Maybe<Scalars['ID']>;
	title?: Maybe<Scalars['String']>;
};

export type RefundBankAccountStored = {
	__typename?: 'RefundBankAccountStored';
	/** Refunds Payment reference. */
	reference: Scalars['String'];
};

export enum RefundBankAccountTypeChoices {
	Current = 'CURRENT',
	Ordinary = 'ORDINARY',
	Saving = 'SAVING',
}

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3924: Unauthorized.
 * - KT-CT-3928: Idempotency key used for another repayment request.
 * - KT-CT-3929: The payment is not in a refundable state.
 * - KT-CT-3933: Refund amount greater than payment amount.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type RefundPayment = {
	__typename?: 'RefundPayment';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** The repayment for the requested refund. */
	repayment?: Maybe<AccountRepaymentType>;
};

export type RefundPaymentInput = {
	/** The account number. */
	accountNumber: Scalars['ID'];
	/** The amount to be repaid. */
	amountInMinorUnit: Scalars['Int'];
	/** Unique constraint to prevent duplicate requests. */
	idempotencyKey: Scalars['String'];
	/** The ID of the payment to refund. */
	paymentId: Scalars['ID'];
	/** Reason for refunding the payment. */
	reason: Scalars['String'];
};

export type RefundPaymentRequestType = {
	__typename?: 'RefundPaymentRequestType';
	/** The amount of money requested. */
	amount?: Maybe<Scalars['Int']>;
	/** The payment which is being refunded. */
	payment?: Maybe<AccountPaymentType>;
	/** Internal code for the reason the refund is being requested. */
	reasonCode?: Maybe<Scalars['String']>;
	/** The ID of the refund request. */
	requestId?: Maybe<Scalars['ID']>;
	/** The current status of the refund request. */
	status?: Maybe<RepaymentRequestStatus>;
};

export type RefundRequestConnectionTypeConnection = {
	__typename?: 'RefundRequestConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<RefundRequestConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `RefundRequestConnectionType` and its cursor. */
export type RefundRequestConnectionTypeEdge = {
	__typename?: 'RefundRequestConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<RefundPaymentRequestType>;
};

/** Regenerate the user's live secret key. */
export type RegenerateSecretKey = {
	__typename?: 'RegenerateSecretKey';
	/**
	 *  The currently authenticated user.
	 *
	 *
	 *
	 * This field requires the `Authorization` header to be set.
	 *
	 *
	 */
	viewer?: Maybe<AccountUser>;
};

export type RegisterDemandResponseCampaignParticipantInput = {
	/** Account number. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** Campaign name. */
	campaignName?: InputMaybe<Scalars['String']>;
};

export type RegisterDemandResponseCampaignParticipantRegistered = {
	__typename?: 'RegisterDemandResponseCampaignParticipantRegistered';
	/** Campaign name. */
	campaignName?: Maybe<Scalars['String']>;
	/** Date and time at which the participant was registered. */
	registeredAt?: Maybe<Scalars['DateTime']>;
};

/** Returned when a customer registers for a Demand Response campaign. */
export type RegisterDemandResponseCampaignParticipantResponse =
	| CommonError
	| RegisterDemandResponseCampaignParticipantRegistered;

export type RegisterPushNotificationBinding = {
	__typename?: 'RegisterPushNotificationBinding';
	pushNotificationBinding?: Maybe<PushNotificationBindingType>;
};

export type RegisterPushNotificationBindingInput = {
	/** Register a push notification binding. A push notification binding connects an account user to a specific application running on a specific device through a 'registration token' (Android) or 'device token' (iOS). Using this binding we can send push notifications to the account user's devices. */
	bundleId: Scalars['String'];
	/** Device push notification token. */
	token: Scalars['String'];
};

/** A payment schedule which triggers a payment at regular intervals. */
export type RegularTriggeredScheduleInput = {
	/** The based unit of frequency at which payments are to be taken. */
	frequency?: InputMaybe<ScheduleFrequencyEnum>;
	/** The multiple of the frequency at which payment are taken; should be between 1 and 11. */
	frequencyMultiplier?: InputMaybe<Scalars['Int']>;
	/** The day of the month/week at which to take payment; ranges from 1 to 28 for 'Monthly'. */
	paymentDay?: InputMaybe<Scalars['Int']>;
};

/** Input fields for Repayment Intervention. */
export type RepaymentInput = {
	/** The Repayment Intervention reason. */
	reason?: InputMaybe<Scalars['String']>;
	/** The repayment ID. */
	repaymentId: Scalars['ID'];
};

export type RepaymentInterventionType = {
	__typename?: 'RepaymentInterventionType';
	/** The repayment intervention outcome. */
	outcome?: Maybe<Scalars['String']>;
	/** The repayment intervention reason. */
	reason?: Maybe<Scalars['String']>;
};

/**
 *
 *     Methods by which repayments can be sent to the customer.
 *
 */
export enum RepaymentMethod {
	BankTransfer = 'BANK_TRANSFER',
	Card = 'CARD',
	Cheque = 'CHEQUE',
}

export type RepaymentRequestConnectionTypeConnection = {
	__typename?: 'RepaymentRequestConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<RepaymentRequestConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `RepaymentRequestConnectionType` and its cursor. */
export type RepaymentRequestConnectionTypeEdge = {
	__typename?: 'RepaymentRequestConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<RepaymentRequestType>;
};

/**
 *
 *     Possible status' for a repayment (or refund) request
 *
 */
export enum RepaymentRequestStatus {
	Accepted = 'ACCEPTED',
	Cancelled = 'CANCELLED',
	Rejected = 'REJECTED',
	Requested = 'REQUESTED',
}

export type RepaymentRequestType = {
	__typename?: 'RepaymentRequestType';
	/** The amount of money requested. */
	amount?: Maybe<Scalars['Int']>;
	/** The payment instruction, if any, associated with the repayment request. */
	instruction?: Maybe<PaymentInstructionType>;
	/** The method by which the money will be transferred to the customer. */
	method?: Maybe<RepaymentMethod>;
	/** Classifier code for repayment reason. */
	reasonCode?: Maybe<Scalars['String']>;
	/** The ID of the repayment request. */
	requestId?: Maybe<Scalars['String']>;
	/** The current status of the repayment request. */
	status?: Maybe<RepaymentRequestStatus>;
};

/**
 * Create an amperage change process.
 *
 * This mutation is used for amperage change requests by organizations.
 *
 * The possible errors that can be raised are:
 *
 * - KT-JP-4511: Invalid data.
 * - KT-CT-4501: Unauthorized.
 * - KT-JP-4501: Unauthorized.
 * - KT-JP-4502: Unauthorized.
 * - KT-JP-4512: Unable to process amperage change request.
 * - KT-JP-4513: Unable to process amperage change request.
 * - KT-JP-4520: Unable to process request.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type RequestAmperageChange = {
	__typename?: 'RequestAmperageChange';
	/** Success message. */
	message?: Maybe<Scalars['String']>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type RequestAmperageChangeInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** Whether the customer requires to be contacted by the grid operator before the amperage change takes place. */
	isContactRequired?: InputMaybe<Scalars['Boolean']>;
	/** If the grid operator needs to visit the property to make a physical change, the time at which the meeting should happen. */
	meetingRequestTimeCode?: InputMaybe<TimeOptions>;
	/** The phone number to contact the customer on, in case it differs from the number registered with the grid operator. */
	phoneNumber?: InputMaybe<Scalars['String']>;
	/** The desired new amperage value. */
	requestedAmperageValue: Amperage;
	/** The date in which the amperage change should take effect. */
	requestedDate: Scalars['Date'];
	/** The supply point identification number. */
	spin: Scalars['String'];
};

/** Input type for the RequestPasswordReset mutation. */
export type RequestPasswordResetInput = {
	/** The email requesting a password reset email. */
	email: Scalars['String'];
};

export type RequestPasswordResetOutputType = {
	__typename?: 'RequestPasswordResetOutputType';
	/** The email that requested a password reset email. */
	email?: Maybe<Scalars['String']>;
};

export type RequestRefundEligibilityType = {
	__typename?: 'RequestRefundEligibilityType';
	/** Whether the account can request a refund. */
	canRequestRefund: Scalars['Boolean'];
	/** The reason why a refund cannot be requested. */
	reason?: Maybe<Scalars['String']>;
};

export type RequestRepaymentInputType = {
	/** The account number for the requested ledger's account. */
	accountNumber: Scalars['String'];
	/** The amount to be repaid. */
	amountInMinorUnit: Scalars['Int'];
	/** Unique constraint to prevent duplicate requests. */
	idempotencyKey: Scalars['String'];
	/** The ledger id from which the repayment will be requested. */
	ledgerId: Scalars['String'];
	/** The method by which the money will be transferred to the customer. */
	method?: InputMaybe<RequestableRepaymentMethod>;
	/** The reason for the repayment. */
	reason?: InputMaybe<Scalars['String']>;
};

/** Output for creating a repayment request. */
export type RequestRepaymentOutputType = {
	__typename?: 'RequestRepaymentOutputType';
	/** The ID of the repayment request. */
	requestId?: Maybe<Scalars['String']>;
	/** The current status of the repayment request. */
	status?: Maybe<RepaymentRequestStatus>;
};

/** An enumeration. */
export enum RequestableRepaymentMethod {
	BankTransfer = 'BANK_TRANSFER',
	Cheque = 'CHEQUE',
}

export type ResetPasswordMutationInput = {
	clientMutationId?: InputMaybe<Scalars['String']>;
	password: Scalars['String'];
	token: Scalars['String'];
	userId: Scalars['String'];
};

export type ResetPasswordMutationPayload = {
	__typename?: 'ResetPasswordMutationPayload';
	clientMutationId?: Maybe<Scalars['String']>;
	errors?: Maybe<Array<Maybe<SerializerFieldErrorsType>>>;
};

export type ResetUserPasswordInput = {
	/** The new password. */
	newPassword: Scalars['String'];
	/** The token from the presigned url. */
	token: Scalars['String'];
	/** A base64 bytestring representing the user's unique id. */
	userId: Scalars['String'];
};

export type ResetUserPasswordOutput = {
	__typename?: 'ResetUserPasswordOutput';
	/** A list of which password validations the new password failed against if applicable. */
	failureReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
	/** True if the password update was successful, false otherwise. */
	passwordUpdated?: Maybe<Scalars['Boolean']>;
};

export type Retailer = {
	__typename?: 'Retailer';
	code: Scalars['String'];
	displayName?: Maybe<Scalars['String']>;
	displayOrder?: Maybe<Scalars['Int']>;
	name: Scalars['String'];
	params: RetailerParams;
};

export type RetailerParams = {
	__typename?: 'RetailerParams';
	/** A list of aliases to use with autocomplete suggestions. */
	aliases: Array<Scalars['String']>;
};

/** Revert a product switch by revoking an unbilled agreement and restoring the previous one. */
export type RevertProductSwitch = {
	__typename?: 'RevertProductSwitch';
	/** The restored agreement. */
	agreement?: Maybe<Agreement>;
};

export type RevertProductSwitchInput = {
	/** A code that uniquely identifies the account. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** Supply Point Identification Number. */
	spin: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-1501: Agreement not found.
 * - KT-CT-1502: Billed agreements cannot be revoked.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type RevokeAgreement = {
	__typename?: 'RevokeAgreement';
	/** Account responsible for the revoked agreement. */
	account?: Maybe<Account>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type RevokeAgreementInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The ID of the agreement to be revoked. */
	agreementId: Scalars['ID'];
	/** The reason for revoking the agreement. */
	reason?: InputMaybe<Scalars['String']>;
};

export type RewardInterface = {
	paymentDate?: Maybe<Scalars['Date']>;
	/** The status of the reward payment. */
	paymentStatus?: Maybe<ReferralStatusChoices>;
	/** Reward amount given to the account in yen. */
	rewardAmount?: Maybe<Scalars['Int']>;
	/** The type of reward scheme. */
	schemeType?: Maybe<ReferralSchemeTypeChoices>;
};

/**
 *
 *             A reward is based on a scheme that an account has applied for in order to be
 *             eligible for a discount. Examples can include signup, promo, or partner codes that
 *             were applied to an account.
 *
 */
export type RewardType = RewardInterface & {
	__typename?: 'RewardType';
	id: Scalars['ID'];
	paymentDate?: Maybe<Scalars['Date']>;
	/** The status of the reward payment. */
	paymentStatus?: Maybe<ReferralStatusChoices>;
	/** Reward amount given to the account in yen. */
	rewardAmount?: Maybe<Scalars['Int']>;
	/** The type of reward scheme. */
	schemeType?: Maybe<ReferralSchemeTypeChoices>;
};

/**
 *
 * A postal address.
 *
 * This data model is based on the structure used by Google's
 * [libaddressinput library](https://github.com/google/libaddressinput)&mdash;so
 * you can use it, or other libraries that use its data model
 * and reference data, to accept input.
 *
 * All fields can be blank, except for ``country`` which must
 * always be supplied.
 *
 */
export type RichAddressType = {
	__typename?: 'RichAddressType';
	/**
	 *
	 * Top-level administrative subdivision, e.g. US state, AU
	 * state/territory, IT region, JP prefecture.
	 *
	 * ### `AU`: Australia
	 *
	 * This must be one of `NSW`, `VIC`, `QLD`, `TAS`, `ACT`, `SA`,
	 * `NT`, `WA`. For addresses not within these locations, use
	 * the value that Australia Post uses, e.g. `ACT` for the
	 * Jervis Bay Territory or `WA` for Christmas Island.
	 *
	 */
	administrativeArea?: Maybe<Scalars['String']>;
	/**
	 *
	 * ISO 3166-1 alpha-2 code of the country this address belongs
	 * to, e.g. `AU`, `GB`.
	 *
	 */
	country?: Maybe<Scalars['String']>;
	/**
	 *
	 * Identifier used by the local postal service for this
	 * address, e.g. AU DPID, GB postcode + Delivery Point Suffix,
	 * US Zip-9 + Delivery Point.
	 *
	 * This is the value that gets encoded in the barcode printed
	 * on the envelope by large-volume bulk mail providers.
	 *
	 */
	deliveryPointIdentifier?: Maybe<Scalars['String']>;
	/**
	 *
	 * UK dependent localities, or neighbourhoods or boroughs in
	 * some other locations.
	 *
	 */
	dependentLocality?: Maybe<Scalars['String']>;
	/**
	 *
	 * City or town portion of an address, e.g. US city, AU
	 * suburb/town, IT comune, UK post town.
	 *
	 */
	locality?: Maybe<Scalars['String']>;
	/** A personal name. */
	name?: Maybe<Scalars['String']>;
	/** The name of a business or organisation. */
	organization?: Maybe<Scalars['String']>;
	/**
	 *
	 * Postal code (ZIP code in the US).
	 *
	 */
	postalCode?: Maybe<Scalars['String']>;
	/**
	 *
	 * Sorting code, e.g. FR CEDEX code. This field is not used in many countries.
	 *
	 */
	sortingCode?: Maybe<Scalars['String']>;
	/**
	 *
	 * The 'street address' component.
	 *
	 * This value can (and often will) contain newline characters
	 * when appropriate.
	 *
	 * In some cases, data may appear in this field instead of the
	 * below fields; e.g. a UK post town name may appear here
	 * instead of in the `dependent_locality` field. This happens
	 * when data has been migrated from a legacy format, and that
	 * format had insufficient metadata to determine the
	 * appropriate field.
	 *
	 * If `structured_street_address` is also set, the value of
	 * this field will be a string generated from that value.
	 *
	 */
	streetAddress?: Maybe<Scalars['String']>;
	/**
	 *
	 * The 'street address' component, in a structured format.
	 *
	 * This field stores the same value as `street_address`, but
	 * with more detail; for instance, instead of `123 Example
	 * Street` it might be `{'street_number': '123',
	 * 'street_name': 'Example', 'street_type': 'Street'}`. In
	 * many cases this will be blank; we only use this field for
	 * Krakens where we need to supply this level of granularity
	 * to some third-party service, like a bulk mail provider.
	 *
	 * The exact structure of this value depends on the country _of
	 * the address_, which is not necessarily the same as the
	 * country this Kraken is configured to serve. For addresses
	 * outside of the countries listed below, this field will be
	 * left blank.
	 *
	 * ### `AU`: Australia
	 *
	 * The following keys may be present; all are optional. All
	 * keys have string values, and their meaning is the same as
	 * their aseXML counterparts. (Note that, unlike aseXML, all
	 * keys are provided at the top level, rather than being
	 * nested.)
	 *
	 * - `flat_or_unit_type`
	 * - `flat_or_unit_number`
	 * - `floor_or_level_type`
	 * - `floor_or_level_number`
	 * - `building_or_property_name`
	 * - `location_descriptor`
	 * - `lot_number`
	 * - `house_number_1`
	 * - `house_number_suffix_1`
	 * - `house_number_2`
	 * - `house_number_suffix_2`
	 * - `street_name`
	 * - `street_type`
	 * - `street_suffix`
	 * - `postal_delivery_type`
	 * - `postal_delivery_number_prefix`
	 * - `postal_delivery_number_value`
	 * - `postal_delivery_number_suffix`
	 *
	 * ### `JP`: Japan
	 *
	 * The following keys may be present; all are optional.
	 * If keys are empty, they may be omitted from the response entirely.
	 *
	 * - `chome`
	 * - `banchi`
	 * - `go`
	 * - `edaban`
	 * - `kana_building_name`
	 * - `kanji_building_name`
	 * - `building_number`
	 * - `room_number`
	 * - `address_code`
	 * - `physical_location_identifier`
	 *
	 */
	structuredStreetAddress?: Maybe<Scalars['GenericScalar']>;
};

/** An enumeration. */
export enum SalesChannelChoices {
	Acquisition = 'ACQUISITION',
	Aggregator = 'AGGREGATOR',
	B2B2C = 'B2B2C',
	Direct = 'DIRECT',
	Events = 'EVENTS',
	FieldSales = 'FIELD_SALES',
	LifelineAgency = 'LIFELINE_AGENCY',
	NewTenant = 'NEW_TENANT',
	Partnerships = 'PARTNERSHIPS',
	PriceComparison = 'PRICE_COMPARISON',
	PvBattery = 'PV_BATTERY',
	TeleSales = 'TELE_SALES',
}

/** Information about the sale to associate with the account. */
export type SalesInformationInput = {
	/** Affiliate params. */
	affiliateParams?: InputMaybe<Scalars['String']>;
	/** Affiliate subdomain. */
	affiliateSubdomain?: InputMaybe<Scalars['String']>;
	/** The referral code used by the customer when signing up. */
	referralCode?: InputMaybe<Scalars['String']>;
	/** Sales channel. */
	salesChannel: Scalars['String'];
	/** Sales subchannel. */
	salesSubchannel?: InputMaybe<Scalars['String']>;
	/** Unique reference number. */
	urn?: InputMaybe<Scalars['String']>;
};

/**
 * Mutation to save a user's onboarding journey progress.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4122: Invalid email.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type SaveOnboardingJourneyProgress = {
	__typename?: 'SaveOnboardingJourneyProgress';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** Boolean indicator that save onboarding journey progress is sent. */
	success?: Maybe<Scalars['Boolean']>;
};

export type SaveOnboardingJourneyProgressInput = {
	/** The email address the user specifies to send the url to. */
	emailAddress: Scalars['String'];
	/** To save a user's progress on the onboarding journey, the completed form fields are encoded as a string that can be attached to the url. */
	encodedFormFields: Scalars['String'];
	/** The url path for the specified journey type. */
	path: Scalars['String'];
};

export enum ScheduleFrequencyEnum {
	Monthly = 'MONTHLY',
	Weekly = 'WEEKLY',
}

/**
 * Schedule a quote follow up message to the provided recipient.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4619: Quote with given code not found.
 * - KT-CT-4632: Invalid recipient information.
 * - KT-CT-4633: Mutation not enabled in this environment.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type ScheduleQuoteFollowUp = {
	__typename?: 'ScheduleQuoteFollowUp';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** Whether the message was scheduled successfully. */
	success: Scalars['Boolean'];
};

/** An enumeration. */
export enum ScheduleType {
	BacsTransfer = 'BACS_TRANSFER',
	CardPayment = 'CARD_PAYMENT',
	DirectDebit = 'DIRECT_DEBIT',
	PaymentSlip = 'PAYMENT_SLIP',
}

/** An action which calls another backend screen via its screen id. */
export type ScreenActionType = ActionInterface &
	IdentifiableInterface & {
		__typename?: 'ScreenActionType';
		/** Whether to allow returning to the original caller screen. */
		allowBack: Scalars['Boolean'];
		/** Unique identifier of the object. */
		id?: Maybe<Scalars['ID']>;
		/** Map of the parameters (key-value pairs) to pass to the next backend screen. */
		params: Array<Maybe<BackendScreenParam>>;
		/** The ID of the screen to navigate to. */
		screenId: Scalars['String'];
		/** The name of the action object's type. */
		typeName?: Maybe<Scalars['String']>;
		/** The name of the object's type. */
		typename?: Maybe<Scalars['String']>;
	};

export type SectionContent = CardComponentType | CarouselItemType;

/** A section containing a list of cards or carousel items */
export type SectionType = IdentifiableInterface & {
	__typename?: 'SectionType';
	/** The content of the section. */
	content: SectionContent;
	/** Unique identifier of the object. */
	id?: Maybe<Scalars['ID']>;
	/** The order of the section. */
	order: Scalars['Int'];
	/** The name of the object's type. */
	typename?: Maybe<Scalars['String']>;
};

export type SelfServeSwitchInCorrectionInput = {
	/** Customer's contract number with the current supplier. */
	contractNumber?: InputMaybe<Scalars['String']>;
	/** Customer's name under contract with the current supplier. */
	customerName?: InputMaybe<Scalars['String']>;
	/** Customer's name (kana) under contract with the current supplier. */
	customerNameKana?: InputMaybe<Scalars['String']>;
	/** The ID of the request for supply. */
	requestId: Scalars['ID'];
	/** Current supplier's code. */
	retailerCode?: InputMaybe<Scalars['String']>;
};

/** An enumeration. */
export enum SelfServiceAction {
	ContractNumberCorrection = 'CONTRACT_NUMBER_CORRECTION',
	CustomerNameCorrection = 'CUSTOMER_NAME_CORRECTION',
	RetailerCorrection = 'RETAILER_CORRECTION',
}

/**
 * Mutation to send family switch in offer to the customer.
 * This mutation calls the process to send family switch in offer to the customer.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type SendFamilySwitchInOffer = {
	__typename?: 'SendFamilySwitchInOffer';
	/** Boolean indicator that indicated family switch in offer is sent. */
	hasSent?: Maybe<Scalars['Boolean']>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type SendFamilySwitchInOfferInput = {
	/** Account number of the customer. */
	accountNumber: Scalars['String'];
};

/**
 * Trigger the sending of a quote summary to the provided recipient.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4619: Quote with given code not found.
 * - KT-CT-4632: Invalid recipient information.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type SendQuoteSummary = {
	__typename?: 'SendQuoteSummary';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** Whether the triggering of the quote summary was successful. */
	success: Scalars['Boolean'];
};

export type SerializerErrorType = {
	__typename?: 'SerializerErrorType';
	code?: Maybe<Scalars['String']>;
	message?: Maybe<Scalars['String']>;
};

export type SerializerFieldErrorsType = {
	__typename?: 'SerializerFieldErrorsType';
	errors?: Maybe<Array<ErrorTypeUnion>>;
	field?: Maybe<Scalars['String']>;
};

/**
 * Set the Loyalty Points user for the account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-9210: Unhandled Loyalty Points exception.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type SetLoyaltyPointsUser = {
	__typename?: 'SetLoyaltyPointsUser';
	/** ID of the new Loyalty Points user. */
	newLoyaltyPointsUserId?: Maybe<Scalars['String']>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for setting the Loyalty Points user. */
export type SetLoyaltyPointsUserInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The account user receiving the points. */
	newLoyaltyPointsUserId: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3940: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type SetUpDirectDebitInstruction = {
	__typename?: 'SetUpDirectDebitInstruction';
	paymentInstruction?: Maybe<DirectDebitInstructionType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type SetUpDirectDebitInstructionInput = {
	accountNumber: Scalars['String'];
	bankDetails: BankDetailsInput;
	ledgerId: Scalars['ID'];
	validFrom: Scalars['DateTime'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4122: Invalid email.
 * - KT-CT-8203: Received an invalid quote code.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type ShareGoodsQuote = {
	__typename?: 'ShareGoodsQuote';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** Goods quote shared. */
	share?: Maybe<GoodsQuoteShare>;
};

export type ShareGoodsQuoteInput = {
	/** The email to share the quote with. */
	email: Scalars['String'];
	/** The quote to share. */
	quoteCode: Scalars['String'];
};

/** Sizes for elements. */
export type SizedItemInterface = {
	/** The measurement of the element. */
	width?: Maybe<ItemSizeType>;
};

/** An enumeration. */
export enum Songs {
	/** . */
	NoSongPreferred = 'NO_SONG_PREFERRED',
}

/** Any special circumstances that the user has notified us about, which may entitle them to some specialist services. */
export type SpecialCircumstanceRecordType = {
	__typename?: 'SpecialCircumstanceRecordType';
	id?: Maybe<Scalars['ID']>;
	internalCode?: Maybe<Scalars['String']>;
	summary?: Maybe<Scalars['String']>;
};

export type SpecialCircumstanceRecordUnion =
	| SpecialCircumstanceRecordType
	| TemporarySpecialCircumstanceRecordType;

/** Information about the special circumstances that relate to a user. */
export type SpecialCircumstancesType = {
	__typename?: 'SpecialCircumstancesType';
	/** Whether the user has consented for their data to be given to the appropriate industry or regulatory bodies. We typically only ask for this once, so this field can be used to decide whether to ask the user for their initial consent. */
	isSharingConsentGiven?: Maybe<Scalars['Boolean']>;
	records?: Maybe<Array<Maybe<SpecialCircumstanceRecordUnion>>>;
};

export type StandingCharge = {
	__typename?: 'StandingCharge';
	/** The product rate band information. */
	band?: Maybe<Scalars['String']>;
	/** Duration in months for discount product rates. */
	durationMonths?: Maybe<Scalars['Int']>;
	/** Grid operator. */
	gridOperatorCode?: Maybe<Scalars['String']>;
	/** Price per ampere per day without tax. */
	pricePerUnit: Scalars['Decimal'];
	/** Price per ampere per day including tax. */
	pricePerUnitIncTax: Scalars['Decimal'];
	/** Region of operation. */
	regionOfOperation?: Maybe<Scalars['String']>;
	/** What are we charging for (kWhs consumed, Days on supply, etc). */
	unitType?: Maybe<UnitType>;
	/** Rate validity start. */
	validFrom?: Maybe<Scalars['DateTime']>;
	/** Rate validity end. */
	validTo?: Maybe<Scalars['DateTime']>;
};

export type StandingChargeRate = {
	__typename?: 'StandingChargeRate';
	/** The standing charge's label. */
	label: Scalars['String'];
	/** Price per unit per day including tax. */
	pricePerUnitIncTax: Scalars['Decimal'];
	/** The standing charge's unit type. */
	unitType: Scalars['String'];
};

export type StandingChargeValues = {
	__typename?: 'StandingChargeValues';
	/** The amperage the standing charge is for. */
	amperage?: Maybe<Array<Maybe<StandingChargeRate>>>;
	/** The kVA that the standing charge is for. */
	kva?: Maybe<Array<Maybe<StandingChargeRate>>>;
};

/** A statement is a billing document that contains all entries on a ledger during a period of time. A customer can understand how their ledger's balance has changed by looking at each statement in series. */
export type StatementBillingDocumentConnectionTypeConnection = {
	__typename?: 'StatementBillingDocumentConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<StatementBillingDocumentConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `StatementBillingDocumentConnectionType` and its cursor. */
export type StatementBillingDocumentConnectionTypeEdge = {
	__typename?: 'StatementBillingDocumentConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<StatementBillingDocumentType>;
};

/** A statement is a billing document that contains all entries on a ledger during a period of time. A customer can understand how their ledger's balance has changed by looking at each statement in series. */
export type StatementBillingDocumentType = {
	__typename?: 'StatementBillingDocumentType';
	/** Billing document that annuls this statement. */
	annulledBy?: Maybe<AnnulmentBillingDocumentType>;
	/** The earliest charge date of the statement. */
	earliestChargeAt?: Maybe<Scalars['DateTime']>;
	/** The end of the statement's period. */
	endAt: Scalars['DateTime'];
	/** The date and time the statement was sent to the customer. */
	firstIssuedAt?: Maybe<Scalars['DateTime']>;
	id?: Maybe<Scalars['Int']>;
	/** The unique reference of the statement that can be used for identifying the statement externally. */
	identifier?: Maybe<Scalars['String']>;
	/** The latest charge date of the statement. */
	latestChargeAt?: Maybe<Scalars['DateTime']>;
	/** URL to the PDF of the statement. */
	pdfUrl?: Maybe<Scalars['String']>;
	/** The start of the statement's period. */
	startAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum StatementReversalsAfterClose {
	/** All charges have been reversed after the statement was closed. */
	All = 'ALL',
	/** No reversals after the statement was closed. */
	None = 'NONE',
	/** The statement has not been closed yet. */
	NotClosed = 'NOT_CLOSED',
	/** Some charges have been reversed after the statement was closed. */
	Some = 'SOME',
}

export type StatementTotalType = {
	__typename?: 'StatementTotalType';
	/** The gross total amount for the statement (in minor currency units). */
	grossTotal?: Maybe<Scalars['Int']>;
	/** The net total amount for the statement (in minor currency units). */
	netTotal?: Maybe<Scalars['Int']>;
	/** The total amount of tax on the statement (in minor currency units). */
	taxTotal?: Maybe<Scalars['Int']>;
};

export type StatementType = BillInterface & {
	__typename?: 'StatementType';
	billType?: Maybe<BillTypeEnum>;
	/** This field returns the closing balance of an issued statement. */
	closingBalance?: Maybe<Scalars['Int']>;
	/** The last day of consumption that this statement includes. */
	consumptionEndDate?: Maybe<Scalars['Date']>;
	/** The first day of consumption that this statement includes. */
	consumptionStartDate?: Maybe<Scalars['Date']>;
	fromDate?: Maybe<Scalars['Date']>;
	/** Retrieve the held status of a account statement. */
	heldStatus?: Maybe<HeldStatus>;
	id?: Maybe<Scalars['ID']>;
	/** Whether the bill originated in Kraken or externally. */
	isExternalBill?: Maybe<Scalars['Boolean']>;
	/** The date the bill was sent to the customer. */
	issuedDate?: Maybe<Scalars['Date']>;
	/** This field returns the opening balance of a statement. */
	openingBalance?: Maybe<Scalars['Int']>;
	/** The date the bill is due to be paid. */
	paymentDueDate?: Maybe<Scalars['Date']>;
	/** How many charges have been reversed after the close date. */
	reversalsAfterClose: StatementReversalsAfterClose;
	/** Current status of the associated statement. */
	status?: Maybe<AccountStatementStatus>;
	/**
	 * Requesting this field generates a temporary URL at which bill is available.
	 *         This URL will expire after approximately an hour.  It is intended for redirection purposes,
	 *         NOT persistence in any form (e.g. inclusion in emails or the body of a web page).
	 *         This field can raise an error with errorClass NOT_FOUND if the bill document has not
	 *         been created/issued yet.
	 *
	 */
	temporaryUrl?: Maybe<Scalars['String']>;
	/** Email recipient address. */
	toAddress?: Maybe<Scalars['String']>;
	toDate?: Maybe<Scalars['Date']>;
	/** The total amounts for all charges on the statement. */
	totalCharges?: Maybe<StatementTotalType>;
	/** The total amounts for all credits on the statement. */
	totalCredits?: Maybe<StatementTotalType>;
	/** Transactions on the bill. */
	transactions?: Maybe<TransactionConnectionTypeConnection>;
	/** Email recipient user ID. */
	userId?: Maybe<Scalars['Int']>;
};

export type StatementTypeTransactionsArgs = {
	after?: InputMaybe<Scalars['String']>;
	before?: InputMaybe<Scalars['String']>;
	first?: InputMaybe<Scalars['Int']>;
	last?: InputMaybe<Scalars['Int']>;
};

export type StatisticOutput = {
	__typename?: 'StatisticOutput';
	/** Monetary cost of the statistic (excluding tax), if applicable. */
	costExclTax?: Maybe<EstimatedMoneyType>;
	/** Monetary cost of the statistic (including tax), if applicable. */
	costInclTax?: Maybe<EstimatedMoneyType>;
	/** Description of the statistic for the parent node. */
	description?: Maybe<Scalars['String']>;
	/** Display label of the statistic for the parent node. */
	label?: Maybe<Scalars['String']>;
	/** The type of statistic being measured for the parent node. */
	type?: Maybe<ReadingStatisticTypeEnum>;
	/** Consumption / generation value of the statistic, if applicable. */
	value?: Maybe<Scalars['Decimal']>;
};

/** Unit rate which is valid within a consumption range. */
export type SteppedConsumptionRate = {
	__typename?: 'SteppedConsumptionRate';
	/** The product rate band information. */
	band?: Maybe<Scalars['String']>;
	/** Duration in months for discount product rates. */
	durationMonths?: Maybe<Scalars['Int']>;
	/** Grid operator. */
	gridOperatorCode?: Maybe<Scalars['String']>;
	/** Price per kWh without tax. */
	pricePerUnit: Scalars['Decimal'];
	/** Price per kWh including tax. */
	pricePerUnitIncTax: Scalars['Decimal'];
	/** Region of operation. */
	regionOfOperation?: Maybe<Scalars['String']>;
	/** The kWh that the unit rate is valid until. */
	stepEnd?: Maybe<Scalars['Int']>;
	/** The kWh that the unit rate is valid from. */
	stepStart?: Maybe<Scalars['Int']>;
	/** Time of use label for the rate. */
	timeOfUse?: Maybe<Scalars['String']>;
	/** What are we charging for (kWhs consumed, Days on supply, etc). */
	unitType?: Maybe<UnitType>;
	/** Rate validity start. */
	validFrom?: Maybe<Scalars['DateTime']>;
	/** Rate validity end. */
	validTo?: Maybe<Scalars['DateTime']>;
};

/** Store bank account details in GMO and get a token to be used when creating a Kraken account. */
export type StoreBankAccountDetails = {
	__typename?: 'StoreBankAccountDetails';
	/** Financial institution redirect URL. */
	financialInstitutionRedirectUrl?: Maybe<Scalars['String']>;
	/** Financial institution token. */
	token?: Maybe<Scalars['String']>;
};

/**
 * Store a new payment instruction created through the embedded process.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4177: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type StorePaymentInstruction = {
	__typename?: 'StorePaymentInstruction';
	/** The stored payment instruction. */
	paymentInstruction?: Maybe<PaymentInstructionType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input for storing a new payment instruction created through the embedded process. */
export type StorePaymentInstructionInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The type of the new payment instruction. */
	instructionType: PaymentType;
	/**
	 * **WARNING: Will be mandatory in future versions**
	 *
	 *  The ledger ID to which the instructions will be linked.
	 */
	ledgerId?: InputMaybe<Scalars['String']>;
	/** The datetime from which the instruction is vaild. */
	validFrom: Scalars['DateTime'];
	/** The vendor's reference for this payment method. */
	vendorReference: Scalars['String'];
};

/** Returned when a customer stores a refund bank account from their MyPage. */
export type StoreRefundBankAccountResponse =
	| CommonError
	| RefundBankAccountStored;

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-5514: Unable to submit feedback.
 * - KT-CT-5511: The feedback_id should be provided for feedback source.
 * - KT-CT-5512: The feedback doesn't match the account.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type SubmitCustomerFeedback = {
	__typename?: 'SubmitCustomerFeedback';
	customerFeedback?: Maybe<CustomerFeedbackType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Mutation for executing the repayment request use case.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1132: Unauthorized.
 * - KT-CT-3927: Invalid Amount.
 * - KT-CT-3928: Idempotency key used for another repayment request.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type SubmitRepaymentRequest = {
	__typename?: 'SubmitRepaymentRequest';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** The newly created repayment request. */
	repaymentRequest?: Maybe<RequestRepaymentOutputType>;
};

export type SuggestFixedPaymentAmountInput = {
	/** Input a customer account number. */
	accountNumber: Scalars['String'];
};

/** Returned when a customer creates a fixed payment schedule from their MyPage. */
export type SuggestFixedPaymentAmountResponse =
	| CommonError
	| FixedPaymentAmountSuggested;

export type SupplementaryLedgerInterface = {
	/** The current final balance of the ledger in pence. */
	currentBalance?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['ID']>;
	ledgerType?: Maybe<Scalars['String']>;
	/** The display name of the ledger. */
	name?: Maybe<Scalars['String']>;
	paymentAdequacy?: Maybe<PaymentAdequacyDetailsType>;
};

/** Ledgers provide the foundation of Kraken’s bookkeeping functionality. Similar to a bank account, they allow us to keep track of financial activity on a particular Kraken account. */
export type SupplementaryLedgerType = SupplementaryLedgerInterface & {
	__typename?: 'SupplementaryLedgerType';
	/** The current final balance of the ledger in pence. */
	currentBalance?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['ID']>;
	ledgerType?: Maybe<Scalars['String']>;
	/** The display name of the ledger. */
	name?: Maybe<Scalars['String']>;
	paymentAdequacy?: Maybe<PaymentAdequacyDetailsType>;
};

export type SupplyDetails = {
	__typename?: 'SupplyDetails';
	/** Supply details capacity in Amperes. */
	amperage?: Maybe<Scalars['Int']>;
	/** Supply details capacity in kVA. */
	kva?: Maybe<Scalars['Int']>;
	/** Supply details capacity in kW. */
	kw?: Maybe<Scalars['Decimal']>;
	/** Date when supply details came into effect. This can be before the account came on supply. */
	validFrom?: Maybe<Scalars['Date']>;
};

/** Time slot requested by the customer for on-site attendance. */
export enum SupplyEndScheduledTime {
	Am = 'AM',
	Pm_1_3 = 'PM_1_3',
	Pm_3_5 = 'PM_3_5',
	Pm_5_7 = 'PM_5_7',
}

export type SupplyPeriodType = {
	__typename?: 'SupplyPeriodType';
	id: Scalars['ID'];
	/** Should this period be billed? False for accepted erroneous gains. */
	isBillable: Scalars['Boolean'];
	supplyEndAt?: Maybe<Scalars['DateTime']>;
	supplyStartAt: Scalars['DateTime'];
};

export type SupplyPointConnectionTypeConnection = {
	__typename?: 'SupplyPointConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<SupplyPointConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `SupplyPointConnectionType` and its cursor. */
export type SupplyPointConnectionTypeEdge = {
	__typename?: 'SupplyPointConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<SupplyPointType>;
};

/** Customer's supply point details, raw, as fetched from OCCTO */
export type SupplyPointDetailsRaw = {
	__typename?: 'SupplyPointDetailsRaw';
	/** Address of the Supply Point. */
	address: Scalars['String'];
	/** Building name of the Supply Point. */
	addressBuildingName: Scalars['String'];
	/** Postcode of the Supply Point. */
	addressPostcode: Scalars['String'];
	/** The code describing the electrical service area - e.g. `03` = TEPCO. */
	gridOperatorCode: Scalars['String'];
	/** The next reading date for this meter. */
	readingDateNext: Scalars['String'];
	/** SPIN - Supply Point Identification Number. */
	spin: Scalars['String'];
};

/** An enumeration. */
export enum SupplyPointStatus {
	Lost = 'LOST',
	MoveInPending = 'MOVE_IN_PENDING',
	MoveOutMoveInPending = 'MOVE_OUT_MOVE_IN_PENDING',
	MoveOutPending = 'MOVE_OUT_PENDING',
	OffSupply = 'OFF_SUPPLY',
	OnSupply = 'ON_SUPPLY',
	RegistrationRejected = 'REGISTRATION_REJECTED',
	SwitchInPending = 'SWITCH_IN_PENDING',
	SwitchOutPending = 'SWITCH_OUT_PENDING',
}

/** Represents a SupplyPoint. */
export type SupplyPointType = CommonSupplyPointInterface & {
	__typename?: 'SupplyPointType';
	/** The external identifier of the supply point. */
	externalIdentifier?: Maybe<Scalars['String']>;
	/** The ID of the supply point. */
	id: Scalars['ID'];
	/** The market this supply point belongs to. */
	marketName: Scalars['String'];
};

/** Switch-in correction was performed. */
export type SwitchInCorrectionPerformed = {
	__typename?: 'SwitchInCorrectionPerformed';
	/** List of actions that were performed. */
	performedActions: Array<Maybe<SelfServiceAction>>;
};

/** Returned when a customer self-services a correction of switch-in data. */
export type SwitchInCorrectionResponse =
	| CommonError
	| SwitchInCorrectionPerformed;

export type SwitchInCustomerInput = {
	/** Customer's family name under contract with the current supplier. */
	contractFamilyName?: InputMaybe<Scalars['String']>;
	/** Customer's family name (kana) under contract with the current supplier. */
	contractFamilyNameKana?: InputMaybe<Scalars['String']>;
	/** Customer's given name under contract with the current supplier. */
	contractGivenName?: InputMaybe<Scalars['String']>;
	/** Customer's given name (kana) under contract with the current supplier. */
	contractGivenNameKana?: InputMaybe<Scalars['String']>;
	/** Current supplier's customer's contract number. */
	contractNumber?: InputMaybe<Scalars['String']>;
	dateOfBirth?: InputMaybe<Scalars['Date']>;
	email?: InputMaybe<Scalars['String']>;
	familyName?: InputMaybe<Scalars['String']>;
	familyNameKana?: InputMaybe<Scalars['String']>;
	givenName?: InputMaybe<Scalars['String']>;
	givenNameKana?: InputMaybe<Scalars['String']>;
	marketingCommunicationsOptIn?: InputMaybe<Scalars['Boolean']>;
	mobile?: InputMaybe<Scalars['String']>;
};

export type SwitchInPropertyInput = {
	addressLine1?: InputMaybe<Scalars['String']>;
	addressLine2?: InputMaybe<Scalars['String']>;
	/** Current supplier code. */
	currentSupplierCode?: InputMaybe<Scalars['String']>;
	postcode?: InputMaybe<Scalars['String']>;
	/** Supply point identifier number. */
	supplyPointId?: InputMaybe<Scalars['String']>;
};

export type SwitchToProductInput = {
	/** A code that uniquely identifies the account. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** A code that uniquely identifies the product. */
	productCode: Scalars['String'];
	/** Supply Point Identification Number. */
	spin: Scalars['String'];
};

/**
 * Terminate the current agreement and create the agreement with the new
 * product on the next reading date.
 *
 * The possible errors that can be raised are:
 *
 * - KT-JP-4901: Invalid data.
 * - KT-JP-4902: Unable to update agreement product.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type SwitchToProductOnNextReadingDate = {
	__typename?: 'SwitchToProductOnNextReadingDate';
	/** The new agreement. */
	agreement?: Maybe<Agreement>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type SwitchToProductWithFixedFuelCostAdjustmentInput = {
	/** A code that uniquely identifies the account. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** A code that uniquely identifies the product. */
	productCode: Scalars['String'];
	/** Supply Point Identification Number. */
	spin: Scalars['String'];
};

/** Returned when a customer switches to the fixed FCA product on the consumer site. */
export type SwitchToProductWithFixedFuelCostAdjustmentResponse =
	| CommonError
	| ProductWithFixedFuelCostAdjustmentSwitched;

/** Move-in input fields required for single Account MOMI journey. */
export type SynchronousMoveInInput = {
	/** Affiliate link subdomain for onboarding request. */
	affiliateLinkSubdomain?: InputMaybe<Scalars['String']>;
	/** Optional ID of an audio recording object that will be linked to the quote's affiliate session. */
	audioRecordingId?: InputMaybe<Scalars['String']>;
	/** Account billing information. */
	billing?: InputMaybe<BillingInput>;
	/** Target move-in date. */
	moveInDate: Scalars['Date'];
	/** Product code for move-in request. */
	productCode: Scalars['String'];
	/** The address where the customer is moving. */
	property: MoveInPropertyInput;
	/** Quote code for move-in request. */
	quoteCode: Scalars['String'];
	/** Referral code for onboarding request. */
	referralCode?: InputMaybe<Scalars['String']>;
	/** Referral scheme code for onboarding request. */
	referralSchemeCode?: InputMaybe<Scalars['String']>;
};

export type TariffSummary = {
	__typename?: 'TariffSummary';
	/** The tariff's product code. */
	code: Scalars['String'];
	/** The tariff's product's human-readable name. */
	displayName: Scalars['String'];
	/** The grid operator code for this specific tariff. */
	gridOperatorCode: Scalars['String'];
	/** A JSON object containing extra information. */
	productParams?: Maybe<Scalars['GenericScalar']>;
	/** A list of tiers available to the tariff. */
	tiers: Array<Maybe<TariffTier>>;
};

export type TariffTier = {
	__typename?: 'TariffTier';
	/** A list of consumption charge rates. */
	consumptionRates: Array<Maybe<ConsumptionChargeRate>>;
	/** The contract capacity pattern of the product. */
	contractCapacityPattern?: Maybe<ContractCapacityPattern>;
	/** A list of consumption charge rates. */
	standingRates: StandingChargeValues;
};

export type TaskResult = {
	__typename?: 'TaskResult';
	/** The error message if the task failed. */
	error?: Maybe<Scalars['String']>;
	/** The result of the task. */
	result?: Maybe<Scalars['JSONString']>;
	/** The status of the task. */
	status?: Maybe<TaskStatusEnum>;
};

/** An enumeration. */
export enum TaskStatusEnum {
	Failed = 'FAILED',
	Finished = 'FINISHED',
	Started = 'STARTED',
}

/** Any special circumstances that the user has notified us about, which may entitle them to some specialist services. These circumstances have an end date, after which they will not longer apply. Having young children is an example of this in the UK. */
export type TemporarySpecialCircumstanceRecordType = {
	__typename?: 'TemporarySpecialCircumstanceRecordType';
	expiryDate?: Maybe<Scalars['Date']>;
	id?: Maybe<Scalars['ID']>;
	internalCode?: Maybe<Scalars['String']>;
	summary?: Maybe<Scalars['String']>;
};

export type TermsAndConditionsType = {
	__typename?: 'TermsAndConditionsType';
	brandCode?: Maybe<Scalars['String']>;
	effectiveFrom?: Maybe<Scalars['DateTime']>;
	/** The html of the terms and conditions document rendered as a JSON string. */
	html?: Maybe<Scalars['String']>;
	/** The markdown text of the terms and conditions. */
	markdown?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	pdfUrl?: Maybe<Scalars['String']>;
	version?: Maybe<Scalars['String']>;
};

export type TextInterface = {
	/** The text alignment. */
	textAlignment?: Maybe<Alignment>;
	/** The text style, i.e. header, body. */
	textStyle?: Maybe<TextStyleV1>;
	/** The text content. */
	value: Scalars['String'];
};

/**
 * The style is the typographical hierarchy.
 * These are Typescale Categories from the Mobile Design System (Figma).
 */
export enum TextStyleV1 {
	Body1 = 'BODY1',
	Body2 = 'BODY2',
	ButtonText = 'BUTTON_TEXT',
	Callout1 = 'CALLOUT1',
	Callout2 = 'CALLOUT2',
	Callout3 = 'CALLOUT3',
	InputTitle = 'INPUT_TITLE',
	Small1 = 'SMALL1',
	Small2 = 'SMALL2',
	Small3 = 'SMALL3',
	Tabular = 'TABULAR',
	Title1 = 'TITLE1',
	Title2 = 'TITLE2',
	Title3 = 'TITLE3',
	Title4 = 'TITLE4',
	Title5 = 'TITLE5',
	Title6 = 'TITLE6',
}

/** A block of text. */
export type TextType = IdentifiableInterface &
	TextInterface & {
		__typename?: 'TextType';
		/** Unique identifier of the object. */
		id?: Maybe<Scalars['ID']>;
		/** The text alignment. */
		textAlignment?: Maybe<Alignment>;
		/** The text style, i.e. header, body. */
		textStyle?: Maybe<TextStyleV1>;
		/** The name of the object's type. */
		typename?: Maybe<Scalars['String']>;
		/** The text content. */
		value: Scalars['String'];
	};

/** Type for the third party organization. */
export type ThirdPartyOrganizationType = {
	__typename?: 'ThirdPartyOrganizationType';
	name: Scalars['String'];
};

/** Unit rate which is valid within a consumption range. */
export type TieredConsumptionRate = {
	__typename?: 'TieredConsumptionRate';
	/** The product rate band information. */
	band?: Maybe<Scalars['String']>;
	/** Duration in months for discount product rates. */
	durationMonths?: Maybe<Scalars['Int']>;
	/** Grid operator. */
	gridOperatorCode?: Maybe<Scalars['String']>;
	/** Price per kWh without tax. */
	pricePerUnit: Scalars['Decimal'];
	/** Price per kWh including tax. */
	pricePerUnitIncTax: Scalars['Decimal'];
	/** Region of operation. */
	regionOfOperation?: Maybe<Scalars['String']>;
	/** The m3 that the unit rate is valid until. */
	tierEnd?: Maybe<Scalars['Int']>;
	/** The m3 that the unit rate is valid from. */
	tierStart?: Maybe<Scalars['Int']>;
	/** Time of use label for the rate. */
	timeOfUse?: Maybe<Scalars['String']>;
	/** What are we charging for (kWhs consumed, Days on supply, etc). */
	unitType?: Maybe<UnitType>;
	/** Rate validity start. */
	validFrom?: Maybe<Scalars['DateTime']>;
	/** Rate validity end. */
	validTo?: Maybe<Scalars['DateTime']>;
};

/** Standing charge rate which is valid within a consumption range. */
export type TieredStandingCharge = {
	__typename?: 'TieredStandingCharge';
	/** The product rate band information. */
	band?: Maybe<Scalars['String']>;
	/** Duration in months for discount product rates. */
	durationMonths?: Maybe<Scalars['Int']>;
	/** Grid operator. */
	gridOperatorCode?: Maybe<Scalars['String']>;
	/** Price per ampere per day without tax. */
	pricePerUnit: Scalars['Decimal'];
	/** Price per ampere per day including tax. */
	pricePerUnitIncTax: Scalars['Decimal'];
	/** Region of operation. */
	regionOfOperation?: Maybe<Scalars['String']>;
	/** The m3 that the unit rate is valid until. */
	tierEnd?: Maybe<Scalars['Int']>;
	/** The m3 that the unit rate is valid from. */
	tierStart?: Maybe<Scalars['Int']>;
	/** What are we charging for (kWhs consumed, Days on supply, etc). */
	unitType?: Maybe<UnitType>;
	/** Rate validity start. */
	validFrom?: Maybe<Scalars['DateTime']>;
	/** Rate validity end. */
	validTo?: Maybe<Scalars['DateTime']>;
};

/** Time slot requested by the customer for demolition. */
export enum TimeOptions {
	Am = 'AM',
	NotSpecified = 'NOT_SPECIFIED',
	Pm = 'PM',
	'0900' = '_0900',
	'1000' = '_1000',
	'1100' = '_1100',
	'1200' = '_1200',
	'1300' = '_1300',
	'1400' = '_1400',
	'1500' = '_1500',
	'1600' = '_1600',
	'1700' = '_1700',
}

export type TransactionAmountType = {
	__typename?: 'TransactionAmountType';
	/** The gross amount (in minor currency units). */
	gross?: Maybe<Scalars['Int']>;
	/** The net amount (in minor currency units). */
	net?: Maybe<Scalars['Int']>;
	/** The amount of tax (in minor currency units). */
	tax?: Maybe<Scalars['Int']>;
};

export type TransactionConnectionTypeConnection = {
	__typename?: 'TransactionConnectionTypeConnection';
	/** Number of nodes in the edge. */
	edgeCount: Scalars['Int'];
	/** Contains the nodes in this connection. */
	edges: Array<Maybe<TransactionConnectionTypeEdge>>;
	/** Pagination data for this connection. */
	pageInfo: PageInfo;
	/** Total number of nodes. */
	totalCount: Scalars['Int'];
};

/** A Relay edge containing a `TransactionConnectionType` and its cursor. */
export type TransactionConnectionTypeEdge = {
	__typename?: 'TransactionConnectionTypeEdge';
	/** A cursor for use in pagination */
	cursor: Scalars['String'];
	/** The item at the end of the edge */
	node?: Maybe<TransactionType>;
};

/** Transactions are a record of money being added or subtracted from the overall account balance */
export type TransactionType = {
	/** Unique identifier of the account the transaction belongs to. */
	accountNumber?: Maybe<Scalars['String']>;
	/**
	 * Gross amount including tax (when payable). Refer to the `amounts` field for a breakdown of this information.
	 * @deprecated The 'amount' field is deprecated.
	 *
	 * Use `amounts` instead for a breakdown of the relevant net, tax, and gross amounts.
	 *
	 * - Marked as deprecated on 2023-12-06.
	 * - Will be removed on 2024-06-01.
	 */
	amount?: Maybe<Scalars['Int']>;
	/** The net, tax and gross amounts for the transaction. Note: for payments and repayments, only the net amount is returned. */
	amounts?: Maybe<TransactionAmountType>;
	/** The customer's resulting balance after this transaction has been applied, in the smallest unit of currency. */
	balanceCarriedForward?: Maybe<Scalars['Int']>;
	/** The unique identifier for the most recent billing document linked with the transaction.Note: a transaction may be linked with multiple documents, but this field will only return the identifier for the most recent billing document. */
	billingDocumentIdentifier?: Maybe<Scalars['ID']>;
	/** The date time when the transaction is created. */
	createdAt?: Maybe<Scalars['DateTime']>;
	/** Returns True if the transaction is linked with a statement. */
	hasStatement?: Maybe<Scalars['Boolean']>;
	id?: Maybe<Scalars['ID']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isAccountCharge' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isAccountCharge?: Maybe<Scalars['Boolean']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isAccountPayment' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isAccountPayment?: Maybe<Scalars['Boolean']>;
	/**
	 * Deprecated.
	 * @deprecated The 'isCredit' field is deprecated.
	 *
	 * This information is provided by the __typename introspection query.
	 *
	 * - Marked as deprecated on 2020-06-19.
	 * - Will be removed on 2022-11-15.
	 */
	isCredit?: Maybe<Scalars['Boolean']>;
	/** Whether the statement this transaction is on has been held. A held statement is not sent to a customer automatically, but is instead marked for manual attention by operations staff.  Returns False if a statement is not linked with the transaction. */
	isHeld?: Maybe<Scalars['Boolean']>;
	/** Whether this transaction has been issued on any billing document.Note: Look for the most recently issued transaction instead of looking through all transactions as some accounts may have initial transactions that were not issued.This will return False if the transaction is not associated with any billing documents. */
	isIssued?: Maybe<Scalars['Boolean']>;
	isReversed: Scalars['Boolean'];
	/** Returns the note field value for the transaction, which contains additional info. */
	note?: Maybe<Scalars['String']>;
	postedDate?: Maybe<Scalars['Date']>;
	/**
	 * Returns None if a statement is not linked with the transaction.
	 * @deprecated The 'statementId' field is deprecated.
	 *
	 * Use `billingDocumentIdentifier` instead.
	 *
	 * - Marked as deprecated on 2023-11-30.
	 * - Will be removed on 2024-06-01.
	 */
	statementId?: Maybe<Scalars['ID']>;
	title?: Maybe<Scalars['String']>;
};

/** Transaction types which will be included or excluded, depending on the input argument. */
export enum TransactionTypeFilter {
	/** For filtering/excluding energy charge transactions: Gas or Electricity. */
	EnergyCharges = 'ENERGY_CHARGES',
	/** For filtering/excluding imported charge transactions. */
	ImportedCharges = 'IMPORTED_CHARGES',
	/** For filtering/excluding imported credit transactions. */
	ImportedCredits = 'IMPORTED_CREDITS',
	/** For filtering/excluding imported payment transactions. */
	ImportedPayments = 'IMPORTED_PAYMENTS',
	/** For filtering/excluding imported repayment transactions. */
	ImportedRepayments = 'IMPORTED_REPAYMENTS',
	/** For filtering/excluding issued transactions. */
	IssuedTransactions = 'ISSUED_TRANSACTIONS',
}

export enum TransactionsOrderBy {
	PostedDateAsc = 'POSTED_DATE_ASC',
	PostedDateDesc = 'POSTED_DATE_DESC',
}

/**
 * Trigger balance transfer between accounts.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-3822: Unauthorized.
 * - KT-CT-3823: Unauthorized.
 * - KT-CT-9701: Balance transfer to same account is not allowed.
 * - KT-CT-9702: Balance transfer is not support for debit account with Zero balance.
 * - KT-CT-9703: Balance transfer is not supported for debit account.
 * - KT-CT-9704: Balance transfer amount should be non-zero.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type TransferLedgerBalance = {
	__typename?: 'TransferLedgerBalance';
	/** Balance transfer details. */
	balanceTransfer?: Maybe<AccountBalanceTransferType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type TransferLedgerBalanceInputType = {
	/** The amount ( in lowest unit ) to transfer. If the amount is negative,the effect is reversed  (the source ledger's balance increases and the destination ledger's balance decreases). */
	amount: Scalars['Int'];
	/** Optional short note about transfer reason. */
	note?: InputMaybe<Scalars['String']>;
	/** Account's ledger from which the requested amount is debited. */
	sourceAccountLedger: AccountLedgerInput;
	/** Account's ledger to which the requested amount is credited. */
	targetAccountLedger: AccountLedgerInput;
};

/**
 * Transfer Loyalty Points between users.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-9205: Insufficient Loyalty Points.
 * - KT-CT-9204: Negative or zero points set.
 * - KT-CT-9208: Invalid posted at datetime.
 * - KT-CT-9209: Negative Loyalty Points balance.
 * - KT-CT-9210: Unhandled Loyalty Points exception.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type TransferLoyaltyPointsBetweenUsers = {
	__typename?: 'TransferLoyaltyPointsBetweenUsers';
	/** The number of OctoPoints that were transferred. */
	pointsTransferred?: Maybe<Scalars['Int']>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** The input type for transferring Loyalty Points. */
export type TransferLoyaltyPointsBetweenUsersInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** The number of Loyalty Points to transfer. */
	points: Scalars['Int'];
	/** The account user receiving the points. */
	receivingUserId: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-JP-7802: File cannot be found with given s3 key.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type TriggerPostUploadOperationsJpn = {
	__typename?: 'TriggerPostUploadOperationsJPN';
	/** Have post upload operations been triggered. */
	operationsTriggered?: Maybe<Scalars['Boolean']>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type TriggerType = {
	__typename?: 'TriggerType';
	/** The ID of the trigger. */
	id: Scalars['String'];
};

/**
 *
 * Provide a typed source to filter measurements.
 * If a typed_source and a raw source is given, preference is given to the raw source.
 * To get better results, make sure none of the input fields are empty.
 *
 */
export type TypedSourceInputType = {
	/** Reading direction is based on the utility generated or consumed by the customer. */
	readingDirection?: InputMaybe<ReadingDirectionType>;
	readingFrequencyType?: InputMaybe<ReadingFrequencyType>;
	readingQuality?: InputMaybe<ReadingQualityType>;
	sourceIdentifier?: InputMaybe<Scalars['String']>;
	utility?: InputMaybe<UtilityType>;
};

/**
 * Note this is deprecated and will be removed in the future.
 * Please use the `UtilityFiltersOutput` type instead.
 */
export type TypedSourceOutput = {
	__typename?: 'TypedSourceOutput';
	/** Reading direction is based on the utility generated or consumed by the customer. */
	readingDirection?: Maybe<ReadingDirectionType>;
	readingFrequencyType?: Maybe<ReadingFrequencyType>;
	readingQuality?: Maybe<ReadingQualityType>;
	sourceIdentifier?: Maybe<Scalars['String']>;
	utility?: Maybe<UtilityType>;
};

/** An enumeration. */
export enum Unit {
	KilowattHours = 'KILOWATT_HOURS',
	MetersCubed = 'METERS_CUBED',
}

/** An enumeration. */
export enum UnitType {
	ChargesIncurred = 'CHARGES_INCURRED',
	CubicMetresConsumption = 'CUBIC_METRES_CONSUMPTION',
	CurrencyYear = 'CURRENCY_YEAR',
	DaysOnSupply = 'DAYS_ON_SUPPLY',
	KvarhConsumption = 'KVARH_CONSUMPTION',
	KvaCapacityDays = 'KVA_CAPACITY_DAYS',
	KvaCapacityMonths = 'KVA_CAPACITY_MONTHS',
	KvaDemandDays = 'KVA_DEMAND_DAYS',
	KvaDemandMonths = 'KVA_DEMAND_MONTHS',
	KwhConsumption = 'KWH_CONSUMPTION',
	KwCapacityMonths = 'KW_CAPACITY_MONTHS',
	KwDemand = 'KW_DEMAND',
	LitresConsumption = 'LITRES_CONSUMPTION',
	MegajoulesConsumption = 'MEGAJOULES_CONSUMPTION',
	MonthsOnSupply = 'MONTHS_ON_SUPPLY',
	PartnerChargesIncurred = 'PARTNER_CHARGES_INCURRED',
	PerItem = 'PER_ITEM',
	UnitsWa = 'UNITS_WA',
	YearsOnSupplyPerDiem = 'YEARS_ON_SUPPLY_PER_DIEM',
}

export type UnlinkUserFromLineResponse =
	| LineCommonError
	| LineUnlinkedResponse
	| LinkTokenNotFound;

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7804: No fields present in the input for updating the APIException.
 * - KT-CT-7803: Received an invalid apiExceptionId.
 * - KT-CT-7809: Update results in no changes to API Exception.
 * - KT-CT-7805: Too many tags associated with this API Exception.
 * - KT-CT-7806: Cannot create duplicate tags for the same API exception.
 * - KT-CT-7801: Received an invalid operationsTeamId.
 * - KT-CT-7811: Received an invalid assignedUserId.
 * - KT-CT-7812: Support user is inactive.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateApiException = {
	__typename?: 'UpdateAPIException';
	/** The updated APIException. */
	apiException?: Maybe<ApiExceptionType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateApiExceptionInput = {
	/** The ID of the user assigned to handle this exception.If no user is provided, no user will be assigned to the exception. */
	assignedUserId?: InputMaybe<Scalars['Int']>;
	/** The new category. If none is provided, the field won't be updated. */
	category?: InputMaybe<ApiExceptionCategories>;
	/** The new context. If none is provided, the field won't be updated. This will completely replace the existing context by the new one. */
	context?: InputMaybe<Scalars['JSONString']>;
	/** The ID of the API Exception that will be updated. */
	id: Scalars['Int'];
	/** The new key date. If none is provided, the field won't be updated. */
	keyDate?: InputMaybe<Scalars['Date']>;
	/** The ID of an operations team to handle this exception. If no team is provided, no team will be assigned to the exception. */
	operationsTeamId?: InputMaybe<Scalars['Int']>;
	/** The new priority. If none is provided, the field won't be updated. */
	priority?: InputMaybe<ApiExceptionPriority>;
	/** The new resolution status. If none is provided, the field won't be updated. */
	resolutionStatus?: InputMaybe<ApiExceptionResolutionStatus>;
	/** The new resolution type. If none is provided, the field won't be updated. */
	resolutionType?: InputMaybe<ApiExceptionResolutionType>;
	/** The updated list of tags. If none is provided, the field won't be updated. */
	tags?: InputMaybe<Array<InputMaybe<ApiExceptionTags>>>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-7807: Received an invalid apiExceptionNoteId.
 * - KT-CT-7808: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateApiExceptionNote = {
	__typename?: 'UpdateAPIExceptionNote';
	/** The updates APIExceptionNote. */
	apiException?: Maybe<ApiExceptionType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateApiExceptionNoteInput = {
	/** The ID of the API Exception note being updated. */
	apiExceptionNoteId: Scalars['ID'];
	/** The body of the note. */
	body: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-4145: Invalid address.
 * - KT-CT-7123: Unauthorized.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAccountBillingAddress = {
	__typename?: 'UpdateAccountBillingAddress';
	/** The updated account. */
	account?: Maybe<Account>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Update the billing email for the input account number to the
 * received email value.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-4122: Invalid email.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAccountBillingEmail = {
	__typename?: 'UpdateAccountBillingEmail';
	/** Account that was changed. */
	account?: Maybe<AccountInterface>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input fields for updating billing email for an account. */
export type UpdateAccountBillingEmailInput = {
	/** Account number for account. */
	accountNumber: Scalars['String'];
	/** The billing_email which can be up to 512 characters. Use null to unset billing_email. */
	billingEmail?: InputMaybe<Scalars['String']>;
};

/** Updates Account consent data. */
export type UpdateAccountConsentData = {
	__typename?: 'UpdateAccountConsentData';
	consentData?: Maybe<Scalars['GenericScalar']>;
};

export type UpdateAccountConsentDataInput = {
	accountNumber?: InputMaybe<Scalars['String']>;
	consentData?: InputMaybe<Scalars['JSONString']>;
};

/**
 * Update a reference for a particular account and namespace.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-8310: Invalid data.
 * - KT-CT-8311: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAccountReference = {
	__typename?: 'UpdateAccountReference';
	accountReference?: Maybe<AccountReferenceType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Updates AccountUser and JPN AccountUserDetails
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-5414: Invalid data.
 * - KT-JP-5401: Account user details not found.
 * - KT-JP-5402: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAccountUser = {
	__typename?: 'UpdateAccountUser';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	viewer?: Maybe<AccountUser>;
};

/**
 * Updates AccountUser and JPN AccountUserDetails by id.
 *
 * ==== CAUTION ====
 * This mutation doesn't have the limitation of only being able to update one field per day.
 * =================
 *
 * This mutation is only available to organizations with the appropriate permissions.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-5414: Invalid data.
 * - KT-JP-5401: Account user details not found.
 * - KT-JP-5402: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAccountUserByUserId = {
	__typename?: 'UpdateAccountUserByUserId';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	user?: Maybe<AccountUser>;
};

export type UpdateAccountUserCommsPreferencesInput = {
	emailFormat?: InputMaybe<EmailFormats>;
	fontSizeMultiplier?: InputMaybe<Scalars['Float']>;
	isOptedInMeterReadingConfirmations?: InputMaybe<Scalars['Boolean']>;
	isOptedInToClientMessages?: InputMaybe<Scalars['Boolean']>;
	isOptedInToOfferMessages?: InputMaybe<Scalars['Boolean']>;
	isOptedInToRecommendedMessages?: InputMaybe<Scalars['Boolean']>;
	isOptedInToSmsMessages?: InputMaybe<Scalars['Boolean']>;
	isOptedInToThirdPartyMessages?: InputMaybe<Scalars['Boolean']>;
	isOptedInToUpdateMessages?: InputMaybe<Scalars['Boolean']>;
	isUsingInvertedEmailColours?: InputMaybe<Scalars['Boolean']>;
	preferredHoldMusic?: InputMaybe<Songs>;
};

export type UpdateAccountUserCommsPreferencesMutationInput = {
	clientMutationId?: InputMaybe<Scalars['String']>;
	emailFormat?: InputMaybe<Scalars['String']>;
	fontSizeMultiplier?: InputMaybe<Scalars['Float']>;
	isOptedInMeterReadingConfirmations?: InputMaybe<Scalars['Boolean']>;
	isOptedInToClientMessages?: InputMaybe<Scalars['Boolean']>;
	isOptedInToOfferMessages?: InputMaybe<Scalars['Boolean']>;
	isOptedInToRecommendedMessages?: InputMaybe<Scalars['Boolean']>;
	isOptedInToSmsMessages?: InputMaybe<Scalars['Boolean']>;
	isOptedInToThirdPartyMessages?: InputMaybe<Scalars['Boolean']>;
	isOptedInToUpdateMessages?: InputMaybe<Scalars['Boolean']>;
	isUsingInvertedEmailColours?: InputMaybe<Scalars['Boolean']>;
	preferredHoldMusic?: InputMaybe<Scalars['String']>;
};

/** Update the account user comms preferences. */
export type UpdateAccountUserCommsPreferencesMutationPayload = {
	__typename?: 'UpdateAccountUserCommsPreferencesMutationPayload';
	clientMutationId?: Maybe<Scalars['String']>;
	commsPreferences?: Maybe<AccountUserCommsPreferences>;
	emailFormat?: Maybe<Scalars['String']>;
	errors?: Maybe<Array<Maybe<ErrorType>>>;
	fontSizeMultiplier?: Maybe<Scalars['Float']>;
	isOptedInMeterReadingConfirmations?: Maybe<Scalars['Boolean']>;
	isOptedInToClientMessages?: Maybe<Scalars['Boolean']>;
	isOptedInToOfferMessages?: Maybe<Scalars['Boolean']>;
	isOptedInToRecommendedMessages?: Maybe<Scalars['Boolean']>;
	isOptedInToSmsMessages?: Maybe<Scalars['Boolean']>;
	isOptedInToThirdPartyMessages?: Maybe<Scalars['Boolean']>;
	isOptedInToUpdateMessages?: Maybe<Scalars['Boolean']>;
	isUsingInvertedEmailColours?: Maybe<Scalars['Boolean']>;
	preferredHoldMusic?: Maybe<Scalars['String']>;
};

/** Input required to update an AccountUser and its AccountUserDetails. */
export type UpdateAccountUserInput = {
	dateOfBirth?: InputMaybe<Scalars['Date']>;
	/** User details. */
	details?: InputMaybe<Array<InputMaybe<DetailsInputType>>>;
	email?: InputMaybe<Scalars['String']>;
	familyName?: InputMaybe<Scalars['String']>;
	givenName?: InputMaybe<Scalars['String']>;
	kanaFamilyName?: InputMaybe<Scalars['String']>;
	kanaGivenName?: InputMaybe<Scalars['String']>;
	/** Because this field is clearable, null and the empty string are treated differently; passing null or omitting the field leaves the value as-is, but explicitly passing an empty string clears this value. */
	landline?: InputMaybe<Scalars['String']>;
	/** Because this field is clearable, null and the empty string are treated differently; passing null or omitting the field leaves the value as-is, but explicitly passing an empty string clears this value. */
	mobile?: InputMaybe<Scalars['String']>;
	/** How the user would like us to address them (e.g. 'she/her', 'they/them'). Because this field is clearable, null and the empty string are treated differently; passing null or omitting the field leaves the value as-is, but explicitly passing an empty string clears this value. */
	pronouns?: InputMaybe<Scalars['String']>;
	/** The user's title. */
	title?: InputMaybe<Scalars['String']>;
	/** The user for whom to perform the update. This is only needed when using an Organisation role. */
	userId?: InputMaybe<Scalars['String']>;
};

export type UpdateAccountUserMutationInput = {
	clientMutationId?: InputMaybe<Scalars['String']>;
	dateOfBirth?: InputMaybe<Scalars['Date']>;
	email?: InputMaybe<Scalars['String']>;
	familyName?: InputMaybe<Scalars['String']>;
	givenName?: InputMaybe<Scalars['String']>;
	landline?: InputMaybe<Scalars['String']>;
	mobile?: InputMaybe<Scalars['String']>;
	pronouns?: InputMaybe<Scalars['String']>;
};

export type UpdateAccountUserMutationPayload = {
	__typename?: 'UpdateAccountUserMutationPayload';
	clientMutationId?: Maybe<Scalars['String']>;
	dateOfBirth?: Maybe<Scalars['Date']>;
	email?: Maybe<Scalars['String']>;
	errors?: Maybe<Array<Maybe<ErrorType>>>;
	familyName?: Maybe<Scalars['String']>;
	givenName?: Maybe<Scalars['String']>;
	landline?: Maybe<Scalars['String']>;
	mobile?: Maybe<Scalars['String']>;
	pronouns?: Maybe<Scalars['String']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-8225: Received an invalid purchaseId.
 * - KT-CT-8226: The provided purchase is not active.
 * - KT-CT-8206: Invalid data.
 * - KT-CT-8227: Available grants could not be applied.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateActivePurchase = {
	__typename?: 'UpdateActivePurchase';
	/** Goods purchase updated. */
	goodsPurchase?: Maybe<GoodsPurchase>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/**
 * Update an affiliate link of an existing sales agent.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7711: Invalid data.
 * - KT-CT-7713: Invalid data.
 * - KT-CT-7714: Invalid data.
 * - KT-CT-7715: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAffiliateLink = {
	__typename?: 'UpdateAffiliateLink';
	affiliateLink?: Maybe<AffiliateLinkType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateAffiliateLinkInputType = {
	contactEmail?: InputMaybe<Scalars['String']>;
	contactName?: InputMaybe<Scalars['String']>;
	isBusiness?: InputMaybe<Scalars['Boolean']>;
	landingUrl?: InputMaybe<Scalars['String']>;
	/** The id of the affiliate link that is going to be edited. */
	linkId: Scalars['ID'];
	/** The organisation for whom to update the affiliate link for. */
	organisationId?: InputMaybe<Scalars['ID']>;
	/**
	 *
	 * Will be validated as follows:
	 *
	 * - should be at least two characters
	 * - should only contain (letters, numbers, and Hyphen)
	 * - should not contain bad words
	 * - should not contain any of the reserved words including:
	 *  affiliates, api, business, click, consul, developer, friends, kraken, mail, sendgrid, tech, webhooks, www, www2
	 */
	subdomain?: InputMaybe<Scalars['String']>;
	trainingStatus?: InputMaybe<Scalars['String']>;
};

/**
 * Update an affiliate organisation.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-7717: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAffiliateOrganisation = {
	__typename?: 'UpdateAffiliateOrganisation';
	affiliateOrganisation?: Maybe<AffiliateOrganisationType>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateAffiliateOrganisationInputType = {
	/** Is this partner allowed to specify payment methods other than Direct Debit in the import csv or API. */
	allowAlternativePaymentMethods?: InputMaybe<Scalars['Boolean']>;
	/** Are meter point registrations limited for profile classes 1 and 2 for registrations from csv or API. */
	canRegisterBusinessMeterPoints?: InputMaybe<Scalars['Boolean']>;
	/** Allow registration requests with customers without an email address. */
	canRegisterCustomersWithoutEmailAddress?: InputMaybe<Scalars['Boolean']>;
	/** Allow registration requests with exiting account user emails to add to the portfolio belonging to the account user. */
	canRegisterPortfolioAccounts?: InputMaybe<Scalars['Boolean']>;
	/** Allow performing tariff renewals via API. */
	canRenewTariffs?: InputMaybe<Scalars['Boolean']>;
	/** Allow this partner access to the IVR support API (modify their own IVR handling through third party 'IVR Flow Editor'). */
	canUseIvrSupportApi?: InputMaybe<Scalars['Boolean']>;
	/** Default Account Type. */
	defaultAccountType?: InputMaybe<AccountTypeChoices>;
	/** Restrict to field-sales-only products? This is only allowed for the 'field-sales' and 'events' sales channels. */
	isFieldSalesOnlyProduct?: InputMaybe<Scalars['Boolean']>;
	name?: InputMaybe<Scalars['String']>;
	/** The organisation that is going to be edited. */
	organisationId: Scalars['ID'];
	/** Sales Channel. */
	salesChannel?: InputMaybe<SalesChannelChoices>;
	/** Allow this partner to skip validation that ensures all meter points belong to the same address. */
	skipMeterPointAddressValidation?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Update the period of an agreement.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4178: No account found with given account number.
 * - KT-CT-1501: Agreement not found.
 * - KT-CT-1503: Agreement valid_to date must be later than valid_from date.
 * - KT-CT-1504: Account does not match with the agreement.
 * - KT-CT-1505: Unable to edit agreement.
 * - KT-CT-1506: Agreement period is not within the supply and property period.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAgreementPeriod = {
	__typename?: 'UpdateAgreementPeriod';
	/** Account responsible for the update agreement. */
	account?: Maybe<Account>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateAgreementPeriodInput = {
	/** A code that uniquely identifies the account. */
	accountNumber: Scalars['String'];
	/** A code that uniquely identifies the agreement. */
	agreementId: Scalars['ID'];
	/** The reason for the change. */
	reason?: InputMaybe<Scalars['String']>;
	/** The start date of the agreement. */
	validFrom: Scalars['Date'];
	/** The end date of the agreement (exclusive). The agreement will end on midnight of this date, such that the previous day is the last day covered by this agreement. */
	validTo?: InputMaybe<Scalars['Date']>;
};

/**
 * Update an amperage change request
 *
 * This mutation is used by organizations for updating amperage change requests
 *
 * The possible errors that can be raised are:
 *
 * - KT-JP-4511: Invalid data.
 * - KT-CT-4501: Unauthorized.
 * - KT-JP-4501: Unauthorized.
 * - KT-JP-4502: Unauthorized.
 * - KT-JP-4515: Amperage change process not found.
 * - KT-JP-4517: Unable to update amperage change process.
 * - KT-JP-4503: Unable to communicate with industry organization.
 * - KT-JP-4520: Unable to process request.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAmperageChangeRequest = {
	__typename?: 'UpdateAmperageChangeRequest';
	/** Success message. */
	message?: Maybe<Scalars['String']>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type UpdateAmperageChangeRequestInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** Whether the customer requires to be contacted by the grid operator before the amperage change takes place. */
	isContactRequired: Scalars['Boolean'];
	/** If the grid operator needs to visit the property to make a physical change, the time at which the meeting should happen. */
	meetingRequestTimeCode: TimeOptions;
	/** The desired new amperage value. */
	requestedAmperageValue: Amperage;
	/** The date in which the amperage change should take effect. */
	requestedDate: Scalars['Date'];
	/** The supply point identification number. */
	spin: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-3815: No active payment schedule found for this account.
 * - KT-CT-3941: Invalid data.
 * - KT-CT-3942: An unexpected error occurred.
 * - KT-CT-3947: An unexpected error occurred.
 * - KT-CT-3953: The payment schedule is not a balance triggered schedule.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateAutoTopUpAmount = {
	__typename?: 'UpdateAutoTopUpAmount';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** The new schedule created. */
	schedule?: Maybe<PaymentScheduleType>;
};

/** Input type for updating the schedule auto top up amount for for an account. Requires an `account_number`, ledger_id and `payment_amount` to be provided. */
export type UpdateAutoTopUpAmountInput = {
	/** Account number to update the schedule auto top up amount for. */
	accountNumber: Scalars['String'];
	/** Specifies the ledger ID associated with the current schedule for updates. */
	ledgerId: Scalars['Int'];
	/** The new auto-top-up amount for the payment schedule. */
	paymentAmount: Scalars['Int'];
};

/** Update bank account details for an existing customer in GMO. */
export type UpdateBankAccountDetails = {
	__typename?: 'UpdateBankAccountDetails';
	/** Financial institution redirect URL. */
	financialInstitutionRedirectUrl?: Maybe<Scalars['String']>;
	/** Financial institution token. */
	token?: Maybe<Scalars['String']>;
};

export type UpdateBankAccountDetailsInput = {
	/** Account holder name. */
	accountHolder?: InputMaybe<Scalars['String']>;
	/** Bank account number. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** Type of bank account. */
	accountType?: InputMaybe<BankAccountTypeChoices>;
	/** Bank code. */
	bankCode?: InputMaybe<Scalars['String']>;
	/** Branch code. */
	branchCode?: InputMaybe<Scalars['String']>;
	/** Kraken account number. */
	krakenAccountNumber?: InputMaybe<Scalars['String']>;
};

/**
 * Update the comms delivery preference for the input account number to the
 * received commsDeliveryPreference value.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-4123: Unauthorized.
 * - KT-CT-4136: Cannot set comms preference to email when account has no email.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateCommsDeliveryPreference = {
	__typename?: 'UpdateCommsDeliveryPreference';
	account?: Maybe<AccountInterface>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Input fields for updating comms delivery preferences for an account */
export type UpdateCommsDeliveryPreferenceInput = {
	accountNumber: Scalars['String'];
	commsDeliveryPreference: CommsDeliveryPreference;
};

/** Updates the credit card details of an account through GMO. */
export type UpdateCreditCardDetails = {
	__typename?: 'UpdateCreditCardDetails';
	/** Credit card number. */
	cardNumber: Scalars['String'];
};

export type UpdateCreditCardDetailsInput = {
	/** Kraken account number. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** Payment day for the fixed payment schedule. */
	paymentDay?: InputMaybe<Scalars['Int']>;
	/** GMO update token. */
	token?: InputMaybe<Scalars['String']>;
};

export type UpdateDemandResponseCampaignParticipantCommsPreferencesInput = {
	/** Account number. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** Opt-in or out of future events. */
	isOptedIntoDemandResponseEvents?: InputMaybe<Scalars['Boolean']>;
	/** Opt-in or out to email communications. */
	isOptedIntoEmails?: InputMaybe<Scalars['Boolean']>;
	/** Opt-in or out to line communications. */
	isOptedIntoLine?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateDemandResponseCampaignParticipantTipInput = {
	/** Account number. */
	accountNumber?: InputMaybe<Scalars['String']>;
	/** Campaign name. */
	campaignName?: InputMaybe<Scalars['String']>;
	/** Whether the tip has been done. */
	done?: InputMaybe<Scalars['Boolean']>;
	/** Tip id. */
	key?: InputMaybe<Scalars['String']>;
};

export type UpdateDemandResponseCampaignParticipantTipResponse =
	| CommonError
	| DemandResponseCampaignParticipantTipUpdated;

export type UpdateMessageTagsInput = {
	clientMutationId?: InputMaybe<Scalars['String']>;
	/** The message to set the tags on. */
	messageRelayId: Scalars['ID'];
	/** The tag names to set on the message. */
	tagNames: Array<Scalars['String']>;
	/** The tag code to set on the message. */
	taggerCode: Scalars['String'];
	/** The tag version to set on the message. */
	taggerVersion: Scalars['String'];
};

export type UpdateMessageTagsPayload = {
	__typename?: 'UpdateMessageTagsPayload';
	clientMutationId?: Maybe<Scalars['String']>;
	/** Confirmed tags. */
	tags: Array<InkTag>;
};

/**
 * Update existing metadata on an object.
 *
 * The possible errors that can be raised are:
 *
 * - KT-CT-8413: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateMetadata = {
	__typename?: 'UpdateMetadata';
	metadata?: Maybe<Metadata>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

/** Updates a move-in date for a supply point. */
export type UpdateMoveInDate = {
	__typename?: 'UpdateMoveInDate';
	/** New move-in date in YYYY-MM-DD format. */
	newMoveInDate: Scalars['Date'];
	/** Supply point information number. */
	spin: Scalars['String'];
};

export type UpdateMoveInDateInput = {
	/** The new move-in date. */
	newMoveInDate: Scalars['Date'];
	/** The supply point identification number. */
	spin: Scalars['String'];
};

/**
 * Updates a move-out date for a supply point.
 *
 * The possible errors that can be raised are:
 *
 * - KT-JP-4539: Move out date update input data is invalid.
 * - KT-JP-4501: Unauthorized.
 * - KT-JP-4536: Move out active process not found.
 * - KT-JP-4540: Move out date update cannot self-service.
 * - KT-JP-4101: Unauthorized.
 * - KT-JP-4541: Not allowed to update move out process.
 * - KT-JP-4520: Unable to process request.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type UpdateMoveOutDate = {
	__typename?: 'UpdateMoveOutDate';
	/** New move-out date in YYYY-MM-DD format. */
	newMoveOutDate: Scalars['Date'];
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** Supply point information number. */
	spin: Scalars['String'];
	/** Task ID for the update move-out date task. */
	taskId?: Maybe<Scalars['ID']>;
};

export type UpdateMoveOutDateInput = {
	/** The new move-out date. */
	newMoveOutDate: Scalars['Date'];
	/** The supply point identification number. */
	spin: Scalars['String'];
};

/** Update user's password. */
export type UpdatePassword = {
	__typename?: 'UpdatePassword';
	/**
	 *  The currently authenticated user.
	 *
	 *
	 *
	 * This field requires the `Authorization` header to be set.
	 *
	 *
	 */
	viewer?: Maybe<AccountUser>;
};

export type UpdatePasswordInput = {
	/** New password. */
	newPassword: Scalars['String'];
	/** Confirm new password. */
	newPasswordConfirmed: Scalars['String'];
	/** Old password. */
	oldPassword: Scalars['String'];
};

export type UpdatePurchaseInput = {
	/** The account number. */
	accountNumber: Scalars['String'];
	/** A JSON object containing client parameters to store on the purchase. */
	clientParams?: InputMaybe<Scalars['JSONString']>;
	/** A JSON object containing market parameters to store on the purchase. */
	marketParams?: InputMaybe<Scalars['JSONString']>;
	/** The purchase ID. */
	purchaseId: Scalars['ID'];
	/** Products being purchased. */
	saleItems: Array<InputMaybe<ProductToPurchaseInput>>;
};

export type UpdateUserInput = {
	dateOfBirth?: InputMaybe<Scalars['Date']>;
	/** User details. */
	details?: InputMaybe<Array<InputMaybe<DetailsInputType>>>;
	email?: InputMaybe<Scalars['String']>;
	familyName?: InputMaybe<Scalars['String']>;
	givenName?: InputMaybe<Scalars['String']>;
	/** Because this field is clearable, null and the empty string are treated differently; passing null or omitting the field leaves the value as-is, but explicitly passing an empty string clears this value. */
	landline?: InputMaybe<Scalars['String']>;
	/** Because this field is clearable, null and the empty string are treated differently; passing null or omitting the field leaves the value as-is, but explicitly passing an empty string clears this value. */
	mobile?: InputMaybe<Scalars['String']>;
	/** How the user would like us to address them (e.g. 'she/her', 'they/them'). Because this field is clearable, null and the empty string are treated differently; passing null or omitting the field leaves the value as-is, but explicitly passing an empty string clears this value. */
	pronouns?: InputMaybe<Scalars['String']>;
	/** The user's title. */
	title?: InputMaybe<Scalars['String']>;
	/** The user for whom to perform the update. This is only needed when using an Organisation role. */
	userId?: InputMaybe<Scalars['String']>;
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-5413: Invalid data.
 * - KT-CT-5414: Invalid data.
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type UpdateUserMutation = {
	__typename?: 'UpdateUserMutation';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	viewer?: Maybe<AccountUser>;
};

/**
 * Information that should be used in the POST request to the S3 API.
 *
 * For more details please see [this](https://boto3.amazonaws.com/v1/documentation/api/latest/guide/s3-presigned-urls.html#generating-a-presigned-url-to-upload-a-file).
 */
export type UploadPostRequest = {
	__typename?: 'UploadPostRequest';
	/** Required fields for presigned post. */
	fields: Scalars['JSONString'];
	/** S3 key for resigned post. */
	key: Scalars['String'];
	/** S3 presigned post url. */
	url: Scalars['String'];
};

/**
 *
 * Filter measurements by the given utility parameters.
 *
 */
export type UtilityFiltersInput = {
	electricityFilters?: InputMaybe<ElectricityFiltersInput>;
};

export type UtilityFiltersOutput =
	| ElectricityFiltersOutput
	| EmbeddedElectricityFiltersOutput
	| GasFiltersOutput;

export enum UtilityType {
	Electricity = 'ELECTRICITY',
	EmbeddedElectricity = 'EMBEDDED_ELECTRICITY',
	Gas = 'GAS',
}

/** Validate whether a user's email is a valid email via the Kickbox API. */
export type ValidateEmail = {
	__typename?: 'ValidateEmail';
	/** Whether the email is valid or not. */
	isValid?: Maybe<Scalars['Boolean']>;
};

/** Input required to validate email address via Kickbox */
export type ValidateEmailInput = {
	/** Check if an email is already in use. */
	checkUniqueness?: InputMaybe<Scalars['Boolean']>;
	/** The user's email address. */
	email: Scalars['String'];
};

export type ValidateGpin = {
	__typename?: 'ValidateGPIN';
	/** Whether the GPIN is valid. */
	isValid: Scalars['Boolean'];
};

/** Validate whether a user's phone number is a valid phone number. */
export type ValidatePhone = {
	__typename?: 'ValidatePhone';
	/** Whether the phone number is valid or not. */
	isValid?: Maybe<Scalars['Boolean']>;
};

export type ValidatePhoneNumberInput = {
	/** The user's phone number. */
	phoneNumber: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1113: Disabled GraphQL field requested.
 * - KT-CT-1111: Unauthorized.
 * - KT-CT-1112: 'Authorization' header not provided.
 *
 */
export type VerifyEmail = {
	__typename?: 'VerifyEmail';
	/** Whether the email is verified. */
	isVerified?: Maybe<Scalars['Boolean']>;
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type VerifyEmailInput = {
	/** Token string that will be used to verify the email. */
	token: Scalars['String'];
};

/**
 * The possible errors that can be raised are:
 *
 * - KT-CT-1145: Account/user details do not match.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type VerifyIdentity = {
	__typename?: 'VerifyIdentity';
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
	/** An expiring token that can be used to request to update the user's email address. */
	token: Scalars['String'];
};

export type VerifyIdentityInput = {
	/** The number of the account belonging to the user (e.g. A-12345678). */
	accountNumber: Scalars['String'];
	/** The first line of the user's address (this could be the energy supply property address or the billing address on the account). */
	firstLineOfAddress: Scalars['String'];
	/** The user's full name. */
	fullName: Scalars['String'];
	/** The user's postcode (this could be the postcode of the energy supply property address or of the billing address on the account). */
	postcode: Scalars['String'];
};

export type WhatsAppTextMessage = {
	__typename?: 'WhatsAppTextMessage';
	/** Whatsapp text message body. */
	body: Scalars['String'];
};

/**
 * Withdraws an account.
 *
 * The possible errors that can be raised are:
 *
 * - KT-JP-7801: Unknown error occurred.
 * - KT-CT-1113: Disabled GraphQL field requested.
 *
 */
export type WithdrawAccount = {
	__typename?: 'WithdrawAccount';
	/** The number of the account to withdraw. */
	accountNumber: Scalars['String'];
	possibleErrors?: Maybe<Array<Maybe<PossibleErrorType>>>;
};

export type WithdrawAccountInput = {
	/** The number of the account to withdraw. */
	accountNumber?: InputMaybe<Scalars['String']>;
};

export const PostalAreaFragmentDoc = gql`
	fragment postalArea on PostalArea {
		postcode
		prefecture
		city
		area
	}
`;
export const FitProductFragmentDoc = gql`
	fragment fitProduct on ElectricityFitProduct {
		__typename
		code
		displayName
		params
		generationCredits {
			pricePerUnit
			pricePerUnitIncTax
		}
	}
`;
export const StandingChargeFragmentDoc = gql`
	fragment standingCharge on StandingCharge {
		gridOperatorCode
		pricePerUnitIncTax
	}
`;
export const SingleStepProductFragmentDoc = gql`
	fragment singleStepProduct on ElectricitySingleStepProduct {
		__typename
		code
		consumptionCharges {
			pricePerUnitIncTax
			gridOperatorCode
		}
		displayName
		params
		fuelCostAdjustment {
			pricePerUnitIncTax
			validTo
		}
		params
		standingChargeUnitType
		standingChargePricePerDay
		standingCharges {
			...standingCharge
		}
	}
	${StandingChargeFragmentDoc}
`;
export const SteppedProductFragmentDoc = gql`
	fragment steppedProduct on ElectricitySteppedProduct {
		__typename
		code
		consumptionCharges {
			pricePerUnitIncTax
			gridOperatorCode
			stepStart
			stepEnd
		}
		displayName
		fuelCostAdjustment {
			pricePerUnitIncTax
			validTo
		}
		params
		standingChargeUnitType
		standingChargePricePerDay
		standingCharges {
			...standingCharge
		}
	}
	${StandingChargeFragmentDoc}
`;
export const AccountDocument = gql`
	query account(
		$accountNumber: String!
		$activeOnDate: Date!
		$propertiesActiveFrom: DateTime
	) {
		account(accountNumber: $accountNumber) {
			accountType
			canBeWithdrawn
			hasActiveAgreement
			hasFutureAgreement
			paymentSchedules(first: 1, active: true, activeOnDate: $activeOnDate) {
				edges {
					node {
						id
						scheduleType
						isVariablePaymentAmount
						paymentAmount
						paymentDay
					}
				}
			}
			payments(first: 99) {
				edges {
					node {
						status
						paymentDate
						amount
					}
				}
			}
			paymentInstructions(first: 2, statuses: [ACTIVE]) {
				edges {
					node {
						accountHolder
						instructionType
						maskedAccountIdentifier
						cardExpiryYear
						cardExpiryMonth
					}
				}
			}
			balance
			activeReferralSchemes {
				domestic {
					referralUrl
					referrerRewardAmount
					referredRewardAmount
				}
			}
			number
			lifelineAgencyApplication {
				status
			}
			properties(activeFrom: $propertiesActiveFrom) {
				id
				postcode
				address
				electricityGenerationPoints {
					gpin
					status
					agreements {
						validFrom
						validTo
						product {
							...fitProduct
						}
					}
				}
				electricitySupplyPoints {
					status
					supplyDetails {
						amperage
						kva
						kw
						validFrom
					}
					earliestAmperageChangeDate
					canApplyAmperageChange
					canUpdateMoveInDate
					canUpdateMoveOutDate
					newAmperageOptions
					agreements {
						validFrom
						validTo
						product {
							...singleStepProduct
							...steppedProduct
						}
					}
					spin
					latestCosGainProcess {
						availableActions
						requestId
						forecastedSupplyStartDate
					}
					activeCosLossProcess {
						requestedSupplyEndDate
					}
					latestMoveInProcess {
						requestedMoveInDate
					}
					activeMoveOutProcess {
						canBeCancelled
						requestedMoveOutDate
					}
					activeAmperageChangeProcess {
						amperageChangeDate
						requestedAmperageValue
					}
				}
			}
			consentData
		}
	}
	${FitProductFragmentDoc}
	${SingleStepProductFragmentDoc}
	${SteppedProductFragmentDoc}
`;
export const AccountEventsDocument = gql`
	query accountEvents($accountNumber: String!, $cursor: String) {
		account(accountNumber: $accountNumber) {
			events(
				first: 10
				eventTypes: [EMAIL_SENT, MARKETING_EMAIL_SENT]
				after: $cursor
			) {
				edges {
					node {
						... on EmailEventType {
							id
							eventType
							message {
								id
								sentAt
								subject
								attachments {
									id
									filename
									temporaryUrl
								}
								textBody
								htmlBody
								sender
								recipient
							}
						}
					}
					cursor
				}
				pageInfo {
					endCursor
					hasNextPage
				}
			}
		}
	}
`;
export const AccountReferralsDocument = gql`
	query accountReferrals($accountNumber: String!, $startCursor: String) {
		account(accountNumber: $accountNumber) {
			referrals(first: 100, after: $startCursor) {
				pageInfo {
					hasNextPage
					endCursor
				}
				edges {
					node {
						id
						referredUserName
						referredUserJoinDate
						referringUserPaymentAmount
						paymentDate
						schemeType
					}
				}
			}
		}
	}
`;
export const AccountBillsDocument = gql`
	query accountBills($accountNumber: String!, $cursor: String) {
		account(accountNumber: $accountNumber) {
			bills(first: 12, after: $cursor) {
				edges {
					node {
						id
						billType
						issuedDate
						temporaryUrl
					}
				}
				pageInfo {
					endCursor
					hasNextPage
				}
			}
		}
	}
`;
export const CancelMoveOutDocument = gql`
	mutation cancelMoveOut($input: CancelMoveOutInput!) {
		cancelMoveOut(input: $input) {
			spin
		}
	}
`;
export const CreateAffiliateSessionDocument = gql`
	mutation createAffiliateSession($input: CreateAffiliateSessionInputType!) {
		createAffiliateSession(input: $input) {
			affiliateSession {
				id
			}
		}
	}
`;
export const CreateBankTransferPaymentMethodDocument = gql`
	mutation createBankTransferPaymentMethod(
		$input: CreateBankTransferPaymentMethodInput!
	) {
		createBankTransferPaymentMethod(input: $input) {
			__typename
			... on CommonError {
				field
				message
			}
			... on BankTransferPaymentMethodCreated {
				accountNumber
			}
		}
	}
`;
export const CreateElectricityQuoteDocument = gql`
	mutation createElectricityQuote($input: CreateElectricityQuoteInput!) {
		createElectricityQuote(input: $input) {
			quoteRequest {
				code
			}
		}
	}
`;
export const CreateJpnFileAttachmentDocument = gql`
	mutation createJpnFileAttachment($input: CreateJPNFileAttachmentInput!) {
		createJpnFileAttachment(input: $input) {
			postRequest {
				fields
				url
				key
			}
			clientMutationId
		}
	}
`;
export const CreateKonbiniPaymentDocument = gql`
	mutation createKonbiniPayment($input: CreateKonbiniPaymentInput!) {
		createKonbiniPayment(input: $input) {
			... on CommonError {
				__typename
				field
				message
			}
			... on KonbiniPaymentCreated {
				__typename
				expireAt
				voucherUrl
			}
		}
	}
`;
export const CreateKonbiniPaymentMethodDocument = gql`
	mutation createKonbiniPaymentMethod(
		$input: CreateKonbiniPaymentMethodInput!
	) {
		createKonbiniPaymentMethod(input: $input) {
			__typename
			... on CommonError {
				field
				message
			}
			... on KonbiniPaymentMethodCreated {
				reference
			}
		}
	}
`;
export const CreatePaymentMethodDocument = gql`
	mutation createPaymentMethod($input: CreatePaymentMethodInput) {
		createPaymentMethod(input: $input) {
			reference
		}
	}
`;
export const CreatePaymentScheduleDocument = gql`
	mutation createPaymentSchedule($input: PaymentScheduleInput!) {
		createPaymentSchedule(input: $input) {
			... on PaymentScheduleCreated {
				paymentAmount
				paymentDay
			}
		}
	}
`;
export const CustomerFeedbackDocument = gql`
	query customerFeedback($formId: Int!) {
		question(formId: $formId)
	}
`;
export const DemandResponseDocument = gql`
	query demandResponse($accountNumber: String!) {
		account(accountNumber: $accountNumber) {
			number
			availableDemandResponseCampaignsForRegistration
			demandResponseCampaignParticipants {
				campaignName
				campaignStart
				campaignEnd
				optedInAt
				totalDiffKwh
				totalDiffCarbon
				totalTreesSaved
				successfulDaysCount
				averagePercentageDifference
				tips
				numberOfEntries
				isEligibleForEvents
				isOptedIntoEvents
				isOptedIntoLine
				isOptedIntoEmails
				demandResponseDailyResults {
					eventDate
					amountSaved
					numberOfEntries
					baselineConsumptionKwh
					outturnConsumptionKwh
					percentageDifference
					validFrom
					validTo
				}
			}
		}
	}
`;
export const DunningProcessDocument = gql`
	query dunningProcess($accountNumber: String!) {
		dunningProcess(accountNumber: $accountNumber) {
			nextStep
			previousStep
			actions {
				stepName
				dueDate
			}
			badDebt
		}
	}
`;
export const EmailQuoteDocument = gql`
	mutation emailQuote($input: EmailQuoteInput!) {
		emailQuote(input: $input) {
			sent
		}
	}
`;
export const FetchElectricityGenerationPointDetailsDocument = gql`
	mutation fetchElectricityGenerationPointDetails(
		$input: FetchElectricityGenerationPointDetailsInput!
	) {
		fetchElectricityGenerationPointDetails(input: $input) {
			quoteRequest {
				quotedGenerationPoints {
					__typename
					... on QuotedElectricityGenerationPoint {
						canSupply
						hasMultipleSpins
						rejectionReasons
					}
				}
			}
		}
	}
`;
export const FetchElectricitySupplyPointDetailsDocument = gql`
	mutation fetchElectricitySupplyPointDetails(
		$input: FetchElectricitySupplyPointDetailsInput!
	) {
		fetchElectricitySupplyPointDetails(input: $input) {
			quoteRequest {
				quotedSupplyPoints {
					... on QuotedElectricitySupplyPoint {
						__typename
						canSupply
						hasMultipleSpins
						rejectionReasons
						supplyPointDetails {
							spin
						}
					}
				}
			}
		}
	}
`;
export const FetchRetailersDocument = gql`
	query fetchRetailers {
		retailers {
			code
			name
			displayName
			displayOrder
			params {
				aliases
			}
		}
	}
`;
export const GenerateCreditCardTokenDocument = gql`
	mutation generateCreditCardToken {
		generateCreditCardToken {
			creditCardToken
		}
	}
`;
export const ActiveDomesticSignupRewardSchemeDocument = gql`
	query activeDomesticSignupRewardScheme {
		activeDomesticSignupRewardScheme {
			code
		}
	}
`;
export const GetAffiliateLinkDocument = gql`
	query getAffiliateLink($subdomain: String!) {
		affiliateLink(subdomain: $subdomain) {
			id
			subdomain
			contactName
			contactEmail
			landingUrl
			organisation {
				name
				salesChannel
			}
		}
		activeAffiliateReferralScheme(subdomain: $subdomain) {
			code
		}
	}
`;
export const DomesticAccountReferralRewardSchemeDocument = gql`
	query domesticAccountReferralRewardScheme($referralCode: String!) {
		domesticAccountReferralRewardScheme(code: $referralCode) {
			referrerRewardAmount
			referredRewardAmount
			combinedRewardAmount
			referrerGivenName
			referrerFamilyName
		}
	}
`;
export const DomesticSignupRewardSchemeDocument = gql`
	query domesticSignupRewardScheme($code: String!) {
		domesticSignupRewardScheme(code: $code) {
			code
		}
	}
`;
export const GetEmailEventDocument = gql`
	query getEmailEvent($id: ID!) {
		node(id: $id) {
			id
			... on EmailEventType {
				message {
					id
					subject
					sentAt
					recipient
					attachments {
						id
						filename
						temporaryUrl
					}
					htmlBody
					textBody
				}
			}
		}
	}
`;
export const GetFixedFuelCostAdjustmentContextDocument = gql`
	query getFixedFuelCostAdjustmentContext(
		$accountNumber: String!
		$spin: String!
		$productCode: String!
	) {
		productEligibleStatus(
			accountNumber: $accountNumber
			spin: $spin
			productCode: $productCode
		) {
			isCampaignAvailable
			isUserEligible
			isUserOptedIn
		}
	}
`;
export const HalfHourlyReadingsDocument = gql`
	query halfHourlyReadings(
		$accountNumber: String!
		$fromDatetime: DateTime
		$toDatetime: DateTime
	) {
		account(accountNumber: $accountNumber) {
			properties {
				electricitySupplyPoints {
					status
					agreements {
						validFrom
					}
					halfHourlyReadings(
						fromDatetime: $fromDatetime
						toDatetime: $toDatetime
					) {
						startAt
						value
						costEstimate
						consumptionStep
						consumptionRateBand
					}
				}
			}
		}
	}
`;
export const GetPostalAreasDocument = gql`
	query getPostalAreas($postcode: String!) {
		postalAreas(postcode: $postcode) {
			...postalArea
		}
	}
	${PostalAreaFragmentDoc}
`;
export const GetStatementsDocument = gql`
	query getStatements($accountNumber: String!, $count: Int, $cursor: String!) {
		account(accountNumber: $accountNumber) {
			bills(
				first: $count
				after: $cursor
				includeOpenStatements: true
				includeBillsWithoutPDF: true
			) {
				pageInfo {
					hasNextPage
					endCursor
				}
				edges {
					node {
						id
						billType
						fromDate
						toDate
						temporaryUrl
						issuedDate
						... on StatementType {
							__typename
							consumptionStartDate
							consumptionEndDate
						}
					}
				}
			}
		}
	}
`;
export const GetTariffSummaryDocument = gql`
	query getTariffSummary($gridOperatorCode: String!, $productCode: String) {
		tariffSummary(
			gridOperatorCode: $gridOperatorCode
			productCode: $productCode
		) {
			code
			displayName
			gridOperatorCode
			productParams
			tiers {
				contractCapacityPattern
				consumptionRates {
					pricePerUnitIncTax
					stepStart
					stepEnd
					band
				}
				standingRates {
					amperage {
						pricePerUnitIncTax
						label
						unitType
					}
					kva {
						pricePerUnitIncTax
						label
						unitType
					}
				}
			}
		}
	}
`;
export const GetTransactionsDocument = gql`
	query getTransactions(
		$accountNumber: String!
		$count: Int
		$cursor: String!
	) {
		account(accountNumber: $accountNumber) {
			balance
			transactions(first: $count, after: $cursor) {
				pageInfo {
					hasNextPage
					endCursor
				}
				edges {
					node {
						__typename
						id
						statementId
						postedDate
						createdAt
						title
						amount
						balanceCarriedForward
						note
					}
				}
			}
		}
	}
`;
export const GetUserDocument = gql`
	query getUser {
		viewer {
			givenName
			familyName
			email
			mobile
			accountUserMeta {
				kanaGivenName
				kanaFamilyName
			}
		}
	}
`;
export const InitiateAmperageChangeDocument = gql`
	mutation initiateAmperageChange($input: InitiateAmperageChangeInput!) {
		initiateAmperageChange(input: $input) {
			... on AmperageChangeInitiated {
				__typename
				message
			}
			... on AmperageChangeDateInvalid {
				__typename
				message
				startDate
			}
			... on CommonError {
				__typename
				message
				field
			}
		}
	}
`;
export const InitiateMoveInDocument = gql`
	mutation initiateMoveIn($input: InitiateMoveInInput!) {
		initiateMoveIn(input: $input) {
			__typename
			... on MoveInSuccess {
				accountNumber
				token
				userId
				hasUsablePassword
			}
			... on CommonError {
				message
				field
			}
		}
	}
`;
export const InitiateMoveOutDocument = gql`
	mutation initiateMoveOut($input: InitiateMoveOutInput!) {
		initiateMoveOut(input: $input) {
			__typename
			... on CommonError {
				message
				field
			}
			... on MoveOutInitiated {
				message
			}
			... on MoveOutDateInvalid {
				message
				field
			}
		}
	}
`;
export const InitiateMoveOutAndMoveInDocument = gql`
	mutation initiateMoveOutAndMoveIn($input: InitiateMoveOutAndMoveInInput!) {
		initiateMoveOutAndMoveIn(input: $input) {
			__typename
			... on CommonError {
				message
				field
			}
			... on MoveOutAndMoveInInitiated {
				message
				accountNumber
				token
				hasUsablePassword
			}
			... on MoveInDateInvalid {
				message
			}
			... on MoveOutDateInvalid {
				message
				field
			}
		}
	}
`;
export const InitiateStandalonePaymentDocument = gql`
	mutation initiateStandalonePayment($input: InitiateStandalonePaymentInput!) {
		initiateStandalonePayment(input: $input) {
			payment {
				retrievalToken
				secretToken
			}
		}
	}
`;
export const InitiateSwitchInDocument = gql`
	mutation initiateSwitchIn($input: InitiateSwitchInInput) {
		initiateSwitchIn(input: $input) {
			accountNumber
			token
			userId
			hasUsablePassword
		}
	}
`;
export const IntegrationsDocument = gql`
	query integrations {
		integrations {
			... on LineIntegration {
				type
				status
				displayName
			}
			... on Integration {
				type
				status
			}
		}
	}
`;
export const LinkUserToLineDocument = gql`
	mutation linkUserToLine($input: LinkUserToLineInput!) {
		linkUserToLine(input: $input) {
			... on LineLinkRedirectResponse {
				__typename
				redirectUrl
			}
			... on LinkTokenNotFound {
				__typename
			}
			... on AlreadyLinkedError {
				__typename
			}
		}
	}
`;
export const MasqueradeAuthenticationDocument = gql`
	mutation masqueradeAuthentication(
		$masqueradeToken: String!
		$userId: String!
	) {
		masqueradeAuthentication(
			masqueradeToken: $masqueradeToken
			userId: $userId
		) {
			token
			errors {
				field
				messages
			}
		}
	}
`;
export const MonthlyReadingsDocument = gql`
	query monthlyReadings($accountNumber: String!) {
		account(accountNumber: $accountNumber) {
			properties {
				electricitySupplyPoints {
					status
					agreements {
						validFrom
					}
					intervalReadings {
						endAt
						startAt
						value
						costEstimate
					}
				}
			}
		}
	}
`;
export const MoveInContextDocument = gql`
	query moveInContext($isSpinKnown: Boolean!) {
		moveInContext {
			earliestMoveInDate(isSpinKnown: $isSpinKnown)
		}
	}
`;
export const ObtainKrakenTokenDocument = gql`
	mutation obtainKrakenToken($input: ObtainJSONWebTokenInput!) {
		obtainKrakenToken(input: $input) {
			refreshToken
			refreshExpiresIn
			payload
			token
		}
	}
`;
export const OnboardingJourneyAblyJwtDocument = gql`
	query onboardingJourneyAblyJwt($clientId: String!) {
		onboardingJourneyAblyJwt(clientId: $clientId) {
			token
		}
	}
`;
export const PaymentDetailsDocument = gql`
	query paymentDetails($accountNumber: String!, $activeOnDate: Date!) {
		account(accountNumber: $accountNumber) {
			paymentSchedules(first: 1, active: true, activeOnDate: $activeOnDate) {
				edges {
					node {
						paymentDay
						scheduleType
						isVariablePaymentAmount
						paymentAmount
					}
				}
			}
			paymentInstructions(first: 2, statuses: [ACTIVE]) {
				edges {
					node {
						accountHolder
						instructionType
						maskedAccountIdentifier
						cardExpiryYear
						cardExpiryMonth
					}
				}
			}
		}
	}
`;
export const ProductEligibleStatusDocument = gql`
	query productEligibleStatus(
		$accountNumber: String!
		$spin: String!
		$productCode: String!
	) {
		productEligibleStatus(
			accountNumber: $accountNumber
			spin: $spin
			productCode: $productCode
		) {
			isCampaignAvailable
			isUserEligible
			isUserOptedIn
		}
	}
`;
export const QuoteRequestDocument = gql`
	query quoteRequest($code: String!) {
		quoteRequest(code: $code) {
			code
			params {
				affiliateLinkSubdomain
				referralCode
				referralSchemeCode
			}
			quotedSupplyPoints {
				__typename
				... on QuotedElectricitySupplyPoint {
					postalArea {
						prefecture
						city
						area
						postcode
					}
					amperage
					kva
					gridOperatorCode
					annualEstimatedUsage
					quotedProducts {
						estimatedCosts {
							monthlyBreakdown
							quotedMonth {
								month
								cost
								usageAmount
							}
							standingChargePricePerDay
							standingChargeUnitType
						}
						product {
							__typename
							...singleStepProduct
							...steppedProduct
						}
					}
				}
			}
			quotedGenerationPoints {
				__typename
				... on QuotedElectricityGenerationPoint {
					postalArea {
						prefecture
						city
						area
						postcode
					}
					gridOperatorCode
					annualEstimatedGeneration
					quotedProducts {
						estimatedCosts {
							monthlyBreakdown
							quotedMonth {
								month
								cost
							}
						}
						product {
							__typename
							...singleStepProduct
							...steppedProduct
						}
					}
				}
			}
		}
	}
	${SingleStepProductFragmentDoc}
	${SteppedProductFragmentDoc}
`;
export const RegisterDemandResponseCampaignParticipantDocument = gql`
	mutation registerDemandResponseCampaignParticipant(
		$input: RegisterDemandResponseCampaignParticipantInput!
	) {
		registerDemandResponseCampaignParticipant(input: $input) {
			__typename
			... on RegisterDemandResponseCampaignParticipantRegistered {
				campaignName
				registeredAt
			}
			... on CommonError {
				message
				field
			}
		}
	}
`;
export const RequestPasswordResetDocument = gql`
	mutation requestPasswordReset($input: RequestPasswordResetInput!) {
		requestPasswordReset(input: $input) {
			email
		}
	}
`;
export const ResetPasswordDocument = gql`
	mutation resetPassword($input: ResetPasswordMutationInput!) {
		resetPassword(input: $input) {
			errors {
				... on SerializerFieldErrorsType {
					field
					errors {
						... on SerializerErrorType {
							message
							code
						}
					}
				}
			}
		}
	}
`;
export const SaveOnboardingJourneyProgressDocument = gql`
	mutation saveOnboardingJourneyProgress(
		$input: SaveOnboardingJourneyProgressInput!
	) {
		saveOnboardingJourneyProgress(input: $input) {
			success
		}
	}
`;
export const SelfServeSwitchInCorrectionDocument = gql`
	mutation selfServeSwitchInCorrection(
		$input: SelfServeSwitchInCorrectionInput!
	) {
		selfServeSwitchInCorrection(input: $input) {
			... on SwitchInCorrectionPerformed {
				__typename
				performedActions
			}
			... on CommonError {
				__typename
				message
				field
			}
		}
	}
`;
export const SendFamilySwitchInOfferDocument = gql`
	mutation sendFamilySwitchInOffer($input: SendFamilySwitchInOfferInput!) {
		sendFamilySwitchInOffer(input: $input) {
			hasSent
		}
	}
`;
export const StoreBankAccountDetailsDocument = gql`
	mutation storeBankAccountDetails($input: NewBankAccountDetailsInput) {
		storeBankAccountDetails(input: $input) {
			financialInstitutionRedirectUrl
			token
		}
	}
`;
export const StoreRefundBankAccountDocument = gql`
	mutation storeRefundBankAccount($input: NewRefundBankAccountDetailsInput!) {
		storeRefundBankAccount(input: $input) {
			__typename
			... on RefundBankAccountStored {
				reference
			}
			... on CommonError {
				field
				message
			}
		}
	}
`;
export const SubmitCustomerFeedbackDocument = gql`
	mutation submitCustomerFeedback($input: CustomerFeedbackInputType!) {
		submitCustomerFeedback(input: $input) {
			customerFeedback {
				id
			}
		}
	}
`;
export const SuggestFixedPaymentAmountDocument = gql`
	mutation suggestFixedPaymentAmount($input: SuggestFixedPaymentAmountInput!) {
		suggestFixedPaymentAmount(input: $input) {
			... on CommonError {
				field
				message
				__typename
			}
			... on FixedPaymentAmountSuggested {
				__typename
				amount
			}
		}
	}
`;
export const SwitchToFixedFuelCostAdjustmentDocument = gql`
	mutation switchToFixedFuelCostAdjustment(
		$input: SwitchToProductWithFixedFuelCostAdjustmentInput!
	) {
		switchToProductWithFixedFuelCostAdjustment(input: $input) {
			... on CommonError {
				__typename
				message
			}
			... on ProductWithFixedFuelCostAdjustmentSwitched {
				__typename
				isCampaignAvailable
				isUserEligible
				isUserOptedIn
			}
		}
	}
`;
export const TriggerPostUploadOperationsJpnDocument = gql`
	mutation triggerPostUploadOperationsJpn($input: String!) {
		triggerPostUploadOperationsJpn(s3Key: $input) {
			operationsTriggered
		}
	}
`;
export const UnlinkUserFromLineDocument = gql`
	mutation unlinkUserFromLine {
		unlinkUserFromLine {
			... on LineUnlinkedResponse {
				__typename
				message
			}
			... on LinkTokenNotFound {
				__typename
			}
			... on LineCommonError {
				__typename
				message
			}
		}
	}
`;
export const UpdateAccountUserDocument = gql`
	mutation updateAccountUser($input: UpdateAccountUserInput!) {
		updateAccountUser(input: $input) {
			viewer {
				familyName
				givenName
				email
				mobile
				accountUserMeta {
					kanaFamilyName
					kanaGivenName
				}
			}
		}
	}
`;
export const UpdateBankAccountDetailsDocument = gql`
	mutation updateBankAccountDetails($input: UpdateBankAccountDetailsInput) {
		updateBankAccountDetails(input: $input) {
			financialInstitutionRedirectUrl
			token
		}
	}
`;
export const UpdateCommsPreferencesDocument = gql`
	mutation updateCommsPreferences(
		$input: UpdateAccountUserCommsPreferencesMutationInput!
	) {
		updateCommsPreferences(input: $input) {
			errors {
				messages
			}
		}
	}
`;
export const UpdateAccountConsentDataDocument = gql`
	mutation updateAccountConsentData($input: UpdateAccountConsentDataInput!) {
		updateAccountConsentData(input: $input) {
			consentData
		}
	}
`;
export const UpdateCreditCardDetailsDocument = gql`
	mutation updateCreditCardDetails($input: UpdateCreditCardDetailsInput) {
		updateCreditCardDetails(input: $input) {
			cardNumber
		}
	}
`;
export const UpdateDemandResponseCampaignParticipantCommsPreferenceAndGeneralCommsPreferenceDocument = gql`
	mutation updateDemandResponseCampaignParticipantCommsPreferenceAndGeneralCommsPreference(
		$inputDR: UpdateDemandResponseCampaignParticipantCommsPreferencesInput!
		$input: UpdateAccountUserCommsPreferencesMutationInput!
	) {
		updateDemandResponseCampaignParticipantCommsPreferences(input: $inputDR) {
			__typename
			... on DemandResponseCampaignParticipantCommsPreferencesUpdated {
				isOptedIntoEmails
				isOptedIntoLine
				isOptedIntoDemandResponseEvents
			}
			... on CommonError {
				message
			}
		}
		updateCommsPreferences(input: $input) {
			__typename
			errors {
				messages
			}
		}
	}
`;
export const UpdateDemandResponseCampaignParticipantCommsPreferencesDocument = gql`
	mutation updateDemandResponseCampaignParticipantCommsPreferences(
		$input: UpdateDemandResponseCampaignParticipantCommsPreferencesInput!
	) {
		updateDemandResponseCampaignParticipantCommsPreferences(input: $input) {
			__typename
			... on DemandResponseCampaignParticipantCommsPreferencesUpdated {
				isOptedIntoEmails
				isOptedIntoLine
				isOptedIntoDemandResponseEvents
			}
			... on CommonError {
				message
			}
		}
	}
`;
export const UpdateDemandResponseCampaignParticipantTipDocument = gql`
	mutation updateDemandResponseCampaignParticipantTip(
		$input: UpdateDemandResponseCampaignParticipantTipInput!
	) {
		updateDemandResponseCampaignParticipantTip(input: $input) {
			__typename
			... on DemandResponseCampaignParticipantTipUpdated {
				tips
				totalNumberOfEntries
			}
			... on CommonError {
				message
			}
		}
	}
`;
export const UpdateMoveInDateDocument = gql`
	mutation updateMoveInDate($input: UpdateMoveInDateInput!) {
		updateMoveInDate(input: $input) {
			__typename
			newMoveInDate
		}
	}
`;
export const UpdateMoveOutDateDocument = gql`
	mutation updateMoveOutDate($input: UpdateMoveOutDateInput!) {
		updateMoveOutDate(input: $input) {
			__typename
			newMoveOutDate
			spin
		}
	}
`;
export const UpdatePasswordDocument = gql`
	mutation updatePassword($input: UpdatePasswordInput!) {
		updatePassword(input: $input) {
			viewer {
				email
			}
		}
	}
`;
export const ValidateEmailDocument = gql`
	mutation validateEmail($input: ValidateEmailInput!) {
		validateEmail(input: $input) {
			isValid
		}
	}
`;
export const ValidatePhoneDocument = gql`
	mutation validatePhone($input: ValidatePhoneNumberInput!) {
		validatePhone(input: $input) {
			isValid
		}
	}
`;
export const AccountViewerDocument = gql`
	query accountViewer {
		viewer {
			email
			familyName
			preferences {
				isOptedInToSmsMessages
				isOptedInToOfferMessages
			}
			accounts {
				balance
				number
				... on Account {
					properties {
						address
						electricitySupplyPoints {
							status
						}
					}
				}
			}
		}
	}
`;
export const WithdrawAccountDocument = gql`
	mutation withdrawAccount($input: WithdrawAccountInput!) {
		withdrawAccount(input: $input) {
			accountNumber
		}
	}
`;

export type SdkFunctionWrapper = <T>(
	action: (requestHeaders?: Record<string, string>) => Promise<T>,
	operationName: string,
	operationType?: string,
	variables?: any
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
	action,
	_operationName,
	_operationType,
	_variables
) => action();

export function getSdk(
	client: GraphQLClient,
	withWrapper: SdkFunctionWrapper = defaultWrapper
) {
	return {
		account(
			variables: AccountQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<AccountQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<AccountQuery>(AccountDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'account',
				'query',
				variables
			);
		},
		accountEvents(
			variables: AccountEventsQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<AccountEventsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<AccountEventsQuery>(AccountEventsDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'accountEvents',
				'query',
				variables
			);
		},
		accountReferrals(
			variables: AccountReferralsQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<AccountReferralsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<AccountReferralsQuery>(
						AccountReferralsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'accountReferrals',
				'query',
				variables
			);
		},
		accountBills(
			variables: AccountBillsQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<AccountBillsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<AccountBillsQuery>(AccountBillsDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'accountBills',
				'query',
				variables
			);
		},
		cancelMoveOut(
			variables: CancelMoveOutMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<CancelMoveOutMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<CancelMoveOutMutation>(
						CancelMoveOutDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'cancelMoveOut',
				'mutation',
				variables
			);
		},
		createAffiliateSession(
			variables: CreateAffiliateSessionMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<CreateAffiliateSessionMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<CreateAffiliateSessionMutation>(
						CreateAffiliateSessionDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'createAffiliateSession',
				'mutation',
				variables
			);
		},
		createBankTransferPaymentMethod(
			variables: CreateBankTransferPaymentMethodMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<CreateBankTransferPaymentMethodMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<CreateBankTransferPaymentMethodMutation>(
						CreateBankTransferPaymentMethodDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'createBankTransferPaymentMethod',
				'mutation',
				variables
			);
		},
		createElectricityQuote(
			variables: CreateElectricityQuoteMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<CreateElectricityQuoteMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<CreateElectricityQuoteMutation>(
						CreateElectricityQuoteDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'createElectricityQuote',
				'mutation',
				variables
			);
		},
		createJpnFileAttachment(
			variables: CreateJpnFileAttachmentMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<CreateJpnFileAttachmentMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<CreateJpnFileAttachmentMutation>(
						CreateJpnFileAttachmentDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'createJpnFileAttachment',
				'mutation',
				variables
			);
		},
		createKonbiniPayment(
			variables: CreateKonbiniPaymentMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<CreateKonbiniPaymentMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<CreateKonbiniPaymentMutation>(
						CreateKonbiniPaymentDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'createKonbiniPayment',
				'mutation',
				variables
			);
		},
		createKonbiniPaymentMethod(
			variables: CreateKonbiniPaymentMethodMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<CreateKonbiniPaymentMethodMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<CreateKonbiniPaymentMethodMutation>(
						CreateKonbiniPaymentMethodDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'createKonbiniPaymentMethod',
				'mutation',
				variables
			);
		},
		createPaymentMethod(
			variables?: CreatePaymentMethodMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<CreatePaymentMethodMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<CreatePaymentMethodMutation>(
						CreatePaymentMethodDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'createPaymentMethod',
				'mutation',
				variables
			);
		},
		createPaymentSchedule(
			variables: CreatePaymentScheduleMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<CreatePaymentScheduleMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<CreatePaymentScheduleMutation>(
						CreatePaymentScheduleDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'createPaymentSchedule',
				'mutation',
				variables
			);
		},
		customerFeedback(
			variables: CustomerFeedbackQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<CustomerFeedbackQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<CustomerFeedbackQuery>(
						CustomerFeedbackDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'customerFeedback',
				'query',
				variables
			);
		},
		demandResponse(
			variables: DemandResponseQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<DemandResponseQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<DemandResponseQuery>(
						DemandResponseDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'demandResponse',
				'query',
				variables
			);
		},
		dunningProcess(
			variables: DunningProcessQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<DunningProcessQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<DunningProcessQuery>(
						DunningProcessDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'dunningProcess',
				'query',
				variables
			);
		},
		emailQuote(
			variables: EmailQuoteMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<EmailQuoteMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<EmailQuoteMutation>(EmailQuoteDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'emailQuote',
				'mutation',
				variables
			);
		},
		fetchElectricityGenerationPointDetails(
			variables: FetchElectricityGenerationPointDetailsMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<FetchElectricityGenerationPointDetailsMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<FetchElectricityGenerationPointDetailsMutation>(
						FetchElectricityGenerationPointDetailsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'fetchElectricityGenerationPointDetails',
				'mutation',
				variables
			);
		},
		fetchElectricitySupplyPointDetails(
			variables: FetchElectricitySupplyPointDetailsMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<FetchElectricitySupplyPointDetailsMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<FetchElectricitySupplyPointDetailsMutation>(
						FetchElectricitySupplyPointDetailsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'fetchElectricitySupplyPointDetails',
				'mutation',
				variables
			);
		},
		fetchRetailers(
			variables?: FetchRetailersQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<FetchRetailersQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<FetchRetailersQuery>(
						FetchRetailersDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'fetchRetailers',
				'query',
				variables
			);
		},
		generateCreditCardToken(
			variables?: GenerateCreditCardTokenMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<GenerateCreditCardTokenMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GenerateCreditCardTokenMutation>(
						GenerateCreditCardTokenDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'generateCreditCardToken',
				'mutation',
				variables
			);
		},
		activeDomesticSignupRewardScheme(
			variables?: ActiveDomesticSignupRewardSchemeQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<ActiveDomesticSignupRewardSchemeQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<ActiveDomesticSignupRewardSchemeQuery>(
						ActiveDomesticSignupRewardSchemeDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'activeDomesticSignupRewardScheme',
				'query',
				variables
			);
		},
		getAffiliateLink(
			variables: GetAffiliateLinkQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<GetAffiliateLinkQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetAffiliateLinkQuery>(
						GetAffiliateLinkDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'getAffiliateLink',
				'query',
				variables
			);
		},
		domesticAccountReferralRewardScheme(
			variables: DomesticAccountReferralRewardSchemeQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<DomesticAccountReferralRewardSchemeQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<DomesticAccountReferralRewardSchemeQuery>(
						DomesticAccountReferralRewardSchemeDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'domesticAccountReferralRewardScheme',
				'query',
				variables
			);
		},
		domesticSignupRewardScheme(
			variables: DomesticSignupRewardSchemeQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<DomesticSignupRewardSchemeQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<DomesticSignupRewardSchemeQuery>(
						DomesticSignupRewardSchemeDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'domesticSignupRewardScheme',
				'query',
				variables
			);
		},
		getEmailEvent(
			variables: GetEmailEventQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<GetEmailEventQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetEmailEventQuery>(GetEmailEventDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'getEmailEvent',
				'query',
				variables
			);
		},
		getFixedFuelCostAdjustmentContext(
			variables: GetFixedFuelCostAdjustmentContextQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<GetFixedFuelCostAdjustmentContextQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetFixedFuelCostAdjustmentContextQuery>(
						GetFixedFuelCostAdjustmentContextDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'getFixedFuelCostAdjustmentContext',
				'query',
				variables
			);
		},
		halfHourlyReadings(
			variables: HalfHourlyReadingsQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<HalfHourlyReadingsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<HalfHourlyReadingsQuery>(
						HalfHourlyReadingsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'halfHourlyReadings',
				'query',
				variables
			);
		},
		getPostalAreas(
			variables: GetPostalAreasQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<GetPostalAreasQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetPostalAreasQuery>(
						GetPostalAreasDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'getPostalAreas',
				'query',
				variables
			);
		},
		getStatements(
			variables: GetStatementsQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<GetStatementsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetStatementsQuery>(GetStatementsDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'getStatements',
				'query',
				variables
			);
		},
		getTariffSummary(
			variables: GetTariffSummaryQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<GetTariffSummaryQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetTariffSummaryQuery>(
						GetTariffSummaryDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'getTariffSummary',
				'query',
				variables
			);
		},
		getTransactions(
			variables: GetTransactionsQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<GetTransactionsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetTransactionsQuery>(
						GetTransactionsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'getTransactions',
				'query',
				variables
			);
		},
		getUser(
			variables?: GetUserQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<GetUserQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<GetUserQuery>(GetUserDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'getUser',
				'query',
				variables
			);
		},
		initiateAmperageChange(
			variables: InitiateAmperageChangeMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<InitiateAmperageChangeMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<InitiateAmperageChangeMutation>(
						InitiateAmperageChangeDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'initiateAmperageChange',
				'mutation',
				variables
			);
		},
		initiateMoveIn(
			variables: InitiateMoveInMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<InitiateMoveInMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<InitiateMoveInMutation>(
						InitiateMoveInDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'initiateMoveIn',
				'mutation',
				variables
			);
		},
		initiateMoveOut(
			variables: InitiateMoveOutMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<InitiateMoveOutMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<InitiateMoveOutMutation>(
						InitiateMoveOutDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'initiateMoveOut',
				'mutation',
				variables
			);
		},
		initiateMoveOutAndMoveIn(
			variables: InitiateMoveOutAndMoveInMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<InitiateMoveOutAndMoveInMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<InitiateMoveOutAndMoveInMutation>(
						InitiateMoveOutAndMoveInDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'initiateMoveOutAndMoveIn',
				'mutation',
				variables
			);
		},
		initiateStandalonePayment(
			variables: InitiateStandalonePaymentMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<InitiateStandalonePaymentMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<InitiateStandalonePaymentMutation>(
						InitiateStandalonePaymentDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'initiateStandalonePayment',
				'mutation',
				variables
			);
		},
		initiateSwitchIn(
			variables?: InitiateSwitchInMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<InitiateSwitchInMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<InitiateSwitchInMutation>(
						InitiateSwitchInDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'initiateSwitchIn',
				'mutation',
				variables
			);
		},
		integrations(
			variables?: IntegrationsQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<IntegrationsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<IntegrationsQuery>(IntegrationsDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'integrations',
				'query',
				variables
			);
		},
		linkUserToLine(
			variables: LinkUserToLineMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<LinkUserToLineMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<LinkUserToLineMutation>(
						LinkUserToLineDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'linkUserToLine',
				'mutation',
				variables
			);
		},
		masqueradeAuthentication(
			variables: MasqueradeAuthenticationMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<MasqueradeAuthenticationMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<MasqueradeAuthenticationMutation>(
						MasqueradeAuthenticationDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'masqueradeAuthentication',
				'mutation',
				variables
			);
		},
		monthlyReadings(
			variables: MonthlyReadingsQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<MonthlyReadingsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<MonthlyReadingsQuery>(
						MonthlyReadingsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'monthlyReadings',
				'query',
				variables
			);
		},
		moveInContext(
			variables: MoveInContextQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<MoveInContextQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<MoveInContextQuery>(MoveInContextDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'moveInContext',
				'query',
				variables
			);
		},
		obtainKrakenToken(
			variables: ObtainKrakenTokenMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<ObtainKrakenTokenMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<ObtainKrakenTokenMutation>(
						ObtainKrakenTokenDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'obtainKrakenToken',
				'mutation',
				variables
			);
		},
		onboardingJourneyAblyJwt(
			variables: OnboardingJourneyAblyJwtQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<OnboardingJourneyAblyJwtQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<OnboardingJourneyAblyJwtQuery>(
						OnboardingJourneyAblyJwtDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'onboardingJourneyAblyJwt',
				'query',
				variables
			);
		},
		paymentDetails(
			variables: PaymentDetailsQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<PaymentDetailsQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<PaymentDetailsQuery>(
						PaymentDetailsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'paymentDetails',
				'query',
				variables
			);
		},
		productEligibleStatus(
			variables: ProductEligibleStatusQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<ProductEligibleStatusQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<ProductEligibleStatusQuery>(
						ProductEligibleStatusDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'productEligibleStatus',
				'query',
				variables
			);
		},
		quoteRequest(
			variables: QuoteRequestQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<QuoteRequestQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<QuoteRequestQuery>(QuoteRequestDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'quoteRequest',
				'query',
				variables
			);
		},
		registerDemandResponseCampaignParticipant(
			variables: RegisterDemandResponseCampaignParticipantMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<RegisterDemandResponseCampaignParticipantMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<RegisterDemandResponseCampaignParticipantMutation>(
						RegisterDemandResponseCampaignParticipantDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'registerDemandResponseCampaignParticipant',
				'mutation',
				variables
			);
		},
		requestPasswordReset(
			variables: RequestPasswordResetMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<RequestPasswordResetMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<RequestPasswordResetMutation>(
						RequestPasswordResetDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'requestPasswordReset',
				'mutation',
				variables
			);
		},
		resetPassword(
			variables: ResetPasswordMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<ResetPasswordMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<ResetPasswordMutation>(
						ResetPasswordDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'resetPassword',
				'mutation',
				variables
			);
		},
		saveOnboardingJourneyProgress(
			variables: SaveOnboardingJourneyProgressMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<SaveOnboardingJourneyProgressMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<SaveOnboardingJourneyProgressMutation>(
						SaveOnboardingJourneyProgressDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'saveOnboardingJourneyProgress',
				'mutation',
				variables
			);
		},
		selfServeSwitchInCorrection(
			variables: SelfServeSwitchInCorrectionMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<SelfServeSwitchInCorrectionMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<SelfServeSwitchInCorrectionMutation>(
						SelfServeSwitchInCorrectionDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'selfServeSwitchInCorrection',
				'mutation',
				variables
			);
		},
		sendFamilySwitchInOffer(
			variables: SendFamilySwitchInOfferMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<SendFamilySwitchInOfferMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<SendFamilySwitchInOfferMutation>(
						SendFamilySwitchInOfferDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'sendFamilySwitchInOffer',
				'mutation',
				variables
			);
		},
		storeBankAccountDetails(
			variables?: StoreBankAccountDetailsMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<StoreBankAccountDetailsMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<StoreBankAccountDetailsMutation>(
						StoreBankAccountDetailsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'storeBankAccountDetails',
				'mutation',
				variables
			);
		},
		storeRefundBankAccount(
			variables: StoreRefundBankAccountMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<StoreRefundBankAccountMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<StoreRefundBankAccountMutation>(
						StoreRefundBankAccountDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'storeRefundBankAccount',
				'mutation',
				variables
			);
		},
		submitCustomerFeedback(
			variables: SubmitCustomerFeedbackMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<SubmitCustomerFeedbackMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<SubmitCustomerFeedbackMutation>(
						SubmitCustomerFeedbackDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'submitCustomerFeedback',
				'mutation',
				variables
			);
		},
		suggestFixedPaymentAmount(
			variables: SuggestFixedPaymentAmountMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<SuggestFixedPaymentAmountMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<SuggestFixedPaymentAmountMutation>(
						SuggestFixedPaymentAmountDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'suggestFixedPaymentAmount',
				'mutation',
				variables
			);
		},
		switchToFixedFuelCostAdjustment(
			variables: SwitchToFixedFuelCostAdjustmentMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<SwitchToFixedFuelCostAdjustmentMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<SwitchToFixedFuelCostAdjustmentMutation>(
						SwitchToFixedFuelCostAdjustmentDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'switchToFixedFuelCostAdjustment',
				'mutation',
				variables
			);
		},
		triggerPostUploadOperationsJpn(
			variables: TriggerPostUploadOperationsJpnMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<TriggerPostUploadOperationsJpnMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<TriggerPostUploadOperationsJpnMutation>(
						TriggerPostUploadOperationsJpnDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'triggerPostUploadOperationsJpn',
				'mutation',
				variables
			);
		},
		unlinkUserFromLine(
			variables?: UnlinkUserFromLineMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<UnlinkUserFromLineMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UnlinkUserFromLineMutation>(
						UnlinkUserFromLineDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'unlinkUserFromLine',
				'mutation',
				variables
			);
		},
		updateAccountUser(
			variables: UpdateAccountUserMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<UpdateAccountUserMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdateAccountUserMutation>(
						UpdateAccountUserDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'updateAccountUser',
				'mutation',
				variables
			);
		},
		updateBankAccountDetails(
			variables?: UpdateBankAccountDetailsMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<UpdateBankAccountDetailsMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdateBankAccountDetailsMutation>(
						UpdateBankAccountDetailsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'updateBankAccountDetails',
				'mutation',
				variables
			);
		},
		updateCommsPreferences(
			variables: UpdateCommsPreferencesMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<UpdateCommsPreferencesMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdateCommsPreferencesMutation>(
						UpdateCommsPreferencesDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'updateCommsPreferences',
				'mutation',
				variables
			);
		},
		updateAccountConsentData(
			variables: UpdateAccountConsentDataMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<UpdateAccountConsentDataMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdateAccountConsentDataMutation>(
						UpdateAccountConsentDataDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'updateAccountConsentData',
				'mutation',
				variables
			);
		},
		updateCreditCardDetails(
			variables?: UpdateCreditCardDetailsMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<UpdateCreditCardDetailsMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdateCreditCardDetailsMutation>(
						UpdateCreditCardDetailsDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'updateCreditCardDetails',
				'mutation',
				variables
			);
		},
		updateDemandResponseCampaignParticipantCommsPreferenceAndGeneralCommsPreference(
			variables: UpdateDemandResponseCampaignParticipantCommsPreferenceAndGeneralCommsPreferenceMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<UpdateDemandResponseCampaignParticipantCommsPreferenceAndGeneralCommsPreferenceMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdateDemandResponseCampaignParticipantCommsPreferenceAndGeneralCommsPreferenceMutation>(
						UpdateDemandResponseCampaignParticipantCommsPreferenceAndGeneralCommsPreferenceDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'updateDemandResponseCampaignParticipantCommsPreferenceAndGeneralCommsPreference',
				'mutation',
				variables
			);
		},
		updateDemandResponseCampaignParticipantCommsPreferences(
			variables: UpdateDemandResponseCampaignParticipantCommsPreferencesMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<UpdateDemandResponseCampaignParticipantCommsPreferencesMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdateDemandResponseCampaignParticipantCommsPreferencesMutation>(
						UpdateDemandResponseCampaignParticipantCommsPreferencesDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'updateDemandResponseCampaignParticipantCommsPreferences',
				'mutation',
				variables
			);
		},
		updateDemandResponseCampaignParticipantTip(
			variables: UpdateDemandResponseCampaignParticipantTipMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<UpdateDemandResponseCampaignParticipantTipMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdateDemandResponseCampaignParticipantTipMutation>(
						UpdateDemandResponseCampaignParticipantTipDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'updateDemandResponseCampaignParticipantTip',
				'mutation',
				variables
			);
		},
		updateMoveInDate(
			variables: UpdateMoveInDateMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<UpdateMoveInDateMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdateMoveInDateMutation>(
						UpdateMoveInDateDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'updateMoveInDate',
				'mutation',
				variables
			);
		},
		updateMoveOutDate(
			variables: UpdateMoveOutDateMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<UpdateMoveOutDateMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdateMoveOutDateMutation>(
						UpdateMoveOutDateDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'updateMoveOutDate',
				'mutation',
				variables
			);
		},
		updatePassword(
			variables: UpdatePasswordMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<UpdatePasswordMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<UpdatePasswordMutation>(
						UpdatePasswordDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'updatePassword',
				'mutation',
				variables
			);
		},
		validateEmail(
			variables: ValidateEmailMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<ValidateEmailMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<ValidateEmailMutation>(
						ValidateEmailDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'validateEmail',
				'mutation',
				variables
			);
		},
		validatePhone(
			variables: ValidatePhoneMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<ValidatePhoneMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<ValidatePhoneMutation>(
						ValidatePhoneDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'validatePhone',
				'mutation',
				variables
			);
		},
		accountViewer(
			variables?: AccountViewerQueryVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<AccountViewerQuery> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<AccountViewerQuery>(AccountViewerDocument, variables, {
						...requestHeaders,
						...wrappedRequestHeaders,
					}),
				'accountViewer',
				'query',
				variables
			);
		},
		withdrawAccount(
			variables: WithdrawAccountMutationVariables,
			requestHeaders?: GraphQLClientRequestHeaders
		): Promise<WithdrawAccountMutation> {
			return withWrapper(
				(wrappedRequestHeaders) =>
					client.request<WithdrawAccountMutation>(
						WithdrawAccountDocument,
						variables,
						{ ...requestHeaders, ...wrappedRequestHeaders }
					),
				'withdrawAccount',
				'mutation',
				variables
			);
		},
	};
}
export type Sdk = ReturnType<typeof getSdk>;
export type AccountQueryVariables = Exact<{
	accountNumber: Scalars['String'];
	activeOnDate: Scalars['Date'];
	propertiesActiveFrom?: InputMaybe<Scalars['DateTime']>;
}>;

export type AccountQuery = {
	__typename?: 'Query';
	account?: {
		__typename?: 'Account';
		accountType?: AccountTypeChoices | null;
		canBeWithdrawn?: boolean | null;
		hasActiveAgreement?: boolean | null;
		hasFutureAgreement?: boolean | null;
		balance: number;
		number?: string | null;
		consentData?: any | null;
		paymentSchedules?: {
			__typename?: 'PaymentScheduleConnectionTypeConnection';
			edges: Array<{
				__typename?: 'PaymentScheduleConnectionTypeEdge';
				node?: {
					__typename?: 'PaymentScheduleType';
					id: string;
					scheduleType?: ScheduleType | null;
					isVariablePaymentAmount: boolean;
					paymentAmount: number;
					paymentDay?: number | null;
				} | null;
			} | null>;
		} | null;
		payments?: {
			__typename?: 'AccountPaymentConnectionTypeConnection';
			edges: Array<{
				__typename?: 'AccountPaymentConnectionTypeEdge';
				node?: {
					__typename?: 'AccountPaymentType';
					status?: AccountPaymentStatusOptions | null;
					paymentDate: any;
					amount: any;
				} | null;
			} | null>;
		} | null;
		paymentInstructions?: {
			__typename?: 'PaymentInstructionConnection';
			edges: Array<{
				__typename?: 'PaymentInstructionEdge';
				node?: {
					__typename?: 'PaymentInstructionType';
					accountHolder: string;
					instructionType?: string | null;
					maskedAccountIdentifier?: string | null;
					cardExpiryYear?: number | null;
					cardExpiryMonth?: number | null;
				} | null;
			} | null>;
		} | null;
		activeReferralSchemes?: {
			__typename?: 'ReferralSchemeTypes';
			domestic?: {
				__typename?: 'ReferralSchemeType';
				referralUrl?: string | null;
				referrerRewardAmount?: number | null;
				referredRewardAmount?: number | null;
			} | null;
		} | null;
		lifelineAgencyApplication?: {
			__typename?: 'LifelineAgencyApplication';
			status: LifelineAgencySignUpsFileRecordStatus;
		} | null;
		properties?: Array<{
			__typename?: 'Property';
			id?: string | null;
			postcode?: string | null;
			address?: string | null;
			electricityGenerationPoints?: Array<{
				__typename?: 'ElectricityGenerationPoint';
				gpin: string;
				status: GenerationPointStatus;
				agreements?: Array<{
					__typename?: 'GenerationAgreement';
					validFrom?: any | null;
					validTo?: any | null;
					product?:
						| {
								__typename: 'ElectricityFitProduct';
								code: string;
								displayName: string;
								params?: any | null;
								generationCredits: Array<{
									__typename?: 'GenerationCreditRate';
									pricePerUnit: string;
									pricePerUnitIncTax: string;
								} | null>;
						  }
						| { __typename?: 'ElectricitySingleStepProduct' }
						| { __typename?: 'ElectricitySteppedProduct' }
						| { __typename?: 'GasTieredProduct' }
						| null;
				} | null> | null;
			} | null> | null;
			electricitySupplyPoints?: Array<{
				__typename?: 'ElectricitySupplyPoint';
				status: SupplyPointStatus;
				earliestAmperageChangeDate: any;
				canApplyAmperageChange?: boolean | null;
				canUpdateMoveInDate?: boolean | null;
				canUpdateMoveOutDate?: boolean | null;
				newAmperageOptions: Array<number | null>;
				spin: string;
				supplyDetails?: Array<{
					__typename?: 'SupplyDetails';
					amperage?: number | null;
					kva?: number | null;
					kw?: string | null;
					validFrom?: any | null;
				} | null> | null;
				agreements: Array<{
					__typename?: 'Agreement';
					validFrom?: any | null;
					validTo?: any | null;
					product?:
						| { __typename?: 'ElectricityFitProduct' }
						| {
								__typename: 'ElectricitySingleStepProduct';
								code: string;
								displayName: string;
								params?: any | null;
								standingChargeUnitType?: string | null;
								standingChargePricePerDay?: string | null;
								consumptionCharges: Array<{
									__typename?: 'ConsumptionRate';
									pricePerUnitIncTax: string;
									gridOperatorCode?: string | null;
								} | null>;
								fuelCostAdjustment?: {
									__typename?: 'FuelCostAdjustmentRate';
									pricePerUnitIncTax: string;
									validTo: any;
								} | null;
								standingCharges: Array<{
									__typename?: 'StandingCharge';
									gridOperatorCode?: string | null;
									pricePerUnitIncTax: string;
								} | null>;
						  }
						| {
								__typename: 'ElectricitySteppedProduct';
								code: string;
								displayName: string;
								params?: any | null;
								standingChargeUnitType?: string | null;
								standingChargePricePerDay?: string | null;
								consumptionCharges: Array<{
									__typename?: 'SteppedConsumptionRate';
									pricePerUnitIncTax: string;
									gridOperatorCode?: string | null;
									stepStart?: number | null;
									stepEnd?: number | null;
								} | null>;
								fuelCostAdjustment?: {
									__typename?: 'FuelCostAdjustmentRate';
									pricePerUnitIncTax: string;
									validTo: any;
								} | null;
								standingCharges: Array<{
									__typename?: 'StandingCharge';
									gridOperatorCode?: string | null;
									pricePerUnitIncTax: string;
								} | null>;
						  }
						| { __typename?: 'GasTieredProduct' }
						| null;
				} | null>;
				latestCosGainProcess?: {
					__typename?: 'CosGainProcess';
					availableActions?: Array<SelfServiceAction | null> | null;
					requestId?: string | null;
					forecastedSupplyStartDate?: any | null;
				} | null;
				activeCosLossProcess?: {
					__typename?: 'CosLossProcess';
					requestedSupplyEndDate?: any | null;
				} | null;
				latestMoveInProcess?: {
					__typename?: 'MoveInProcess';
					requestedMoveInDate?: any | null;
				} | null;
				activeMoveOutProcess?: {
					__typename?: 'MoveOutProcess';
					canBeCancelled?: boolean | null;
					requestedMoveOutDate?: any | null;
				} | null;
				activeAmperageChangeProcess?: {
					__typename?: 'AmperageChangeProcess';
					amperageChangeDate?: any | null;
					requestedAmperageValue?: number | null;
				} | null;
			} | null> | null;
		} | null> | null;
	} | null;
};

export type AccountEventsQueryVariables = Exact<{
	accountNumber: Scalars['String'];
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type AccountEventsQuery = {
	__typename?: 'Query';
	account?: {
		__typename?: 'Account';
		events?: {
			__typename?: 'AccountEventConnectionTypeConnection';
			edges: Array<{
				__typename?: 'AccountEventConnectionTypeEdge';
				cursor: string;
				node?:
					| {
							__typename?: 'EmailEventType';
							id: string;
							eventType: string;
							message?: {
								__typename?: 'EmailType';
								id: string;
								sentAt?: any | null;
								subject?: string | null;
								textBody?: string | null;
								htmlBody?: string | null;
								sender?: string | null;
								recipient?: string | null;
								attachments?: Array<{
									__typename?: 'EmailAttachmentType';
									id: string;
									filename: string;
									temporaryUrl?: string | null;
								} | null> | null;
							} | null;
					  }
					| { __typename?: 'PrintEventType' }
					| null;
			} | null>;
			pageInfo: {
				__typename?: 'PageInfo';
				endCursor?: string | null;
				hasNextPage: boolean;
			};
		} | null;
	} | null;
};

export type AccountReferralsQueryVariables = Exact<{
	accountNumber: Scalars['String'];
	startCursor?: InputMaybe<Scalars['String']>;
}>;

export type AccountReferralsQuery = {
	__typename?: 'Query';
	account?: {
		__typename?: 'Account';
		referrals?: {
			__typename?: 'ReferralConnectionTypeConnection';
			pageInfo: {
				__typename?: 'PageInfo';
				hasNextPage: boolean;
				endCursor?: string | null;
			};
			edges: Array<{
				__typename?: 'ReferralConnectionTypeEdge';
				node?: {
					__typename?: 'ReferralType';
					id: string;
					referredUserName?: string | null;
					referredUserJoinDate?: any | null;
					referringUserPaymentAmount?: number | null;
					paymentDate?: any | null;
					schemeType?: ReferralSchemeTypeChoices | null;
				} | null;
			} | null>;
		} | null;
	} | null;
};

export type AccountBillsQueryVariables = Exact<{
	accountNumber: Scalars['String'];
	cursor?: InputMaybe<Scalars['String']>;
}>;

export type AccountBillsQuery = {
	__typename?: 'Query';
	account?: {
		__typename?: 'Account';
		bills?: {
			__typename?: 'BillConnectionTypeConnection';
			edges: Array<{
				__typename?: 'BillConnectionTypeEdge';
				node?:
					| {
							__typename?: 'InvoiceType';
							id?: string | null;
							billType?: BillTypeEnum | null;
							issuedDate?: any | null;
							temporaryUrl?: string | null;
					  }
					| {
							__typename?: 'PeriodBasedDocumentType';
							id?: string | null;
							billType?: BillTypeEnum | null;
							issuedDate?: any | null;
							temporaryUrl?: string | null;
					  }
					| {
							__typename?: 'PreKrakenBillType';
							id?: string | null;
							billType?: BillTypeEnum | null;
							issuedDate?: any | null;
							temporaryUrl?: string | null;
					  }
					| {
							__typename?: 'StatementType';
							id?: string | null;
							billType?: BillTypeEnum | null;
							issuedDate?: any | null;
							temporaryUrl?: string | null;
					  }
					| null;
			} | null>;
			pageInfo: {
				__typename?: 'PageInfo';
				endCursor?: string | null;
				hasNextPage: boolean;
			};
		} | null;
	} | null;
};

export type CancelMoveOutMutationVariables = Exact<{
	input: CancelMoveOutInput;
}>;

export type CancelMoveOutMutation = {
	__typename?: 'Mutation';
	cancelMoveOut?: { __typename?: 'CancelMoveOut'; spin: string } | null;
};

export type CreateAffiliateSessionMutationVariables = Exact<{
	input: CreateAffiliateSessionInputType;
}>;

export type CreateAffiliateSessionMutation = {
	__typename?: 'Mutation';
	createAffiliateSession: {
		__typename?: 'CreateAffiliateSession';
		affiliateSession?: {
			__typename?: 'AffiliateSessionType';
			id: string;
		} | null;
	};
};

export type CreateBankTransferPaymentMethodMutationVariables = Exact<{
	input: CreateBankTransferPaymentMethodInput;
}>;

export type CreateBankTransferPaymentMethodMutation = {
	__typename?: 'Mutation';
	createBankTransferPaymentMethod:
		| { __typename: 'BankTransferPaymentMethodCreated'; accountNumber: string }
		| { __typename: 'CommonError'; field?: string | null; message: string };
};

export type CreateElectricityQuoteMutationVariables = Exact<{
	input: CreateElectricityQuoteInput;
}>;

export type CreateElectricityQuoteMutation = {
	__typename?: 'Mutation';
	createElectricityQuote?: {
		__typename?: 'CreateElectricityQuote';
		quoteRequest?: { __typename?: 'Quote'; code: string } | null;
	} | null;
};

export type CreateJpnFileAttachmentMutationVariables = Exact<{
	input: CreateJpnFileAttachmentInput;
}>;

export type CreateJpnFileAttachmentMutation = {
	__typename?: 'Mutation';
	createJpnFileAttachment: {
		__typename?: 'CreateJPNFileAttachmentPayload';
		clientMutationId?: string | null;
		postRequest?: {
			__typename?: 'UploadPostRequest';
			fields: any;
			url: string;
			key: string;
		} | null;
	};
};

export type CreateKonbiniPaymentMutationVariables = Exact<{
	input: CreateKonbiniPaymentInput;
}>;

export type CreateKonbiniPaymentMutation = {
	__typename?: 'Mutation';
	createKonbiniPayment:
		| { __typename: 'CommonError'; field?: string | null; message: string }
		| {
				__typename: 'KonbiniPaymentCreated';
				expireAt: any;
				voucherUrl: string;
		  };
};

export type CreateKonbiniPaymentMethodMutationVariables = Exact<{
	input: CreateKonbiniPaymentMethodInput;
}>;

export type CreateKonbiniPaymentMethodMutation = {
	__typename?: 'Mutation';
	createKonbiniPaymentMethod:
		| { __typename: 'CommonError'; field?: string | null; message: string }
		| { __typename: 'KonbiniPaymentMethodCreated'; reference: string };
};

export type CreatePaymentMethodMutationVariables = Exact<{
	input?: InputMaybe<CreatePaymentMethodInput>;
}>;

export type CreatePaymentMethodMutation = {
	__typename?: 'Mutation';
	createPaymentMethod?: {
		__typename?: 'CreatePaymentMethod';
		reference: string;
	} | null;
};

export type CreatePaymentScheduleMutationVariables = Exact<{
	input: PaymentScheduleInput;
}>;

export type CreatePaymentScheduleMutation = {
	__typename?: 'Mutation';
	createPaymentSchedule:
		| { __typename?: 'CommonError' }
		| {
				__typename?: 'PaymentScheduleCreated';
				paymentAmount: number;
				paymentDay: number;
		  };
};

export type CustomerFeedbackQueryVariables = Exact<{
	formId: Scalars['Int'];
}>;

export type CustomerFeedbackQuery = {
	__typename?: 'Query';
	question?: string | null;
};

export type DemandResponseQueryVariables = Exact<{
	accountNumber: Scalars['String'];
}>;

export type DemandResponseQuery = {
	__typename?: 'Query';
	account?: {
		__typename?: 'Account';
		number?: string | null;
		availableDemandResponseCampaignsForRegistration?: Array<
			string | null
		> | null;
		demandResponseCampaignParticipants?: Array<{
			__typename?: 'DemandResponseCampaignParticipantType';
			campaignName?: string | null;
			campaignStart?: any | null;
			campaignEnd?: any | null;
			optedInAt?: any | null;
			totalDiffKwh?: number | null;
			totalDiffCarbon?: number | null;
			totalTreesSaved?: number | null;
			successfulDaysCount?: number | null;
			averagePercentageDifference?: number | null;
			tips?: any | null;
			numberOfEntries?: number | null;
			isEligibleForEvents?: boolean | null;
			isOptedIntoEvents?: boolean | null;
			isOptedIntoLine?: boolean | null;
			isOptedIntoEmails?: boolean | null;
			demandResponseDailyResults: Array<{
				__typename?: 'DemandResponseDailyResultType';
				eventDate?: any | null;
				amountSaved?: number | null;
				numberOfEntries?: number | null;
				baselineConsumptionKwh?: number | null;
				outturnConsumptionKwh?: number | null;
				percentageDifference?: number | null;
				validFrom?: any | null;
				validTo?: any | null;
			}>;
		} | null> | null;
	} | null;
};

export type DunningProcessQueryVariables = Exact<{
	accountNumber: Scalars['String'];
}>;

export type DunningProcessQuery = {
	__typename?: 'Query';
	dunningProcess?: {
		__typename?: 'DunningProcess';
		nextStep?: string | null;
		previousStep?: string | null;
		badDebt?: number | null;
		actions?: Array<{
			__typename?: 'DunningAction';
			stepName: string;
			dueDate: any;
		} | null> | null;
	} | null;
};

export type EmailQuoteMutationVariables = Exact<{
	input: EmailQuoteInput;
}>;

export type EmailQuoteMutation = {
	__typename?: 'Mutation';
	emailQuote?: { __typename?: 'EmailQuote'; sent?: boolean | null } | null;
};

export type FetchElectricityGenerationPointDetailsMutationVariables = Exact<{
	input: FetchElectricityGenerationPointDetailsInput;
}>;

export type FetchElectricityGenerationPointDetailsMutation = {
	__typename?: 'Mutation';
	fetchElectricityGenerationPointDetails?: {
		__typename?: 'FetchElectricityGenerationPointDetails';
		quoteRequest?: {
			__typename?: 'Quote';
			quotedGenerationPoints?: Array<
				| {
						__typename: 'QuotedElectricityGenerationPoint';
						canSupply: boolean;
						hasMultipleSpins?: boolean | null;
						rejectionReasons: Array<CanSupplyRejectionType | null>;
				  }
				| { __typename: 'QuotedElectricitySupplyPoint' }
				| null
			> | null;
		} | null;
	} | null;
};

export type FetchElectricitySupplyPointDetailsMutationVariables = Exact<{
	input: FetchElectricitySupplyPointDetailsInput;
}>;

export type FetchElectricitySupplyPointDetailsMutation = {
	__typename?: 'Mutation';
	fetchElectricitySupplyPointDetails?: {
		__typename?: 'FetchElectricitySupplyPointDetails';
		quoteRequest?: {
			__typename?: 'Quote';
			quotedSupplyPoints: Array<
				| { __typename?: 'QuotedElectricityGenerationPoint' }
				| {
						__typename: 'QuotedElectricitySupplyPoint';
						canSupply: boolean;
						hasMultipleSpins?: boolean | null;
						rejectionReasons: Array<CanSupplyRejectionType | null>;
						supplyPointDetails?: {
							__typename?: 'SupplyPointDetailsRaw';
							spin: string;
						} | null;
				  }
				| null
			>;
		} | null;
	} | null;
};

export type FetchRetailersQueryVariables = Exact<{ [key: string]: never }>;

export type FetchRetailersQuery = {
	__typename?: 'Query';
	retailers?: Array<{
		__typename?: 'Retailer';
		code: string;
		name: string;
		displayName?: string | null;
		displayOrder?: number | null;
		params: { __typename?: 'RetailerParams'; aliases: Array<string> };
	} | null> | null;
};

export type PostalAreaFragment = {
	__typename?: 'PostalArea';
	postcode: string;
	prefecture: string;
	city: string;
	area: string;
};

export type FitProductFragment = {
	__typename: 'ElectricityFitProduct';
	code: string;
	displayName: string;
	params?: any | null;
	generationCredits: Array<{
		__typename?: 'GenerationCreditRate';
		pricePerUnit: string;
		pricePerUnitIncTax: string;
	} | null>;
};

export type SingleStepProductFragment = {
	__typename: 'ElectricitySingleStepProduct';
	code: string;
	displayName: string;
	params?: any | null;
	standingChargeUnitType?: string | null;
	standingChargePricePerDay?: string | null;
	consumptionCharges: Array<{
		__typename?: 'ConsumptionRate';
		pricePerUnitIncTax: string;
		gridOperatorCode?: string | null;
	} | null>;
	fuelCostAdjustment?: {
		__typename?: 'FuelCostAdjustmentRate';
		pricePerUnitIncTax: string;
		validTo: any;
	} | null;
	standingCharges: Array<{
		__typename?: 'StandingCharge';
		gridOperatorCode?: string | null;
		pricePerUnitIncTax: string;
	} | null>;
};

export type SteppedProductFragment = {
	__typename: 'ElectricitySteppedProduct';
	code: string;
	displayName: string;
	params?: any | null;
	standingChargeUnitType?: string | null;
	standingChargePricePerDay?: string | null;
	consumptionCharges: Array<{
		__typename?: 'SteppedConsumptionRate';
		pricePerUnitIncTax: string;
		gridOperatorCode?: string | null;
		stepStart?: number | null;
		stepEnd?: number | null;
	} | null>;
	fuelCostAdjustment?: {
		__typename?: 'FuelCostAdjustmentRate';
		pricePerUnitIncTax: string;
		validTo: any;
	} | null;
	standingCharges: Array<{
		__typename?: 'StandingCharge';
		gridOperatorCode?: string | null;
		pricePerUnitIncTax: string;
	} | null>;
};

export type StandingChargeFragment = {
	__typename?: 'StandingCharge';
	gridOperatorCode?: string | null;
	pricePerUnitIncTax: string;
};

export type GenerateCreditCardTokenMutationVariables = Exact<{
	[key: string]: never;
}>;

export type GenerateCreditCardTokenMutation = {
	__typename?: 'Mutation';
	generateCreditCardToken?: {
		__typename?: 'GenerateCreditCardToken';
		creditCardToken?: string | null;
	} | null;
};

export type ActiveDomesticSignupRewardSchemeQueryVariables = Exact<{
	[key: string]: never;
}>;

export type ActiveDomesticSignupRewardSchemeQuery = {
	__typename?: 'Query';
	activeDomesticSignupRewardScheme?: {
		__typename?: 'ReferralSchemeType';
		code?: string | null;
	} | null;
};

export type GetAffiliateLinkQueryVariables = Exact<{
	subdomain: Scalars['String'];
}>;

export type GetAffiliateLinkQuery = {
	__typename?: 'Query';
	affiliateLink: {
		__typename?: 'AffiliateLinkType';
		id: string;
		subdomain: string;
		contactName: string;
		contactEmail: string;
		landingUrl: string;
		organisation?: {
			__typename?: 'AffiliateOrganisationType';
			name: string;
			salesChannel?: SalesChannelChoices | null;
		} | null;
	};
	activeAffiliateReferralScheme?: {
		__typename?: 'ReferralSchemeType';
		code?: string | null;
	} | null;
};

export type DomesticAccountReferralRewardSchemeQueryVariables = Exact<{
	referralCode: Scalars['String'];
}>;

export type DomesticAccountReferralRewardSchemeQuery = {
	__typename?: 'Query';
	domesticAccountReferralRewardScheme?: {
		__typename?: 'ReferralSchemeType';
		referrerRewardAmount?: number | null;
		referredRewardAmount?: number | null;
		combinedRewardAmount?: number | null;
		referrerGivenName?: string | null;
		referrerFamilyName?: string | null;
	} | null;
};

export type DomesticSignupRewardSchemeQueryVariables = Exact<{
	code: Scalars['String'];
}>;

export type DomesticSignupRewardSchemeQuery = {
	__typename?: 'Query';
	domesticSignupRewardScheme?: {
		__typename?: 'ReferralSchemeType';
		code?: string | null;
	} | null;
};

export type GetEmailEventQueryVariables = Exact<{
	id: Scalars['ID'];
}>;

export type GetEmailEventQuery = {
	__typename?: 'Query';
	node?:
		| { __typename?: 'AccountFileAttachment'; id: string }
		| {
				__typename?: 'EmailEventType';
				id: string;
				message?: {
					__typename?: 'EmailType';
					id: string;
					subject?: string | null;
					sentAt?: any | null;
					recipient?: string | null;
					htmlBody?: string | null;
					textBody?: string | null;
					attachments?: Array<{
						__typename?: 'EmailAttachmentType';
						id: string;
						filename: string;
						temporaryUrl?: string | null;
					} | null> | null;
				} | null;
		  }
		| { __typename?: 'InkBucket'; id: string }
		| { __typename?: 'InkGenericMessage'; id: string }
		| { __typename?: 'InkTag'; id: string }
		| { __typename?: 'PrintEventType'; id: string }
		| { __typename?: 'PrintMessageType'; id: string }
		| null;
};

export type GetFixedFuelCostAdjustmentContextQueryVariables = Exact<{
	accountNumber: Scalars['String'];
	spin: Scalars['String'];
	productCode: Scalars['String'];
}>;

export type GetFixedFuelCostAdjustmentContextQuery = {
	__typename?: 'Query';
	productEligibleStatus?: {
		__typename?: 'ProductEligibleStatus';
		isCampaignAvailable: boolean;
		isUserEligible: boolean;
		isUserOptedIn: boolean;
	} | null;
};

export type HalfHourlyReadingsQueryVariables = Exact<{
	accountNumber: Scalars['String'];
	fromDatetime?: InputMaybe<Scalars['DateTime']>;
	toDatetime?: InputMaybe<Scalars['DateTime']>;
}>;

export type HalfHourlyReadingsQuery = {
	__typename?: 'Query';
	account?: {
		__typename?: 'Account';
		properties?: Array<{
			__typename?: 'Property';
			electricitySupplyPoints?: Array<{
				__typename?: 'ElectricitySupplyPoint';
				status: SupplyPointStatus;
				agreements: Array<{
					__typename?: 'Agreement';
					validFrom?: any | null;
				} | null>;
				halfHourlyReadings: Array<{
					__typename?: 'ElectricityHalfHourReading';
					startAt: any;
					value: string;
					costEstimate: string;
					consumptionStep: number;
					consumptionRateBand: string;
				}>;
			} | null> | null;
		} | null> | null;
	} | null;
};

export type GetPostalAreasQueryVariables = Exact<{
	postcode: Scalars['String'];
}>;

export type GetPostalAreasQuery = {
	__typename?: 'Query';
	postalAreas: Array<{
		__typename?: 'PostalArea';
		postcode: string;
		prefecture: string;
		city: string;
		area: string;
	} | null>;
};

export type GetStatementsQueryVariables = Exact<{
	accountNumber: Scalars['String'];
	count?: InputMaybe<Scalars['Int']>;
	cursor: Scalars['String'];
}>;

export type GetStatementsQuery = {
	__typename?: 'Query';
	account?: {
		__typename?: 'Account';
		bills?: {
			__typename?: 'BillConnectionTypeConnection';
			pageInfo: {
				__typename?: 'PageInfo';
				hasNextPage: boolean;
				endCursor?: string | null;
			};
			edges: Array<{
				__typename?: 'BillConnectionTypeEdge';
				node?:
					| {
							__typename?: 'InvoiceType';
							id?: string | null;
							billType?: BillTypeEnum | null;
							fromDate?: any | null;
							toDate?: any | null;
							temporaryUrl?: string | null;
							issuedDate?: any | null;
					  }
					| {
							__typename?: 'PeriodBasedDocumentType';
							id?: string | null;
							billType?: BillTypeEnum | null;
							fromDate?: any | null;
							toDate?: any | null;
							temporaryUrl?: string | null;
							issuedDate?: any | null;
					  }
					| {
							__typename?: 'PreKrakenBillType';
							id?: string | null;
							billType?: BillTypeEnum | null;
							fromDate?: any | null;
							toDate?: any | null;
							temporaryUrl?: string | null;
							issuedDate?: any | null;
					  }
					| {
							__typename: 'StatementType';
							consumptionStartDate?: any | null;
							consumptionEndDate?: any | null;
							id?: string | null;
							billType?: BillTypeEnum | null;
							fromDate?: any | null;
							toDate?: any | null;
							temporaryUrl?: string | null;
							issuedDate?: any | null;
					  }
					| null;
			} | null>;
		} | null;
	} | null;
};

export type GetTariffSummaryQueryVariables = Exact<{
	gridOperatorCode: Scalars['String'];
	productCode?: InputMaybe<Scalars['String']>;
}>;

export type GetTariffSummaryQuery = {
	__typename?: 'Query';
	tariffSummary?: Array<{
		__typename?: 'TariffSummary';
		code: string;
		displayName: string;
		gridOperatorCode: string;
		productParams?: any | null;
		tiers: Array<{
			__typename?: 'TariffTier';
			contractCapacityPattern?: ContractCapacityPattern | null;
			consumptionRates: Array<{
				__typename?: 'ConsumptionChargeRate';
				pricePerUnitIncTax: string;
				stepStart?: number | null;
				stepEnd?: number | null;
				band?: string | null;
			} | null>;
			standingRates: {
				__typename?: 'StandingChargeValues';
				amperage?: Array<{
					__typename?: 'StandingChargeRate';
					pricePerUnitIncTax: string;
					label: string;
					unitType: string;
				} | null> | null;
				kva?: Array<{
					__typename?: 'StandingChargeRate';
					pricePerUnitIncTax: string;
					label: string;
					unitType: string;
				} | null> | null;
			};
		} | null>;
	}> | null;
};

export type GetTransactionsQueryVariables = Exact<{
	accountNumber: Scalars['String'];
	count?: InputMaybe<Scalars['Int']>;
	cursor: Scalars['String'];
}>;

export type GetTransactionsQuery = {
	__typename?: 'Query';
	account?: {
		__typename?: 'Account';
		balance: number;
		transactions?: {
			__typename?: 'TransactionConnectionTypeConnection';
			pageInfo: {
				__typename?: 'PageInfo';
				hasNextPage: boolean;
				endCursor?: string | null;
			};
			edges: Array<{
				__typename?: 'TransactionConnectionTypeEdge';
				node?:
					| {
							__typename: 'Charge';
							id?: string | null;
							statementId?: string | null;
							postedDate?: any | null;
							createdAt?: any | null;
							title?: string | null;
							amount?: number | null;
							balanceCarriedForward?: number | null;
							note?: string | null;
					  }
					| {
							__typename: 'Credit';
							id?: string | null;
							statementId?: string | null;
							postedDate?: any | null;
							createdAt?: any | null;
							title?: string | null;
							amount?: number | null;
							balanceCarriedForward?: number | null;
							note?: string | null;
					  }
					| {
							__typename: 'Payment';
							id?: string | null;
							statementId?: string | null;
							postedDate?: any | null;
							createdAt?: any | null;
							title?: string | null;
							amount?: number | null;
							balanceCarriedForward?: number | null;
							note?: string | null;
					  }
					| {
							__typename: 'Refund';
							id?: string | null;
							statementId?: string | null;
							postedDate?: any | null;
							createdAt?: any | null;
							title?: string | null;
							amount?: number | null;
							balanceCarriedForward?: number | null;
							note?: string | null;
					  }
					| null;
			} | null>;
		} | null;
	} | null;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
	__typename?: 'Query';
	viewer?: {
		__typename?: 'AccountUser';
		givenName: string;
		familyName: string;
		email: string;
		mobile: string;
		accountUserMeta?: {
			__typename?: 'AccountUserMeta';
			kanaGivenName?: string | null;
			kanaFamilyName?: string | null;
		} | null;
	} | null;
};

export type InitiateAmperageChangeMutationVariables = Exact<{
	input: InitiateAmperageChangeInput;
}>;

export type InitiateAmperageChangeMutation = {
	__typename?: 'Mutation';
	initiateAmperageChange:
		| {
				__typename: 'AmperageChangeDateInvalid';
				message: string;
				startDate: any;
		  }
		| { __typename: 'AmperageChangeInitiated'; message: string }
		| { __typename: 'CommonError'; message: string; field?: string | null };
};

export type InitiateMoveInMutationVariables = Exact<{
	input: InitiateMoveInInput;
}>;

export type InitiateMoveInMutation = {
	__typename?: 'Mutation';
	initiateMoveIn:
		| { __typename: 'CommonError'; message: string; field?: string | null }
		| {
				__typename: 'MoveInSuccess';
				accountNumber: string;
				token?: string | null;
				userId?: string | null;
				hasUsablePassword: boolean;
		  };
};

export type InitiateMoveOutMutationVariables = Exact<{
	input: InitiateMoveOutInput;
}>;

export type InitiateMoveOutMutation = {
	__typename?: 'Mutation';
	initiateMoveOut:
		| { __typename: 'CommonError'; message: string; field?: string | null }
		| {
				__typename: 'MoveOutDateInvalid';
				message: string;
				field?: string | null;
		  }
		| { __typename: 'MoveOutInitiated'; message: string };
};

export type InitiateMoveOutAndMoveInMutationVariables = Exact<{
	input: InitiateMoveOutAndMoveInInput;
}>;

export type InitiateMoveOutAndMoveInMutation = {
	__typename?: 'Mutation';
	initiateMoveOutAndMoveIn:
		| { __typename: 'CommonError'; message: string; field?: string | null }
		| { __typename: 'MoveInDateInvalid'; message: string }
		| {
				__typename: 'MoveOutAndMoveInInitiated';
				message: string;
				accountNumber: string;
				token?: string | null;
				hasUsablePassword: boolean;
		  }
		| {
				__typename: 'MoveOutDateInvalid';
				message: string;
				field?: string | null;
		  };
};

export type InitiateStandalonePaymentMutationVariables = Exact<{
	input: InitiateStandalonePaymentInput;
}>;

export type InitiateStandalonePaymentMutation = {
	__typename?: 'Mutation';
	initiateStandalonePayment?: {
		__typename?: 'InitiateStandalonePayment';
		payment?: {
			__typename?: 'InitiateStandalonePaymentOutput';
			retrievalToken: string;
			secretToken: string;
		} | null;
	} | null;
};

export type InitiateSwitchInMutationVariables = Exact<{
	input?: InputMaybe<InitiateSwitchInInput>;
}>;

export type InitiateSwitchInMutation = {
	__typename?: 'Mutation';
	initiateSwitchIn?: {
		__typename?: 'InitiateSwitchIn';
		accountNumber: string;
		token?: string | null;
		userId?: string | null;
		hasUsablePassword: boolean;
	} | null;
};

export type IntegrationsQueryVariables = Exact<{ [key: string]: never }>;

export type IntegrationsQuery = {
	__typename?: 'Query';
	integrations: Array<
		| {
				__typename?: 'Integration';
				type?: IntegrationType | null;
				status?: IntegrationStatus | null;
		  }
		| {
				__typename?: 'LineIntegration';
				type?: IntegrationType | null;
				status?: IntegrationStatus | null;
				displayName: string;
		  }
	>;
};

export type LinkUserToLineMutationVariables = Exact<{
	input: LinkUserToLineInput;
}>;

export type LinkUserToLineMutation = {
	__typename?: 'Mutation';
	linkUserToLine:
		| { __typename: 'AlreadyLinkedError' }
		| { __typename: 'LineLinkRedirectResponse'; redirectUrl: string }
		| { __typename: 'LinkTokenNotFound' };
};

export type MasqueradeAuthenticationMutationVariables = Exact<{
	masqueradeToken: Scalars['String'];
	userId: Scalars['String'];
}>;

export type MasqueradeAuthenticationMutation = {
	__typename?: 'Mutation';
	masqueradeAuthentication?: {
		__typename?: 'MasqueradeAuthentication';
		token?: string | null;
		errors?: Array<{
			__typename?: 'ErrorType';
			field: string;
			messages: Array<string>;
		} | null> | null;
	} | null;
};

export type MonthlyReadingsQueryVariables = Exact<{
	accountNumber: Scalars['String'];
}>;

export type MonthlyReadingsQuery = {
	__typename?: 'Query';
	account?: {
		__typename?: 'Account';
		properties?: Array<{
			__typename?: 'Property';
			electricitySupplyPoints?: Array<{
				__typename?: 'ElectricitySupplyPoint';
				status: SupplyPointStatus;
				agreements: Array<{
					__typename?: 'Agreement';
					validFrom?: any | null;
				} | null>;
				intervalReadings: Array<{
					__typename?: 'ElectricityIntervalReading';
					endAt: any;
					startAt: any;
					value: string;
					costEstimate: string;
				}>;
			} | null> | null;
		} | null> | null;
	} | null;
};

export type MoveInContextQueryVariables = Exact<{
	isSpinKnown: Scalars['Boolean'];
}>;

export type MoveInContextQuery = {
	__typename?: 'Query';
	moveInContext: { __typename?: 'MoveInContext'; earliestMoveInDate: any };
};

export type ObtainKrakenTokenMutationVariables = Exact<{
	input: ObtainJsonWebTokenInput;
}>;

export type ObtainKrakenTokenMutation = {
	__typename?: 'Mutation';
	obtainKrakenToken?: {
		__typename?: 'ObtainKrakenJSONWebToken';
		refreshToken?: string | null;
		refreshExpiresIn?: number | null;
		payload: any;
		token: string;
	} | null;
};

export type OnboardingJourneyAblyJwtQueryVariables = Exact<{
	clientId: Scalars['String'];
}>;

export type OnboardingJourneyAblyJwtQuery = {
	__typename?: 'Query';
	onboardingJourneyAblyJwt: {
		__typename?: 'OnboardingJourneyAblyJWT';
		token?: string | null;
	};
};

export type PaymentDetailsQueryVariables = Exact<{
	accountNumber: Scalars['String'];
	activeOnDate: Scalars['Date'];
}>;

export type PaymentDetailsQuery = {
	__typename?: 'Query';
	account?: {
		__typename?: 'Account';
		paymentSchedules?: {
			__typename?: 'PaymentScheduleConnectionTypeConnection';
			edges: Array<{
				__typename?: 'PaymentScheduleConnectionTypeEdge';
				node?: {
					__typename?: 'PaymentScheduleType';
					paymentDay?: number | null;
					scheduleType?: ScheduleType | null;
					isVariablePaymentAmount: boolean;
					paymentAmount: number;
				} | null;
			} | null>;
		} | null;
		paymentInstructions?: {
			__typename?: 'PaymentInstructionConnection';
			edges: Array<{
				__typename?: 'PaymentInstructionEdge';
				node?: {
					__typename?: 'PaymentInstructionType';
					accountHolder: string;
					instructionType?: string | null;
					maskedAccountIdentifier?: string | null;
					cardExpiryYear?: number | null;
					cardExpiryMonth?: number | null;
				} | null;
			} | null>;
		} | null;
	} | null;
};

export type ProductEligibleStatusQueryVariables = Exact<{
	accountNumber: Scalars['String'];
	spin: Scalars['String'];
	productCode: Scalars['String'];
}>;

export type ProductEligibleStatusQuery = {
	__typename?: 'Query';
	productEligibleStatus?: {
		__typename?: 'ProductEligibleStatus';
		isCampaignAvailable: boolean;
		isUserEligible: boolean;
		isUserOptedIn: boolean;
	} | null;
};

export type QuoteRequestQueryVariables = Exact<{
	code: Scalars['String'];
}>;

export type QuoteRequestQuery = {
	__typename?: 'Query';
	quoteRequest?: {
		__typename?: 'Quote';
		code: string;
		params?: {
			__typename?: 'QuoteRequestParams';
			affiliateLinkSubdomain?: string | null;
			referralCode?: string | null;
			referralSchemeCode?: string | null;
		} | null;
		quotedSupplyPoints: Array<
			| { __typename: 'QuotedElectricityGenerationPoint' }
			| {
					__typename: 'QuotedElectricitySupplyPoint';
					amperage?: number | null;
					kva?: number | null;
					gridOperatorCode?: string | null;
					annualEstimatedUsage: Array<string | null>;
					postalArea?: {
						__typename?: 'PostalArea';
						prefecture: string;
						city: string;
						area: string;
						postcode: string;
					} | null;
					quotedProducts: Array<{
						__typename?: 'QuotedElectricityProduct';
						estimatedCosts: {
							__typename?: 'QuotedProductEstimatedCosts';
							monthlyBreakdown: Array<number | null>;
							standingChargePricePerDay?: string | null;
							standingChargeUnitType?: string | null;
							quotedMonth: {
								__typename?: 'QuotedProductEstimatedCostsQuotedMonth';
								month: string;
								cost: string;
								usageAmount?: string | null;
							};
						};
						product:
							| { __typename: 'ElectricityFitProduct' }
							| {
									__typename: 'ElectricitySingleStepProduct';
									code: string;
									displayName: string;
									params?: any | null;
									standingChargeUnitType?: string | null;
									standingChargePricePerDay?: string | null;
									consumptionCharges: Array<{
										__typename?: 'ConsumptionRate';
										pricePerUnitIncTax: string;
										gridOperatorCode?: string | null;
									} | null>;
									fuelCostAdjustment?: {
										__typename?: 'FuelCostAdjustmentRate';
										pricePerUnitIncTax: string;
										validTo: any;
									} | null;
									standingCharges: Array<{
										__typename?: 'StandingCharge';
										gridOperatorCode?: string | null;
										pricePerUnitIncTax: string;
									} | null>;
							  }
							| {
									__typename: 'ElectricitySteppedProduct';
									code: string;
									displayName: string;
									params?: any | null;
									standingChargeUnitType?: string | null;
									standingChargePricePerDay?: string | null;
									consumptionCharges: Array<{
										__typename?: 'SteppedConsumptionRate';
										pricePerUnitIncTax: string;
										gridOperatorCode?: string | null;
										stepStart?: number | null;
										stepEnd?: number | null;
									} | null>;
									fuelCostAdjustment?: {
										__typename?: 'FuelCostAdjustmentRate';
										pricePerUnitIncTax: string;
										validTo: any;
									} | null;
									standingCharges: Array<{
										__typename?: 'StandingCharge';
										gridOperatorCode?: string | null;
										pricePerUnitIncTax: string;
									} | null>;
							  }
							| { __typename: 'GasTieredProduct' };
					} | null>;
			  }
			| null
		>;
		quotedGenerationPoints?: Array<
			| {
					__typename: 'QuotedElectricityGenerationPoint';
					gridOperatorCode?: string | null;
					annualEstimatedGeneration?: Array<string | null> | null;
					postalArea?: {
						__typename?: 'PostalArea';
						prefecture: string;
						city: string;
						area: string;
						postcode: string;
					} | null;
					quotedProducts: Array<{
						__typename?: 'QuotedElectricityProduct';
						estimatedCosts: {
							__typename?: 'QuotedProductEstimatedCosts';
							monthlyBreakdown: Array<number | null>;
							quotedMonth: {
								__typename?: 'QuotedProductEstimatedCostsQuotedMonth';
								month: string;
								cost: string;
							};
						};
						product:
							| { __typename: 'ElectricityFitProduct' }
							| {
									__typename: 'ElectricitySingleStepProduct';
									code: string;
									displayName: string;
									params?: any | null;
									standingChargeUnitType?: string | null;
									standingChargePricePerDay?: string | null;
									consumptionCharges: Array<{
										__typename?: 'ConsumptionRate';
										pricePerUnitIncTax: string;
										gridOperatorCode?: string | null;
									} | null>;
									fuelCostAdjustment?: {
										__typename?: 'FuelCostAdjustmentRate';
										pricePerUnitIncTax: string;
										validTo: any;
									} | null;
									standingCharges: Array<{
										__typename?: 'StandingCharge';
										gridOperatorCode?: string | null;
										pricePerUnitIncTax: string;
									} | null>;
							  }
							| {
									__typename: 'ElectricitySteppedProduct';
									code: string;
									displayName: string;
									params?: any | null;
									standingChargeUnitType?: string | null;
									standingChargePricePerDay?: string | null;
									consumptionCharges: Array<{
										__typename?: 'SteppedConsumptionRate';
										pricePerUnitIncTax: string;
										gridOperatorCode?: string | null;
										stepStart?: number | null;
										stepEnd?: number | null;
									} | null>;
									fuelCostAdjustment?: {
										__typename?: 'FuelCostAdjustmentRate';
										pricePerUnitIncTax: string;
										validTo: any;
									} | null;
									standingCharges: Array<{
										__typename?: 'StandingCharge';
										gridOperatorCode?: string | null;
										pricePerUnitIncTax: string;
									} | null>;
							  }
							| { __typename: 'GasTieredProduct' };
					} | null>;
			  }
			| { __typename: 'QuotedElectricitySupplyPoint' }
			| null
		> | null;
	} | null;
};

export type RegisterDemandResponseCampaignParticipantMutationVariables = Exact<{
	input: RegisterDemandResponseCampaignParticipantInput;
}>;

export type RegisterDemandResponseCampaignParticipantMutation = {
	__typename?: 'Mutation';
	registerDemandResponseCampaignParticipant:
		| { __typename: 'CommonError'; message: string; field?: string | null }
		| {
				__typename: 'RegisterDemandResponseCampaignParticipantRegistered';
				campaignName?: string | null;
				registeredAt?: any | null;
		  };
};

export type RequestPasswordResetMutationVariables = Exact<{
	input: RequestPasswordResetInput;
}>;

export type RequestPasswordResetMutation = {
	__typename?: 'Mutation';
	requestPasswordReset?: {
		__typename?: 'RequestPasswordResetOutputType';
		email?: string | null;
	} | null;
};

export type ResetPasswordMutationVariables = Exact<{
	input: ResetPasswordMutationInput;
}>;

export type ResetPasswordMutation = {
	__typename?: 'Mutation';
	resetPassword?: {
		__typename?: 'ResetPasswordMutationPayload';
		errors?: Array<{
			__typename?: 'SerializerFieldErrorsType';
			field?: string | null;
			errors?: Array<
				| {
						__typename?: 'SerializerErrorType';
						message?: string | null;
						code?: string | null;
				  }
				| { __typename?: 'SerializerFieldErrorsType' }
			> | null;
		} | null> | null;
	} | null;
};

export type SaveOnboardingJourneyProgressMutationVariables = Exact<{
	input: SaveOnboardingJourneyProgressInput;
}>;

export type SaveOnboardingJourneyProgressMutation = {
	__typename?: 'Mutation';
	saveOnboardingJourneyProgress?: {
		__typename?: 'SaveOnboardingJourneyProgress';
		success?: boolean | null;
	} | null;
};

export type SelfServeSwitchInCorrectionMutationVariables = Exact<{
	input: SelfServeSwitchInCorrectionInput;
}>;

export type SelfServeSwitchInCorrectionMutation = {
	__typename?: 'Mutation';
	selfServeSwitchInCorrection:
		| { __typename: 'CommonError'; message: string; field?: string | null }
		| {
				__typename: 'SwitchInCorrectionPerformed';
				performedActions: Array<SelfServiceAction | null>;
		  };
};

export type SendFamilySwitchInOfferMutationVariables = Exact<{
	input: SendFamilySwitchInOfferInput;
}>;

export type SendFamilySwitchInOfferMutation = {
	__typename?: 'Mutation';
	sendFamilySwitchInOffer?: {
		__typename?: 'SendFamilySwitchInOffer';
		hasSent?: boolean | null;
	} | null;
};

export type StoreBankAccountDetailsMutationVariables = Exact<{
	input?: InputMaybe<NewBankAccountDetailsInput>;
}>;

export type StoreBankAccountDetailsMutation = {
	__typename?: 'Mutation';
	storeBankAccountDetails?: {
		__typename?: 'StoreBankAccountDetails';
		financialInstitutionRedirectUrl?: string | null;
		token?: string | null;
	} | null;
};

export type StoreRefundBankAccountMutationVariables = Exact<{
	input: NewRefundBankAccountDetailsInput;
}>;

export type StoreRefundBankAccountMutation = {
	__typename?: 'Mutation';
	storeRefundBankAccount:
		| { __typename: 'CommonError'; field?: string | null; message: string }
		| { __typename: 'RefundBankAccountStored'; reference: string };
};

export type SubmitCustomerFeedbackMutationVariables = Exact<{
	input: CustomerFeedbackInputType;
}>;

export type SubmitCustomerFeedbackMutation = {
	__typename?: 'Mutation';
	submitCustomerFeedback?: {
		__typename?: 'SubmitCustomerFeedback';
		customerFeedback?: {
			__typename?: 'CustomerFeedbackType';
			id: string;
		} | null;
	} | null;
};

export type SuggestFixedPaymentAmountMutationVariables = Exact<{
	input: SuggestFixedPaymentAmountInput;
}>;

export type SuggestFixedPaymentAmountMutation = {
	__typename?: 'Mutation';
	suggestFixedPaymentAmount:
		| { __typename: 'CommonError'; field?: string | null; message: string }
		| { __typename: 'FixedPaymentAmountSuggested'; amount: number };
};

export type SwitchToFixedFuelCostAdjustmentMutationVariables = Exact<{
	input: SwitchToProductWithFixedFuelCostAdjustmentInput;
}>;

export type SwitchToFixedFuelCostAdjustmentMutation = {
	__typename?: 'Mutation';
	switchToProductWithFixedFuelCostAdjustment:
		| { __typename: 'CommonError'; message: string }
		| {
				__typename: 'ProductWithFixedFuelCostAdjustmentSwitched';
				isCampaignAvailable: boolean;
				isUserEligible: boolean;
				isUserOptedIn: boolean;
		  };
};

export type TriggerPostUploadOperationsJpnMutationVariables = Exact<{
	input: Scalars['String'];
}>;

export type TriggerPostUploadOperationsJpnMutation = {
	__typename?: 'Mutation';
	triggerPostUploadOperationsJpn: {
		__typename?: 'TriggerPostUploadOperationsJPN';
		operationsTriggered?: boolean | null;
	};
};

export type UnlinkUserFromLineMutationVariables = Exact<{
	[key: string]: never;
}>;

export type UnlinkUserFromLineMutation = {
	__typename?: 'Mutation';
	unlinkUserFromLine:
		| { __typename: 'LineCommonError'; message: string }
		| { __typename: 'LineUnlinkedResponse'; message: string }
		| { __typename: 'LinkTokenNotFound' };
};

export type UpdateAccountUserMutationVariables = Exact<{
	input: UpdateAccountUserInput;
}>;

export type UpdateAccountUserMutation = {
	__typename?: 'Mutation';
	updateAccountUser?: {
		__typename?: 'UpdateAccountUser';
		viewer?: {
			__typename?: 'AccountUser';
			familyName: string;
			givenName: string;
			email: string;
			mobile: string;
			accountUserMeta?: {
				__typename?: 'AccountUserMeta';
				kanaFamilyName?: string | null;
				kanaGivenName?: string | null;
			} | null;
		} | null;
	} | null;
};

export type UpdateBankAccountDetailsMutationVariables = Exact<{
	input?: InputMaybe<UpdateBankAccountDetailsInput>;
}>;

export type UpdateBankAccountDetailsMutation = {
	__typename?: 'Mutation';
	updateBankAccountDetails?: {
		__typename?: 'UpdateBankAccountDetails';
		financialInstitutionRedirectUrl?: string | null;
		token?: string | null;
	} | null;
};

export type UpdateCommsPreferencesMutationVariables = Exact<{
	input: UpdateAccountUserCommsPreferencesMutationInput;
}>;

export type UpdateCommsPreferencesMutation = {
	__typename?: 'Mutation';
	updateCommsPreferences?: {
		__typename?: 'UpdateAccountUserCommsPreferencesMutationPayload';
		errors?: Array<{
			__typename?: 'ErrorType';
			messages: Array<string>;
		} | null> | null;
	} | null;
};

export type UpdateAccountConsentDataMutationVariables = Exact<{
	input: UpdateAccountConsentDataInput;
}>;

export type UpdateAccountConsentDataMutation = {
	__typename?: 'Mutation';
	updateAccountConsentData?: {
		__typename?: 'UpdateAccountConsentData';
		consentData?: any | null;
	} | null;
};

export type UpdateCreditCardDetailsMutationVariables = Exact<{
	input?: InputMaybe<UpdateCreditCardDetailsInput>;
}>;

export type UpdateCreditCardDetailsMutation = {
	__typename?: 'Mutation';
	updateCreditCardDetails?: {
		__typename?: 'UpdateCreditCardDetails';
		cardNumber: string;
	} | null;
};

export type UpdateDemandResponseCampaignParticipantCommsPreferenceAndGeneralCommsPreferenceMutationVariables =
	Exact<{
		inputDR: UpdateDemandResponseCampaignParticipantCommsPreferencesInput;
		input: UpdateAccountUserCommsPreferencesMutationInput;
	}>;

export type UpdateDemandResponseCampaignParticipantCommsPreferenceAndGeneralCommsPreferenceMutation =
	{
		__typename?: 'Mutation';
		updateDemandResponseCampaignParticipantCommsPreferences:
			| { __typename: 'CommonError'; message: string }
			| {
					__typename: 'DemandResponseCampaignParticipantCommsPreferencesUpdated';
					isOptedIntoEmails?: boolean | null;
					isOptedIntoLine?: boolean | null;
					isOptedIntoDemandResponseEvents?: boolean | null;
			  };
		updateCommsPreferences?: {
			__typename: 'UpdateAccountUserCommsPreferencesMutationPayload';
			errors?: Array<{
				__typename?: 'ErrorType';
				messages: Array<string>;
			} | null> | null;
		} | null;
	};

export type UpdateDemandResponseCampaignParticipantCommsPreferencesMutationVariables =
	Exact<{
		input: UpdateDemandResponseCampaignParticipantCommsPreferencesInput;
	}>;

export type UpdateDemandResponseCampaignParticipantCommsPreferencesMutation = {
	__typename?: 'Mutation';
	updateDemandResponseCampaignParticipantCommsPreferences:
		| { __typename: 'CommonError'; message: string }
		| {
				__typename: 'DemandResponseCampaignParticipantCommsPreferencesUpdated';
				isOptedIntoEmails?: boolean | null;
				isOptedIntoLine?: boolean | null;
				isOptedIntoDemandResponseEvents?: boolean | null;
		  };
};

export type UpdateDemandResponseCampaignParticipantTipMutationVariables =
	Exact<{
		input: UpdateDemandResponseCampaignParticipantTipInput;
	}>;

export type UpdateDemandResponseCampaignParticipantTipMutation = {
	__typename?: 'Mutation';
	updateDemandResponseCampaignParticipantTip:
		| { __typename: 'CommonError'; message: string }
		| {
				__typename: 'DemandResponseCampaignParticipantTipUpdated';
				tips?: any | null;
				totalNumberOfEntries?: number | null;
		  };
};

export type UpdateMoveInDateMutationVariables = Exact<{
	input: UpdateMoveInDateInput;
}>;

export type UpdateMoveInDateMutation = {
	__typename?: 'Mutation';
	updateMoveInDate?: {
		__typename: 'UpdateMoveInDate';
		newMoveInDate: any;
	} | null;
};

export type UpdateMoveOutDateMutationVariables = Exact<{
	input: UpdateMoveOutDateInput;
}>;

export type UpdateMoveOutDateMutation = {
	__typename?: 'Mutation';
	updateMoveOutDate?: {
		__typename: 'UpdateMoveOutDate';
		newMoveOutDate: any;
		spin: string;
	} | null;
};

export type UpdatePasswordMutationVariables = Exact<{
	input: UpdatePasswordInput;
}>;

export type UpdatePasswordMutation = {
	__typename?: 'Mutation';
	updatePassword?: {
		__typename?: 'UpdatePassword';
		viewer?: { __typename?: 'AccountUser'; email: string } | null;
	} | null;
};

export type ValidateEmailMutationVariables = Exact<{
	input: ValidateEmailInput;
}>;

export type ValidateEmailMutation = {
	__typename?: 'Mutation';
	validateEmail?: {
		__typename?: 'ValidateEmail';
		isValid?: boolean | null;
	} | null;
};

export type ValidatePhoneMutationVariables = Exact<{
	input: ValidatePhoneNumberInput;
}>;

export type ValidatePhoneMutation = {
	__typename?: 'Mutation';
	validatePhone?: {
		__typename?: 'ValidatePhone';
		isValid?: boolean | null;
	} | null;
};

export type AccountViewerQueryVariables = Exact<{ [key: string]: never }>;

export type AccountViewerQuery = {
	__typename?: 'Query';
	viewer?: {
		__typename?: 'AccountUser';
		email: string;
		familyName: string;
		preferences?: {
			__typename?: 'AccountUserCommsPreferences';
			isOptedInToSmsMessages?: boolean | null;
			isOptedInToOfferMessages?: boolean | null;
		} | null;
		accounts?: Array<{
			__typename?: 'Account';
			balance: number;
			number?: string | null;
			properties?: Array<{
				__typename?: 'Property';
				address?: string | null;
				electricitySupplyPoints?: Array<{
					__typename?: 'ElectricitySupplyPoint';
					status: SupplyPointStatus;
				} | null> | null;
			} | null> | null;
		} | null> | null;
	} | null;
};

export type WithdrawAccountMutationVariables = Exact<{
	input: WithdrawAccountInput;
}>;

export type WithdrawAccountMutation = {
	__typename?: 'Mutation';
	withdrawAccount?: {
		__typename?: 'WithdrawAccount';
		accountNumber: string;
	} | null;
};
