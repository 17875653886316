import { FC } from 'react';

import { DynamicComponent } from '@/components/storyblok/index';
import { CampaignProps } from '@/pages/campaigns/[slug]';
import { CMSFriendsProps } from '@/pages/data/friend/[slug]';
import { CompanyInformationContentFragment } from '@/services/graphql-storyblok';
import { StoryContent } from '@/types/storyblok';

interface Props {
	blok?: CompanyInformationContentFragment;
	props?: CMSFriendsProps | CampaignProps;
}

export const CMSCompanyInformation: FC<Props> = ({ blok, props }) => {
	return (
		<main>
			{(blok?.content as StoryContent)?.map((blok) => (
				<DynamicComponent blok={blok} props={props} key={blok._uid} />
			))}
		</main>
	);
};
