import { Button } from '@krakentech/coral';
import { FormikCheckbox, FormikTextField } from '@krakentech/coral-formik';
import { IconError } from '@krakentech/icons';
import { Field, FieldProps, Formik, FormikValues } from 'formik';
import { ChangeEvent, FC, useState } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';
import * as Yup from 'yup';

import { errorMessages } from '@/components/helpers/errorMessages';
import { validationRegex } from '@/components/helpers/validationRegex';
import { ConstantineLoader } from '@/components/shared/ConstantineLoader';
import { Link } from '@/components/shared/Link';
import { RICH_TEXT_CONSTANTS } from '@/components/storyblok/utils/constants';
import { ConstantineLaptop } from '@/components/svgs/ConstantineLaptop';
import { copy } from '@/copy';
import { ColorHex } from '@/utils/constants/colors';
import { JAPANESE_POSTCODE_CHARACTER_LENGTH } from '@/utils/constants/constants';
import { fullWidthToHalfWidth } from '@/utils/formatters/fullWidthToHalfWidth';
import { numberHyphenator9000 } from '@/utils/formatters/numberHyphenator9000';

type Props = {
	blok: {
		description_text: string;
		journey: OnboardingJourneyVariants;
		postcode: string;
		title_text: string;
	};
};

type EmailFormInput = {
	email: string;
	hasAgreedToPrivacyPolicy: boolean;
};

const initialValue: EmailFormInput = {
	email: '',
	hasAgreedToPrivacyPolicy: true,
};

const schema = Yup.object().shape({
	email: Yup.string()
		.email(errorMessages.invalidEmail)
		.required(errorMessages.required),
	hasAgreedToPrivacyPolicy: Yup.boolean().oneOf(
		[true],
		errorMessages.privacyPolicyConsentRequired
	),
	postcode: Yup.string()
		.trim()
		.required(errorMessages.required)
		.test('postcode', errorMessages.invalidPostcode, (value) =>
			validationRegex.postcode.test(value || '')
		),
});

const IMAGE_WIDTH = 150;

const CMSEmailSubscription: FC<Props> = ({ blok }) => {
	const [isCompleted, SetIsCompleted] = useState(false);

	const onSubmit = (values: FormikValues) => {
		fetch('/api/onboarding/store-interests', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				email: values.email,
				postcode: values.postcode,
				journey: blok.journey,
			}),
		}).then((resp) => {
			if (!resp.ok) {
				throw new Error('SUBMIT FAILED');
			}
			SetIsCompleted(true);
		});
	};

	const SubscriptionDialog = (
		<div className="space-y-8">
			<h2 className="text-2xl font-bold">{blok.title_text}</h2>
			<section>
				{render(blok.description_text, {
					...RICH_TEXT_CONSTANTS(),
				})}
			</section>
			<Formik
				initialValues={initialValue}
				onSubmit={onSubmit}
				validationSchema={schema}
			>
				{({ isSubmitting, handleSubmit, setFieldValue, isValid }) => {
					const onPostcodeChange = (value: string) => {
						const hyphenatedInput = numberHyphenator9000(value, [3, 4]);
						setFieldValue('postcode', hyphenatedInput);
					};
					return (
						<form onSubmit={handleSubmit} className="flex flex-col gap-8">
							<FormikTextField
								name="email"
								type="email"
								label={copy.emailAddress}
								onChange={(e: { target: HTMLInputElement }) => {
									setFieldValue('email', e.target.value);
								}}
							/>
							<Field required type="text" name="postcode" label={copy.postcode}>
								{({ field, meta }: FieldProps) => (
									<div>
										<input
											className="w-full rounded-lg border-none p-4 text-lg text-black outline-none placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-soholights"
											{...field}
											placeholder="郵便番号を入力"
											inputMode="numeric"
											maxLength={JAPANESE_POSTCODE_CHARACTER_LENGTH}
											onChange={(e: { target: HTMLInputElement }) =>
												onPostcodeChange(e.target.value)
											}
											onCompositionEndCapture={(e) => {
												const { target } =
													e as unknown as ChangeEvent<HTMLInputElement>;
												onPostcodeChange(
													fullWidthToHalfWidth(target.value, target.maxLength)
												);
											}}
											data-testid="cms-postcode-input"
											type="search"
										/>
										{meta.touched && meta.error && (
											<div className="relative">
												<div className="absolute py-1 text-left">
													<div className="flex items-center gap-1">
														<IconError size={15} />
														<span className="inline-block leading-snug text-error">
															{meta.error}
														</span>
													</div>
												</div>
											</div>
										)}
									</div>
								)}
							</Field>
							<FormikCheckbox
								name="hasAgreedToPrivacyPolicy"
								label={
									<p>
										<Link href="/privacy" color="light">
											個人情報の取り扱い
										</Link>
										に同意します.
									</p>
								}
							/>
							<div className="flex gap-4">
								<Button
									disabled={isSubmitting || !isValid}
									loading={isSubmitting ? true : undefined}
									loadingLabel={
										(
											<ConstantineLoader color={ColorHex.purplehaze} />
										) as unknown as string
									}
									fullWidth
									type="submit"
								>
									{copy.register}
								</Button>
							</div>
						</form>
					);
				}}
			</Formik>
		</div>
	);

	const EmailSubscriptionCompletedDialog = (
		<div className="space-y-8">
			<h2 className="text-2xl font-bold" id="email-subscription-dialog-title">
				{copy.thanksRegisteringEmail}
			</h2>
			<p>{copy.emailWhenAvailable}</p>
			<div className="flex items-center justify-center">
				<ConstantineLaptop width={IMAGE_WIDTH} />
			</div>
		</div>
	);

	return (
		<div className="flex w-full flex-col items-center bg-primary px-4">
			<div className="w-full max-w-3xl rounded-lg border-2 border-ink bg-primary p-4 text-lg md:w-2/3 md:p-6">
				{isCompleted ? EmailSubscriptionCompletedDialog : SubscriptionDialog}
			</div>
		</div>
	);
};

export { CMSEmailSubscription };
