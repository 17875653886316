import { Product } from '@/services/typed-graphql-sdk';
import { isBrowser } from '@/utils/browser/isBrowser';
import {
	GA_EVENT,
	GA_EVENT_ACCEPT_COOKIES,
	GA_EVENT_ADD_EMAIL_PRODUCT_PAGE,
	GA_EVENT_CLICK_FAQ,
	GA_EVENT_CLICK_QUOTE_BLOG,
	GA_EVENT_CLICK_QUOTE_HOMEPAGE,
	GA_EVENT_CLICK_QUOTE_TARIFFS,
	GA_EVENT_DECLINE_COOKIES,
	GA_EVENT_GENERATE_QUOTE_ESTIMATION,
	GA_EVENT_HOMEPAGE_GOOGLEREVIEW_MORE_BUTTON,
	GA_EVENT_HOMEPAGE_PRICECHECK_CHUBU,
	GA_EVENT_HOMEPAGE_PRICECHECK_CHUGOKU,
	GA_EVENT_HOMEPAGE_PRICECHECK_HOKKAIDO,
	GA_EVENT_HOMEPAGE_PRICECHECK_HOKURIKU,
	GA_EVENT_HOMEPAGE_PRICECHECK_KANSAI,
	GA_EVENT_HOMEPAGE_PRICECHECK_KYUSHU,
	GA_EVENT_HOMEPAGE_PRICECHECK_QUOTE_MORE_DETAILS,
	GA_EVENT_HOMEPAGE_PRICECHECK_SHIKOKU,
	GA_EVENT_HOMEPAGE_PRICECHECK_TOHOKU,
	GA_EVENT_HOMEPAGE_PRICECHECK_TOKYO,
	GA_EVENT_LOGIN,
	GA_EVENT_MOMI_SELECT_OPT1_OFFER_CTA_BTN,
	GA_EVENT_MOMI_SELECT_OPT1_OFFER_MOVE_IN_CTA,
	GA_EVENT_MOMI_SELECT_OPT1_OFFER_MOVE_OUT_CTA,
	GA_EVENT_MOMI_SELECT_OPT2_ALREADY_ON_SUPPLY_CTA_BTN,
	GA_EVENT_MOMI_SELECT_OPT3_OTHER_SUPPLIER_CTA_BTN,
	GA_EVENT_MOMI_SELECT_OPT3_OTHER_SUPPLIER_DISCOUNT_EMAIL_CTA,
	GA_EVENT_MOMI_SELECT_OPT3_OTHER_SUPPLIER_SKIP_CTA,
	GA_EVENT_MOMI_SUBMIT_FINAL_STEP,
	GA_EVENT_MOVE_IN_SELECT,
	GA_EVENT_MOVE_IN_SIGN_UP,
	GA_EVENT_MOVE_OUT_CANCEL,
	GA_EVENT_MOVE_OUT_IN_SIGN_UP,
	GA_EVENT_MOVE_OUT_LOST_OBJ_CTA,
	GA_EVENT_OBJ_GREEN_OCTOPUS_PRODUCT_PG,
	GA_EVENT_OBJ_SIMPLE_OCTOPUS_PRODUCT_PG,
	GA_EVENT_SHORTCUT_SUBMIT_FINAL_STEP,
	GA_EVENT_SHORTCUT_SUBMIT_PAYMENT_PAGE_ERROR,
	GA_EVENT_SHORTCUT_SUBMIT_PAYMENT_PAGE_TRY,
	GA_EVENT_SHORTCUT_TYPE_CLICK_APPLY_TARIFF_PAGE,
	GA_EVENT_SIMPLE_OCTOPUS_CALCULATOR_CTA,
	GA_EVENT_SUBMIT_FINAL_STEP,
	GA_EVENT_SUBMIT_PAYMENT_PAGE_ERROR,
	GA_EVENT_SUBMIT_PAYMENT_PAGE_TRY,
	GA_EVENT_SWITCH_IN_SIGN_UP,
	GA_EVENT_TOP_PRODUCT_CARD_CLICK,
	GA_EVENT_TYPE_CLICK_APPLY_PRODUCT_PAGE,
	GA_EVENT_TYPE_SHORTCUT_SIGN_UP,
	GA_EVENT_TYPE_SIGN_UP,
	GA_EVENT_WALLET_REVERT_TO_OLD_VERSION,
	GA_EVENT_YOUR_DETAILS_SPIN_AND_POSTCODE_REQUOTE,
	GA_EVENT_YOUR_DETAILS_VALIDATE_SPIN,
} from '@/utils/constants/analytics';
import { SupportedArea } from '@/utils/constants/industry/gridOperator';

const sendEventAnalytics = (name: string, params?: object): void => {
	// Prevent an error in SSR by checking for window first https://stackoverflow.com/questions/62791299/typeerror-window-gtag-is-not-a-function
	if (isBrowser() && window.gtag) {
		window.gtag(GA_EVENT, name, params ? { ...params } : {});
	}
};

export const sendSignUpAnalytics = (params: object): void =>
	sendEventAnalytics(GA_EVENT_TYPE_SIGN_UP, params);
export const sendProductPageApplyAnalytics = (params: object): void =>
	sendEventAnalytics(GA_EVENT_TYPE_CLICK_APPLY_PRODUCT_PAGE, params);
export const sendProductPageEmailAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_ADD_EMAIL_PRODUCT_PAGE);
export const sendPaymentPageSubmitAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_SUBMIT_PAYMENT_PAGE_TRY);
export const sendPaymentPageSubmitErrorAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_SUBMIT_PAYMENT_PAGE_ERROR);
export const sendQuoteHomepageAnalytics = (params?: object): void =>
	sendEventAnalytics(GA_EVENT_CLICK_QUOTE_HOMEPAGE, params);
export const sendQuoteTariffsAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_CLICK_QUOTE_TARIFFS);
export const sendQuoteBlogAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_CLICK_QUOTE_BLOG);
export const sendLoginAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_LOGIN);
export const sendMoveInSelectAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_MOVE_IN_SELECT);
export const sendMoveInSignUpAnalytics = (params: object): void =>
	sendEventAnalytics(GA_EVENT_MOVE_IN_SIGN_UP, params);
export const sendValidateSPINAnalytics = (params: object): void =>
	sendEventAnalytics(GA_EVENT_YOUR_DETAILS_VALIDATE_SPIN, params);
export const sendGenerateQuoteAnalytics = (params: object): void =>
	sendEventAnalytics(GA_EVENT_GENERATE_QUOTE_ESTIMATION, params);
export const sendFaqClickAnalytics = (params: object): void =>
	sendEventAnalytics(GA_EVENT_CLICK_FAQ, params);
export const sendFinalPageSubmitAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_SUBMIT_FINAL_STEP);
export const sendMoveOutCancelAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_MOVE_OUT_CANCEL);
export const sendMoveOutLostObjCtaAnalytics = (params: object): void => {
	sendEventAnalytics(GA_EVENT_MOVE_OUT_LOST_OBJ_CTA, params);
};
export const sendShortcutSignUpAnalytics = (params: object): void =>
	sendEventAnalytics(GA_EVENT_TYPE_SHORTCUT_SIGN_UP, params);
export const sendShortcutTariffPageApplyAnalytics = (params: object): void =>
	sendEventAnalytics(GA_EVENT_SHORTCUT_TYPE_CLICK_APPLY_TARIFF_PAGE, params);
export const sendShortcutPaymentPageSubmitAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_SHORTCUT_SUBMIT_PAYMENT_PAGE_TRY);
export const sendShortcutPaymentPageSubmitErrorAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_SHORTCUT_SUBMIT_PAYMENT_PAGE_ERROR);
export const sendShortcutFinalPageSubmitAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_SHORTCUT_SUBMIT_FINAL_STEP);
export const sendTopProductCardClickAnalytics = (params: object): void =>
	sendEventAnalytics(GA_EVENT_TOP_PRODUCT_CARD_CLICK, params);
export const sendMoveOutInSignUpAnalytics = (): void => {
	sendEventAnalytics(GA_EVENT_MOVE_OUT_IN_SIGN_UP);
};
export const sendSwitchInSignUpAnalytics = (): void => {
	sendEventAnalytics(GA_EVENT_SWITCH_IN_SIGN_UP);
};
export const sendCookieAcceptAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_ACCEPT_COOKIES);
export const sendCookieDeclineAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_DECLINE_COOKIES);
export const sendMomiFinalPageSubmitAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_MOMI_SUBMIT_FINAL_STEP);
export const sendProductPageSelectedProductAnalytics = (
	product: Product
): void => {
	if (product?.params?.is_simple) {
		sendEventAnalytics(GA_EVENT_OBJ_SIMPLE_OCTOPUS_PRODUCT_PG);
	} else if (product?.params?.is_green) {
		sendEventAnalytics(GA_EVENT_OBJ_GREEN_OCTOPUS_PRODUCT_PG);
	}
};

// Homepage Quote Analytics
const EventBySelectedArea: Record<SupportedArea, string> = {
	北海道: GA_EVENT_HOMEPAGE_PRICECHECK_HOKKAIDO,
	東北: GA_EVENT_HOMEPAGE_PRICECHECK_TOHOKU,
	関東: GA_EVENT_HOMEPAGE_PRICECHECK_TOKYO,
	中部: GA_EVENT_HOMEPAGE_PRICECHECK_CHUBU,
	北陸: GA_EVENT_HOMEPAGE_PRICECHECK_HOKURIKU,
	関西: GA_EVENT_HOMEPAGE_PRICECHECK_KANSAI,
	中国: GA_EVENT_HOMEPAGE_PRICECHECK_CHUGOKU,
	四国: GA_EVENT_HOMEPAGE_PRICECHECK_SHIKOKU,
	九州: GA_EVENT_HOMEPAGE_PRICECHECK_KYUSHU,
};

export const sendHomepagePriceCheckAreaAnalytics = (
	selectedArea: SupportedArea
): void => {
	sendEventAnalytics(EventBySelectedArea[selectedArea]);
};
export const sendHomepagePriceCheckHouseholdAnalytics = (event: string): void =>
	sendEventAnalytics(event);
export const sendHomepagePriceCheckQuoteApplyAnalytics = (
	event: string
): void => sendEventAnalytics(event);
export const sendHomepagePriceCheckQuoteMoreDetailsAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_HOMEPAGE_PRICECHECK_QUOTE_MORE_DETAILS);
export const sendHomepageGoogleReviewMoreButtonAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_HOMEPAGE_GOOGLEREVIEW_MORE_BUTTON);

export const sendMOMIUndecidedAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_MOMI_SELECT_OPT1_OFFER_CTA_BTN);
export const sendMOMIAlreadyOnSupplyAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_MOMI_SELECT_OPT2_ALREADY_ON_SUPPLY_CTA_BTN);
export const sendMOMIEndContractAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_MOMI_SELECT_OPT3_OTHER_SUPPLIER_CTA_BTN);
export const sendMOMIMoveOutAndMoveInAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_MOMI_SELECT_OPT1_OFFER_MOVE_IN_CTA);
export const sendMOMIRejectOfferAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_MOMI_SELECT_OPT1_OFFER_MOVE_OUT_CTA);
export const sendMOMISkipEmailAnalytics = (): void =>
	sendEventAnalytics(GA_EVENT_MOMI_SELECT_OPT3_OTHER_SUPPLIER_SKIP_CTA);
export const sendMOMISendEmailAnalytics = (): void =>
	sendEventAnalytics(
		GA_EVENT_MOMI_SELECT_OPT3_OTHER_SUPPLIER_DISCOUNT_EMAIL_CTA
	);
export const sendSimpleOctopusCalculatorAnalytics = (
	region: string,
	usage: string
): void =>
	sendEventAnalytics(GA_EVENT_SIMPLE_OCTOPUS_CALCULATOR_CTA, { region, usage });

export const sendYourDetailsSpinAndPostcodeRequote = ({
	isRequoteMoreExpensive,
}: {
	isRequoteMoreExpensive: boolean;
}): void =>
	sendEventAnalytics(GA_EVENT_YOUR_DETAILS_SPIN_AND_POSTCODE_REQUOTE, {
		isRequoteMoreExpensive,
	});

export const sendWalletRevertToOldVersion = (): void =>
	sendEventAnalytics(GA_EVENT_WALLET_REVERT_TO_OLD_VERSION);
