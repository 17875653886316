import { Button as CoralButton } from '@krakentech/coral';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';

import { useAuthContext } from '@/components/contexts/auth';
import { useAccountContext } from '@/components/contexts/currentAccount';
import { Container } from '@/components/storyblok/bloks/Container';
import apiClient from '@/services/api-client';
import { ValidateEmailMutation } from '@/services/typed-graphql-sdk';
import { PAGES } from '@/utils/constants/pages';
import { redirectToNextPage, setNextPageSearchParam } from '@/utils/urlHelpers';

export type ButtonProps = {
	blok: {
		background_color?: 'bg-primary' | 'bg-secondary';
		logged_in_text: string;
		logged_out_text: string;
		size?: 'standard' | 'full-width';
		variant?: 'primary' | 'secondary';
	};
};

export const EmailTester: FC<ButtonProps> = ({
	blok: {
		logged_in_text,
		logged_out_text,
		size = 'standard',
		variant = 'primary',
		background_color = 'bg-primary',
	},
}) => {
	const [isEmailValid, setIsEmailValid] = useState(false);
	const [state, setState] = useState<'idle' | 'validating' | 'done' | 'error'>(
		'idle'
	);
	const router = useRouter();
	const { isLoggedIn } = useAuthContext();
	const { accountViewer } = useAccountContext();

	const handleOnClick = () => {
		setState('validating');
		if (!isLoggedIn || !accountViewer || !accountViewer.email) {
			setNextPageSearchParam({
				url: new URL(PAGES.login),
				redirect: '/email-issue/',
			});
			redirectToNextPage({ router, fallback: '/', redirectType: 'push' });
			return;
		} else {
			// @todo: Use the proper graphql mutation once this PR is merged:
			//  https://github.com/octoenergy/kraken-core/pull/96378/files
			// For now, I will set it up with kickbox.
			// We can simply switch out the graphql query for Karim's one after.
			try {
				// note: this will be a graphqlClient query, not an apiClient, in tne final version
				apiClient
					.post<ValidateEmailMutation>('/api/onboarding/validate-email', {
						input: { email: accountViewer.email },
					})
					.then((response) => {
						setIsEmailValid(Boolean(response.validateEmail?.isValid));
						setState('done');
					});
			} catch (error) {
				Sentry.captureMessage(
					'There was an error checking the users email in the storyblok emailTesterComponent.',
					{ extra: error }
				);
				setState('error');
			}
		}
	};

	return (
		<div className={`w-full ${background_color}`}>
			<Container
				className={`flex w-full flex-col items-center justify-center space-y-4 py-4 ${size === 'standard' ? 'md:max-w-[300px]' : ''}`}
			>
				{accountViewer?.email && (
					<p>
						お客さんのメール:{' '}
						<span className="font-bold underline">{accountViewer.email}</span>
					</p>
				)}
				<CoralButton
					variant="contained"
					color={variant}
					fullWidth
					onClick={handleOnClick}
					disabled={state === 'validating'}
				>
					{isLoggedIn ? logged_in_text : logged_out_text}
				</CoralButton>
				{state === 'done' &&
					(isEmailValid ? (
						<p>お客さんのメールは配信可能です。</p>
					) : (
						<p>お客さんのメールは配信不可能のようです。</p>
					))}
				{state === 'error' && <p>we couldn't check your email at this time.</p>}
			</Container>
		</div>
	);
};
