import { IconX } from '@krakentech/icons';
import { FC } from 'react';

import { currencyFormatter } from '@/utils/formatters/currencyFormatter';

export const ReferralTwitterShareIcon: FC<{
	campaignTweetText?: string;
	hashtags?: string;
	referredRewardAmount?: number;
	referrerRewardAmount: number;
	shareLink: string;
	shareLinkParam?: string;
}> = ({
	referrerRewardAmount,
	shareLink,
	shareLinkParam,
	campaignTweetText,
	hashtags,
}) => {
	const defaultCopy =
		/**
		 * Switch to Octopus Energy using this link and we'll both get ￥<reward amount>!
		 */
		`この紹介用URLからオクトパスエナジーに切り替えて、${currencyFormatter.format(
			referrerRewardAmount
		)}円分のボーナスをゲットしよう！：`;

	const tweetText = campaignTweetText
		? encodeURIComponent(campaignTweetText)
		: defaultCopy;

	const encodedHashtags = encodeURIComponent(hashtags || 'オクトパスエナジー');

	const encodedShareUrl = encodeURIComponent(`${shareLink}?${shareLinkParam}`);

	const shareLinkUrl = `https://twitter.com/intent/tweet/?text=${tweetText}&url=${encodedShareUrl}&hashtags=${encodedHashtags}`;

	return (
		<a
			aria-label="ツイッターでシェアする" // Share to Twitter
			href={shareLinkUrl}
			rel="noreferrer noopener"
			target="_blank"
		>
			<IconX size={49} />
		</a>
	);
};
