import { createContext, FC, ReactNode, useContext, useState } from 'react';

import { noop } from '@/utils/noop';

export type AffiliateContextValue = {
	isAffiliatesReady: boolean;
	salesChannel: string;
	setIsAffiliatesReady: (isReady: boolean) => void;
	setSalesChannel: (channel: string) => void;
};
const AffiliatesContext = createContext<AffiliateContextValue>({
	isAffiliatesReady: false,
	setIsAffiliatesReady: noop,
	salesChannel: '',
	setSalesChannel: noop,
});

type Props = {
	children: ReactNode;
};

export const AffiliateProvider: FC<Props> = ({ children }) => {
	const [isAffiliatesReady, setIsAffiliatesReady] = useState(false);
	const [salesChannel, setSalesChannel] = useState('');
	return (
		<AffiliatesContext.Provider
			value={{
				isAffiliatesReady,
				setIsAffiliatesReady,
				salesChannel,
				setSalesChannel,
			}}
		>
			{children}
		</AffiliatesContext.Provider>
	);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAffiliatesContext = () => useContext(AffiliatesContext);
