import { useQuery, UseQueryResult } from 'react-query';

import graphqlClient from '@/services/graphql-client';
import { DemandResponseQuery } from '@/services/typed-graphql-sdk';

export const useDemandResponse = ({
	accountNumber,
	onSuccess,
}: {
	accountNumber: string;
	onSuccess?: (account: DemandResponseQuery) => void;
}): UseQueryResult<DemandResponseQuery> =>
	useQuery(
		['demandResponse', accountNumber],
		async () =>
			await graphqlClient.demandResponse({
				accountNumber: accountNumber ?? '',
			}),
		{
			enabled: Boolean(accountNumber),
			refetchOnWindowFocus: false,
			onSuccess,
		}
	);
