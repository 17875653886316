import {
	Container,
	CoralContainerMaxWidth,
	CoralMargin,
	CoralPadding,
} from '@krakentech/coral';
import { FC } from 'react';

import { DynamicComponent } from '@/components/storyblok';
import { CampaignProps } from '@/pages/campaigns/[slug]';
import { CMSFriendsProps } from '@/pages/data/friend/[slug]';
import { StoryContent } from '@/types/storyblok';

export type CoralContainerProps = {
	blok: {
		children: StoryContent;
		full_height?: boolean;
		full_width?: boolean;
		margin_x?: CoralMargin;
		margin_y?: CoralMargin;
		max_height?: number;
		max_width?: CoralContainerMaxWidth;
		min_height?: number;
		min_width?: number;
		padding_x?: CoralPadding;
		padding_y?: CoralPadding;
	};
	props?: CMSFriendsProps | CampaignProps;
};

const CoralContainer: FC<CoralContainerProps> = ({
	blok: {
		children,
		full_height,
		full_width,
		margin_x,
		margin_y,
		max_height,
		max_width,
		padding_x,
		padding_y,
	},
	props,
}) => {
	return (
		<Container
			fullHeight={full_height}
			fullWidth={full_width}
			marginX={margin_x}
			marginY={margin_y}
			maxHeight={max_height}
			maxWidth={max_width}
			paddingX={padding_x}
			paddingY={padding_y}
		>
			<>
				{children.map((blok) => (
					<DynamicComponent blok={blok} key={blok._uid} props={props} />
				))}
			</>
		</Container>
	);
};

export { CoralContainer };
