import { Button, ButtonVariant, Card, Stack } from '@krakentech/coral';
import { copyToClipboard } from '@krakentech/utils';
import { ISbRichtext } from '@storyblok/react';
import { Trans, useTranslation } from 'next-i18next';
import { FC, useState } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import { Link } from '@/components/shared/Link';
import { ReferralFacebookShareIcon } from '@/components/storyblok/bloks/friend-components/ReferralFacebookShareIcon';
import { ReferralLineShareIcon } from '@/components/storyblok/bloks/friend-components/ReferralLineShareIcon';
import { ReferralTwitterShareIcon } from '@/components/storyblok/bloks/friend-components/ReferralTwitterShareIcon';
import { ReferralHistory } from '@/components/storyblok/utils/constants';
import { ReferralType } from '@/services/typed-graphql-sdk';

export type ReferralLinkShareCardProps = {
	props: {
		referrals: ReferralType[];
		referredRewardAmount: number;
		referrerRewardAmount: number;
		shareLink: string;
	};
};

type ReferralShareLinkBlokProps = {
	blok: {
		campaign_link_param?: string;
		cta_copy_idle?: string;
		cta_copy_success?: string;
		cta_style?: ButtonVariant;
		details: ISbRichtext | string;
		footer_copy?: string;
		share_link_param?: string;
		title?: string;
		tweet_text?: string;
		twitter_hashtags?: string;
	};
} & ReferralLinkShareCardProps;

export const ReferralLinkShareCardBlok: FC<ReferralShareLinkBlokProps> = ({
	blok: {
		cta_copy_idle = 'URLをコピーする',
		cta_copy_success = 'URLをコピーしました！',
		cta_style = 'contained',
		details,
		footer_copy = 'お友達を紹介してくれてありがとう！',
		share_link_param,
		title = '愛とパワーをシェアしよう',
		tweet_text,
		twitter_hashtags,
	},
	props: { referrals, referredRewardAmount, referrerRewardAmount, shareLink },
}) => {
	const [valueCopied, setValueCopied] = useState<boolean>(false);
	const { t } = useTranslation();

	return (
		<Card>
			<Stack direction="vertical">
				<div className="space-y-4">
					<h3 className="pb-2 text-2xl font-bold">{title}</h3>
					<div>{render(details)}</div>
					<p className="text-lg">
						<Trans
							i18nKey="account:friend-referral-card.terms-and-condition"
							components={{ linkElement: <Link href="/privacy" /> }}
						/>
					</p>
					<Card padding="small">
						<p className="break-all font-bold text-ice">{shareLink}</p>
					</Card>
					<Button
						fullWidth
						onClick={() => {
							copyToClipboard(shareLink, () => setValueCopied(true));
						}}
						variant={cta_style}
					>
						{!valueCopied ? cta_copy_idle : cta_copy_success}
					</Button>
					<p className="text-lg font-bold md:text-center md:text-xl">
						{t('account:friend-referral-card.share-referral-link')}
					</p>
					<Stack justifyContent="center">
						<ReferralTwitterShareIcon
							campaignTweetText={tweet_text}
							shareLink={shareLink}
							shareLinkParam={share_link_param}
							referredRewardAmount={referredRewardAmount}
							referrerRewardAmount={referrerRewardAmount}
							hashtags={twitter_hashtags}
						/>
						<ReferralFacebookShareIcon shareLink={shareLink} />
						<ReferralLineShareIcon shareLink={shareLink} />
					</Stack>
				</div>
				<ReferralHistory referrals={referrals} title={footer_copy} />
			</Stack>
		</Card>
	);
};
