/* eslint-disable no-restricted-imports */
import { apiPlugin, storyblokInit } from '@storyblok/react';
import {
	QueryClientProvider as QueryClientProviderV5,
	QueryClient as QueryClientV5,
} from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import { FC, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import '../../styles/globals.css';

import { AffiliateProvider } from '@/components/contexts/affiliates';
import { AnalyticsContext } from '@/components/contexts/analyticsReady';
import { AuthContext } from '@/components/contexts/auth';
import { AccountContext } from '@/components/contexts/currentAccount';
import { AppWithTranslation } from '@/components/providers/AppWithTranslation';
import { AnalyticsScripts } from '@/components/shared/AnalyticsScripts';
import { ErrorBoundary } from '@/components/shared/ErrorBoundary';
import { HeadAndMeta } from '@/components/shared/HeadAndMeta';
import { SeasonalAnimation } from '@/components/shared/SeasonalAnimation';
import { Components } from '@/components/storyblok';
import { Context, Event, machine, StateSchema } from '@/machines/auth/machine';
import { useMachine, XStateDevTools } from '@/machines/hooks';
import { initializeDatadogLogger } from '@/services/datadog';
import graphqlClient from '@/services/graphql-client';
import {
	Account,
	AccountQuery,
	AccountViewerQuery,
} from '@/services/typed-graphql-sdk';
import { axeDevTools } from '@/utils/axeDevTools';
import { isBrowser } from '@/utils/browser/isBrowser';
import { compareProperties } from '@/utils/compareProperties';
import { DATE_PLUS_2_MONTHS } from '@/utils/constants/constants';
import { PAGES } from '@/utils/constants/pages';
import { prodLog, RECRUIT_EASTER_EGG } from '@/utils/logger';

storyblokInit({
	accessToken: process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_TOKEN,
	components: Components,
	use: [apiPlugin],
});

/**
 * XState Dev Tools
 * Use to inspect state machines 🤖
 */
XStateDevTools();

/**
 * Axe Dev Tools
 * Use to analyze DOM accessibility issues ♿️
 */
axeDevTools();

if (isBrowser()) {
	initializeDatadogLogger();
	prodLog(RECRUIT_EASTER_EGG);
}

/**
 * Creates the react-query client with a default queryClient `retry` value of "3"
 * See: https://tanstack.com/query/v5/docs/react/guides/query-retries
 */
const queryClient = new QueryClient();

queryClient.setQueryDefaults(
	['obtainGmoScriptObjectURL', 'postalAreas', 'requestPasswordReset'],
	{
		retry: 5,
	}
);

const queryClientV5 = new QueryClientV5();

const MyApp: FC<AppProps> = ({ Component, pageProps, router }) => {
	const [state, send] = useMachine<Context, StateSchema, Event>(
		machine.withContext({ ...machine.context, router })
	);

	const isCheckingAuthentication = state.hasTag('checkingAuthentication');
	const isLoggedIn = state.matches('loggedIn');
	const isMasquerading = state.matches('loggedIn.masquerading');
	const shouldRedirect = state.context.shouldRedirectOnLogin;

	const [isAnalyticsReady, setIsAnalyticsReady] = useState(false);
	const [account, setAccount] = useState<Account>();
	const [email, setEmail] = useState('');
	const [isAccountError, setIsAccountError] = useState<boolean>(false);
	const [accountViewer, setAccountViewer] =
		useState<AccountViewerQuery['viewer']>();

	const handleAccountQueryError = () => {
		setIsAccountError(true);
	};

	useEffect(() => {
		async function fetchAndSetAccount() {
			const { viewer } = await graphqlClient.accountViewer();
			setAccountViewer(viewer);
			const activeOnDate = DATE_PLUS_2_MONTHS(new Date());

			/**
			 * Account number in the URL is the first source of truth.
			 * If the URL contains an `[accountNumber]` param:
			 *  1. check if the URL account number matches any viewer accounts and return that if it matches
			 *  2. otherwise, push to /account page
			 */
			const accountNumberFromUrl = router?.query?.accountNumber as string;

			const handleAccountQuerySuccess = ({ account }: AccountQuery) =>
				setAccount({
					...account,
					// @ts-expect-error
					properties: account?.properties?.sort(compareProperties),
				});

			if (accountNumberFromUrl) {
				const loggedInAccount = viewer?.accounts?.find(
					(account) => account?.number === accountNumberFromUrl
				);
				loggedInAccount?.number
					? graphqlClient
							.account({
								accountNumber: loggedInAccount.number,
								activeOnDate,
							})
							.then(handleAccountQuerySuccess)
							.catch(handleAccountQueryError)
					: router.push(PAGES.accountRoot);
			} else {
				const selectedAccountInLocalStorage =
					window.localStorage.getItem('selectedAccount');
				const selectedAccount = viewer?.accounts?.find(
					(account) => account?.number === selectedAccountInLocalStorage
				);

				const accountNumber = selectedAccount
					? selectedAccountInLocalStorage
					: viewer?.accounts?.[0]?.number;

				if (!accountNumber) {
					throw new Error('Account number is not in local storage or viewer');
				}

				graphqlClient
					.account({
						accountNumber,
						activeOnDate,
					})
					.then(handleAccountQuerySuccess)
					.catch(handleAccountQueryError);
			}
		}

		if (!isCheckingAuthentication && isLoggedIn) {
			fetchAndSetAccount();
		}
	}, [
		isCheckingAuthentication,
		router?.query?.accountNumber,
		isLoggedIn,
		router.asPath,
	]);

	useEffect(() => {
		// When a user signs out, ensure they are signed out in in all other tabs.
		// This is done by listening to when the localStorage refresh token is set to null.
		const handleSignOut = ({ key, newValue }: StorageEvent) => {
			if (key === 'refreshToken' && newValue === null) {
				send({ type: 'SIGN_OUT' });
			}
		};

		window.addEventListener('storage', handleSignOut);

		return () => window.removeEventListener('storage', handleSignOut);
	}, []);

	return (
		<div className="h-full">
			<AnalyticsContext.Provider
				value={{
					isAnalyticsReady,
					setIsAnalyticsReady,
				}}
			>
				<AnalyticsScripts />
				<HeadAndMeta />

				<QueryClientProviderV5 client={queryClientV5}>
					<QueryClientProvider client={queryClient}>
						<AuthContext.Provider
							value={{
								isCheckingAuthentication: state.hasTag(
									'checkingAuthentication'
								),
								isLoggedIn: state.matches('loggedIn'),
								isMasquerading: state.matches('loggedIn.masquerading'),
								handleSignOut: () => send({ type: 'SIGN_OUT' }),
								login: (data) => send({ type: 'LOGIN', data }),
							}}
						>
							<ErrorBoundary>
								<SeasonalAnimation dateString={new Date().toISOString()} />
								<AffiliateProvider>
									<AccountContext.Provider
										value={{
											account,
											accountViewer,
											isError: isAccountError,
											email,
											setEmail,
										}}
									>
										<AppWithTranslation
											Component={Component}
											pageProps={{
												...pageProps,
												isLoggedIn,
												isMasquerading,
												isCheckingAuthentication,
												shouldRedirect,
											}}
											router={router}
										/>
									</AccountContext.Provider>
								</AffiliateProvider>
							</ErrorBoundary>
						</AuthContext.Provider>
					</QueryClientProvider>
				</QueryClientProviderV5>
			</AnalyticsContext.Provider>
		</div>
	);
};

export default MyApp;
