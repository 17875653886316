import { createContext, useContext } from 'react';

import { Account, AccountViewerQuery } from '@/services/typed-graphql-sdk';
import { noop } from '@/utils/noop';

export type AccountContextValue = {
	account: Maybe<Account>;
	accountViewer: Maybe<AccountViewerQuery['viewer']>;
	email?: string;
	isError?: boolean;
	setEmail?: (value: string) => void;
};

export const AccountContext = createContext<AccountContextValue>({
	account: null,
	accountViewer: null,
	isError: false,
	email: '',
	setEmail: noop,
});

export const useAccountContext = (): AccountContextValue =>
	useContext(AccountContext);
