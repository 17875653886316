import * as Sentry from '@sentry/nextjs';
import { appWithTranslation } from 'next-i18next';
import { AppProps } from 'next/app';

import { CoralProvider } from '@/components/providers/CoralProvider';
import { JapaneseRegex } from '@/utils/transactions';

/**
 * Provides the app context next-i18next translations.
 *
 * CoralProvider uses translations, so it is also wrapped by appWithTranslation here.
 */

export const AppWithTranslation = appWithTranslation(
	({ Component, pageProps }: AppProps) => (
		<CoralProvider>
			<Component {...pageProps} />
		</CoralProvider>
	),
	{
		i18n: {
			defaultLocale: 'ja',
			locales: ['ja', 'en'],
			localeDetection: false,
		},
		saveMissing: true,
		/**
		 * Sentry log missing keys that are not already in the fallback language (Japanese).
		 * The fallbackValue is displayed when a key can not be found.
		 */
		missingKeyHandler: (lng, ns, key, fallbackValue) => {
			if (!JapaneseRegex.test(fallbackValue)) {
				Sentry.captureMessage(
					`Missing translation key [${key}] for [${lng}/${ns}]`
				);
			}
			return fallbackValue;
		},
	}
);
