import { FC } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import {
	RICH_TEXT_CONSTANTS,
	RICH_TEXT_NODE_RESOLVERS_HEADING,
	RICH_TEXT_NODE_RESOLVERS_PARAGRAPHS,
	textAlignMap,
} from '@/components/storyblok/utils/constants';
import { CampaignProps } from '@/pages/campaigns/[slug]';
import { CMSFriendsProps } from '@/pages/data/friend/[slug]';

type InfoBlockProps = {
	blok: {
		background: 'primary' | 'secondary';
		block_align: 'left' | 'right' | 'center';
		page_background: 'primary' | 'secondary';
		rich_text: unknown;
		size: 'sm' | 'md' | 'lg' | 'full';
		text_align: 'left' | 'right' | 'center';
	};
	props?: CampaignProps | CMSFriendsProps;
};

const infoBlockSizeMap = {
	sm: 'max-w-xl md:w-1/3 w-full',
	md: 'max-w-3xl md:w-2/3 w-full',
	lg: 'max-w-5xl md:w-3/4 w-full',
	full: 'w-full',
};

const infoBlockAlignMap = {
	left: 'items-start',
	right: 'items-end',
	center: 'items-center',
};

const infoBlockBgMap = {
	primary: 'bg-primary',
	secondary: 'bg-secondary',
};

export const InfoBlock: FC<InfoBlockProps> = ({
	blok: {
		rich_text,
		size,
		text_align,
		block_align,
		background,
		page_background,
	},
	props,
}) => {
	return (
		<div
			className={`flex w-full flex-col py-10 px-4 ${infoBlockAlignMap[block_align]} ${infoBlockBgMap[page_background]}`}
		>
			<div
				className={`${infoBlockBgMap[background]} rounded-lg border-2 border-ink p-1 text-lg ${infoBlockSizeMap[size]} ${textAlignMap[text_align]}`}
			>
				<section className="break-words p-4 text-lg leading-10">
					{render(rich_text, {
						...RICH_TEXT_CONSTANTS(props),
						nodeResolvers: {
							...RICH_TEXT_NODE_RESOLVERS_HEADING,
							...RICH_TEXT_NODE_RESOLVERS_PARAGRAPHS(text_align),
						},
					})}
				</section>
			</div>
		</div>
	);
};
