import { Button } from '@krakentech/coral';
import { FC } from 'react';

import { Container } from '@/components/storyblok/bloks/Container';
import { CampaignProps } from '@/pages/campaigns/[slug]';

/**
 * @note Component to be deleted once PR allowing us to find where it is used is complete
 */

type ButtonProps = {
	blok: {
		background_color?: 'bg-primary' | 'bg-secondary';
		button_variant?: 'contained' | 'outlined' | 'text' | 'ghost';
		color?: 'primary' | 'secondary';
		logged_in_but_not_opted_in_text?: string;
		logged_out_text?: string;
		opted_in_text?: string;
		size?: 'standard' | 'full-width';
		url: string;
	};
	props?: CampaignProps;
};

export const CampaignButton: FC<ButtonProps> = ({
	blok: {
		background_color,
		button_variant,
		size,
		color,
		logged_in_but_not_opted_in_text,
		logged_out_text,
		opted_in_text,
		url,
	},
	props,
}) => {
	const handleOnClick = () => {
		url && url.startsWith('#')
			? document.getElementById(url.split('#')[1])?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				})
			: window.open(url, '_blank');
	};

	return (
		<div className={`w-full ${background_color}`}>
			<Container className="flex w-full items-center justify-center py-4">
				<Button
					variant={button_variant}
					color={color}
					fullWidth={size === 'full-width'}
					onClick={handleOnClick}
					loading={props?.isLoading ? true : undefined}
				>
					{props?.participationStatus === 'LOGGED_OUT' && logged_out_text}
					{props?.participationStatus === 'LOGGED_IN_BUT_NOT_OPTED_IN' &&
						logged_in_but_not_opted_in_text}
					{props?.participationStatus === 'OPTED_IN' && opted_in_text}
				</Button>
			</Container>
		</div>
	);
};
