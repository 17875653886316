import { Alert } from '@krakentech/coral';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import {
	ChangeEvent,
	CompositionEventHandler,
	FC,
	HTMLAttributes,
	ReactNode,
	useState,
} from 'react';
import { useQuery } from 'react-query';

import { OutOfServiceAreaDialog } from '@/components/shared/OutOfServiceAreaDialog';
import { OutOfServiceAreaDialogWithEmailInput } from '@/components/shared/OutOfServiceAreaDialogWithEmailInput';
import { PostcodeFormCMSVariant } from '@/components/shared/PostcodeForm/PostcodeFormCMSVariant';
import { PostcodeFormWithButtonAttached } from '@/components/shared/PostcodeForm/PostcodeFormWithButtonAttached';
import { usePostcodeValidation } from '@/components/shared/PostcodeForm/usePostcodeValidation';
import { copy } from '@/copy';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import { useUpdateJourney } from '@/hooks/useUpdateJourney';
import graphqlClient from '@/services/graphql-client';
import { PostalAreaFragment } from '@/services/typed-graphql-sdk';
import { JAPANESE_POSTCODE_CHARACTER_LENGTH } from '@/utils/constants/constants';
import {
	GridOperatorName,
	PrefectureName,
	SUPPORTED_EV_OCTOPUS_PREFECTURE_NAMES,
	SUPPORTED_FIT_PREFECTURE_NAMES,
	SUPPORTED_PREFECTURE_NAMES,
	SUPPORTED_SIMPLE_PREFECTURE_NAMES,
	SUPPORTED_SOLAR_TARIFF_PREFECTURE_NAMES,
} from '@/utils/constants/industry/gridOperator';
import {
	JourneyVariantByAffiliate,
	JourneyVariantByPath,
	MINATO_MIRAI_OCTOPUS_TARIFF_ELIGIBLE_POSTCODE,
} from '@/utils/constants/marketing';
import { PAGES } from '@/utils/constants/pages';
import { fullWidthToHalfWidth } from '@/utils/formatters/fullWidthToHalfWidth';
import { numberHyphenator9000 } from '@/utils/formatters/numberHyphenator9000';
import { removeInvalidPostalAreas } from '@/utils/formatters/removeInvalidPostalAreas';
import {
	sendQuoteBlogAnalytics,
	sendQuoteHomepageAnalytics,
	sendQuoteTariffsAnalytics,
} from '@/utils/googleAnalytics';

export type PostcodeFormProps = {
	/** Overrides affiliate code from route params. */
	affiliateSubdomain?: string;
	analyticsVariant?: 'homepage' | 'tariffs' | 'blog';
	className?: HTMLAttributes<HTMLDivElement>['className'];
	postcodeButtonText?: string;
	postcodeInputLabelText?: string | ReactNode;
	postcodePlaceholderText?: string;
	referralCode?: string;
	restrictByGridOperator?: GridOperatorName;
	variant?: 'attached' | 'cms';
};

export const PostcodeForm: FC<PostcodeFormProps> = ({
	affiliateSubdomain,
	variant = 'attached',
	postcodeButtonText,
	postcodeInputLabelText,
	postcodePlaceholderText,
	referralCode,
	analyticsVariant,
	className,
}) => {
	const router = useRouter();

	const { t } = useTranslation('common');

	const [inputValue, setInputValue] = useState('');

	const [, setReferralCode] = useSessionStorage<string | undefined>(
		'referralCode',
		undefined
	);

	const [
		shouldShowEmailSubscriptionDialog,
		setShouldShowEmailSubscriptionDialog,
	] = useState(false);

	const affiliateLinkSubdomain =
		affiliateSubdomain ?? (router.query?.affiliate as string)?.toLowerCase();
	const referralSchemeCode = router.query?.referralSchemeCode as string;

	const [, setPostalAreasToLS] = useLocalStorage<PostalAreaFragment[]>(
		'postalAreas',
		[{ city: '', area: '', prefecture: '', postcode: '' }]
	);
	const [, setLocale] = useLocalStorage('i18nextLng', '');

	const updateJourney = useUpdateJourney();

	const getAndSetUserJourney = () => {
		const journeyByPathOrAffiliate = Object.values(
			JourneyVariantByPath
		).includes(path)
			? path
			: (JourneyVariantByAffiliate.get(affiliateLinkSubdomain) ??
				JourneyVariantByPath.default);

		updateJourney(journeyByPathOrAffiliate);
	};

	const path = router.asPath?.slice(1) as JourneyVariantByPath;
	const isElectricVehicleJourney = path === JourneyVariantByPath.ev;
	const isSolarJourney = path === JourneyVariantByPath.solar;
	const isFiTJourney = path === JourneyVariantByPath.fit;
	const isMinatoMiraiJourney = path === JourneyVariantByPath.minatoMirai;
	const isSimpleJourney = path === JourneyVariantByPath.simple;

	const [errorMessage, setErrorMessage] = useState('');

	const { refetch, isLoading, isError } = useQuery(
		['postalAreas', inputValue],
		async ({ queryKey }) => {
			const [, postcode] = queryKey;
			const postalAreas = await graphqlClient
				.getPostalAreas({ postcode })
				.then(({ postalAreas }) => postalAreas)
				.catch((error) => {
					if (error?.toString().includes('Postcode invalid')) {
						return [];
					} else {
						throw error;
					}
				});
			const [postalArea] = postalAreas;
			if (
				!postalArea?.prefecture ||
				!(
					isElectricVehicleJourney
						? SUPPORTED_EV_OCTOPUS_PREFECTURE_NAMES
						: isSolarJourney
							? SUPPORTED_SOLAR_TARIFF_PREFECTURE_NAMES
							: isFiTJourney
								? SUPPORTED_FIT_PREFECTURE_NAMES
								: isSimpleJourney
									? SUPPORTED_SIMPLE_PREFECTURE_NAMES
									: SUPPORTED_PREFECTURE_NAMES
				).includes(postalArea.prefecture as PrefectureName) ||
				(isMinatoMiraiJourney &&
					postalArea.postcode !== MINATO_MIRAI_OCTOPUS_TARIFF_ELIGIBLE_POSTCODE)
			) {
				return { status: 'invalid' };
			}
			removeInvalidPostalAreas(postalArea);
			/**@todo Fix nullable type in Kraken API to avoid casting. */
			setPostalAreasToLS(postalAreas as PostalAreaFragment[]);
			analyticsVariant === 'blog'
				? sendQuoteBlogAnalytics()
				: analyticsVariant === 'homepage'
					? sendQuoteHomepageAnalytics()
					: analyticsVariant === 'tariffs'
						? sendQuoteTariffsAnalytics()
						: null;
			return { status: 'valid', postalAreas };
		},
		{
			refetchOnWindowFocus: false,
			enabled: false,
			onSuccess: (data) => {
				switch (data.status) {
					case 'valid': {
						if (router.asPath.startsWith(PAGES.englishOBJ)) {
							setLocale('en');
						}
						setPostalAreasToLS(data.postalAreas as PostalAreaFragment[]);
						getAndSetUserJourney();
						if (referralCode) {
							setReferralCode(referralCode);
						}
						router.push({
							pathname: '/join',
							query: {
								// This must come after handling for regional postcodes to ensure other affiliates take precedence over region discounts on the home page
								...(affiliateLinkSubdomain &&
									!referralCode && {
										affiliate: affiliateLinkSubdomain,
									}),
								...(referralSchemeCode && {
									referralSchemeCode,
								}),
								...(referralCode && { referral: referralCode }),
							},
						});
						break;
					}
					case 'invalid':
						setShouldShowEmailSubscriptionDialog(true);
				}
			},
			onError: (error: Error) => {
				if (error.message === 'Network request failed') {
					setErrorMessage(t('common:errors.network-error'));
				} else {
					setShouldShowEmailSubscriptionDialog(true);
				}
			},
		}
	);

	const onInputChange = (input: string) => {
		const postcode = numberHyphenator9000(input, [3, 4]);

		setInputValue(postcode);
	};

	const onCompositionEndCapture: CompositionEventHandler<HTMLInputElement> = (
		e
	) => {
		const { target } = e as unknown as ChangeEvent<HTMLInputElement>;
		onInputChange(fullWidthToHalfWidth(target.value, target.maxLength));
	};

	const postcodeInputProps: Partial<React.HTMLProps<HTMLInputElement>> = {
		value: inputValue,
		onChange: (e) => onInputChange(e.currentTarget.value),
		maxLength: JAPANESE_POSTCODE_CHARACTER_LENGTH,
		type: 'text',
		inputMode: 'numeric',
		placeholder: '(〒) 000-0000',
		onCompositionEndCapture,
	};

	const { postcodeValidation, setPostcodeValidation } = usePostcodeValidation({
		postcode: inputValue,
	});

	const postcodeButtonProps: React.HTMLProps<HTMLButtonElement> = {
		disabled: isLoading,
	};

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				if (postcodeValidation === 'VALID') {
					refetch();
				} else {
					setPostcodeValidation('INVALID');
				}
			}}
			className="flex flex-col gap-3"
		>
			{/* Include this 'out of service area' dialog for region restricted postcode inputs.
			The dialog will only be shown if the status = 'invalid'. */}
			{isMinatoMiraiJourney ? (
				<OutOfServiceAreaDialog
					ctaButtonText={copy.tariffs + 'へ'}
					ctaButtonUrl={PAGES.tariffs}
					descriptionText={
						<p data-testid="invalid-prefecture-for-region-dialog">
							この料金プランは、お住まいの地域ではお申し込みできません。
							<br />
							下のボタンからお住まいの地域でお選び頂けるプランを検索いただけます。
						</p>
					}
					titleText={copy.cantServicePostalArea}
					onBack={() => setShouldShowEmailSubscriptionDialog(false)}
					open={shouldShowEmailSubscriptionDialog}
				/>
			) : (
				<OutOfServiceAreaDialogWithEmailInput
					onBack={() => setShouldShowEmailSubscriptionDialog(false)}
					titleText={copy.cantServicePostalArea}
					descriptionText={copy.serviceAreasAreExpanding}
					completedTitleText={copy.thanksRegisteringEmail}
					completedDescriptionText={copy.emailWhenAvailable}
					postcode={inputValue}
					journey={
						isElectricVehicleJourney
							? 'ev-octopus'
							: isSolarJourney
								? 'solar-octopus'
								: isFiTJourney
									? 'kaitori'
									: 'default'
					}
					open={shouldShowEmailSubscriptionDialog}
				/>
			)}
			{variant === 'cms' ? (
				<PostcodeFormCMSVariant
					className={className}
					postcodeInputLabelText={postcodeInputLabelText}
					postcodeInputProps={postcodeInputProps}
					postcodeButtonProps={postcodeButtonProps}
					postcodeButtonText={postcodeButtonText}
					postcodePlaceholderText={postcodePlaceholderText}
				/>
			) : (
				<PostcodeFormWithButtonAttached
					postcodeInputLabelText={postcodeInputLabelText}
					postcodeInputProps={postcodeInputProps}
					postcodeButtonProps={postcodeButtonProps}
				/>
			)}
			<div className="flex flex-col items-start justify-center gap-3 lg:justify-start">
				{postcodeValidation === 'INVALID' && (
					<div className="mt-2 w-full rounded-md bg-warning p-2 text-black md:w-80 xl:w-108 xl:text-xl">
						<p>
							<span role="img" aria-label="汗をかいているタコの絵文字">
								🐙💦&nbsp;
							</span>
							<span>{t('common:errors.invalid-postcode')}</span>
						</p>
					</div>
				)}
			</div>
			{isError && errorMessage ? (
				<Alert severity="error">{errorMessage}</Alert>
			) : null}
		</form>
	);
};

export type PostcodeFormVariantProps = {
	className?: HTMLAttributes<HTMLDivElement>['className'];
	postcodeButtonProps: React.HTMLProps<HTMLButtonElement>;
	postcodeButtonText?: string;
	postcodeInputLabelText?: string | ReactNode;
	postcodeInputProps: React.HTMLProps<HTMLInputElement>;
	postcodePlaceholderText?: string;
};
