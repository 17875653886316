import { createContext, useContext } from 'react';
import { send } from 'xstate';

import { ObtainKrakenTokenMutation } from '@/services/typed-graphql-sdk';

export type AuthContextValue = {
	handleSignOut: () => void;
	isCheckingAuthentication: boolean;
	isLoggedIn: boolean;
	isMasquerading: boolean;
	login: (data: NonNullable<ObtainKrakenTokenMutation>) => void;
};

export const AuthContext = createContext<AuthContextValue>({
	isLoggedIn: false,
	isMasquerading: false,
	isCheckingAuthentication: true,
	handleSignOut: () => {
		send({ type: 'SIGN_OUT' });
	},
	login: (data) => {
		send({ type: 'LOGIN', data });
	},
});

export const useAuthContext = (): AuthContextValue => useContext(AuthContext);
