/**
 * Example of usage:
 * <a href={PAGES.homepage} />       // '/'
 * <a href={PAGES.account('A-1234567')} />         // 'account/A-1234567'
 */

import {
	CURRENT_DR_CAMPAIGN_DASHBOARD_PAGE_NAME,
	CURRENT_DR_CAMPAIGN_OPT_IN_PAGE_NAME,
} from '@/utils/constants/marketing';

export const PAGES = {
	/**
	 * Basic Pages
	 */
	homepage: '/',
	404: '/404',
	join: '/join',
	login: '/login',
	tariffs: '/tariffs',
	requestPasswordReset: '/request-reset-password',
	contactUs: '/contact-us',
	blog: '/blogs',
	/**
	 * OBJ
	 */
	estimateStep: '/join?step=estimation',
	setPasswordPage: '/join/set-user-password',
	englishOBJ: '/eng',

	shortcutFirstPage: '/shortcut/tariffs',
	shortcutTariffs: '/shortcut/tariffs',
	shortcutYourDetails: '/shortcut/your-details',
	shortcutPayment: '/shortcut/payment',
	shortcutFinal: '/shortcut/final',

	moveFirstPage: '/move/your-details',
	moveYourDetails: '/move/your-details',
	moveTariffs: '/move/tariffs',
	movePayment: '/move/payment',
	moveFinal: '/move/final',

	/**
	 * Tariff Landing Pages
	 */
	evLandingPage: '/ev',
	fitLandingPage: '/kaitori',
	fixedFuelCostAdjustment2023: '/fixed-fuel-cost-tariff',
	minatoMiraiLandingPage: '/minatomirai-octopus',
	solarLandingPage: '/solar-octopus',
	hokkaidoWinter2023: '/hokkaido-winter-discount-2023',
	kyushuWinter2023: '/kyushu-winter-discount-2023',
	hokurikuWinter2023: '/hokuriku-winter-discount-2023',
	chubuWinter2023: '/chubu-winter-discount-2023',
	tokyoRegionalDiscount2024: '/tokyo-regional-discount-2024',

	/**
	 * Marketing / Campaigns
	 */
	demandResponseOptIn: `/campaigns/${CURRENT_DR_CAMPAIGN_OPT_IN_PAGE_NAME}`,
	demandResponseDashboard: (accountNumber: string) =>
		`/account/${accountNumber}/${CURRENT_DR_CAMPAIGN_DASHBOARD_PAGE_NAME}`,
	demandResponse2023SummerOptIn: '/octopus-challenge/summer-2023',
	demandResponse2023SummerDashboard: (accountNumber: string) =>
		`/account/${accountNumber}/summer-2023-dashboard`,
	twitterWithPrefilledTweet: (tweetText: string) =>
		`https://twitter.com/intent/tweet/?text=${tweetText}`,

	/**
	 * Account/Dashboard
	 */
	accountRoot: '/account',
	account: (accountNumber: string) => `/account/${accountNumber}`,
	api: (accountNumber: string) => `/account/${accountNumber}/api`,
	editPaymentDetails: (accountNumber: string) =>
		`/account/${accountNumber}/edit-payment-details`,
	editContactDetails: (accountNumber: string) =>
		`/account/${accountNumber}/edit-contact-details`,
	updatePassword: (accountNumber: string) =>
		`/account/${accountNumber}/update-password`,
	updateComms: (accountNumber: string) => `/account/${accountNumber}/comms`,
	overview: (accountNumber: string) => `/account/${accountNumber}/overview`,
	movingOut: (accountNumber: string, propertyId: string) =>
		`/account/${accountNumber}/moving-out?propertyId=${propertyId}`,
	changeMoveOutDate: (accountNumber: string, spin: string) =>
		`/account/${accountNumber}/change-your-move-out-date?spin=${spin}`,
} as const;
