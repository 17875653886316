import { rem, Review } from '@krakentech/coral';
import { FC } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';
import styled, { css, CSSObject } from 'styled-components';

import {
	CMS_GOOGLE_REVIEW_RESOLVERS,
	RICH_TEXT_CONSTANTS,
} from '@/components/storyblok/utils/constants';
import { CampaignProps } from '@/pages/campaigns/[slug]';
import { CMSFriendsProps } from '@/pages/data/friend/[slug]';

type CMSGoogleAlertType = {
	blok: {
		review_author?: string;
		review_quote?: any;
		stars: number;
	};
	props?: CampaignProps | CMSFriendsProps;
};

/**
 * Coral wraps this with a `p` or `span` for this as a styled element `Review.Quote`.
 * This creates issues for our use as a CMS inner content, which may contain `div` elements.
 *
 * Taken from: https://github.com/octopus-energy/coral/blob/main/packages/ui/src/atoms/Review/Review.styled.ts#L21-L41
 */
const StyledReviewLine = styled.div`
	display: flex;
	gap: ${({ theme: { spacing } }) => spacing.sm};

	${({ theme: { color, remSize } }) => css`
		&:before {
			content: '';
			display: inline-block;
			width: ${rem(3, remSize)};
			border-radius: ${rem(5, remSize)};
			background-color: ${color.primary.main};
			flex-shrink: 0;
		}
	`}

	${({
		theme: {
			overrides: { review },
		},
	}) => review && review.root && css(review.root as CSSObject)}
`;

export const CMSGoogleReview: FC<CMSGoogleAlertType> = ({
	blok: { review_author = '', review_quote, stars = 5 },
	props,
}) => {
	return (
		<div className="mx-auto w-full max-w-3xl px-2 md:px-4">
			<Review>
				<StyledReviewLine>
					<div className="m-0 text-lg text-ice">
						<div className="mx-auto flex w-full flex-col">
							{render(review_quote, {
								...RICH_TEXT_CONSTANTS(props),
								nodeResolvers: CMS_GOOGLE_REVIEW_RESOLVERS,
							})}
						</div>
					</div>
				</StyledReviewLine>
				<Review.Author stars={stars}>{review_author}</Review.Author>
			</Review>
		</div>
	);
};
