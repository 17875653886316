import { FC } from 'react';

import { CMSFriendsProps } from '@/pages/data/friend/[slug]';

export type ReferredRewardAmountProps = {
	blok: {
		text_bold?: boolean;
		text_size?: 'text-base' | 'text-lg' | 'text-xl' | 'text-2xl';
	};
	props?: CMSFriendsProps;
};

const ReferredRewardAmount: FC<ReferredRewardAmountProps> = ({
	blok: { text_size = 'text-base', text_bold = false },
	props,
}) => {
	return (
		<span
			className={`relative inline-block ${text_size} ${
				text_bold ? 'font-bold' : ''
			}`}
		>
			{props?.referredRewardAmount}
		</span>
	);
};

export { ReferredRewardAmount };
