import { createContext, useContext } from 'react';

import { noop } from '@/utils/noop';

export type AnalyticsReadyContext = {
	isAnalyticsReady: boolean;
	setIsAnalyticsReady: (value: boolean) => void;
};

export const AnalyticsContext = createContext<AnalyticsReadyContext>({
	isAnalyticsReady: false,
	setIsAnalyticsReady: noop,
});

export const useAnalyticsContext = (): AnalyticsReadyContext =>
	useContext(AnalyticsContext);
